import * as React from "react";
import * as Redux from "react-redux";
import type {SerializedStyles} from "@emotion/react";
import {trackGlobalEvent} from "@atg-global-shared/analytics";
import * as Analytics from "@atg-shared/analytics";
import {ButtonBase} from "atg-ui-components";
import * as AuthSelectors from "@atg-shared/auth/domain/authSelectors";
import ClickAwayListener from "@atg-ui/toolkit/components/ClickAwayListener";
import * as styles from "./MenuController.styles";
import {FadeInTransition, FadeStatusTransition} from "./MenuTransitions";
import {SideMenuIcon} from "./SideMenuButton";
import Notification from "./Notification";

type Props = {
    index: string;
    icon?: "menu" | "ticket" | "user";
    title: string;
    children?: React.ReactNode;
    isDropdownMenuOpen: boolean;
    style?: SerializedStyles;
    openDropdownMenu: (index: string) => void;
    closeDropdownMenu: () => void;
    dataTestId?: string;
};

function DropdownMenu({
    index,
    icon,
    title,
    children,
    isDropdownMenuOpen,
    style,
    openDropdownMenu,
    closeDropdownMenu,
    dataTestId,
}: Props) {
    const isNormalLogin = Redux.useSelector(AuthSelectors.isNormalLogin);

    const toggleMenu = () => {
        if (isDropdownMenuOpen) {
            if (!isNormalLogin) {
                trackGlobalEvent({
                    event: "accountMenuSessionTimeout",
                    action: "dismiss",
                });
            }
            closeDropdownMenu();
        } else {
            openDropdownMenu(index);
        }

        Analytics.deprecated_logEvent({
            newTopMenuLinkClickEvent: `newTopMenuLinkClick-${title}`,
        });
    };

    const handleClickOutside = () => {
        if (isDropdownMenuOpen) {
            closeDropdownMenu();

            if (!isNormalLogin) {
                trackGlobalEvent({
                    event: "accountMenuSessionTimeout",
                    action: "dismiss",
                });
            }
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClickOutside}>
            <div css={styles.headerMenu}>
                <FadeStatusTransition isIn={isDropdownMenuOpen}>
                    {(animationState) => (
                        <ButtonBase
                            style={[
                                styles.title({
                                    open:
                                        animationState === "entered" ||
                                        animationState === "exiting",
                                }),
                                style,
                            ]}
                            data-test-id={dataTestId || "dropdown-menu-title"}
                            onClick={toggleMenu}
                            id={`${index}_button`}
                            aria-haspopup="true"
                            aria-controls={index}
                            aria-expanded={isDropdownMenuOpen}
                        >
                            {title}
                            <SideMenuIcon icon={icon} />
                            {icon === "user" && <Notification />}
                        </ButtonBase>
                    )}
                </FadeStatusTransition>

                <FadeInTransition isIn={isDropdownMenuOpen}>
                    <div
                        id={index}
                        data-test-id="account-drop-down-menu"
                        css={styles.dropdownMenu}
                        data-test="test"
                        role="menu"
                        aria-labelledby={`${index}_button`}
                    >
                        {children}
                    </div>
                </FadeInTransition>
            </div>
        </ClickAwayListener>
    );
}

export default DropdownMenu;
