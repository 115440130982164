import {isString} from "lodash";
import * as React from "react";
import * as Analytics from "@atg-shared/analytics";
// @ts-expect-error
import SwipeTransition from "atg-transitions/components/SwipeTransition";
import {
    FadeInTransition,
    SlideInLeftTransition,
    SlideInRightTransition,
} from "./MenuTransitions";
import {type Align} from "./MenuController";
import * as styles from "./MenuController.styles";
import Navigation from "./Navigation";

type Props = {
    index: string;
    align?: Align;
    children?: React.ReactNode;
    isSideMenuOpen: boolean;
    openSideMenu: (index: string) => void;
    closeSideMenu: () => void;
    isSmallScreen: boolean;
};

function OverlaySideMenu({
    index,
    align = "left",
    isSideMenuOpen,
    openSideMenu,
    closeSideMenu,
    children,
    isSmallScreen,
}: Props) {
    const toggleMenu = React.useCallback(
        (animationState) => {
            const eventValue = isSideMenuOpen ? "hide" : "show";
            const menuName = align === "left" ? "left-menu" : "account-menu";

            if (animationState === "entered") {
                closeSideMenu();
                Analytics.deprecated_logEvent({
                    overlayMenuClickEvent: `overlayMenuClick-${menuName}_hide`,
                });
            }

            if (!isString(animationState)) {
                if (isSideMenuOpen) closeSideMenu();
                else openSideMenu(index);

                Analytics.deprecated_logEvent({
                    overlayMenuClickEvent: `overlayMenuClick-${menuName}_${eventValue}`,
                });
            }
        },
        [align, closeSideMenu, index, isSideMenuOpen, openSideMenu],
    );

    const handleClick = (animationState: string) => {
        toggleMenu(animationState);
    };

    const handleClose = () => {
        if (isSideMenuOpen) {
            toggleMenu(undefined);
        }
    };

    React.useEffect(() => {
        // open menu from anywhere with Shift + F5 key
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.shiftKey && event.code === "F5") {
                toggleMenu(undefined);
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [toggleMenu]);

    return (
        <>
            {align === "left" ? (
                <SlideInLeftTransition
                    isIn={isSideMenuOpen}
                    isSmallScreen={isSmallScreen}
                >
                    {(animationState: string) => (
                        <SwipeTransition
                            direction="left"
                            onSwipeFinished={closeSideMenu}
                            id={index} // used for accessibiltiy
                            role="dialog"
                            aria-labelledby={`${index}_button`}
                            aria-modal="true"
                        >
                            {animationState !== "entered" && (
                                <div aria-hidden="true" css={styles.clickBlocker} />
                            )}
                            {/* @ts-expect-error suppressed error during flow to TS migration */}
                            <Navigation onClose={handleClose} onToggle={toggleMenu}>
                                {children}
                            </Navigation>
                        </SwipeTransition>
                    )}
                </SlideInLeftTransition>
            ) : (
                <SlideInRightTransition
                    isIn={isSideMenuOpen}
                    isSmallScreen={isSmallScreen}
                >
                    {(animationState: string) => (
                        <SwipeTransition
                            direction="right"
                            onSwipeFinished={closeSideMenu}
                            id={index} // used for accessibiltiy
                            role="dialog"
                            aria-labelledby={`${index}_button`}
                            aria-modal="true"
                        >
                            {animationState !== "entered" && (
                                <div aria-hidden="true" css={styles.clickBlocker} />
                            )}
                            {/* @ts-expect-error suppressed error during flow to TS migration */}
                            <Navigation onClose={handleClose} onToggle={toggleMenu}>
                                {children}
                            </Navigation>
                        </SwipeTransition>
                    )}
                </SlideInRightTransition>
            )}

            <FadeInTransition isIn={isSideMenuOpen}>
                {(animationState: string) => (
                    <div
                        aria-hidden="true"
                        data-test-id="overlay-menu-backdrop"
                        css={styles.backdrop}
                        onClick={() => handleClick(animationState)}
                        onTouchEnd={() => {
                            toggleMenu({animationState});
                        }}
                    />
                )}
            </FadeInTransition>
        </>
    );
}

export default OverlaySideMenu;
