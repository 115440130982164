import {takeLatest, call, fork, take, select} from "redux-saga/effects";
import {trackGlobalEvent} from "@atg-global-shared/analytics";
import type {MemberSegmentation} from "./types";
import {getMemberSegmentationData} from "./getMemberSegmentationData";
import {formatToGTM} from "./tracking";
import {clearMemberSegmentationCache} from "./clearMemberSegmentationCache";
import {AuthActions, AuthSelectors} from "@atg-shared/auth";

export function* trackUserSegmentation() {
    // Action dispatched for every state: logged out, logged in, long time logged in
    yield take(AuthActions.AUTH_CHECK_RESPONSE);

    const isLoggedIn: boolean = yield select(AuthSelectors.isLoggedIn);

    if (!isLoggedIn) {
        yield take(AuthActions.LOGIN_FINISHED);
    }

    const segmentationData: MemberSegmentation = yield call(getMemberSegmentationData);

    trackGlobalEvent({
        event: "memberSegmentation",
        segmentation: formatToGTM(segmentationData),
    });
}

export function* memberSegmentationSaga() {
    yield fork(trackUserSegmentation);

    yield takeLatest(AuthActions.LOGOUT_FINISHED, function* clearCache() {
        yield call(clearMemberSegmentationCache);
    });
}
