const pink = {
    50: "#FFE5F4",
    100: "#FFB2DF",
    200: "#FF66C0",
    300: "#FF33AB",
    400: "#F90695",
    500: "#E50087",
    600: "#CC0078",
    700: "#B20069",
    800: "#99005A",
    900: "#80004B",
};

export default pink;
