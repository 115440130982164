import * as Storage from "@atg-shared/storage";

import type {FlowType} from "./memberFlowType";
import {MODAL_FLOW_TYPE} from "./memberFlowType";

export const isModalFlow = (flowType: FlowType): boolean =>
    flowType.type === MODAL_FLOW_TYPE;

const STORAGE_KEY = "member_hasPreviouslyLoggedIn";

export const getPreviouslyLoggedIn = () => {
    const credentials = Storage.getItem(STORAGE_KEY);
    return Boolean(credentials);
};

export const setPreviouslyLoggedIn = () => {
    Storage.setItem(STORAGE_KEY, "true");
};
