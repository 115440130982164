import MuiTypography from "@mui/material/Typography";
import type {
    TypographyProps as MuiTypographyProps,
    TypographyTypeMap,
} from "@mui/material/Typography";
import type {OverridableStringUnion} from "@mui/types";
import type {OverridableComponent} from "@mui/material/OverridableComponent";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MoreTypographyPropsVariantOverrides {}

export type Variant =
    /**
     * @deprecated
     */
    | "h1Brand"
    /**
     * @deprecated
     */
    | "h2Brand"
    /**
     * @deprecated
     */
    | "h3Brand"
    /**
     * @deprecated
     */
    | "h4Brand"
    /**
     * @deprecated
     */
    | "h1"
    /**
     * @deprecated
     */
    | "h2"
    /**
     * @deprecated
     */
    | "h3"
    /**
     * @deprecated
     */
    | "h4"
    /**
     * @deprecated
     */
    | "h5"
    /**
     * @deprecated
     */
    | "h6"
    /**
     * @deprecated
     */
    | "subtitle1"
    /**
     * @deprecated
     */
    | "subtitle2"
    /**
     * @deprecated
     */
    | "subtitle3"
    | "body1"
    | "body2"
    | "body3"
    | "caption"
    /**
     * @deprecated
     */
    | "overline"
    | "button"
    | "rubrik1"
    | "rubrik2"
    | "rubrik3"
    | "rubrik4"
    | "title1"
    | "title2"
    | "title3"
    | "title4"
    | "title5"
    | "title6";

export interface VariantMapping {
    /**
     * @deprecated
     */
    h1Brand: string;
    /**
     * @deprecated
     */
    h2Brand: string;
    /**
     * @deprecated
     */
    h3Brand: string;
    /**
     * @deprecated
     */
    h4Brand: string;
    /**
     * @deprecated
     */
    h1: string;
    /**
     * @deprecated
     */
    h2: string;
    /**
     * @deprecated
     */
    h3: string;
    /**
     * @deprecated
     */
    h4: string;
    /**
     * @deprecated
     */
    h5: string;
    /**
     * @deprecated
     */
    h6: string;
    /**
     * @deprecated
     */
    subtitle1: string;
    /**
     * @deprecated
     */
    subtitle2: string;
    /**
     * @deprecated
     */
    subtitle3: string;
    body1: string;
    body2: string;
    body3: string;
    caption: string;
    /**
     * @deprecated
     */
    overline: string;
    button: string;
    rubrik1: string;
    rubrik2: string;
    rubrik3: string;
    rubrik4: string;
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    title5: string;
    title6: string;
}

/**
 * These overrides are necessary so the documentation in storybook & the VScode
 * tooltip (when hovering props) is correct
 */
export interface PropsOverride {
    /**
     * Applies the theme typography styles.
     *
     * NOTE: The variants differ between classic Toolkit theme and Next.
     *
     * @variant [Next]
     * available variants:
     * "body1" | "body2" | "body3" | "body3" | "display1" | "display2" | "display3" | "display4" | "display5" |  "title1" | "title2" | "title3" | "title4" | "title5" | "title6" | "label1" | "label2" | "label3" "editorialPreamble" | "editorialBody"
     *
     * deprecated variants:
     * "h1Brand" | "h2Brand" | "h3Brand" | "h4Brand" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "subtitle3" | "overline" | "rubrik1" | "rubrik2" | "rubrik3" | "rubrik4" | "caption" | "button"
     *
     * @default body3
     *
     * ---
     *
     * @variant [Classic toolkit]
     * available variants:
     * "body1" | "body2" | "body3" | "caption" | "button" | "rubrik1" | "rubrik2" | "rubrik3" | "rubrik4" | "title1" | "title2" | "title3" | "title4" | "title5" | "title6"
     *
     * deprecated variants:
     * "h1Brand" | "h2Brand" | "h3Brand" | "h4Brand" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "subtitle3" | "overline"
     *
     * @default body1
     */
    variant?: OverridableStringUnion<
        Variant | "inherit",
        MoreTypographyPropsVariantOverrides
    >;
    /**
     * The component maps the variant prop to a range of different HTML element types.
     * For instance, `h3Brand` to `<h3>`.
     * If you wish to change that mapping, you can provide your own.
     * Alternatively, you can use the `component` prop.
     *
     * @default {
     *   h1Brand: "h1",
     *   h2Brand: "h2",
     *   h3Brand: "h3",
     *   h1: "h1",
     *   h2: "h2",
     *   h3: "h3",
     *   h4: "h4",
     *   h5: "h5",
     *   h6: "h6",
     *   subtitle1: "h6",
     *   subtitle2: "h6",
     *   subtitle3: "h6",
     *   body1: "p",
     *   body2: "p",
     *   body3: "p",
     *   inherit: "p",
     *   rubrik1: "h1",
     *   rubrik2: "h2",
     *   rubrik3: "h3",
     *   rubrik4: "h4",
     *   title1: "h1",
     *   title2: "h2",
     *   title3: "h3",
     *   title4: "h4",
     *   title5: "h5",
     *   title6: "h6",
     * }
     */
    variantMapping?: Partial<
        Record<
            OverridableStringUnion<
                Variant | "inherit",
                MoreTypographyPropsVariantOverrides
            >,
            string
        >
    >;
    // variantMapping?: Partial<
    //     Record<
    //         OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides>,
    //         string
    //     >
    // >;
}

export type TypographyProps = Omit<MuiTypographyProps, keyof PropsOverride> &
    PropsOverride;

/**
 * An extension of the [Typography component from @mui/material](https://mui.com/api/typography/)
 *
 * Use typography to present your design and content as clearly and efficiently as possible
 *
 * MUI Docs:
 * Demos:
 *
 * - [Breadcrumbs](https://mui.com/components/breadcrumbs/)
 * - [Typography](https://mui.com/components/typography/)
 *
 * API:
 *
 * - [Typography API](https://mui.com/api/typography/)
 */
export const Typography: OverridableComponent<{
    props: TypographyProps;
    defaultComponent: TypographyTypeMap["defaultComponent"];
}> = MuiTypography;

export type {TypographyTypeMap};

export default Typography;
