import * as React from "react";
import * as Redux from "react-redux";
import type {SerializedStyles} from "@emotion/react";
import * as Analytics from "@atg-shared/analytics";
import Features, {newVerticalNavbar} from "@atg-shared/client-features";
import {
    MainNavbarActions,
    MainNavbarSelectors,
} from "@atg-global-shared/main-navbar-data-access";

import {
    User as UserIcon,
    Menu as MenuIcon,
    Ticket as TicketIcon,
} from "atg-ui-components/icons";
import {ButtonBase, Typography} from "atg-ui-components";
import {isSmallScreen as isSmallScreenSelector} from "atg-match-media/domain/matchMediaSelectors";
import {MENU_PLAY} from "atg-main-menu/constants/mainMenuActionTypes";
import * as MainMenuActions from "../../domain/mainMenuActions";
import * as MainMenuSelectors from "../../domain/mainMenuSelectors";
import type {GlobalState} from "../../domain/mainMenuSelectors";
import Notification from "../Notification";
import * as styles from "./SideMenuButton.styles";

export type Props = {
    index: string;
    dataTestId?: string;
    icon?: "menu" | "ticket" | "user";
    title?: string;
    style?: SerializedStyles;
    titleClassname?: string;
    menuName: string;
    clickEvent: string;
};

type MenuIconProps = {
    icon?: "menu" | "ticket" | "user";
};

export function SideMenuIcon({icon}: MenuIconProps) {
    const icons = {
        menu: <MenuIcon testId="side-menu-button-icon" style={styles.leftMenuIcon} />,
        ticket: (
            <TicketIcon
                testId="side-menu-button-icon"
                size="inherit"
                style={styles.dropdownMenuIcon}
            />
        ),
        user: (
            <UserIcon
                testId="side-menu-button-icon"
                size="inherit"
                style={styles.dropdownMenuIcon}
            />
        ),
    };
    if (!icon || !icons[icon]) {
        return null;
    }

    const iconComponent = icons[icon];

    return iconComponent;
}

function SideMenuButton({
    index,
    icon,
    title,
    dataTestId,
    style,
    clickEvent,
    menuName,
}: Props) {
    const dispatch = Redux.useDispatch();

    const isSideMenuOpen = Redux.useSelector(
        (state: GlobalState) => MainMenuSelectors.getSideMenu(state) === index,
    );

    const toggleMenu = () => {
        if (isSideMenuOpen) {
            dispatch(MainMenuActions.closeSideMenu());
        } else {
            dispatch(MainMenuActions.openSideMenu(index));
        }
        const eventValue = isSideMenuOpen ? "hide" : "show";
        Analytics.deprecated_logEvent({
            [`${clickEvent}_event`]: `${clickEvent}-${menuName}_${eventValue}`,
        });
    };

    const isNavbarSubmenuOpen = Redux.useSelector(
        MainNavbarSelectors.getSubmenuOpenState,
    );

    const toggleNavbar = () => {
        if (isNavbarSubmenuOpen) {
            dispatch(MainNavbarActions.closeSubmenu());
        } else {
            dispatch(MainNavbarActions.openSubmenu());
        }
        const eventValue = isSideMenuOpen ? "hide" : "show";
        Analytics.deprecated_logEvent({
            [`${clickEvent}_event`]: `${clickEvent}-${menuName}_${eventValue}`,
        });
    };

    const newNavEnabled = Features.isEnabled(newVerticalNavbar);
    const isSmallScreen = Redux.useSelector(isSmallScreenSelector);

    return (
        <ButtonBase
            style={[styles.menuButton, style]}
            onClick={newNavEnabled && index === MENU_PLAY ? toggleNavbar : toggleMenu} // need to check feature and index to not have it trigger on bets and account menu
            data-test-id={dataTestId}
            id={`${index}_button`}
            aria-haspopup="true"
            aria-controls={index}
            aria-expanded={isSideMenuOpen}
        >
            <SideMenuIcon icon={icon} />

            {icon === "user" && <Notification style={styles.notification} />}

            {title && (
                <Typography
                    component="span"
                    variant={isSmallScreen ? "body3" : "body2"}
                    mode="dark"
                    data-di-mask
                >
                    {title}
                </Typography>
            )}
        </ButtonBase>
    );
}

export default SideMenuButton;
