import * as React from "react";
import * as Redux from "react-redux";
import * as AuthSelectors from "@atg-shared/auth/domain/authSelectors";
import {InboxActions, InboxSelectors} from "@atg-aml-shared/inbox-domain";

const NotificationsContext = React.createContext<
    | {
          hasUnanswered?: boolean;
          numberOfUnread?: number | null;
      }
    | undefined
>(undefined);

const loadInboxChunk = async () =>
    (
        await import(
            /* webpackChunkName: "inboxChunk" */
            "@atg-aml-shared/inbox-feature/chunks/inboxChunk"
        )
    ).default;

export const useNotificationState = () => {
    const notificationState = React.useContext(NotificationsContext);
    if (!notificationState) {
        throw new Error(
            "useNotificationState hook must be used inside notifications context. Did you forget to wrap your component with NotificationsProvider?",
        );
    }

    const {hasUnanswered, numberOfUnread} = notificationState;

    return {
        hasUnansweredMessages: hasUnanswered,
        numberOfUnreadMessages: numberOfUnread,
    };
};

type Props = {
    children: React.ReactNode;
};

function NotificationsProvider({children}: Props) {
    const dispatch = Redux.useDispatch();
    const store = Redux.useStore();

    const isNormalLogin = Redux.useSelector(AuthSelectors.isNormalLogin);

    const {numberOfUnread: nrOfUnreadInboxMessages, hasUnanswered} = Redux.useSelector(
        InboxSelectors.getMessageStatus,
    );

    const numberOfUnread = nrOfUnreadInboxMessages;

    React.useEffect(() => {
        if (isNormalLogin) {
            loadInboxChunk().then((inboxChunk) => {
                // @ts-expect-error the store type from useStore() does not match the LazyStore type used in inboxChunk
                inboxChunk(store);
                // load inbox chunk before fetching notifications
                // this prevents race-condition where data might be fetched before the inboxReducer has been loaded and injected on the Redux store
                dispatch(InboxActions.fetchInboxMessageStatus());
            });
        }
    }, [dispatch, isNormalLogin, store]);

    const value = React.useMemo(
        () => ({hasUnanswered, numberOfUnread}),
        [hasUnanswered, numberOfUnread],
    );

    return (
        <NotificationsContext.Provider value={value}>
            {children}
        </NotificationsContext.Provider>
    );
}

export default NotificationsProvider;
