import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import type {PersistPartial} from "redux-persist/lib/persistReducer";
import {omit} from "lodash/fp";
import type {AlertPropsType} from "@atg-global-shared/alerts-types";
import {CLOSE_ALERT, GAME_ALERT} from "./alertActions";
import type {AlertActions} from "./alertActions";

export type CloseState = {
    [id: string]: boolean;
};

export type LocalState = {
    [id: string]: AlertPropsType;
};

export type AlertState = {
    close: CloseState & PersistPartial;
    local: LocalState;
};

export const close = (state: CloseState = {}, action: AlertActions): CloseState => {
    switch (action.type) {
        case CLOSE_ALERT:
            return {
                ...state,
                [action.payload.id]: true,
            };
        case GAME_ALERT:
            return {
                ...state,
                [action.payload.alert.id]: false,
            };
        default:
            return state;
    }
};

export const local = (state: LocalState = {}, action: AlertActions): LocalState => {
    switch (action.type) {
        case GAME_ALERT:
            return {
                ...state,
                [action.payload.alert.id]: action.payload.alert,
            };
        case CLOSE_ALERT:
            return omit([action.payload.id], state);
        default:
            return state;
    }
};

const closePersistConfig = {
    storage,
    key: "close",
    version: 0,
};

const alert = combineReducers<AlertState, AlertActions>({
    close: persistReducer(closePersistConfig, close),
    local,
});

export default alert;
