import * as React from "react";
import * as Redux from "react-redux";
import {t} from "@lingui/macro";
import {Box} from "@atg-ui/toolkit";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import {Link} from "react-router-dom";
import {VerticalsActions, useVerticals} from "@atg-global-shared/verticals";
import {noPrint} from "@atg/utils/print";
import * as Analytics from "@atg-shared/analytics";
import {useNavbarBreakpoints} from "@atg-global-shared/main-navbar-breakpoints";
import features, {newHeaderMenu} from "@atg-shared/client-features";
import {useBreakpoint} from "atg-breakpoints";
import titlelogo from "atg-logos/images/atgicon.svg";
import useIsNewUser from "../hooks/useIsNewUser";
import CreateAccount from "./CreateAccount";
import {Header, Nav, HeaderBox, Logo} from "./MainHeader.styles";
import PlayMenu from "./PlayMenu";

type HeaderProps = {
    renderRightMenu?: () => React.ReactNode;
};

function MainHeader({renderRightMenu}: HeaderProps) {
    // this also cover touch devices
    const {isMobile} = useNavbarBreakpoints();
    const isBelowMd = !useBreakpoint("MD");
    const user = Redux.useSelector(UserSelectors.getUser);
    const dispatch = Redux.useDispatch();
    const vertical = useVerticals();
    const isNewHeaderMenuEnabled = features.isEnabled(newHeaderMenu);
    const isNewUser = useIsNewUser();

    let path;
    if (vertical === "horse") {
        path = "/";
    } else if (vertical === "shops") {
        path = "/andelar";
    } else {
        path = `/${vertical}`;
    }

    const onHomeMenuClickHandler = () => {
        dispatch(VerticalsActions.unlockContextAction());
        Analytics.deprecated_logEvent({
            logoClickEvent: "logoClick-redirect_to_start_page",
        });
    };

    return (
        <Header css={noPrint}>
            <HeaderBox>
                <div>{isMobile && !isNewHeaderMenuEnabled && <PlayMenu />}</div>
                <Logo>
                    <Link
                        to={path}
                        onClick={onHomeMenuClickHandler}
                        aria-label={t`till start`}
                    >
                        <img aria-hidden="true" src={titlelogo} alt="" />
                    </Link>
                </Logo>
                <Nav>{renderRightMenu && renderRightMenu()}</Nav>
            </HeaderBox>

            {isBelowMd && !user.username && isNewUser && (
                <Box padding={1.5}>
                    <CreateAccount />
                </Box>
            )}
        </Header>
    );
}

export default MainHeader;
