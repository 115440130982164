import * as React from "react";
import type {Theme, Components} from "..";
import CheckboxCheckedIcon from "../../icons/CheckboxCheckedFilled";
import CheckboxBlankIcon from "../../icons/CheckboxBlankOutlined";
import CheckboxIndeterminateIcon from "../../icons/CheckboxIndeterminateFilled";
import Grow from "../../components/Zoom";

type CheckboxOverrides = Components<Theme>["MuiCheckbox"];

function DefaultIcon({
    checked = false,
    indeterminate = false,
}: {
    checked?: boolean;
    indeterminate?: boolean;
}) {
    return (
        <>
            <CheckboxBlankIcon />
            <Grow in={checked && !indeterminate} style={{transitionDuration: "150ms"}}>
                <CheckboxCheckedIcon sx={{position: "absolute"}} />
            </Grow>

            <Grow in={indeterminate} style={{transitionDuration: "150ms"}}>
                <CheckboxIndeterminateIcon sx={{position: "absolute"}} />
            </Grow>
        </>
    );
}

const overrides: CheckboxOverrides = {
    defaultProps: {
        checkedIcon: <DefaultIcon checked />,
        icon: <DefaultIcon />,
        indeterminateIcon: <DefaultIcon indeterminate />,
    },
};

export default overrides;
