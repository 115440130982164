import root from "window-or-global";
import {each, compact} from "lodash/fp";
import {Paths} from "@atg-tillsammans-shared/navigation";

type Query = {
    [key: string]: string | null;
};
// @ts-expect-error this is not typed by lodash
const eachWithIndex = each.convert({cap: false});
export const generatePath = (path: string, queryParams: Query): string => {
    const queryString = compact(
        Object.keys(queryParams).map((key) =>
            queryParams[key] ? `${key}=${queryParams[key]}` : null,
        ),
    ).join("&");
    return queryString ? `${path}?${queryString}` : path;
};

export const toQueryString = (queryParams: Query) => {
    let result = "";
    let counter = 0;

    eachWithIndex((value: string, key: string) => {
        if (counter++ > 0) result += "&";
        result += `${key}=${value}`;
    }, queryParams);

    return result;
};

export const extractDomain = (url: string) => {
    let domain = url.indexOf("://") > -1 ? url.split("/")[2] : url.split("/")[0];
    domain = domain.split(":")[0];
    return domain;
};

export const appendPath = (url: string | undefined, path: string) =>
    url ? url + (url.endsWith("/") ? path.substring(1) : path) : path;

/*
 * Function returns true if url param matches a url on the atg.se domain or is a local url.
 */
export const isTrustedUrl = (url: string) =>
    /^(((http([s]){0,1}:\/\/)|(\/\/)){0,1}(localhost|127\.0\.0\.1|[a-zA-Z0-9-.]*\.atg\.se){1}(([:]){0,1}[\0-9]{2,4}){0,1}(\/|\/.*){0,1}){1}$/.test(
        url,
    );

export const getTillsammansUrl = (): string => Paths.getTillsammansHomePath();

export const trimTrailingSlash = (url?: string | null): string => {
    const resultUrl = url ?? "";
    return resultUrl.slice(-1) === "/" ? resultUrl.slice(0, -1) : resultUrl;
};
