import {FETCH, call} from "@atg-shared/fetch-redux";
import type {FetchAction} from "@atg-shared/fetch-types";
import {frameAction} from "atg-store-addons";
import * as twoFactorApi from "./twoFactorApi";

export const GET_TWO_FACTOR_LOGIN_SETTING_REQUEST =
    "GET_TWO_FACTOR_LOGIN_SETTING_REQUEST";
export const GET_TWO_FACTOR_LOGIN_SETTING_RESPONSE =
    "GET_TWO_FACTOR_LOGIN_SETTING_RESPONSE";

export const TWO_FACTOR_ACTIVATION = "TWO_FACTOR_ACTIVATION";
export const TWO_FACTOR_CONFIRMATION_RESPONSE = "TWO_FACTOR_CONFIRMATION_RESPONSE";
export const TWO_FACTOR_ACTIVATION_INIT = "TWO_FACTOR_ACTIVATION_INIT";
export const TWO_FACTOR_ACTIVATION_RESPONSE = "TWO_FACTOR_ACTIVATION_RESPONSE";
export const TWO_FACTOR_ACTIVATION_CLEAR = "TWO_FACTOR_ACTIVATION_CLEAR";

export const TWO_FACTOR_DEACTIVATION = "TWO_FACTOR_DEACTIVATION";
export const TWO_FACTOR_DEACTIVATION_RESPONSE = "TWO_FACTOR_DEACTIVATION_RESPONSE";
export const TWO_FACTOR_DEACTIVATION_INIT = "TWO_FACTOR_DEACTIVATION_INIT";
export const TWO_FACTOR_DEACTIVATION_CLEAR = "TWO_FACTOR_DEACTIVATION_CLEAR";

export const TWO_FACTOR_CONFIRMATION = "TWO_FACTOR_CONFIRMATION";
export const TWO_FACTOR_CONFIRMATION_CLEAR = "TWO_FACTOR_CONFIRMATION_CLEAR";
export const TWO_FACTOR_CONFIRMATION_CANCEL = "TWO_FACTOR_CONFIRMATION_CANCEL";

export type ConfirmationResponse = {
    status: string;
    roles: any;
    user: any;
};

type SuccessResponseAction<T> = {
    type: T;
    error: false;
    payload: {
        loginData: ConfirmationResponse;
    };
};

type ErrorResponseAction<T> = {
    type: T;
    error: true;
    payload: {
        message: string;
        error: {
            [key: string]: any;
        };
    };
};

type GetTwoFactorLoginSettingAction = FetchAction<
    typeof GET_TWO_FACTOR_LOGIN_SETTING_REQUEST,
    typeof GET_TWO_FACTOR_LOGIN_SETTING_RESPONSE,
    twoFactorApi.MemberSettingsResponse
>;

export type TwoFactorConfirmationErrorResponseAction = ErrorResponseAction<
    typeof TWO_FACTOR_CONFIRMATION_RESPONSE
>;
export type TwoFactorConfirmationSuccessResponseAction = SuccessResponseAction<
    typeof TWO_FACTOR_CONFIRMATION_RESPONSE
>;
export type TwoFactorActivationErrorResponseAction = ErrorResponseAction<
    typeof TWO_FACTOR_ACTIVATION_RESPONSE
>;
export type TwoFactorActivationSuccessResponseAction = SuccessResponseAction<
    typeof TWO_FACTOR_ACTIVATION_RESPONSE
>;
export type TwoFactorDeactivationErrorResponseAction = ErrorResponseAction<
    typeof TWO_FACTOR_DEACTIVATION_RESPONSE
>;
export type TwoFactorDeactivationSuccessResponseAction = SuccessResponseAction<
    typeof TWO_FACTOR_DEACTIVATION_RESPONSE
>;

export type TwoFactorConfirmationAction = {
    type: typeof TWO_FACTOR_CONFIRMATION;
    params: {
        [key: string]: any;
    };
};

export type TwoFactorActivationAction = {
    type: typeof TWO_FACTOR_ACTIVATION;
    params: {
        [key: string]: any;
    };
};

export type TwoFactorDeactivationAction = {
    type: typeof TWO_FACTOR_DEACTIVATION;
    params: {
        [key: string]: any;
    };
};

export type TwoFactorConfirmationClearAction = {
    type: typeof TWO_FACTOR_CONFIRMATION_CLEAR;
};

export type TwoFactorActivationClearAction = {
    type: typeof TWO_FACTOR_ACTIVATION_CLEAR;
};

export type TwoFactorDeactivationClearAction = {
    type: typeof TWO_FACTOR_DEACTIVATION_CLEAR;
};

export type TwoFactorAction =
    | TwoFactorConfirmationAction
    | TwoFactorConfirmationErrorResponseAction
    | TwoFactorConfirmationSuccessResponseAction
    | TwoFactorConfirmationClearAction
    | TwoFactorActivationAction
    | TwoFactorActivationErrorResponseAction
    | TwoFactorActivationSuccessResponseAction
    | TwoFactorActivationClearAction
    | TwoFactorDeactivationAction
    | TwoFactorDeactivationErrorResponseAction
    | TwoFactorDeactivationSuccessResponseAction
    | TwoFactorDeactivationClearAction
    | GetTwoFactorLoginSettingAction;

const createErrorResponseAction =
    <T extends TwoFactorAction["type"]>(
        type: T,
    ): ((
        message: string,
        error: {
            [key: string]: any;
        },
    ) => ErrorResponseAction<any>) =>
    (message: any, error: any): any => ({
        type,
        error: true,
        payload: {
            error,
            message,
            loginData: {},
        },
    });

const createSuccessResponseAction =
    <T extends TwoFactorAction["type"]>(
        type: T,
    ): ((payload: ConfirmationResponse) => SuccessResponseAction<any>) =>
    (payload: any): any =>
        frameAction({
            type,
            error: false,
            payload: {
                loginData: payload,
            },
        });

export const getTwoFactorLoginSetting = (): GetTwoFactorLoginSettingAction => ({
    type: FETCH,
    payload: {
        requestAction: GET_TWO_FACTOR_LOGIN_SETTING_REQUEST,
        receiveAction: GET_TWO_FACTOR_LOGIN_SETTING_RESPONSE,
        callApi: call(twoFactorApi.fetchTwoFactorConfirmationSetting),
    },
});

export const twoFactorActivationConfirmation = (params: {[key: string]: any}) => ({
    type: TWO_FACTOR_CONFIRMATION,
    params,
});
export const twoFactorLoginConfirmation = (params: {[key: string]: any}) => ({
    type: TWO_FACTOR_CONFIRMATION,
    login: true,
    params,
});
export const twoFactorConfirmationClear = () => ({type: TWO_FACTOR_CONFIRMATION_CLEAR});
export const twoFactorConfirmationSuccess = createSuccessResponseAction(
    TWO_FACTOR_CONFIRMATION_RESPONSE,
);
export const twoFactorConfirmationError = createErrorResponseAction(
    TWO_FACTOR_CONFIRMATION_RESPONSE,
);
export const twoFactorConfirmationCancel = () => ({type: TWO_FACTOR_CONFIRMATION_CANCEL});
export const twoFactorActivationInit = () => ({type: TWO_FACTOR_ACTIVATION_INIT});
export const twoFactorActivation = (params: {[key: string]: any}) => ({
    type: TWO_FACTOR_ACTIVATION,
    params,
});
export const twoFactorActivationSuccess = createSuccessResponseAction(
    TWO_FACTOR_ACTIVATION_RESPONSE,
);
export const twoFactorActivationError = createErrorResponseAction(
    TWO_FACTOR_ACTIVATION_RESPONSE,
);
export const twoFactorActivationClear = () => ({type: TWO_FACTOR_ACTIVATION_CLEAR});
export const twoFactorDeactivationInit = () => ({type: TWO_FACTOR_DEACTIVATION_INIT});
export const twoFactorDeactivation = () => ({
    type: TWO_FACTOR_DEACTIVATION,
});
export const twoFactorDeactivationSuccess = createSuccessResponseAction(
    TWO_FACTOR_DEACTIVATION_RESPONSE,
);
export const twoFactorDeactivationError = createErrorResponseAction(
    TWO_FACTOR_DEACTIVATION_RESPONSE,
);
export const twoFactorDeactivationClear = () => ({type: TWO_FACTOR_DEACTIVATION_CLEAR});

createSuccessResponseAction(TWO_FACTOR_CONFIRMATION_RESPONSE)({
    roles: [],
    status: "ok",
    user: {},
});
