import {install} from "resize-observer";

// This is needed due to the error: ResizeObserver - loop limit exceeded
// This seems to be happening when the function passed to observe is changing
// reference during a resize observation. It is said to be safe to ignore, but
// logs are piling up so a polyfill is added for chrome.
const isChrome =
    typeof window !== "undefined" &&
    !!window.chrome &&
    (!!window.chrome.webstore || !!window.chrome.runtime);

// The ResizeObserver polyfill from resize-observer uses window
// internally when installed, therefore we do not use root from the
// "window-or-global" package.
const hasNoNativeResizeObserver = typeof window !== "undefined" && !window.ResizeObserver;

if (hasNoNativeResizeObserver || isChrome) {
    install();
}
