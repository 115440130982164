import * as React from "react";
import type {EmotionStyle} from "../../utils/emotion";
import * as styles from "./LoadingIndicator.styles";
import * as buttonTheme from "./LoadingIndicator-button.styles";
import * as overlayTheme from "./LoadingIndicator-overlay.styles";
import * as overlayLightTheme from "./LoadingIndicator-overlayLight.styles";

type Theme = typeof styles;

export type Props = {
    theme?: Theme;
    inverted?: boolean;
    style?: EmotionStyle;
};

type CustomLoadingIndicatorProps = {
    theme?: Theme;
    inverted?: boolean;
};

function LoadingIndicator({style, theme = styles, inverted = false}: Props) {
    return (
        <div data-test-id="loading-indicator" css={[theme.root, style]}>
            <div css={inverted ? theme.invertedRect1 : theme.rect1} />
            <div css={inverted ? theme.invertedRect2 : theme.rect2} />
            <div css={inverted ? theme.invertedRect3 : theme.rect3} />
            <div css={inverted ? theme.invertedRect4 : theme.rect4} />
            <div css={inverted ? theme.invertedRect5 : theme.rect5} />
        </div>
    );
}

export function OverlayLoadingIndicator({
    inverted,
    theme = overlayTheme,
}: CustomLoadingIndicatorProps) {
    return (
        <div css={theme.overlay}>
            <LoadingIndicator theme={theme} inverted={inverted} />
        </div>
    );
}

export function ButtonLoadingIndicator({
    theme = buttonTheme,
    inverted = true,
}: CustomLoadingIndicatorProps) {
    return <LoadingIndicator theme={theme} inverted={inverted} />;
}

export function OverlayLightLoadingIndicator() {
    return <OverlayLoadingIndicator theme={overlayLightTheme} />;
}

export default LoadingIndicator;
