import * as React from "react";
import * as Redux from "react-redux";
import {AtgAlertTypes} from "@atg-global-shared/alerts-types";
import {Alert, CloseButton} from "atg-ui";
import {Error, Info, Success} from "atg-ui-components/icons";
import FluidPage from "atg-ui-page/components/FluidPage";
import {type ToastWithId} from "../domain/toast";
import * as ToastActions from "../domain/toastActions";
import * as styles from "./Toasts.styles";

type Props = {
    toast: ToastWithId;
};

const icons = {
    [AtgAlertTypes.DANGER]: <Error size="inherit" style={styles.toastIcon} />,
    [AtgAlertTypes.INFO]: <Info size="inherit" style={styles.toastIcon} />,
    [AtgAlertTypes.SUCCESS]: <Success size="inherit" style={styles.toastIcon} />,
    [AtgAlertTypes.WARNING]: <Error size="inherit" style={styles.toastIcon} />,
};

function Toast({toast}: Props) {
    const dispatch = Redux.useDispatch();

    const icon = icons[toast.type] ?? icons.info;

    const handleClose = () => {
        dispatch(ToastActions.hideToast(toast));
        if (toast.onClose) toast.onClose();
    };

    return (
        <Alert
            type={toast.type}
            spacing="none"
            dataTestId={`toast-${toast.type}`}
            dataTestValue={toast?.reason ? `toast-${toast.reason}` : undefined}
        >
            <FluidPage sm={12} smOffset={0} md={12} mdOffset={0}>
                <span className="alert__icon">{icon}</span>
                {toast.message}
                <CloseButton dataTestId="close-alert-button" onClick={handleClose} />
            </FluidPage>
        </Alert>
    );
}

export default Toast;
