import {forEach, filter, noop, debounce} from "lodash";
import root from "window-or-global";
// @ts-expect-error no ts declaration
import lifecycle from "page-lifecycle";
import log from "@atg-shared/log";

export enum PAGE_STATE {
    PASSIVE = "passive",
    HIDDEN = "hidden",
    ACTIVE = "active",
    FROZEN = "frozen",
}

export type PageStateChangeEvent = Event & {
    newState: PAGE_STATE;
};

const TIMEOUT = 1000;
const options = {
    leading: true,
    trailing: false,
};

const pageVisibilityUtil = () => {
    if (!root.document)
        return {
            onVisible: noop,
            onHidden: noop,
            offVisible: noop,
            offHidden: noop,
        };

    let visibleListeners: Array<EventListener> | null | undefined = [];
    let hiddenListeners: Array<EventListener> | null | undefined = [];

    const runVisibleListeners = debounce(
        (e) => {
            forEach(visibleListeners, (listener) => listener(e));
        },
        TIMEOUT,
        options,
    );

    const runHiddenListeners = debounce(
        (e) => {
            forEach(hiddenListeners, (listener) => listener(e));
        },
        TIMEOUT,
        options,
    );

    try {
        lifecycle.addEventListener(
            "statechange",
            (event: {oldState: PAGE_STATE; newState: PAGE_STATE}) => {
                const {oldState, newState} = event;

                if (
                    (oldState === PAGE_STATE.HIDDEN && newState === PAGE_STATE.PASSIVE) ||
                    (oldState === PAGE_STATE.FROZEN && newState === PAGE_STATE.ACTIVE)
                ) {
                    runVisibleListeners(event);
                } else if (
                    oldState === PAGE_STATE.PASSIVE &&
                    newState === PAGE_STATE.HIDDEN
                ) {
                    runHiddenListeners(event);
                }
            },
        );
    } catch (e: unknown) {
        log.error("Failed attaching page life cycle events");
    }

    return {
        onVisible(listener: EventListener) {
            visibleListeners?.push(listener);
        },

        onHidden(listener: EventListener) {
            hiddenListeners?.push(listener);
        },

        offVisible(listener: EventListener) {
            visibleListeners = filter(
                visibleListeners,
                (visibleListener) => visibleListener !== listener,
            );
        },

        offHidden(listener: EventListener) {
            hiddenListeners = filter(
                hiddenListeners,
                (hiddenListener) => hiddenListener !== listener,
            );
        },
    };
};

export default pageVisibilityUtil();
