import type {UserCredentials, AuthResponse} from "@atg-global-shared/user/user.types";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import {type LoginError, type LoginStepState} from "./loginReducer";
import type {LoginStep} from "./loginSteps";
import type {State} from "./index";

export const getCurrentStep = (state: State): LoginStepState => state.login.loginStep;

export const getCurrentStepId = (state: State): LoginStep | null | undefined =>
    getCurrentStep(state).id || null;

export const getCurrentStepData = (state: State): AuthResponse | null | undefined =>
    getCurrentStep(state).data || null;

export const getResponseUsername = (state: State): string | null => {
    const currentStepData = getCurrentStepData(state);
    if (!currentStepData) return null;
    return currentStepData.username;
};

export const isLoading = (state: State): boolean => state.login.loginLoading;

export const getError = (state: State): LoginError => state.login.loginError;

export const getRememberedUserName = (state: State): string | null | undefined => {
    const user = UserSelectors.getUser(state);
    const {username, rememberUsername} = user;

    if (!rememberUsername) {
        return null;
    }
    if (!username) return null;
    return username;
};

export const getRememberedUserCredentials = (state: State): UserCredentials =>
    state.login.rememberMe;

export const getRememberedLoginUser = (state: State): UserCredentials => {
    const user = UserSelectors.getUser(state);
    const credentials = getRememberedUserCredentials(state);
    return {
        ...user,
        ...credentials,
    };
};

export const shouldRememberUsername = (state: State): boolean =>
    UserSelectors.getRememberUserName(state) ||
    getRememberedUserCredentials(state).rememberUsername;

export const isRememberedUser = (state: State): boolean => {
    const getValidUsername =
        getRememberedUserName(state) !== undefined &&
        getRememberedUserName(state) !== null;

    return shouldRememberUsername(state) && getValidUsername;
};

export const getAuthServerState = (state: State): string => state.login.authServerState;

export const isLoginCancelled = (state: State): boolean => state.login.isLoginCancelled;
