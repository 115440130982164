import * as React from "react";
import * as Redux from "react-redux";
import {type SerializedStyles} from "@emotion/react";

import * as MatchMediaSelectors from "atg-match-media/domain/matchMediaSelectors";

import * as MainMenuActions from "../domain/mainMenuActions";
import * as MainMenuSelectors from "../domain/mainMenuSelectors";
import OverlaySideMenu from "./OverlaySideMenu";
import DropdownMenu from "./DropdownMenu";
import SideMenuButton from "./SideMenuButton";
import * as styles from "./MenuController.styles";

export const OVERLAY_SIDE_MENU = "OVERLAY_SIDE_MENU";
export const SIDE_MENU_PORTAL = "SIDE_MENU_PORTAL";
export const DROPDOWN_MENU = "DROPDOWN_MENU";

export type MenuType =
    | typeof OVERLAY_SIDE_MENU
    | typeof SIDE_MENU_PORTAL
    | typeof DROPDOWN_MENU;

export type Align = "left" | "right";

export type Icon = {
    key: string;
    size: number;
    viewBox: string;
    className?: string;
};

type Props = {
    index: string;
    title: string;
    icon?: "menu" | "ticket" | "user";
    align?: Align;
    style?: SerializedStyles;
    type: MenuType;
    dataTestId?: string;
    children?: React.ReactNode;
};

function MenuController({
    index,
    title,
    icon,
    align,
    style,
    type,
    dataTestId,
    children,
}: Props) {
    const dispatch = Redux.useDispatch();
    const isStickyMenu = Redux.useSelector(MainMenuSelectors.getStickyState);
    const isSideMenuOpen = Redux.useSelector(
        (state: MainMenuSelectors.GlobalState) =>
            MainMenuSelectors.getSideMenu(state) === index,
    );
    const isDropdownMenuOpen = Redux.useSelector(
        (state: MainMenuSelectors.GlobalState) =>
            MainMenuSelectors.getDropdownMenu(state) === index,
    );
    const isSmallScreen = Redux.useSelector(MatchMediaSelectors.isSmallScreen);

    const renderMenu = () => {
        const menuName = align === "right" ? "account-menu" : "left-menu";

        switch (type) {
            case OVERLAY_SIDE_MENU:
                return (
                    <>
                        <SideMenuButton
                            index={index}
                            icon={icon}
                            title={title}
                            style={style}
                            dataTestId={dataTestId || "overlay-side-menu"}
                            menuName={menuName}
                            clickEvent="overlayMenuClick"
                        />
                        <OverlaySideMenu
                            index={index}
                            align={align}
                            isSideMenuOpen={isSideMenuOpen}
                            openSideMenu={(key) =>
                                dispatch(MainMenuActions.openSideMenu(key))
                            }
                            closeSideMenu={() =>
                                dispatch(MainMenuActions.closeSideMenu())
                            }
                            isSmallScreen={isSmallScreen}
                        >
                            {children}
                        </OverlaySideMenu>
                    </>
                );

            case SIDE_MENU_PORTAL:
                return (
                    <SideMenuButton
                        index={index}
                        icon={icon}
                        title={title}
                        style={style}
                        dataTestId={
                            dataTestId || isStickyMenu
                                ? "sticky-side-menu"
                                : "overlay-side-menu"
                        }
                        menuName={menuName}
                        clickEvent={
                            isStickyMenu ? "toggleStickySideMenu" : "overlayMenuClick"
                        }
                    />
                );
            case DROPDOWN_MENU:
                return (
                    <DropdownMenu
                        openDropdownMenu={(key) =>
                            dispatch(MainMenuActions.openDropdownMenu(key))
                        }
                        closeDropdownMenu={() =>
                            dispatch(MainMenuActions.closeDropdownMenu())
                        }
                        isDropdownMenuOpen={isDropdownMenuOpen}
                        index={index}
                        icon={icon}
                        title={title}
                        style={style}
                        dataTestId={dataTestId}
                    >
                        {children}
                    </DropdownMenu>
                );
            default:
                return null;
        }
    };

    return <div css={styles.headerMenu}>{renderMenu()}</div>;
}

export default MenuController;
