const blue = {
    50: "#E5F4FF",
    100: "#B4DEFD",
    200: "#6EBCF7",
    300: "#42A4F0",
    400: "#198DE5",
    500: "#1180D4",
    600: "#2483CC",
    700: "#006DB6",
    800: "#004F9F",
    900: "#1B3481",
};

export default blue;
