import root from "window-or-global";
import Features, {mockRequestsWithMSW} from "@atg-shared/client-features";

/**
 * lazily load the worker and set it up with handlers from local storage
 */
export const initAndSetMswHandlers = async () => {
    if (Features.isEnabled(mockRequestsWithMSW)) {
        try {
            if (!root.mswWorker) {
                // if there is no root.mswWorker, we need to set it up
                const {worker} = await import(
                    /* webpackChunkName: "mswWorker" */ "./mswWorker"
                );

                await worker.start();

                // set mswWorker on window object so that it is accessible on MSW page
                root.mswWorker = worker;

                console.info(
                    "MSW service worker started. Navigate to to /internal/msw to define and choose the mocks",
                );
            }

            // load the handlers from storage and use them
            const {applyActiveHandlers} = await import(
                /* webpackChunkName: "mswUtils" */ "./mswUtils"
            );
            applyActiveHandlers();
        } catch (err: unknown) {
            console.error("MSW service worker failed to start: ", err);
        }
    }
};
