import type {Theme, Components} from "..";

// packages/atgse/assets/fonts/atgrubrik-font.ttf;
type CssBaselineOverride = Components<Theme>["MuiCssBaseline"];
const overrides: CssBaselineOverride = {
    styleOverrides: `
  @font-face {
    font-family: '"ATGRubrik", sans-serif';
    src: url('/fonts/atgrubrik-font.ttf') format('embedded-opentype'), url('/fonts/atgrubrik-font.woff') format('woff'), url('/fonts/atgrubrik-font.tts') format('truetype'), url('/fonts/atgrubrik-font.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  `,
};

export default overrides;
