const green = {
    50: "#EAF7E8",
    100: "#C9ECC6",
    200: "#93D98C",
    300: "#6ECC66",
    400: "#4ABF40",
    500: "#2EB82E",
    600: "#1FAD1F",
    700: "#12A120",
    800: "#00990F",
    900: "#00800D",
};

export default green;
