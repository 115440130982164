import styled from "@emotion/styled";
import {atgBlue} from "atg-ui/css/variables.styles";

export const Header = styled.header`
    background-color: ${atgBlue};
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 1.31%, rgba(0, 0, 0, 0) 36.44%),
        ${atgBlue};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    display: grid;
`;

export const HeaderBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    min-height: 56px;
`;

export const Logo = styled.div`
    display: flex;
    justify-content: center;

    img {
        height: 1.25rem;
    }
`;

export const Nav = styled.nav`
    display: flex;
    justify-content: flex-end;
`;
