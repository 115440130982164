const yellow = {
    50: "#FFFCE5",
    100: "#FFF5B2",
    200: "#FFEB66",
    300: "#FFE433",
    400: "#FFDD00",
    500: "#E6C700",
    600: "#CCB100",
    700: "#B39B00",
    800: "#998500",
    900: "#806E00",
};

export default yellow;
