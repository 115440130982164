import {takeLatest, select, call} from "redux-saga/effects";
import {type SagaIterator} from "redux-saga";

import root from "window-or-global";
import {RouterActions} from "@atg-shared/router";
import isClientUpdateAvailable from "./clientVersionSelectors";

export function* updateClientVersion(): SagaIterator<void> {
    const isUpdateAvailable = yield select(isClientUpdateAvailable);

    if (!isUpdateAvailable) return;
    yield call([root.location, root.location.reload]);
}

export default function* clientVersionSaga(): SagaIterator<void> {
    yield takeLatest([RouterActions.ROUTER_ON_LOCATION_CHANGED], updateClientVersion);
}
