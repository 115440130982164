import * as React from "react";
import type {Props as SvgIconPropType} from "../components/SvgIcon";
import SvgIcon from "../components/SvgIcon";

export function Menu(props: SvgIconPropType) {
    return (
        <SvgIcon {...props}>
            <path d="M32 2H0v4h32V2zM24 14H0v4h24v-4zM32 26H0v4h32v-4z" />
        </SvgIcon>
    );
}

export default Menu;
