import * as React from "react";
import type {Props as SvgIconPropType} from "../components/SvgIcon";
import SvgIcon from "../components/SvgIcon";

export function Info(props: SvgIconPropType) {
    return (
        <SvgIcon {...props}>
            <path d="M32 16c0-8.8-7.2-16-16-16S0 7.2 0 16s7.2 16 16 16 16-7.2 16-16zm-14.4-4.8h-3.2V8h3.2v3.2zm0 12.8h-3.2v-9.6h3.2V24z" />
        </SvgIcon>
    );
}

export default Info;
