import {css} from "@emotion/react";
import {
    xl,
    sideNavigationWidth,
    responsibleGamingHeaderHeight,
} from "atg-ui/css/variables.styles";

export const frame = (enableCanSeeMarketingCampaign: boolean) => css`
    overflow-x: clip;
    ${enableCanSeeMarketingCampaign ? "position: fixed; width: 100%;" : ""}
`;

export const mainContainer = (
    isStickySideMenuOpen = false,
    isCanSeeCampaign: boolean,
) => css`
    @media ${xl} {
        padding-left: ${isCanSeeCampaign && isStickySideMenuOpen
            ? sideNavigationWidth
            : 0}px;
    }

    @media print {
        height: auto;
    }
    margin-top: ${responsibleGamingHeaderHeight}px;
`;

export const a11yContainer = css`
    position: absolute;
    width: 100%;
    // Prevent this container from overlapping ResponsibleGamingHeader. Since
    // this doesn't have overflow hidden, its children will overflow it and be
    // visible.
    height: 0;
    z-index: 2001; /* Place on top of ResponsibleGamingHeader */
    font-weight: 700;
    overflow-x: hidden;

    > button {
        margin: 0;
        background: #fff;
    }
`;
