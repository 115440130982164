import type {
    GamblingResult,
    LatestSuccessfulLoginTime,
} from "@atg-responsible-gambling-shared/user-gambling-summary-types";
import {constants} from "@atg-responsible-gambling-shared/user-gambling-summary-types";
import {LimitsActions} from "@atg-responsible-gambling-shared/limits-domain";
import type {
    GamblingResultSuccessAction,
    FetchGamblingResultAction,
    FetchLatestSuccessfulLoginTimeAction,
    FetchNarBlockedAction,
    LatestSuccessfulLoginTimeSuccessAction,
    NarBlockedSuccessAction,
    NarCheckTriggerAction,
} from "./actionTypes";

const {
    GAMBLING_RESULT_FETCH,
    GAMBLING_RESULT_SUCCESS,
    LATEST_SUCCESFUL_LOGIN_TIME_FETCH,
    LATEST_SUCCESFUL_LOGIN_TIME_SUCCESS,
    NAR_BLOCK_FETCH,
    NAR_BLOCK_SUCCESS,
    NAR_CHECK_TRIGGER,
} = constants;

export const fetchDepositLimitsAction = LimitsActions.fetchDepositLimits;

export const fetchGamblingResultAction = (
    payload?: boolean,
): FetchGamblingResultAction => ({
    type: GAMBLING_RESULT_FETCH,
    payload,
});

export const fetchLatestSuccessfulLoginTimeAction =
    (): FetchLatestSuccessfulLoginTimeAction => ({
        type: LATEST_SUCCESFUL_LOGIN_TIME_FETCH,
    });

export const gamblingResultSuccess = (
    payload: GamblingResult,
): GamblingResultSuccessAction => ({
    type: GAMBLING_RESULT_SUCCESS,
    payload,
});

export const latestSuccessfulLoginTimeSuccess = (
    payload: LatestSuccessfulLoginTime,
): LatestSuccessfulLoginTimeSuccessAction => ({
    type: LATEST_SUCCESFUL_LOGIN_TIME_SUCCESS,
    payload,
});

export const fetchNarBlockedAction = (): FetchNarBlockedAction => ({
    type: NAR_BLOCK_FETCH,
});

export const narCheckTrigger = (): NarCheckTriggerAction => ({
    type: NAR_CHECK_TRIGGER,
});

export const narBlockedSuccess = (payload: boolean): NarBlockedSuccessAction => ({
    type: NAR_BLOCK_SUCCESS,
    payload,
});
