import * as Breakpoints from "./breakpoints";

/** A screen which is _at least_ `XXS` pixels wide */
export const XXS = `(min-width: ${Breakpoints.XXS}px)`;

/** A screen which is _at least_ `XS` pixels wide */
export const XS = `(min-width: ${Breakpoints.XS}px)`;

/** A screen which is _at least_ `SM` pixels wide */
export const SM = `(min-width: ${Breakpoints.SM}px)`;

/** A screen which is _at least_ `MD` pixels wide */
export const MD = `(min-width: ${Breakpoints.MD}px)`;

/** A screen which is _at least_ `LG` pixels wide */
export const LG = `(min-width: ${Breakpoints.LG}px)`;

/** A screen which is _at least_ `XL` pixels wide */
export const XL = `(min-width: ${Breakpoints.XL}px)`;

/** A screen which is _at least_ `HG` pixels wide */
export const HG = `(min-width: ${Breakpoints.HG}px)`;
