import {call, put, select, take, takeLatest, delay, race} from "redux-saga/effects";
import * as Storage from "@atg-shared/storage";
import * as AuthSelectors from "@atg-shared/auth/domain/authSelectors";
import Features, {shortRealityCheck} from "@atg-shared/client-features";
import refreshAccessToken from "@atg-shared/auth/domain/refreshAccessToken";
import {VerticalsSelectors} from "@atg-global-shared/verticals";
import {
    constants,
    type HideModalAction,
} from "@atg-responsible-gambling-shared/reality-check-types";
import {UserGamblingResultActions} from "@atg-responsible-gambling-shared/user-gambling-result-domain";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import {loginTimeKey} from "@atg-global-shared/login-time-data-access";
import {LOGIN_FINISHED, LOGOUT_FINALIZE} from "@atg-global-shared/user/userActionTypes";
import {logoutUser} from "@atg-global-shared/user/userActions";
import {LimitsActions} from "@atg-responsible-gambling-shared/limits-domain";
import * as RealityCheckSelectors from "../selectors";
import {doRealityCheck, showModal, hideModal} from "../realityCheckActions";

const {DO_REALITY_CHECK, HIDE_MODAL, RESET_REALITY_CHECK, SET_NOINTERRUPT_MODE} =
    constants;

export const DEFAULT_PERIOD: 15000 | 3600000 = Features.isEnabled(shortRealityCheck)
    ? 15_000
    : 3_600_000;

const TIME_BEFORE_CLOSING_MODAL: 15_000 | 300_000 = Features.isEnabled(shortRealityCheck)
    ? 15_000
    : 300_000;

const key = "RC_TIMESTAMP";

const clearRealityCheck = () => Storage.removeItem(key);

type Timestamps = {
    lastModal?: number;
    startTime?: number;
};

export function* hideRealityCheckModal({payload}: HideModalAction) {
    const {lastModal} = Storage.getObject<Timestamps>(key) || {};
    if (lastModal) Storage.setObject(key, {lastModal: Date.now()});

    yield put(payload && payload.shouldLogout ? logoutUser() : doRealityCheck());

    if (!payload?.shouldLogout) {
        yield call(refreshAccessToken);
    }
}

export function* showRealityCheckModal(startTime: number) {
    const username: string = yield select(UserSelectors.getUsername);
    const loginTime: number =
        Storage.getItem<number>(loginTimeKey(username)) || startTime;

    yield put(showModal(loginTime));

    // Waits 5 minutes (15 seconds if shortRealityCheck-feature flag is active) until disappear
    // If the user press log out or keep login in (LOGOUT_FINALIZE, HIDE_MODAL action) this delay will be canceled
    const {timeOut} = yield race({
        response: take([LOGOUT_FINALIZE, HIDE_MODAL]),
        timeOut: delay(TIME_BEFORE_CLOSING_MODAL),
    });
    if (timeOut) yield put(hideModal());
}

export function* handleRealityCheck({
    type,
    payload,
}: {
    type: string;
    payload?: {canInterrupt?: boolean};
}) {
    if (type === LOGIN_FINISHED) yield call(clearRealityCheck);

    const normalLogin: boolean = yield select(AuthSelectors.isNormalLogin);
    if (!normalLogin) return;

    let timeToModal = DEFAULT_PERIOD;

    const {lastModal, startTime = Date.now()} = Storage.getObject<Timestamps>(key) || {};

    if (!lastModal) Storage.setObject(key, {startTime, lastModal: startTime});

    timeToModal -= Date.now() - (lastModal || Date.now());

    if (timeToModal <= -1 * DEFAULT_PERIOD) {
        yield call(clearRealityCheck);
        yield put(doRealityCheck(payload?.canInterrupt));
        return;
    }

    const noInterruptMode: boolean = yield select(RealityCheckSelectors.noInterruptMode);
    if (noInterruptMode) {
        if (!(payload && payload.canInterrupt) || timeToModal > 0) return;
        yield call(showRealityCheckModal, startTime);
    }

    // if the user logs out before we are showing the modal, we quit the Saga
    const {userLogsOut} = yield race({
        userLogsOut: take(LOGOUT_FINALIZE),
        timeOut: delay(timeToModal),
    });

    if (userLogsOut) return;

    yield put(UserGamblingResultActions.fetchGamblingPeriodResultAction());
    yield put(LimitsActions.fetchDepositLimits());
    yield call(showRealityCheckModal, startTime);

    const isCasino: boolean = yield select(VerticalsSelectors.isCasino);
    if (!isCasino) Storage.setObject(key, {lastModal: Date.now()});
}

export default function* realityCheckSaga() {
    yield takeLatest(
        [DO_REALITY_CHECK, LOGIN_FINISHED, SET_NOINTERRUPT_MODE],
        handleRealityCheck,
    );
    yield takeLatest(HIDE_MODAL, hideRealityCheckModal);
    yield takeLatest([LOGOUT_FINALIZE, RESET_REALITY_CHECK], clearRealityCheck);
}
