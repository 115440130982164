import {css} from "@emotion/react";

// also see `atg-modals/components/Modal.global.styles.js`
const modalWidth = 333; // 335 - 2px borders

export const outerContainer = (isFullscreenPage: boolean) => css`
    position: relative;
    width: ${modalWidth}px;
    margin-top: ${isFullscreenPage ? "64px" : "0"};
`;

export const container = css`
    position: relative;
`;

export const coverFullIframe = (height: number) => css`
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    height: ${height}px;
    max-width: ${modalWidth}px;

    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const curityLoadingBackground = css`
    background-color: #fff;
`;
