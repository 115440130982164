import type {Theme, Components} from "..";

type LinkOverrides = Components<Theme>["MuiLink"];

const overrides: LinkOverrides = {
    defaultProps: {
        underline: "hover",
    },
};

export default overrides;
