import {type SagaIterator} from "redux-saga";
import {put, call, fork, select, take, takeLatest} from "redux-saga/effects";
import {LOGIN_FINISHED} from "@atg-global-shared/user/userActionTypes";
import {AuthActions} from "@atg-shared/auth";
import * as MemberSelectors from "./memberSelectors";
import {
    type Action,
    START_LOGIN_FLOW,
    START_REGISTER_FLOW,
    CANCELLED_LOGIN_FLOW,
    MEMBER_REGISTER_FINISHED,
    MEMBER_REGISTER_CANCEL,
    type StartLoginFlowAction,
    type StartRegisterFlowAction,
    startLoginFlow,
    startRegisterFlow,
    cancelLoginFlow,
    finishMemberFlow,
} from "./memberActions";
import memberPageFlowSaga from "./memberPageFlowSaga";

export function* loginFlow({
    payload: {finishActionType = LOGIN_FINISHED},
}: StartLoginFlowAction): SagaIterator {
    const action: Action = yield take([CANCELLED_LOGIN_FLOW, finishActionType]);

    if (action.type === finishActionType) {
        yield put(finishMemberFlow());
    }
}

export function* registerFlow({
    payload: {finishActionType = MEMBER_REGISTER_FINISHED},
}: StartRegisterFlowAction): SagaIterator {
    const action: Action = yield take([MEMBER_REGISTER_CANCEL, finishActionType]);

    switch (action.type) {
        case MEMBER_REGISTER_CANCEL:
            yield put(cancelLoginFlow());
            break;
        case finishActionType:
            yield put(finishMemberFlow());
            break;
        default:
            // eslint-disable-next-line no-useless-return
            return;
    }
}

export function* loginOrRegisterFlow({
    payload,
}: AuthActions.StartAuthenticationFlowAction): SagaIterator {
    const returningUser: boolean = yield select(MemberSelectors.isReturningMember);

    if (returningUser) {
        yield put(startLoginFlow(payload.options));
    } else {
        yield put(startRegisterFlow(payload.options));
    }
}

export function* startFlow(action: Action): SagaIterator {
    switch (action.type) {
        case START_LOGIN_FLOW:
            yield call(loginFlow, action);
            return;
        case START_REGISTER_FLOW:
            yield call(registerFlow, action);
            // eslint-disable-next-line no-useless-return
            return;
        default:
            // eslint-disable-next-line no-useless-return
            return;
    }
}

export default function* memberFlowSaga(): SagaIterator {
    yield takeLatest(AuthActions.START_AUTHENTICATION_FLOW, loginOrRegisterFlow);
    yield takeLatest([START_LOGIN_FLOW, START_REGISTER_FLOW], startFlow);
    yield fork(memberPageFlowSaga);
}
