import * as React from "react";
import type {Theme, Components} from "..";
import RadioCheckedIcon from "../../icons/RadioCheckedOutlined";
import RadioBlankIcon from "../../icons/RadioBlankOutlined";
import Zoom from "../../components/Zoom";

type RadioOverrides = Components<Theme>["MuiRadio"];

function DefaultIcon({checked = false}: {checked?: boolean}) {
    return (
        <>
            <RadioBlankIcon />
            <Zoom in={checked} style={{transitionDuration: "150ms"}}>
                <RadioCheckedIcon sx={{position: "absolute"}} />
            </Zoom>
        </>
    );
}

const overrides: RadioOverrides = {
    defaultProps: {
        checkedIcon: <DefaultIcon checked />,
        icon: <DefaultIcon />,
    },
};

export default overrides;
