import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {
    atgBlack,
    cmsAlertInfoColor,
    cmsAlertDangerColor,
    cmsAlertWarningColor,
    cmsAlertSuccessColor,
} from "../css/variables.styles";
import {AlertTypes} from "./Alert.types";

const alertDanger = css`
    background: ${cmsAlertDangerColor};
`;

const alertInfo = css`
    background: ${cmsAlertInfoColor};
`;

const alertSuccess = css`
    background: ${cmsAlertSuccessColor};
`;

const alertWarning = css`
    background: ${cmsAlertWarningColor};
`;

const determineColor = (type: AlertTypes) => {
    switch (type) {
        case "success":
            return alertSuccess;
        case "info":
            return alertInfo;
        case "warning":
            return alertWarning;
        case "danger":
            return alertDanger;
        default:
            return alertDanger;
    }
};

export const styles = {
    alertDanger,
    alertInfo,
    alertSuccess,
    alertWarning,
    determineColor,
};

export const StyledAlert = styled.div`
    ${({type}) => determineColor(type)};
    color: ${atgBlack};
    font-size: 14px;
    padding: 12px;
    position: relative;
    white-space: normal;
    ${({spacing = "normal"}) => {
        switch (spacing) {
            case "none":
                return css`
                    margin-bottom: 0px;
                `;
            case "thin":
                return css`
                    margin-bottom: 3px;
                `;
            default:
                return css`
                    margin-bottom: 15px;
                `;
        }
    }};

    ${({hiddenPrint = false}) =>
        hiddenPrint &&
        css`
            @media print {
                display: none;
            }
        `};
`;
