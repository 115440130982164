/* eslint-disable max-classes-per-file */
import {isEmpty} from "lodash";
import * as React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import root from "window-or-global";
import {ClassNames, Global, ThemeProvider as EmotionThemeProvider} from "@emotion/react";
import {browser} from "@atg/utils";
import {createTheme, ThemeProvider} from "@atg-ui/toolkit/theme";
import {OverlayLoadingIndicator} from "atg-ui/components/LoadingIndicator";
import {ButtonBase} from "atg-ui-components";
import {Close as CloseIcon} from "atg-ui-components/icons";
import TrapFocus from "atg-ui-components/components/TrapFocus";
import ConfirmDialog from "./ModalConfirmBody";
import * as styles from "./Modal.styles";
import {globalStyles} from "./Modal.global.styles";

const ESCAPE_KEY_CODE = 27;

/**
 * Note: This component both supports legacy class names (emotion@9) and modern style objects
 * (emotion@10). The long-term goal is to get rid of class names completely.
 */
class Modal extends React.Component {
    constructor(props, context) {
        super(props, context);

        const {locked, loading} = props;
        this.state = {
            locked,
            loading,
        };
    }

    componentDidMount() {
        root.document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        root.document.removeEventListener("keydown", this.handleKeyDown);
        this.props.onModalUnmount();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const state = {};

        if (nextProps.locked !== this.props.locked) {
            state.locked = nextProps.locked;
        }

        if (nextProps.loading !== this.props.loading) {
            state.loading = nextProps.loading;
        }

        if (!isEmpty(state)) {
            this.setState(state);
        }
    }

    onClose() {
        const {onClose, confirmDismiss, closePurchaseModalDisabled} = this.props;
        if (onClose) {
            onClose();
        }

        if (confirmDismiss || closePurchaseModalDisabled) {
            this.setState({confirmDismissShown: true});
            return;
        }

        this.dismiss();
        this.aborted = true;
    }

    renderConfirmDismiss() {
        const {
            confirmDismissTitle,
            confirmDismissMessage,
            confirmDismiss,
            renderCustomDismiss,
        } = this.props;
        if (!this.state.confirmDismissShown || !confirmDismiss) return null;

        if (renderCustomDismiss) {
            return (
                <TrapFocus>
                    {renderCustomDismiss({
                        onConfirm: () => this.setState({confirmDismissShown: false}),
                        onDismiss: () => this.dismiss(),
                    })}
                </TrapFocus>
            );
        }

        return (
            <ModalHandler
                id="confirm-dismiss"
                title={confirmDismissTitle}
                onDismiss={() => {
                    this.setState({confirmDismissShown: false});
                }}
            >
                <ConfirmDialog
                    onConfirm={this.dismiss}
                    message={confirmDismissMessage}
                    danger
                    buttonTextCancel="Tillbaka"
                    buttonTextConfirm="Avbryt köp"
                />
            </ModalHandler>
        );
    }

    dismiss = () => {
        if (this.props.onDismiss) {
            this.props.onDismiss();
        }
    };

    renderCloseButton() {
        if (this.props.disableClose || this.state.locked) return null;

        return (
            <ButtonBase
                aria-label="avbryt"
                data-test-id="modal-close"
                onClick={() => this.onClose()}
                style={styles.close}
            >
                <CloseIcon size="sm" />
            </ButtonBase>
        );
    }

    renderChildren() {
        return React.Children.map(this.props.children, (child) => {
            if (!child || typeof child.type === "string") return child;

            return React.cloneElement(child, {
                modal: true,
                modalDismiss: this.dismiss,
                onModalDismiss: this.dismiss,
            });
        });
    }

    handleKeyDown = (event) => {
        if (event.keyCode === ESCAPE_KEY_CODE && this.props.keyboardDismiss) {
            this.dismiss();
        }
    };

    handleBackdropClick = (event) => {
        if (!this.props.backdropClickDismiss) {
            return;
        }

        if (event.target === event.currentTarget) {
            event.stopPropagation();
            this.dismiss();
        }
    };

    render() {
        const {
            className = "",
            modalClasses, // legacy classes – can be a string, array, object or anything parseable by `cx`
            contentClasses, // legacy classes – can be a string, array, object or anything parseable by `cx`
            bodyClassName, // legacy classes – can be a string, array, object or anything parseable by `cx`
            dialogStyle, // emotion@10 style object
            contentStyle, // emotion@10 style object
            bodyStyle, // emotion@10 style object
            styled = true,
            noPadding,
            title,
            dataTestId,
        } = this.props;

        // these should be removed once all references in `less` files are removed
        const legacyRootClasses = "modal";
        const legacyBodyClasses = "modal__body";

        let header = null;
        const testIdentifier = `${dataTestId}-title`;

        if (title) {
            header = (
                <div css={styles.header} data-test-id="atg-ui-modal-header">
                    <h4 css={styles.headerTitle} data-test-id={testIdentifier}>
                        {title}
                    </h4>
                    {this.renderCloseButton()}
                </div>
            );
        }

        const loader = this.state.loading ? (
            <div css={styles.loader}>
                <OverlayLoadingIndicator />
            </div>
        ) : null;

        return (
            <>
                <Global styles={globalStyles} />
                <ClassNames>
                    {({cx}) => (
                        <div
                            css={styles.root}
                            className={legacyRootClasses}
                            role="dialog"
                        >
                            <div
                                css={styles.scroll}
                                data-test-id="modal-backdrop"
                                onClick={this.handleBackdropClick}
                            >
                                <div
                                    css={[styled && styles.dialog, dialogStyle]}
                                    className={cx(className, modalClasses)}
                                    data-test-id="atg-ui-modal-dialog"
                                >
                                    <div
                                        css={[styled && styles.content, contentStyle]}
                                        className={cx(contentClasses)}
                                    >
                                        <TrapFocus>
                                            {header}
                                            <div
                                                css={[
                                                    styled && styles.body(noPadding),
                                                    bodyStyle,
                                                ]}
                                                className={cx(
                                                    legacyBodyClasses,
                                                    bodyClassName,
                                                )}
                                                role="document"
                                                data-test-id="atg-ui-modal-document"
                                                onClick={this.handleBackdropClick}
                                            >
                                                {loader}
                                                {this.renderChildren()}
                                            </div>
                                        </TrapFocus>
                                    </div>
                                </div>
                            </div>
                            {this.renderConfirmDismiss()}
                        </div>
                    )}
                </ClassNames>
            </>
        );
    }
}

Modal.defaultProps = {
    confirmDismissMessage:
        "OBS! Ditt spel är inte bekräftat ännu. Om du avbryter köpet kan du fortfarande justera ditt spel.",
    confirmDismissTitle: "Spel ej bekräftat",
};

Modal.propTypes = {
    locked: PropTypes.bool,
    dataTestId: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onDismiss: PropTypes.func,
    closeText: PropTypes.string,
    contentClasses: PropTypes.string,
    modalClasses: PropTypes.object,
    disableClose: PropTypes.bool,
    noPadding: PropTypes.bool,
    styled: PropTypes.bool,
    onModalUnmount: PropTypes.func,
    confirmDismissMessage: PropTypes.string,
    confirmDismissTitle: PropTypes.string,
    confirmDismiss: PropTypes.bool,
    backdropClickDismiss: PropTypes.bool,
    keyboardDismiss: PropTypes.bool,
    onClose: PropTypes.func,
};

const div = (className, testIdentifier) => {
    const el = document.createElement("div");
    el.className = className;
    if (testIdentifier) el.setAttribute("data-test-id", testIdentifier);

    return el;
};
const rootContainer = div("modal-container");

// Single backdrop for all modals
const backdrop = div("modal-backdrop-container");

const DEFAULT_PARENT_NODE = document.body;
let parentNode = DEFAULT_PARENT_NODE;

export const setParentNode = (node) => {
    parentNode = node;
    parentNode.appendChild(rootContainer);
};

export const resetParentNode = () => {
    setParentNode(DEFAULT_PARENT_NODE);
};

const hasVisibleModals = () => rootContainer.childNodes.length > 1;
const noVisibleModals = () => rootContainer.childNodes.length === 0;
const getModalContainer = () => document.getElementsByClassName("modal-open")[0];

const removeClassFromBody = () => {
    if (!noVisibleModals()) return;
    const modalContainer = getModalContainer();
    if (modalContainer) {
        modalContainer.setAttribute("style", "margin-right: 0px;");
    }
    document.body.classList.remove("modal-open");
    const el = document.getElementById("react-root");

    if (el) el.classList.remove("modal-open");
};

const renderBackdrop = () => {
    ReactDOM.render(<div className="modal-backdrop" />, backdrop);
};

class ModalHandler extends React.Component {
    state = {
        browserScrollbarWidth: browser.getBrowserScrollbarWidth(),
    };

    addClassToBody() {
        if (hasVisibleModals()) return;
        // body for desktop, #react-root for mobile devices
        document.body.classList.add("modal-open");
        const el = document.getElementById("react-root");
        if (el) {
            el.classList.add("modal-open");
        }

        const modalContainer = getModalContainer();
        if (modalContainer) {
            modalContainer.setAttribute(
                "style",
                `margin-right: ${this.state.browserScrollbarWidth}px`,
            );
        }
    }

    UNSAFE_componentWillMount() {
        this.mountNodes();
    }

    componentDidMount() {
        renderBackdrop();
        this.addClassToBody();
    }

    dismiss = () => {
        this.props.onDismiss();
    };

    onModalUnmount() {
        if (this.props.onModalUnmount) this.props.onModalUnmount();
        this.unmountNodes();
        removeClassFromBody();
    }

    mountNodes() {
        if (!rootContainer.parentNode) {
            parentNode.appendChild(rootContainer);
        }

        if (!backdrop.parentNode) {
            parentNode.appendChild(backdrop);
        }

        if (!this.holder) {
            this.holder = div(`modal-${this.props.id}`, this.props.dataTestId);
            rootContainer.appendChild(this.holder);
        }
    }

    unmountNodes() {
        if (this.holder) {
            this.holder.parentNode.removeChild(this.holder);
        }
        if (noVisibleModals()) {
            rootContainer.parentNode.removeChild(rootContainer);
            backdrop.parentNode.removeChild(backdrop);
        }
    }

    render() {
        const theme = this.props.theme ?? createTheme();
        return ReactDOM.createPortal(
            <ThemeProvider theme={theme}>
                <EmotionThemeProvider theme={theme}>
                    <Modal
                        {...this.props}
                        key="modal"
                        onDismiss={this.dismiss}
                        onModalUnmount={() => this.onModalUnmount()}
                    >
                        {this.props.children}
                    </Modal>
                </EmotionThemeProvider>
            </ThemeProvider>,
            this.holder,
        );
    }
}

ModalHandler.propTypes = {
    id: PropTypes.string.isRequired,
};

ModalHandler.defaultProps = {
    locked: false,
    disableClose: false,
    loading: false,
    noPadding: false,
    dataTestId: "modal",
};

export default ModalHandler;
