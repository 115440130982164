import {put, takeLatest} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {AuthActions} from "@atg-shared/auth";
import {FRAME_TAB_BECAME_ACTIVE} from "./frameActions";

export function* checkTabBecomingActive(): SagaIterator<void> {
    yield put(AuthActions.checkAuth(false));
}

export default function* frameSaga(): SagaIterator<void> {
    yield takeLatest(FRAME_TAB_BECAME_ACTIVE, checkTabBecomingActive);
}
