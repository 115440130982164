import {persistReducer} from "redux-persist";
import {combineReducers} from "redux";
import storage from "redux-persist/lib/storage";

import * as LoginTypes from "@atg-login-shared/types";

import type * as LoginActions from "./loginActions";

export type State = {
    authMode: LoginTypes.AuthMode;
};

export const initialState: State = {
    authMode: LoginTypes.AuthMode.Login,
};

const authenticatorSelector = (
    state: State = initialState,
    action: LoginActions.SetAuthMode | LoginActions.ResetCurityState,
) => {
    switch (action.type) {
        case LoginTypes.ActionTypes.SET_AUTH_MODE: {
            const {payload} = action;
            return {
                ...state,
                authMode: payload,
            };
        }
        case LoginTypes.ActionTypes.RESET_CURITY_STATE: {
            // store the latest successful auth method
            // but clear the temporary methods
            return {...initialState, authMode: state.authMode};
        }
        default:
            return state;
    }
};

const curityResponse = (
    state: LoginTypes.CurityRootModel | null = null,
    action: LoginActions.SetCurityResponse | LoginActions.ResetCurityState,
) => {
    switch (action.type) {
        case LoginTypes.ActionTypes.SET_CURITY_RESPONSE: {
            return action.payload;
        }
        case LoginTypes.ActionTypes.RESET_CURITY_STATE: {
            return null;
        }
        default:
            return state;
    }
};

const curityState = (
    state: LoginTypes.CurityState = LoginTypes.CurityState.IDLE,
    action: LoginActions.SetCurityState | LoginActions.ResetCurityState,
) => {
    switch (action.type) {
        case LoginTypes.ActionTypes.SET_CURITY_STATE: {
            return action.payload;
        }
        case LoginTypes.ActionTypes.RESET_CURITY_STATE: {
            return "idle";
        }
        default:
            return state;
    }
};

const error = (
    state: LoginTypes.CurityError | null = null,
    action:
        | LoginActions.SetCurityResponseSuccess
        | LoginActions.SetCurityError
        | LoginActions.ResetCurityState,
) => {
    switch (action.type) {
        case LoginTypes.ActionTypes.RESET_CURITY_STATE:
        case LoginTypes.ActionTypes.SET_CURITY_RESPONSE_SUCCESS: {
            return null;
        }
        case LoginTypes.ActionTypes.SET_CURITY_ERROR: {
            return action.payload;
        }
        default:
            return state;
    }
};

const authenticators = (
    state: LoginTypes.Actions = [],
    action: LoginActions.SetAuthenticators | LoginActions.ResetCurityState,
) => {
    switch (action.type) {
        case LoginTypes.ActionTypes.SET_AUTHENTICATORS: {
            return action.payload;
        }
        case LoginTypes.ActionTypes.RESET_CURITY_STATE: {
            return [];
        }
        default:
            return state;
    }
};

const authContext = (
    state: LoginTypes.AuthenticatorTypes | LoginTypes.RecoveryState | null = null,
    action: LoginActions.SetAuthContext | LoginActions.ResetCurityState,
) => {
    switch (action.type) {
        case LoginTypes.ActionTypes.SET_AUTH_CONTEXT: {
            return action.payload;
        }
        case LoginTypes.ActionTypes.RESET_CURITY_STATE: {
            return null;
        }
        default:
            return state;
    }
};

const recovery = (
    state: LoginTypes.RecoveryState | null = null,
    action: LoginActions.SetRecoveryMethod,
) => {
    switch (action.type) {
        case LoginTypes.RecoveryActionTypes.SET_RECOVERY_METHOD: {
            return action.payload;
        }

        default:
            return state;
    }
};

const loginCredentialsRequestStatusState = {
    pending: false,
};

const loginCredentialsRequestStatus = (
    state = loginCredentialsRequestStatusState,
    action:
        | LoginActions.LoginCredentialsRequestStart
        | LoginActions.LoginCredentialsRequestFinish,
) => {
    switch (action.type) {
        case LoginTypes.ActionTypes.LOGIN_CREDENTIALS_REQUEST_START: {
            return {
                ...state,
                pending: true,
            };
        }
        case LoginTypes.ActionTypes.LOGIN_CREDENTIALS_REQUEST_FINISH: {
            return {
                ...state,
                pending: false,
            };
        }
        default:
            return state;
    }
};

const initialAuthMethod = {
    method: LoginTypes.CurityState.AUTHENTICATOR_SELECTOR as LoginTypes.CurityState,
    context: null,
    isSuccessfulLogin: false,
    hideBalance: true,
    temp: null,
};

const authMethod = (
    state: LoginTypes.AuthMethod &
        Omit<LoginTypes.AuthMethod, "temp"> = initialAuthMethod,
    action:
        | LoginActions.SetAuthMethod
        | LoginActions.SetTempAuthMethod
        | LoginActions.SetHideBalanceLoginFlow
        | LoginActions.ResetCurityState,
) => {
    switch (action.type) {
        case LoginTypes.ActionTypes.SET_AUTH_METHOD: {
            return action.payload;
        }

        case LoginTypes.ActionTypes.SET_TEMP_AUTH_METHOD: {
            return {
                ...state,
                temp: action.payload,
            };
        }
        case LoginTypes.ActionTypes.SET_HIDE_BALANCE_LOGIN_FLOW: {
            return {
                ...state,
                hideBalance: action.payload,
            };
        }
        case LoginTypes.ActionTypes.RESET_CURITY_STATE: {
            // store the latest successful auth method
            // but clear the temporary methods
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const {temp, ...newState} = state;
            return newState;
        }
        default:
            return state;
    }
};

const curityPersistConfig = {
    storage,
    key: "curity",
    whitelist: ["authMethod"],
    version: 0,
};

const loginRootReducer = combineReducers({
    authenticatorSelector,
    curityResponse,
    curityState,
    error,
    authenticators,
    authContext,
    authMethod,
    loginCredentialsRequestStatus,
    recovery,
});

export default persistReducer(curityPersistConfig, loginRootReducer);
