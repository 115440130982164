import {MemberActions} from "@atg-global-shared/member-data-access";
import type {MatchMediaAction} from "atg-match-media/domain/matchMediaActions";
import {CHANGE_ACTIVE_BREAKPOINT} from "atg-match-media/domain/matchMediaActions";
import {XL} from "atg-match-media/domain/matchMediaConstants";
import {
    OPEN_DROPDOWN_MENU,
    OPEN_SIDE_MENU,
    CLOSE_DROPDOWN_MENU,
    CLOSE_SIDE_MENU,
    CLOSE_ALL_MENUS,
    MENU_PLAY,
} from "../constants/mainMenuActionTypes";
import type {MainMenuActions} from "./mainMenuActions";

export type MainNavigationState = {
    sideMenu: string | null;
    dropdownMenu: string | null;
    sticky: boolean;
};

const initialState = {
    sideMenu: null,
    dropdownMenu: null,
    sticky: false,
};

type Actions = MainMenuActions | MatchMediaAction | MemberActions.StartLoginFlowAction;

const mainMenu = (
    state: MainNavigationState = initialState,
    action: Actions,
): MainNavigationState => {
    switch (action.type) {
        case CHANGE_ACTIVE_BREAKPOINT: {
            const sideMenu = action.payload >= XL ? MENU_PLAY : null;
            return {
                ...state,
                sideMenu,
                sticky: action.payload >= XL,
            };
        }
        case OPEN_SIDE_MENU:
            return {
                ...state,
                sideMenu: action.payload.key,
            };
        case OPEN_DROPDOWN_MENU:
            return {
                ...state,
                dropdownMenu: action.payload.key,
            };
        case CLOSE_SIDE_MENU:
            return {
                ...state,
                sideMenu: null,
            };
        case MemberActions.START_LOGIN_FLOW:
        case CLOSE_DROPDOWN_MENU:
            return {
                ...state,
                dropdownMenu: null,
            };
        case CLOSE_ALL_MENUS:
            return {
                ...state,
                sideMenu: state.sticky ? state.sideMenu : null,
                dropdownMenu: null,
            };
        default:
            return state;
    }
};

export default mainMenu;
