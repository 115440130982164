import React from "react";

export type CurityIFrameCommunicatorContextProps = {
    isFullscreenPage: boolean;
    showLoader: boolean;
};

const INITIAL_VALUES: CurityIFrameCommunicatorContextProps = {
    isFullscreenPage: false,
    showLoader: false,
};

const CurityIFrameCommunicatorContext = React.createContext(INITIAL_VALUES);

export default CurityIFrameCommunicatorContext;
