import * as React from "react";
import type {Props as SvgIconPropType} from "../components/SvgIcon";
import SvgIcon from "../components/SvgIcon";

export function User(props: SvgIconPropType) {
    return (
        <SvgIcon {...props}>
            <path d="M24.423 25.572c-1.92-3.113-4.958-5.013-8.263-5.072a6.37 6.37 0 01-.32 0c-3.304.06-6.343 1.959-8.263 5.072A12.702 12.702 0 0016 28.75c3.229 0 6.177-1.2 8.423-3.178zm1.09-1.083A12.702 12.702 0 0028.75 16c0-7.042-5.708-12.75-12.75-12.75S3.25 8.958 3.25 16c0 3.26 1.224 6.235 3.237 8.49 1.554-2.385 3.723-4.118 6.187-4.946a6.25 6.25 0 116.653 0c2.463.828 4.632 2.562 6.186 4.945zM16.15 19a4.751 4.751 0 10-.302 0 10.434 10.434 0 01.302 0zM16 30.25C8.13 30.25 1.75 23.87 1.75 16S8.13 1.75 16 1.75 30.25 8.13 30.25 16 23.87 30.25 16 30.25z" />
        </SvgIcon>
    );
}

export default User;
