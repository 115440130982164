import type {Reducer} from "redux";
import {combineReducers} from "redux";
import {MemberActions} from "@atg-global-shared/member-data-access";
import type {UserCredentials, AuthResponse} from "@atg-global-shared/user/user.types";
import {
    LOGIN_FINISHED,
    SET_REMEMBER_USERNAME,
} from "@atg-global-shared/user/userActionTypes";
import {
    LOGIN,
    LOGIN_NEED_TWO_FACTOR,
    LOGIN_NEED_CHANGE_TEMP_PASSWORD,
    LOGIN_NEED_USER_AGREEMENT,
    LOGIN_ERROR,
    AUTH_SERVER_STATE,
    LOGIN_MEMBER_RECOVERY,
} from "./loginActions";
import type {LoginStep} from "./loginSteps";
import {
    LOGIN_FORM_STEP,
    LOGIN_TWO_FACTOR_STEP,
    LOGIN_USER_AGREEMENT_STEP,
    LOGIN_CHANGE_TEMP_PASSWORD_STEP,
    LOGIN_MEMBER_RECOVERY_STEP,
} from "./loginSteps";
import {AUTH_SERVER_STATE_LOGIN, AUTH_SERVER_STATE_DEFAULT} from "./loginConstants";
import type {Action} from "./index";

function getFriendlyErrorMessage(error: Record<string, string>) {
    const errors = {
        LOCKED: "Ditt konto har spärrats på grund av för många felaktiga inloggningsförsök. Klicka på länken längre ner i formuläret för att beställa nya inloggningsuppgifter.",
        FAILED: "Felaktigt användarnamn eller lösenord.",
        LOCKED_TEMPORARILY:
            "Ditt konto är stängt för inloggning i en timme. Prova igen senare.",
        PASSWORD_NEED_CHANGE: "Ditt lösenord måste bytas innan du kan logga in.",
        EXPIRED_TEMP_PASSWORD:
            "Ditt engångslösenord har gått ut och du behöver beställa ett nytt.",
    };

    return (
        errors[error.type as keyof typeof errors] ||
        error.message ||
        "Ett okänt tekniskt fel har inträffat."
    );
}

export type LoginStepState = {
    id?: LoginStep;
    data?: AuthResponse | null | undefined;
};

export type LoginError = {
    type?: string;
    message?: string;
};

export type LoginState = {
    loginStep: LoginStepState;
    loginLoading: boolean;
    loginError: LoginError;
    rememberMe: UserCredentials;
    authServerState: string;
    isLoginCancelled: boolean;
};

const getInitialState = () => ({});
const loginStep = (
    state: LoginStepState = getInitialState(),
    action: Action,
): LoginStepState => {
    switch (action.type) {
        case MemberActions.START_LOGIN_FLOW:
            return {
                id: LOGIN_FORM_STEP,
                data: action.payload.authResponse,
            };
        case LOGIN_NEED_TWO_FACTOR:
            return {
                id: LOGIN_TWO_FACTOR_STEP,
            };
        case LOGIN_MEMBER_RECOVERY:
            return {
                id: LOGIN_MEMBER_RECOVERY_STEP,
            };
        case LOGIN_NEED_USER_AGREEMENT:
            return {
                id: LOGIN_USER_AGREEMENT_STEP,
            };
        case LOGIN_NEED_CHANGE_TEMP_PASSWORD:
            return {
                id: LOGIN_CHANGE_TEMP_PASSWORD_STEP,
            };
        case LOGIN_FINISHED:
        case MemberActions.CANCELLED_LOGIN_FLOW:
        case MemberActions.START_REGISTER_FLOW:
            return getInitialState();
        default:
            return state;
    }
};

const loginLoading = (state = false, action: Action): boolean => {
    const {type} = action;
    switch (type) {
        case LOGIN:
            return true;
        case LOGIN_NEED_TWO_FACTOR:
        case LOGIN_NEED_USER_AGREEMENT:
        case LOGIN_NEED_CHANGE_TEMP_PASSWORD:
        case LOGIN_FINISHED:
        case MemberActions.CANCELLED_LOGIN_FLOW:
        case LOGIN_ERROR:
            return false;
        default:
            return state;
    }
};

const loginError = (state: LoginError = {}, action: Action): LoginError => {
    switch (action.type) {
        case LOGIN_ERROR: {
            const {payload} = action;
            return {
                type: payload.error.type,
                message: getFriendlyErrorMessage(payload.error),
            };
        }
        case LOGIN_FINISHED:
        case MemberActions.CANCELLED_LOGIN_FLOW:
        case MemberActions.START_REGISTER_FLOW:
            return {};
        default:
            return state;
    }
};

export const getInitialUserCredentials = (): UserCredentials => ({
    rememberUsername: false,
});

export const rememberMe = (
    state: UserCredentials = getInitialUserCredentials(),
    action: Action,
): UserCredentials => {
    switch (action.type) {
        case LOGIN_FINISHED:
            return state.rememberUsername
                ? {
                      ...state,
                      username: action.payload.rememberedUsername,
                  }
                : state;
        case SET_REMEMBER_USERNAME:
            return {
                ...state,
                rememberUsername: action.payload.status,
            };
        default:
            return state;
    }
};

export const authServerState = (
    state: string = AUTH_SERVER_STATE_DEFAULT,
    action: Action,
): string => {
    switch (action.type) {
        case AUTH_SERVER_STATE:
            return action.payload;
        case MemberActions.START_LOGIN_FLOW:
            return AUTH_SERVER_STATE_LOGIN;
        case MemberActions.CANCELLED_LOGIN_FLOW:
            return AUTH_SERVER_STATE_DEFAULT;
        default:
            return state;
    }
};

const isLoginCancelled = (state = false, action: Action): boolean => {
    switch (action.type) {
        case MemberActions.START_LOGIN_FLOW:
            return false;
        case MemberActions.CANCELLED_LOGIN_FLOW:
            return true;
        default:
            return state;
    }
};

const login: Reducer<LoginState, Action> = combineReducers({
    loginStep,
    loginLoading,
    loginError,
    rememberMe,
    authServerState,
    isLoginCancelled,
});

export default login;
