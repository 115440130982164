import {isEmpty, omit, includes, forEach, partial, find} from "lodash";
import root from "window-or-global";

import type solaceNamespace from "solclientjs";
import log, {serializeError} from "@atg-shared/log";
import * as Storage from "@atg-shared/storage";
import delay from "./delay";

// Solace API reference: https://docs.solace.com/API-Developer-Online-Ref-Documentation/js/index.html
// Solace tutorials: https://solace.com/samples/solace-samples-javascript/
// Samples on Github: https://github.com/SolaceSamples/solace-samples-javascript

export type MessageHandlerFunction = (data?: any) => void;

// We can't use the namespace directly when we want to refer to the import.
type Solace = typeof solaceNamespace;

export type OnConnectCallback = (client: Client | null) => void;

type CorrelationKey = {
    topicName: string;
};

type SolaceImport = Solace | (() => Promise<Solace>);

// this will be either the solace module directly when used in React Native, or a function which resolves the solace module lazily when used on the web to improve performance
let solaceImport: SolaceImport;
function getSolace(): Promise<Solace> {
    return typeof solaceImport === "function"
        ? solaceImport()
        : Promise.resolve(solaceImport);
}
export const configureSolaceImport = (solace: SolaceImport) => {
    solaceImport = solace;
};

const MIN_DELAY = 100;
const ONE_SECOND = 1000;
const RANDOMIZED_CONNECT_DELAY = Math.random() * ONE_SECOND + MIN_DELAY;

let reconnectAttempts = 0;

let onConnectCallback: OnConnectCallback | null | undefined = null;

export const configureConnectCallback = (cb: OnConnectCallback) => {
    onConnectCallback = cb;
};

const getReconnectTimeout = (attempts: number) => {
    // simplified binary exponential backoff based on https://en.wikipedia.org/wiki/Exponential_backoff#Expected_backoff
    const EXPONENT_BASE = 2;
    const MAX_DELAY = 60000 + RANDOMIZED_CONNECT_DELAY;

    // this yields the following reconnect attemps:
    // 1. random delay + 2000 ms (1000 * 2 ^ 1)
    // 2. random delay + 4000 ms (1000 * 2 ^ 2)
    // 3. random delay + 8000 ms (1000 * 2 ^ 3)
    // 4. random delay + 16000 ms (1000 * 2 ^ 4)
    // 5. random delay + 32000 ms
    // 6. > max reconnect interval: random delay + 60000 ms
    let timeout = RANDOMIZED_CONNECT_DELAY + ONE_SECOND * EXPONENT_BASE ** attempts;
    timeout = Math.min(timeout, MAX_DELAY);

    return timeout;
};

const cacheReturnCodeLookup = [
    /* 0 */
    "", // Not used
    /* 1 */
    "OK",
    /* 2 */
    "FAIL",
    /* 3 */
    "INCOMPLETE",
];

const cacheSubCodeLookup = [
    /* 0 */
    "REQUEST_COMPLETE",
    /* 1 */
    "LIVE_DATA_FULFILL",
    /* 2 */
    "ERROR_RESPONSE",
    /* 3 */
    "INVALID_SESSION",
    /* 4 */
    "REQUEST_TIMEOUT",
    /* 5 */
    "REQUEST_ALREADY_IN_PROGRESS",
    /* 6 */
    "NO_DATA",
    /* 7 */
    "SUSPECT_DATA",
    /* 8 */
    "CACHE_SESSION_DISPOSED",
    /* 9 */
    "SUBSCRIPTION_ERROR",
];

let cacheProperties: solaceNamespace.CacheSessionProperties | null = null;
let topicHandlers: {
    [topicName: string]: {
        handlers: MessageHandlerFunction[];
        useCache: boolean;
        registerMessageHandlerOnly: boolean;
    };
} = {};
export type Client = {
    session: solaceNamespace.Session | null;
    cacheSession: solaceNamespace.CacheSession | null;
};
let client: Client | null = null;
let isConnected = false;
let isReconnectScheduled = false;
let cacheRequestId = 0;
let pendingUnsubscriptions: Array<{
    topicName: string;
    messageHandler: MessageHandlerFunction;
}> = [];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const solaceDebug = (msg: string, details?: any) => log.debug(`solace: ${msg}`, details);
// eslint-disable-next-line atg/log-syntax, @typescript-eslint/no-explicit-any
const solaceWarn = (msg: string, details?: any) => log.warn(`solace: ${msg}`, details);
// eslint-disable-next-line atg/log-syntax, @typescript-eslint/no-explicit-any
const solaceErr = (msg: string, details?: any) => log.error(`solace: ${msg}`, details);

async function createSessionProperties() {
    const solace = await getSolace();
    const sessionProperties = new solace.SessionProperties({});
    const useProdSolace = Storage.getItem("radarDebugger", Storage.SESSION) === "true";
    const clientConfig = useProdSolace
        ? // eslint-disable-next-line global-require
          require("@atg-shared/client-config/prod").default.config
        : root.clientConfig;
    sessionProperties.url = clientConfig.solaceWebsocketURL;
    sessionProperties.vpnName = clientConfig.solaceVpnName;
    sessionProperties.userName = "web";
    sessionProperties.password = clientConfig.solacePassword;

    return sessionProperties;
}

export async function onSessionEvent(
    _session: solaceNamespace.Session,
    event: solaceNamespace.SessionEvent,
) {
    const solace = await getSolace();
    const {
        UP_NOTICE,
        DISCONNECTED,
        SUBSCRIPTION_ERROR,
        SUBSCRIPTION_OK,
        DOWN_ERROR,
        RECONNECTING_NOTICE,
        RECONNECTED_NOTICE,
        CONNECT_FAILED_ERROR,
        VIRTUALROUTER_NAME_CHANGED,
    } = solace.SessionEventCode;
    const {sessionEventCode} = event;
    solaceDebug("New solace event:", {sessionEventCode});
    switch (sessionEventCode) {
        case UP_NOTICE:
        case RECONNECTED_NOTICE: {
            const msg =
                sessionEventCode === RECONNECTED_NOTICE
                    ? "Reconnected, will resume subscriptions"
                    : "Connected, will subscribe";
            solaceDebug(msg, {sessionEventCode});
            isConnected = true;
            reconnectAttempts = 0;

            if (onConnectCallback) onConnectCallback(client);
            resumeSubscriptions();

            pendingUnsubscriptions.forEach((unsub) =>
                unsubscribe(unsub.topicName, unsub.messageHandler),
            );
            pendingUnsubscriptions = [];
            break;
        }
        case CONNECT_FAILED_ERROR:
        case VIRTUALROUTER_NAME_CHANGED:
        case DOWN_ERROR:
            solaceDebug("Connect failed error", {sessionEventCode});
            isConnected = false;
            reconnect();
            break;
        case DISCONNECTED:
            isConnected = false;
            solaceDebug("Solace disconnected");
            break;
        case RECONNECTING_NOTICE:
            solaceDebug("Solace is reconnecting...");
            break;
        case SUBSCRIPTION_ERROR: {
            const topicName = (event.correlationKey as CorrelationKey)?.topicName;
            solaceWarn("Cannot subscribe to topic", {name: topicName});
            delete topicHandlers[topicName];
            break;
        }
        case SUBSCRIPTION_OK:
            solaceDebug("Subscription event successful", {
                topics: Object.keys(topicHandlers)
                    .map((key) => `${key}: ${topicHandlers[key].handlers.length}`)
                    .join(", "),
            });
            break;
        default:
            solaceWarn("Unexpected event", {sessionEventCode});
    }
}

/**
 * Function that returns the right topic name in topicHandlers given
 * topicName recived in push message. It first tries to match the topicName directly.
 * If no topic name found it tries to match topicName to a root topic (e.g. chat/*)
 * and returns that rootTopic.
 */
export const getMatchingTopicName = (topicName: string): string | null | undefined => {
    if (!isEmpty(topicHandlers[topicName])) return topicName;

    const rootTopic = find(Object.keys(topicHandlers), (topic) => {
        if (!topic.includes("*") && !topic.includes(">")) return false;
        const topicRoot = topic.replace("*", "").replace(">", "");
        return topicName.substr(0, topicRoot.length) === topicRoot;
    });

    return rootTopic || null;
};

/**
 * Callback fired when we get a pushed message from Solace
 */
async function onReceivePush(
    _session: solaceNamespace.Session,
    message: solaceNamespace.Message,
) {
    const solace = await getSolace();
    const name = message.getDestination()?.getName();
    if (!name) return;
    const matchingTopicName = getMatchingTopicName(name);
    if (!matchingTopicName) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let parsedMessage: any;
    if (message.getType() === solace.MessageType.TEXT) {
        const value = message.getSdtContainer()?.getValue();
        if (value) parsedMessage = JSON.parse(value);
    } else if (message.getType() === solace.MessageType.BINARY) {
        const content = message.getXmlContent();
        if (content) parsedMessage = JSON.parse(content);
    }
    solaceDebug("Received message on topic", {name, parsedMessage});
    forEach(topicHandlers[matchingTopicName].handlers, (handler) =>
        handler(parsedMessage),
    );
}

async function onCacheResponse(
    requestId: number,
    result: solaceNamespace.CacheRequestResult,
) {
    // Only for debugging purposes..
    const solace = await getSolace();
    const returnCode = result.getReturnCode();
    const subcode = result.getReturnSubcode();
    const topic = result.getTopic();
    const error = result.getError();

    const message = {
        returnCode: `${returnCode}: ${cacheReturnCodeLookup[returnCode]}`,
        subcode: `${subcode}: ${cacheSubCodeLookup[subcode]}`,
        topic: topic.getName(),
        error,
        requestId,
    };
    if (result.getReturnCode() !== solace.CacheReturnCode.OK) {
        solaceDebug("DEBUG: CACHE_REQUEST NOT OK:", {message});
    } else {
        solaceDebug("DEBUG: CACHE_REQUEST OK:", {message});
    }
}

/**
 * Main initialization of the solace
 */
export async function initSolace() {
    /**
     * We don't want to init Solace when pre-rendering since it is not needed for inital render and
     * crawlers like GoogleBot does not support web sockets which leads to an error when trying to
     * create the session if the page is rendered by a crawler (through Prerender)
     */
    if (root.isPrerender) return null;
    const isDevelopment = process.env.NODE_ENV === "development";

    const solace = await getSolace();

    const factoryProps = new solace.SolclientFactoryProperties();
    // set profile version, since default is an older version7
    factoryProps.profile = solace.SolclientFactoryProfiles.version10;

    if (isDevelopment) {
        const prefixLogger = (f: typeof solace.LogImpl.loggingCallback) =>
            partial(f, "#Solace");
        factoryProps.logger = new solace.LogImpl(
            prefixLogger(log.trace),
            prefixLogger(log.debug),
            prefixLogger(log.info),
            prefixLogger(log.warn),
            prefixLogger(log.error),
            prefixLogger(log.error), // Solace LogImpl also has fatal option, we just use error.
        );
    }
    factoryProps.logLevel = solace.LogLevel.WARN;
    solace.SolclientFactory.init(factoryProps);
    factoryProps.logLevel = solace.LogLevel.WARN;
    // small delay. perhaps may be skipped.
    await delay(RANDOMIZED_CONNECT_DELAY);

    await connect();

    return client;
}

/**
 * Creates a connection to the Solace websocket
 */
export async function connect(): Promise<Client> {
    try {
        const solace = await getSolace();

        // create session and add push and event listeners
        const session = solace.SolclientFactory.createSession(
            await createSessionProperties(),
            // @ts-expect-error `MessageRxCBInfo` is missing from the types.
            new solace.MessageRxCBInfo((_session, message) =>
                onReceivePush(_session, message),
            ),
            // @ts-expect-error `SessionEventCBInfo` is missing from the types.
            new solace.SessionEventCBInfo((_session, event) =>
                onSessionEvent(_session, event),
            ),
        );

        if (!cacheProperties)
            cacheProperties = new solace.CacheSessionProperties("atgse", 0, 1, 10000);

        const cacheSession = session.createCacheSession(cacheProperties);

        session.connect();

        client = {session, cacheSession};
    } catch (error: unknown) {
        solaceWarn("Failed to connect. Will reconnect.", {error: serializeError(error)});
        reconnect();
        client = {session: null, cacheSession: null};
    }

    return client;
}

/**
 * Reconnect to the socket with an exponential delay.
 */
export async function reconnect() {
    if (isReconnectScheduled) {
        solaceDebug("Reconnect already schedulled");
        return;
    }
    isReconnectScheduled = true;

    disconnect();

    const timeout = getReconnectTimeout(reconnectAttempts);

    solaceDebug("Will reconnect after delay", {delay: timeout / 1000});

    await delay(timeout);

    reconnectAttempts++;
    solaceDebug("Reconnect attempt", {reconnectAttempts});
    isReconnectScheduled = false;
    await connect();
}

/**
 * Disposes current sessions
 * There is also a client.session.disconnect, but it seems to be enough to just use dispose for our purposes
 */
export function disconnect() {
    if (!client) {
        solaceDebug("Attempted to disconnect without a client");
        return;
    }

    try {
        if (client.session) {
            client.session.dispose();
            client.session = null;
        }

        if (client.cacheSession) {
            client.cacheSession.dispose();
            client.cacheSession = null;
        }

        isConnected = false;

        solaceDebug("Disposed session and cache");
    } catch (err: unknown) {
        solaceErr("Error while disconnecting", {error: serializeError(err)});
    }
}

/**
 * Subscribes to a topic on a websocket
 */
export async function subscribe(
    topicName: string,
    messageHandler: MessageHandlerFunction,
    useCache = true,
    registerMessageHandlerOnly = false,
) {
    solaceDebug("Adding topic handler", {
        topicName,
        useCache,
        registerMessageHandlerOnly,
    });
    if (!topicHandlers[topicName])
        topicHandlers[topicName] = {handlers: [], useCache, registerMessageHandlerOnly};
    if (!includes(topicHandlers[topicName].handlers, messageHandler)) {
        topicHandlers[topicName].handlers.push(messageHandler);
    }

    if (client && isConnected) {
        subscribeAndRequestCache(topicName);
    } else {
        solaceDebug("Not connected, topic handler not subscribed");
    }
    return () => unsubscribe(topicName, messageHandler);
}

/**
 * Actually subscribes to the socket topic and requests a cache
 */
async function subscribeAndRequestCache(topicName: string) {
    const solace = await getSolace();

    const topicHandler = topicHandlers[topicName];
    if (topicHandler && topicHandler.registerMessageHandlerOnly) {
        solaceDebug(
            `Subscription itself for "${topicName} is handled 'on-behalf-of' from backend but messageHandlerFunction was registered to handle incoming messages accordingly`,
        );
        return;
    }

    const useCache = topicHandler && topicHandler.useCache;
    solaceDebug(
        `Subscribes and issues a ${useCache ? "cache" : "non-cache"} request for a topic`,
        {topicName},
    );
    // @ts-expect-error `SolclientFactory.createTopic` is missing from the types.
    const topic = solace.SolclientFactory.createTopic(topicName);
    const cacheCBInfo = new solace.CacheCBInfo(onCacheResponse, {});
    try {
        if (useCache) {
            // https://docs.solace.com/Solace-PubSub-Cache/Working-with-JavaScript-API.htm
            // Wildcard cache requests are only supported for FLOW_THRU cache requests.
            // When a wildcard request matches multiple cached subjects, there is no temporal
            // ordering guarantee between the individual topics returned
            // (although temporal ordering is preserved amongst the messages for a specific topic).
            client?.cacheSession?.sendCacheRequest(
                cacheRequestId++,
                topic,
                /* subscribe: */
                true,
                solace.CacheLiveDataAction.FLOW_THRU,
                cacheCBInfo,
            );
        } else {
            const correlationKey: CorrelationKey = {topicName};
            client?.session?.subscribe(topic, true, correlationKey, 10000);
        }
    } catch (err: unknown) {
        solaceDebug(
            `Failed to subscribe to topic ${topicName}, topic handler not subscribed`,
            {error: err},
        );
    }
}

/**
 * Unsubscribe from a topic and remove the handler
 */
export async function unsubscribe(
    topicName: string,
    messageHandler: MessageHandlerFunction,
) {
    const solace = await getSolace();

    if (!isConnected) {
        solaceDebug("Not connected, unsubscribing later", {topicName});
        pendingUnsubscriptions.push({topicName, messageHandler});
        return;
    }

    const topicHandler = topicHandlers[topicName];
    if (topicHandler) {
        solaceDebug("Removing topic handler", {topicName});
        topicHandler.handlers = topicHandler.handlers.filter(
            (handler) => handler !== messageHandler,
        );

        if (
            topicHandler.handlers.length === 0 &&
            !topicHandler.registerMessageHandlerOnly
        ) {
            try {
                // No listeners remaining - unsubscribe from socket
                solaceDebug("Unsubscribing from topic", {topicName});
                // @ts-expect-error `SolclientFactory.createTopic` is missing from the types.
                const topic = solace.SolclientFactory.createTopic(topicName);
                const correlationKey: CorrelationKey = {topicName};
                client?.session?.unsubscribe(topic, true, correlationKey, 10000);
                topicHandlers = omit(topicHandlers, topicName);
            } catch (err: unknown) {
                solaceWarn("Error while unsubscribing", {error: serializeError(err)});
            }
        }
    }
}

/**
 * unsubscribe from the socket, but do not remove handlers
 */
export async function pauseSubscriptions() {
    if (!client) {
        solaceDebug("Attempted to pauseSubscriptions without a client");
        return;
    }

    const solace = await getSolace();

    Object.entries(topicHandlers).forEach(([topicName, topicHandler]) => {
        try {
            if (topicHandler && !topicHandler.registerMessageHandlerOnly) {
                solaceDebug("Pause subscription to topic", {topicName});
                // @ts-expect-error `SolclientFactory.createTopic` is missing from the types.
                const topic = solace.SolclientFactory.createTopic(topicName);
                const correlationKey: CorrelationKey = {topicName};
                client?.session?.unsubscribe(topic, true, correlationKey, 10000);
            }
        } catch (err: unknown) {
            solaceDebug("failed to unsubscribe", {error: serializeError(err)});
        }
    });
}

/**
 * resume the subscriptions that we have handlers for.
 */
export async function resumeSubscriptions() {
    if (!client) {
        solaceDebug("Attempted to resumeSubscriptions without a client");
        return;
    }

    Object.keys(topicHandlers).forEach((topicName) => {
        subscribeAndRequestCache(topicName);
    });
}

/**
 * get Solace client session
 */
export function getClient() {
    return client;
}
