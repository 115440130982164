import type {
    ShowModalAction,
    HideModalAction,
    NoInterruptModeAction,
    DoRealityCheckAction,
    CloseModalAction,
} from "@atg-responsible-gambling-shared/reality-check-types";
import {constants} from "@atg-responsible-gambling-shared/reality-check-types";
import {frameAction} from "atg-store-addons";

const {
    SHOW_MODAL,
    HIDE_MODAL,
    SET_NOINTERRUPT_MODE,
    DO_REALITY_CHECK,
    RESET_REALITY_CHECK,
    CLOSE_MODAL,
} = constants;

export const showModal = (startTime: number): ShowModalAction => ({
    type: SHOW_MODAL,
    payload: {startTime},
});

export const hideModal = (shouldLogout?: boolean): HideModalAction => ({
    type: HIDE_MODAL,
    payload: {shouldLogout},
});

export const closeModal = (): CloseModalAction => ({type: CLOSE_MODAL});

export const setNoInterruptMode = (noInterrupt?: boolean): NoInterruptModeAction =>
    frameAction({
        type: SET_NOINTERRUPT_MODE,
        payload: {noInterrupt: !!noInterrupt},
    });

export const doRealityCheck = (canInterrupt?: boolean): DoRealityCheckAction =>
    frameAction({
        type: DO_REALITY_CHECK,
        payload: {canInterrupt},
    });

export const resetRealityCheck = (username?: string) => ({
    type: RESET_REALITY_CHECK,
    payload: {username},
});
