import {injectGlobal} from "@emotion/css";
import {device} from "@atg/utils";

export const scrollbarTrackBgColor = "#797c7f";
export const scrollbarBgColor = "#20252a";

if (!device.isDevice()) {
    // We override thikness of the scroll for desktop only,
    // on mobile it's up to mobile OS to decide how to show it,
    // for example what color and what thikness and transparency
    injectGlobal`
        * {
            scrollbar-width: thin;
        }
`;
}
