import * as React from "react";
import {ClassNames, type SerializedStyles} from "@emotion/react";
import {Grid, Row, Col} from "react-flexbox-grid";
import * as styles from "./FluidPage.styles";

type Props = {
    xs?: number;
    xsOffset?: number;
    sm?: number;
    smOffset?: number;
    md?: number;
    mdOffset?: number;
    lg?: number;
    lgOffset?: number;
    children: React.ReactNode;
    wrapperClass?: string;
    gridClass?: string;
    rowClass?: string;
    colClass?: string;
    wrapperStyle?: SerializedStyles;
    gridStyle?: SerializedStyles;
    rowStyle?: SerializedStyles;
    colStyle?: SerializedStyles;
};
function FluidPage({
    children,
    xs = 12,
    xsOffset = 0,
    sm = 12,
    smOffset = 0,
    md = 12,
    mdOffset = 0,
    lg = 12,
    lgOffset = 0,
    wrapperClass,
    gridClass,
    rowClass,
    colClass,
    wrapperStyle,
    gridStyle,
    rowStyle,
    colStyle,
}: Props) {
    return (
        <ClassNames>
            {({css, cx}) => (
                <div className={cx(wrapperClass, css(styles.wrapper), css(wrapperStyle))}>
                    <Grid
                        fluid
                        className={cx(gridClass, css(gridStyle), css(styles.grid))}
                    >
                        <FluidRow
                            rowClass={cx(rowClass, rowStyle && css(rowStyle))}
                            colClass={cx(colClass, colStyle && css(colStyle))}
                            xs={xs}
                            xsOffset={xsOffset}
                            sm={sm}
                            smOffset={smOffset}
                            md={md}
                            mdOffset={mdOffset}
                            lg={lg}
                            lgOffset={lgOffset}
                        >
                            {children}
                        </FluidRow>
                    </Grid>
                </div>
            )}
        </ClassNames>
    );
}

type RowProps = {
    xs?: number;
    xsOffset?: number;
    sm?: number;
    smOffset?: number;
    md?: number;
    mdOffset?: number;
    lg?: number;
    lgOffset?: number;
    children: React.ReactNode;
    rowClass?: string;
    colClass?: string;
    rowStyle?: SerializedStyles;
    colStyle?: SerializedStyles;
};

export function FluidRow({
    children,
    xs = 12,
    xsOffset = 0,
    sm = 12,
    smOffset = 0,
    md = 12,
    mdOffset = 0,
    lg = 12,
    lgOffset = 0,
    rowClass,
    colClass,
    rowStyle,
    colStyle,
}: RowProps) {
    return (
        <ClassNames>
            {({css, cx}) => (
                <div>
                    <Row className={cx(rowClass, rowStyle && css(rowStyle))}>
                        <Col
                            className={cx(colClass, colStyle && css(colStyle))}
                            xs={xs}
                            xsOffset={xsOffset}
                            sm={sm}
                            smOffset={smOffset}
                            md={md}
                            mdOffset={mdOffset}
                            lg={lg}
                            lgOffset={lgOffset}
                        >
                            {children}
                        </Col>
                    </Row>
                </div>
            )}
        </ClassNames>
    );
}

type RowsProps = {
    children: React.ReactNode;
    rowClass?: string;
    gridClass?: string;
    wrapperClass?: string;
    rowStyle?: SerializedStyles;
    gridStyle?: SerializedStyles;
    wrapperStyle?: SerializedStyles;
};

export function FluidPageWithColumns({
    rowClass,
    gridClass,
    wrapperClass,
    rowStyle,
    gridStyle,
    wrapperStyle,
    children,
}: RowsProps) {
    return (
        <ClassNames>
            {({cx, css}) => (
                <div
                    className={cx(
                        css(styles.wrapper),
                        wrapperClass,
                        wrapperStyle && css(wrapperStyle),
                    )}
                >
                    <Grid
                        fluid
                        className={cx(
                            css(styles.grid),
                            gridClass,
                            gridStyle && css(gridStyle),
                        )}
                    >
                        <Row className={cx(rowClass, rowStyle && css(rowStyle))}>
                            {children}
                        </Row>
                    </Grid>
                </div>
            )}
        </ClassNames>
    );
}

type GridProps = {
    children: React.ReactNode;
    gridClass?: string;
    wrapperClass?: string;
    gridStyle?: SerializedStyles;
    wrapperStyle?: SerializedStyles;
};

export function FluidGrid({
    children,
    gridClass,
    wrapperClass,
    gridStyle,
    wrapperStyle,
}: GridProps) {
    return (
        <ClassNames>
            {({css, cx}) => (
                <div
                    className={cx(
                        css(styles.wrapper),
                        wrapperClass,
                        wrapperStyle && css(wrapperStyle),
                    )}
                >
                    <Grid
                        fluid
                        className={cx(
                            css(styles.grid),
                            gridClass,
                            gridStyle && css(gridStyle),
                        )}
                    >
                        {children}
                    </Grid>
                </div>
            )}
        </ClassNames>
    );
}

export default FluidPage;
