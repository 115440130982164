import * as React from "react";
import styled from "@emotion/styled";
import Features, {newVerticalNavbar} from "@atg-shared/client-features";
import {Placeholder, TextPlaceholder} from "atg-ui/components/Placeholder.styles";
import * as sidemenuStyles from "./SideMenu.styles";
import SideMenu from "./SideMenu";
import {listItem} from "./Links.styles";

export const BadgePlaceholder = styled(Placeholder)`
    border-radius: 5px;
    width: 56px;
    height: 56px;
    margin-right: 8px;
    background-color: #404d58;
`;

export const BadgeContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 8px 0 8px 24px;
`;

type ItemCountProps = {
    itemCount: number;
};

export function PlaceholderSectionItems({itemCount}: ItemCountProps) {
    return (
        <>
            {Array(itemCount)
                .fill(undefined)
                .map((_, i) => (
                    <div key={i} css={listItem}>
                        <div css={sidemenuStyles.iconContainer}>
                            <Placeholder
                                backgroundColor="#404d58"
                                height={20}
                                width={20}
                            />
                        </div>
                        <TextPlaceholder
                            backgroundColor="#404d58"
                            width={180}
                            height={16}
                        />
                    </div>
                ))}
        </>
    );
}

export function PlaceholderSection({itemCount}: ItemCountProps) {
    return (
        <>
            <div css={sidemenuStyles.headline}>
                <TextPlaceholder backgroundColor="#404d58" />
            </div>

            <PlaceholderSectionItems itemCount={itemCount} />
        </>
    );
}

function SideMenuPlaceholder() {
    const newNavEnabled = Features.isEnabled(newVerticalNavbar);
    return newNavEnabled ? (
        // todo: this placeholder is exactly like the old on except it wont show the three vertical links at the top
        <div
            style={{
                background: "linearGradient(180deg, #004F9F 0%, #1C3885 82.59%)",
                // temporary fix
                margin: "-32px",
            }}
        >
            <PlaceholderSection itemCount={6} />

            <div css={sidemenuStyles.headline}>
                <TextPlaceholder backgroundColor="#404d58" />
            </div>

            <BadgeContainer>
                <BadgePlaceholder />
                <BadgePlaceholder />
                <BadgePlaceholder />
                <BadgePlaceholder />
            </BadgeContainer>

            <PlaceholderSection itemCount={3} />
            <PlaceholderSection itemCount={4} />
            <PlaceholderSection itemCount={3} />
        </div>
    ) : (
        <SideMenu>
            <PlaceholderSection itemCount={6} />

            <div css={sidemenuStyles.headline}>
                <TextPlaceholder backgroundColor="#404d58" />
            </div>

            <BadgeContainer>
                <BadgePlaceholder />
                <BadgePlaceholder />
                <BadgePlaceholder />
                <BadgePlaceholder />
            </BadgeContainer>

            <PlaceholderSection itemCount={3} />
            <PlaceholderSection itemCount={4} />
            <PlaceholderSection itemCount={3} />
        </SideMenu>
    );
}

export default SideMenuPlaceholder;
