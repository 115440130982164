import * as React from "react";
import { createSvgIcon } from "../utils";

export const RadioChecked = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.1a8.9 8.9 0 1 0 0 17.8 8.9 8.9 0 0 0 0-17.8ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm5.04 0a4.96 4.96 0 1 1 9.92 0 4.96 4.96 0 0 1-9.92 0Z"
    />,
    "RadioChecked",
);

export default RadioChecked;
