import {useMediaQuery} from "@react-hookz/web";
import * as MediaQueries from "./mediaQueries";

/**
 * Check whether the device screen is _at least_ at or above a certain breakpoint
 *
 * Example
 * ```js
 * import {useBreakpoint} from "atg-breakpoints";
 *
 * const MyComponent = () => {
 *      const isAboveMd = useBreakpoint("MD");
 * };
 * ```
 */
const useBreakpoint = (breakpoint: keyof typeof MediaQueries) => {
    const match = useMediaQuery(MediaQueries[breakpoint]);

    if (process.env.NODE_ENV !== "production" && !(breakpoint in MediaQueries)) {
        // eslint-disable-next-line no-console
        console.error(`useBreakpoint: unknown breakpoint "${breakpoint}"`);
        return false;
    }

    return match;
};

export default useBreakpoint;
