import * as React from "react";
import useFocusTrap from "@atg-shared/hooks/useFocusTrap";

export interface Props {
    /**
     * The content of `TrapFocus`
     */
    children: Required<React.ReactNode>;
}
/**
 * Attempts to traps focus inside the component. This is a component wrapper for
 * the `atg-util/hooks/useFocusTrap` hook.
 *
 * NOTE: named `TrapFocus` (as opposed to `FocusTrap`) to avoid future conflict with
 * the `TrapFocus` component MUI in case this component gets replaced
 */
const TrapFocus = React.forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
    const {children} = props;
    const {startFocusTrapProps, endFocusTrapProps} = useFocusTrap({startRef: ref});

    return (
        <>
            <div {...startFocusTrapProps} />
            {children}
            <div {...endFocusTrapProps} />
        </>
    );
});

export default TrapFocus;
