import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {xs} from "atg-ui/css/variables.styles";

export const ListItemNotificationWrapper = styled.div`
    display: inline-flex;
    margin-left: 0;
    margin-right: auto;
    align-items: center;
`;

export const listItemNotification = css`
    position: static;
    margin-left: 4px;
`;

export const listItemNotificationText = css`
    line-height: 12px;
    margin-left: 4px;
`;

export const listItem = css`
    font-family: Roboto, sans-serif;
    font-weight: 500;
    text-transform: none;
    padding: 16px 24px;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    height: 46px;

    @media ${xs} {
        :hover {
            background-color: rgba(255, 255, 255, 0.08);
            transition: all 0.14s ease-in-out;
        }
    }
`;

export const listItemInverted = css`
    ${listItem};
    color: #1f262c;
    font-size: 14px;
    padding: 16px;
    justify-content: space-between;
    margin-bottom: 0;
    border-bottom: 1px solid #e0e2e5;
    height: 48px;

    svg {
        color: darkgrey;
    }

    &:last-child {
        border-bottom: none;
    }

    @media ${xs} {
        :hover {
            color: #fff;
            background: #014991;

            svg {
                color: #fff;
            }
        }
    }
`;
