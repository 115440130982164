const deepgreen = {
    50: "#EEFBF7",
    100: "#C0F2DF",
    200: "#81E4C0",
    300: "#6CE0B5",
    400: "#2DD296",
    500: "#28BD87",
    600: "#24A878",
    700: "#1F9268",
    800: "#2A7B59",
    900: "#31614C",
};

export default deepgreen;
