import type {Reducer} from "redux";
import {combineReducers} from "redux";
import {MemberActions} from "@atg-global-shared/member-data-access";
import type {
    MemberRegisterCreateData,
    MemberRegisterVerifyData,
    MemberRegisterVerifyErrorPayload,
    MemberRegisterCreateErrorPayload,
} from "./memberRegisterActions";
import {
    MEMBER_REGISTER_CREATE,
    MEMBER_REGISTER_CREATE_RESPONSE,
    MEMBER_REGISTER_VERIFY,
    MEMBER_REGISTER_VERIFY_RESPONSE,
    MEMBER_REGISTER_COMPLETE_DISMISS,
    MEMBER_REGISTER_SET_STEP,
    MEMBER_REGISTER_CLEAR,
    TOGGLE_FIRST_GAME_MODAL,
    DISABLE_WELCOME_MODAL,
} from "./memberRegisterActions";
import * as MemberRegisterSteps from "./memberRegisterSteps";
import type {RegisterStep} from "./memberRegisterSteps";
import type {Action} from "./types";

export type MemberRegisterVerifyError = MemberRegisterVerifyErrorPayload;
export type MemberRegisterCreateError = MemberRegisterCreateErrorPayload;

export type VerifyState = {
    data: MemberRegisterVerifyData;
    error: MemberRegisterVerifyError | null | undefined;
};

export type CreateState = {
    data: MemberRegisterCreateData;
    error: MemberRegisterCreateError | null | undefined;
};

export type MemberFirstGameState = {showModal: boolean};
export type DisableWelcomeModal = boolean;

export type MemberRegisterState = {
    verify: VerifyState;
    create: CreateState;
    loading: boolean;
    step: RegisterStep | null | undefined;
    memberFirstGame: MemberFirstGameState | null | undefined;
    disableWelcomeModal: DisableWelcomeModal;
};

const memberRegisterVerifyData = (
    state = {},
    action: Action,
): MemberRegisterVerifyData => {
    switch (action.type) {
        case MEMBER_REGISTER_VERIFY_RESPONSE:
            if (action.error) return state;
            return action.payload;
        case MEMBER_REGISTER_CLEAR:
        case MemberActions.MEMBER_REGISTER_CANCEL:
        case MEMBER_REGISTER_COMPLETE_DISMISS:
            return {};
        default:
            return state;
    }
};

const memberRegisterVerifyError = (
    state: MemberRegisterVerifyError | null | undefined = null,
    action: Action,
): MemberRegisterVerifyError | null | undefined => {
    switch (action.type) {
        case MEMBER_REGISTER_VERIFY_RESPONSE:
            if (!action.error) return null;
            return action.payload;
        case MEMBER_REGISTER_CLEAR:
        case MemberActions.MEMBER_REGISTER_CANCEL:
        case MEMBER_REGISTER_COMPLETE_DISMISS:
            return null;
        default:
            return state;
    }
};

const memberRegisterCreateData = (
    state = {},
    action: Action,
): MemberRegisterCreateData => {
    switch (action.type) {
        case MEMBER_REGISTER_CREATE_RESPONSE:
            if (action.error) return state;
            return {
                ...state,
                ...action.payload,
            };
        case MEMBER_REGISTER_CLEAR:
        case MemberActions.MEMBER_REGISTER_CANCEL:
        case MEMBER_REGISTER_COMPLETE_DISMISS:
            return {};
        default:
            return state;
    }
};

const memberRegisterCreateError = (
    state: MemberRegisterCreateError | null | undefined = null,
    action: Action,
): MemberRegisterCreateError | null | undefined => {
    switch (action.type) {
        case MEMBER_REGISTER_CREATE_RESPONSE:
            if (!action.error) return null;
            return action.payload;
        case MEMBER_REGISTER_CLEAR:
        case MemberActions.MEMBER_REGISTER_CANCEL:
        case MEMBER_REGISTER_COMPLETE_DISMISS:
            return null;
        default:
            return state;
    }
};

const memberRegisterLoading = (state = false, action: Action): boolean => {
    switch (action.type) {
        case MEMBER_REGISTER_VERIFY:
        case MEMBER_REGISTER_CREATE:
            return true;
        case MEMBER_REGISTER_VERIFY_RESPONSE:
        case MEMBER_REGISTER_SET_STEP:
            return false;
        case MEMBER_REGISTER_CREATE_RESPONSE:
            return !action.error;
        case MEMBER_REGISTER_CLEAR:
        case MemberActions.MEMBER_REGISTER_CANCEL:
        case MEMBER_REGISTER_COMPLETE_DISMISS:
            return false;
        default:
            return state;
    }
};

const memberRegisterStep = (
    state: RegisterStep | null | undefined = null,
    action: Action,
): RegisterStep | null | undefined => {
    switch (action.type) {
        case MemberActions.START_REGISTER_FLOW:
            return MemberRegisterSteps.REGISTER_USER_VERIFY_STEP;
        case MEMBER_REGISTER_SET_STEP:
            return action.payload;
        case MemberActions.MEMBER_REGISTER_FINISHED:
            return MemberRegisterSteps.REGISTER_USER_COMPLETED_STEP;
        case MEMBER_REGISTER_COMPLETE_DISMISS:
        case MemberActions.MEMBER_REGISTER_CANCEL:
            return null;
        default:
            return state;
    }
};

const memberFirstGame = (
    state: MemberFirstGameState | null | undefined = {showModal: false},
    action: Action,
): MemberFirstGameState | null | undefined => {
    switch (action.type) {
        case TOGGLE_FIRST_GAME_MODAL:
            return action.payload;
        default:
            return state;
    }
};

const disableWelcomeModal = (state = false, action: Action): DisableWelcomeModal => {
    switch (action.type) {
        case DISABLE_WELCOME_MODAL:
            return true;
        default:
            return state;
    }
};

const memberRegisterReducer: Reducer<MemberRegisterState, Action> = combineReducers({
    verify: combineReducers({
        data: memberRegisterVerifyData,
        error: memberRegisterVerifyError,
    }),
    create: combineReducers({
        data: memberRegisterCreateData,
        error: memberRegisterCreateError,
    }),
    loading: memberRegisterLoading,
    step: memberRegisterStep,
    memberFirstGame,
    disableWelcomeModal,
});

export default memberRegisterReducer;
