import type {GlobalAnalyticsTypes} from "@atg-global-shared/analytics";
import type {MemberSegmentation} from "./types";

export const formatToGTM = (
    data: MemberSegmentation,
): GlobalAnalyticsTypes.MemberSegmentationGTM => ({
    channelClassification: data?.channelClassification?.channelClass,
    crossSelling: data?.crossSelling?.crossSellingClass
        ?.sort((a, b) => a.priority - b.priority)
        .filter((xSell) => xSell?.classType !== "INACTIVE")
        .map((xSell) => `${xSell?.priority} - ${xSell?.classType}`),
    verticalJourneys: data?.verticalJourneys
        ?.map((journey) => `${journey?.vertical} - ${journey?.category}`)
        .sort((a, b) => a.localeCompare(b))
        .join(","),
    customerJourneyPhase: data?.customerJourney?.phase,
});
