import type {LazyStore} from "@atg-shared/lazy-store";
import {runLazySaga} from "@atg-shared/lazy-store";
import {clientVersionSaga} from "@atg-shared/client-version";
import accessTokenSaga from "@atg-shared/auth/domain/accessTokenSaga";
import authSaga from "@atg-shared/auth/domain/authSaga";
import {fetchSaga} from "@atg-shared/fetch-redux";
import {isAppWebview} from "@atg-shared/system";
import {userGamblingSummarySaga} from "@atg-responsible-gambling-shared/user-gambling-summary-domain";
import {realityCheckSaga} from "@atg-responsible-gambling-shared/reality-check-domain";
import {
    alertSaga,
    unauthorizedLoginAlertSaga,
} from "@atg-global-shared/alerts-data-access";
import retailStoreSaga from "@atg-payment-shared/framtidens-butik-domain";
// @ts-expect-error Flow
import cmsSaga from "@atg-global-shared/legacy-cms/domain/cmsSaga";
import userAnalyticsSaga from "@atg-global-shared/user/userAnalyticsSaga";
import {memberFlowSaga, memberAnalyticsSaga} from "@atg-global-shared/member-data-access";
import userSaga from "@atg-global-shared/user/userSaga";
import {
    DepositSaga,
    DepositFlowSaga,
    DepositAnalyticsSaga,
} from "@atg-payment-shared/deposit-domain";
import {loginTimeSaga} from "@atg-global-shared/login-time-data-access";
import appBridgeSaga from "@mobileapp-shared/atgse-bridge/domain/appBridgeSaga";
import pushSaga from "@atg-frame-shared/push-saga/domain/pushSaga";
import {kycQuestionnaireFrameSaga} from "@atg-aml-shared/kyc-domain";
import {limitsSaga} from "@atg-responsible-gambling-shared/limits-domain";
import {memberSegmentationSaga} from "@atg-content-shared/segmentation";
import {loginSaga as loginHAAPISaga} from "@atg-login/data-access";
import loginSaga from "atg-member-login/domain/loginSaga";
import curityLoginSaga from "atg-member-login/domain/curityLoginSaga";
import memberRegisterSaga from "atg-member-register/domain/memberRegisterSaga";
import toastSaga from "atg-ui-toast/domain/toastSaga";
import postLoginFlow from "atg-member-login/domain/postLoginSaga";
import frameSaga from "./domain/frameSaga";

export default function rootSaga(store: LazyStore<any, any>) {
    runLazySaga(store, cmsSaga);
    runLazySaga(store, pushSaga, store.dispatch);
    runLazySaga(store, postLoginFlow);
    runLazySaga(store, kycQuestionnaireFrameSaga);
    runLazySaga(store, retailStoreSaga);
    runLazySaga(store, unauthorizedLoginAlertSaga);
    runLazySaga(store, memberRegisterSaga);
    runLazySaga(store, authSaga);
    runLazySaga(store, memberAnalyticsSaga);
    runLazySaga(store, loginSaga);
    /**
     * Some actions like MemberActions are listened for in the new Curity saga flow.
     * This prevents them from being yielded when the feature is disabled.
     *
     * Note: We need to avoid render AppWebView at the new login flow. https://atgse.atlassian.net/browse/CUST-1198
     */
    if (!isAppWebview) runLazySaga(store, loginHAAPISaga);
    runLazySaga(store, curityLoginSaga);
    runLazySaga(store, accessTokenSaga);
    runLazySaga(store, memberFlowSaga);
    runLazySaga(store, userSaga);
    runLazySaga(store, realityCheckSaga);
    runLazySaga(store, userAnalyticsSaga);
    runLazySaga(store, DepositSaga);
    runLazySaga(store, DepositFlowSaga);
    runLazySaga(store, DepositAnalyticsSaga);
    runLazySaga(store, toastSaga);
    runLazySaga(store, alertSaga);
    runLazySaga(store, fetchSaga);
    runLazySaga(store, userGamblingSummarySaga);
    runLazySaga(store, loginTimeSaga);
    runLazySaga(store, clientVersionSaga);
    runLazySaga(store, appBridgeSaga);
    runLazySaga(store, limitsSaga);
    runLazySaga(store, frameSaga);
    runLazySaga(store, memberSegmentationSaga);
}
