import {select, takeLatest} from "redux-saga/effects";
import {trackVariable} from "@atg-shared/analytics";
import {
    ACR_USERNAME_PASSWORD,
    ACR_BANKID,
    ACR_BANKID_QR,
    ACR_FREJA_EID,
    ACR_FREJA_EID_QR,
} from "@atg-shared/auth/domain/accessTokenConstants";
import * as AuthSelectors from "@atg-shared/auth/domain/authSelectors";
import type {ACR} from "@atg-shared/auth/domain/accessTokenActions";
import * as UserSelectors from "./userSelectors";
import type {AuthorizedUser} from "./user.types";
import {LOGIN_FINISHED, RECEIVE_USER} from "./userActionTypes";

const getAuthenticationType = (acr?: ACR) => {
    switch (acr) {
        case ACR_USERNAME_PASSWORD:
            return "USERNAME_PASSWORD";
        case ACR_BANKID:
            return "BANKID";
        case ACR_BANKID_QR:
            return "BANKID_QR";
        case ACR_FREJA_EID:
            return "FREJA_EID";
        case ACR_FREJA_EID_QR:
            return "FREJA_EID_QR";

        default:
            return null;
    }
};

export function* logUserAnalytics() {
    const user: AuthorizedUser = yield select(UserSelectors.getUser);
    const authType: ACR = yield select(AuthSelectors.getAuthenticationType);

    const userData = user.loggedIn
        ? {
              userStatus: "logged in" as const,
              userAuthType: getAuthenticationType(authType),
              userEncryptedId: user.analyticsId,
              userYob: user.yearOfBirth,
              userGender: user.sex,
              userAccountBalance: user.balance && user.balance.amount,
              userShowBalance: user.showBalance ? "show balance" : "hide balance",
          }
        : {
              userStatus: "not logged in" as const,
              userEncryptedId: null,
              userYob: null,
              userGender: null,
              userAccountBalance: null,
              userShowBalance: null,
          };

    trackVariable(userData);
}

function* userAnalyticsSaga() {
    yield takeLatest([LOGIN_FINISHED, RECEIVE_USER], logUserAnalytics);
}

export default userAnalyticsSaga;
