import type {Theme, Components} from "..";
import {alpha} from "..";

type MuiToggleButtonGroupOverrides = Components<Theme>["MuiToggleButtonGroup"];

const overrides: MuiToggleButtonGroupOverrides = {
    defaultProps: {
        color: "primary",
        classes: {
            grouped: "AtgToggleButtonGroup-grouped",
        },
    },
    styleOverrides: {
        root: ({ownerState, theme}) => ({
            ...(ownerState.color &&
                ownerState.color !== "standard" && {
                    margin: theme.spacing(-0.25),
                }),
        }),
        grouped: ({ownerState, theme}) => ({
            ...(ownerState.color &&
                ownerState.color !== "standard" && {
                    "&.AtgToggleButtonGroup-grouped": {
                        margin: theme.spacing(0.25),
                        borderRadius: theme.shape.borderRadius,
                        border: `1px solid ${alpha(
                            theme.palette[ownerState.color].main,
                            0.5,
                        )}`,
                    },
                    "&.Mui-selected + .MuiToggleButtonGroup-grouped.Mui-selected": {
                        margin: theme.spacing(0.25),
                        border: `1px solid ${alpha(
                            theme.palette[ownerState.color].main,
                            0.5,
                        )}`,
                    },
                }),
        }),
    },
};

export default overrides;
