/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import type {EmotionStyle} from "../../utils/emotion";
import * as styles from "./ButtonBase.styles";

type CustomProps = {
    /**
     * The content of `ButtonBase`.
     */
    children?: React.ReactNode;
    /**
     * The component or element used for the root node.
     */
    component?: React.ElementType;
    /**
     * If `true`, `ButtonBase` is disabled.
     */
    disabled?: boolean;
    /**
     * disables focus styles. Useful for when creating a custom focus style.
     */
    disableFocusStyle?: boolean;
    /**
     * @ignore
     */
    href?: string;
    /**
     * @ignore
     */
    role?: string;
    /**
     * [Emotion 10 style object used in place of the traditional className.](https://developer.atg.se/frontend/styling.html#emotion)
     */
    style?: EmotionStyle;
    /**
     * @ignore
     */
    tabIndex?: string | number;
    /**
     * @ignore
     */
    type?: string;
};

export type Props = Omit<React.HTMLAttributes<HTMLElement>, keyof CustomProps> &
    CustomProps;

/**
 * `ButtonBase` purpose is to be a simple building block for creating buttons or
 * button-like components. Styling is kept to a minimum and contains mostly resets.
 * Useful for when non-button components need to act as buttons.
 */
const ButtonBase = React.forwardRef<HTMLElement, Props>(
    (
        {
            children,
            component: Component = "button",
            disabled = false,
            disableFocusStyle = false,
            href,
            role,
            style,
            tabIndex = 0,
            type,
            ...other
        }: Props,
        ref,
    ) => {
        const buttonProps: Record<string, unknown> = {};

        if (Component === "button") {
            buttonProps.type = type === undefined ? "button" : type;
            buttonProps.disabled = disabled;
        } else {
            if (Component === "a") {
                buttonProps.href = href;
            }

            if (Component !== "a" || !href) {
                buttonProps.role = "button";
            }

            buttonProps["aria-disabled"] = disabled;
        }

        // if role is explicitly set
        if (role) {
            buttonProps.role = role;
        }

        return (
            <Component
                {...other}
                {...buttonProps}
                css={[
                    styles.rootStyle({
                        disabled,
                        disableFocusStyle,
                    }),
                    style,
                ]}
                ref={ref}
                tabIndex={disabled ? -1 : tabIndex}
            >
                {children}
            </Component>
        );
    },
);
export default ButtonBase;
