import type {Breakpoint, Breakpoints} from "@mui/material/styles";
import type {
    Mixins as MixinsType,
    MixinOptions as MixinOptionsType,
} from "@mui/material/styles/createMixins";
import type {Spacing} from "@mui/system/createTheme/createSpacing";

/**
 * Patch types with module augmentation
 * rename type imports to avoid conflicting names
 */
declare module "@mui/material/styles/createMixins" {
    interface Mixins {
        gutters: (amount?: number) => React.CSSProperties;
        contain: (breakpoint?: Breakpoint) => React.CSSProperties;
        horizontalRhythm: (amount?: number, selector?: string) => React.CSSProperties;
        verticalRhythm: (amount?: number, selector?: string) => React.CSSProperties;
        lineClamp: (numberOfLines: number) => React.CSSProperties;
        scrollable: React.CSSProperties;
    }

    interface MixinOptions {
        gutters?: (amount?: number) => React.CSSProperties;
        contain?: (breakpoint?: Breakpoint) => React.CSSProperties;
        horizontalRhythm?: (amount?: number, selector?: string) => React.CSSProperties;
        verticalRhythm?: (amount?: number, selector?: string) => React.CSSProperties;
        lineClamp?: (numberOfLines: number) => React.CSSProperties;
        scrollable?: React.CSSProperties;
    }
}

export default function createMixins(
    breakpoints: Breakpoints,
    spacing: Spacing,
    mixins: MixinOptionsType = {},
): MixinsType {
    return {
        toolbar: {
            // Mui default
            minHeight: 56,
            [`${breakpoints.up("xs")} and (orientation: landscape)`]: {
                minHeight: 48,
            },
            [breakpoints.up("sm")]: {
                minHeight: 64,
            },
        },
        gutters: (amount = 2) => ({
            paddingLeft: spacing(amount),
            paddingRight: spacing(amount),
        }),
        contain: (breakpoint: Breakpoint = "lg") => ({
            maxWidth: breakpoints.values[breakpoint] ?? breakpoint,
            marginRight: "auto",
            marginLeft: "auto",
        }),
        // Higher CSS specificity is needed, hence the `:not(style)` selector is needed.
        // https://github.com/mui-org/material-ui/issues/26384#issuecomment-844890584
        horizontalRhythm: (amount = 1, selector = ":not(style)") => ({
            [`& > ${selector} + ${selector}`]: {
                marginLeft: spacing(amount),
            },
        }),
        verticalRhythm: (amount = 1, selector = ":not(style)") => ({
            [`& > ${selector} + ${selector}`]: {
                marginTop: spacing(amount),
            },
        }),
        lineClamp: (lines: number) => ({
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: lines,
            overflow: "hidden",
        }),
        scrollable: {
            overscrollBehaviorY: "contain",
            overflowX: "hidden",
            overflowY: "auto",
            // Add iOS momentum scrolling.
            WebkitOverflowScrolling: "touch",
        },
        ...mixins,
    };
}
