const purple = {
    50: "#F9EBFA",
    100: "#EEC2F0",
    200: "#DE84E0",
    300: "#D35CD6",
    400: "#C833CC",
    500: "#B42EB8",
    600: "#A029A3",
    700: "#8C248F",
    800: "#711970",
    900: "#681767",
};

export default purple;
