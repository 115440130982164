import type {RegisterStep} from "./memberRegisterSteps";
import type {
    MemberRegisterState,
    MemberRegisterVerifyError,
    MemberRegisterCreateError,
    DisableWelcomeModal,
} from "./memberRegisterReducer";
import type {
    MemberRegisterCreateData,
    MemberRegisterVerifyData,
} from "./memberRegisterActions";
import type {State} from "./types";

export const getState = (state: State): MemberRegisterState => state.memberRegister;
export const getWelcomeModalDisabled = (state: State): DisableWelcomeModal =>
    getState(state).disableWelcomeModal;
export const getVerifyData = (state: State): MemberRegisterVerifyData =>
    getState(state).verify.data;
export const getCreateData = (state: State): MemberRegisterCreateData =>
    getState(state).create.data;
export const getVerifyError = (
    state: State,
): MemberRegisterVerifyError | null | undefined => getState(state).verify.error;
export const getCreateError = (
    state: State,
): MemberRegisterCreateError | null | undefined => getState(state).create.error;
export const getStep = (state: State): RegisterStep | null | undefined =>
    getState(state).step;
export const isLoading = (state: State): boolean => getState(state).loading;
