import {frameAction} from "atg-store-addons";

export const RESPONSIBLE_GAMING_HEADER_ADD_HIJACKER =
    "RESPONSIBLE_GAMING_HEADER_ADD_HIJACKER";
export const RESPONSIBLE_GAMING_HEADER_REMOVE_HIJACKER =
    "RESPONSIBLE_GAMING_HEADER_REMOVE_HIJACKER";

type AddResponsibleGamingHeaderHijacker = {
    type: typeof RESPONSIBLE_GAMING_HEADER_ADD_HIJACKER;
};

type RemoveResponsibleGamingHeaderHijacker = {
    type: typeof RESPONSIBLE_GAMING_HEADER_REMOVE_HIJACKER;
};

export type ResponsibleGamingHeaderActions =
    | AddResponsibleGamingHeaderHijacker
    | RemoveResponsibleGamingHeaderHijacker;

export const addResponsibleGamingHeaderHijacker =
    (): AddResponsibleGamingHeaderHijacker =>
        frameAction({
            type: RESPONSIBLE_GAMING_HEADER_ADD_HIJACKER,
        });

export const removeResponsibleGamingHeaderHijacker =
    (): RemoveResponsibleGamingHeaderHijacker =>
        frameAction({
            type: RESPONSIBLE_GAMING_HEADER_REMOVE_HIJACKER,
        });
