import {fetchSelectors} from "@atg-shared/fetch-redux";
import {PostRequestStatus} from "@atg-aml-shared/inbox-types/src/inboxEnums";
import type {State} from "@atg-aml-shared/inbox-types";
import {createSelector} from "reselect";

export const getInboxMessages = (state: State) => state.inboxMessages?.conversations;

export const getNumberOfUnreadMessages = (state: State) =>
    state.inboxMessages.messageStatus.numberOfUnread;

export const getLoadingState = (state: State) =>
    fetchSelectors.getLoadingState(state.inboxMessages);

const getMessageStatusState = (state: State) => state.inboxMessages?.messageStatus;

export const getMessageStatus = createSelector(
    getMessageStatusState,
    (messageStatus: State["inboxMessages"]["messageStatus"]) => ({
        numberOfUnread: messageStatus?.numberOfUnread,
        hasUnanswered: messageStatus?.hasUnanswered,
    }),
);

const getPostRequestStatus = (state: State) => state.inboxMessages?.postRequest?.status;
const getErrorReason = (state: State) => state.inboxMessages?.postRequest?.errorReason;

export const getMessagePostRequestStatus = createSelector(
    getPostRequestStatus,
    getErrorReason,
    (status, errorReason) => ({
        isIdle: status === PostRequestStatus.IDLE,
        isSubmitting: status === PostRequestStatus.SUBMITTING,
        isSuccessful: status === PostRequestStatus.SUCCESSFUL,
        isError: status === PostRequestStatus.ERROR,
        errorReason,
    }),
);
