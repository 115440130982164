type ElementsWithDisable =
    | HTMLButtonElement
    | HTMLInputElement
    | HTMLSelectElement
    | HTMLTextAreaElement
    | HTMLFieldSetElement;

const isFound = (targetElement: unknown, currentElement: unknown) =>
    targetElement &&
    !(targetElement as ElementsWithDisable).disabled &&
    targetElement !== currentElement;

/**
 * Will loop through all children and siblings of the given element and return
 * the first focusable element it finds.
 */
export const findFirstFocusableElement = (element?: HTMLElement): HTMLElement | null => {
    if (!element) {
        return null;
    }

    if (element.tabIndex > -1) {
        return element;
    }

    if (element.hasChildNodes()) {
        const elementChildren = element.children;

        for (let i = 0; i < elementChildren.length; i += 1) {
            const child = elementChildren[i];
            const found = findFirstFocusableElement(child as HTMLElement);

            if (isFound(found, element)) {
                return found;
            }
        }
    }

    if (element.nextElementSibling) {
        const sibling = element.nextElementSibling;
        return findFirstFocusableElement(sibling as HTMLElement);
    }

    return null;
};

/**
 * Will loop backwards through all children and siblings of the given element and return
 * the first focusable element it finds.
 */
export const findLastFocusableElement = (element?: HTMLElement): HTMLElement | null => {
    if (!element) {
        return null;
    }

    if (element.tabIndex > -1 && !(element as ElementsWithDisable).disabled) {
        return element;
    }

    if (element.hasChildNodes()) {
        const elementChildren = element.children;

        for (let i = elementChildren.length - 1; i >= 0; i -= 1) {
            const child = elementChildren[i];

            const found = findLastFocusableElement(child as HTMLElement);

            if (isFound(found, element)) {
                return found;
            }
        }
    }

    if (element.previousElementSibling) {
        const sibling = element.previousElementSibling;
        return findLastFocusableElement(sibling as HTMLElement);
    }

    return null;
};

/**
 * Looks for the next focusable sibling of the given element. Starts climbing up the DOM
 * if direct sibling is found.
 */
export const nextFocusableSibling = (element: HTMLElement): HTMLElement | null => {
    let found = null;

    if (!element) return null;

    if (element.nextElementSibling) {
        const sibling = element.nextElementSibling;
        found = findFirstFocusableElement(sibling as HTMLElement);

        if (isFound(found, element)) {
            return found;
        }
    }

    if (element.parentElement?.nextElementSibling) {
        const parentSibling = element.parentElement.nextElementSibling as HTMLElement;
        found = findFirstFocusableElement(parentSibling);

        if (isFound(found, element)) {
            return found;
        }
    }

    return nextFocusableSibling(element.parentElement as HTMLElement);
};

/**
 * Looks for the previous focusable sibling of the given element. Starts climbing up the DOM
 * if direct sibling is found.
 */
export const previousFocusableSibling = (element: HTMLElement): HTMLElement | null => {
    let found = null;

    if (!element) return null;

    if (element.previousElementSibling) {
        const sibling = element.previousElementSibling;
        found = findLastFocusableElement(sibling as HTMLElement);

        if (isFound(found, element)) {
            return found;
        }
    }

    if (element.parentElement?.previousElementSibling) {
        const parentSibling = element.parentElement.previousElementSibling as HTMLElement;
        found = findLastFocusableElement(parentSibling);

        if (isFound(found, element)) {
            return found;
        }
    }

    return previousFocusableSibling(element.parentElement as HTMLElement);
};

/**
 * Look for the next focusable element in the DOM tree. Prioritizes children.
 */
export const nextFocusableElement = (element: HTMLElement): HTMLElement | null => {
    let found = null;

    if (!element) return null;

    if (element.hasChildNodes()) {
        const child = element.firstChild as HTMLElement;
        found = findFirstFocusableElement(child);
    }

    if (isFound(found, element)) {
        return found;
    }

    found = nextFocusableSibling(element);

    return found;
};

/**
 * Look for the previous focusable element in the DOM tree. (just an ALIAS for previousFocusableSibling)
 */
export const previousFocusableElement = (element: HTMLElement): HTMLElement | null =>
    previousFocusableSibling(element);

/**
 * scroll to element with id and focus it or its first focusable child/sibling
 * simulates an internal anchor link click
 */
export const jumpToId = (id: string) => {
    const element = document.getElementById(id);

    if (!element) {
        return;
    }

    element.scrollIntoView({behavior: "smooth"});
    const firstFocusableElement = findFirstFocusableElement(element);

    if (firstFocusableElement) {
        firstFocusableElement.focus();
    }
};

export default findFirstFocusableElement;
