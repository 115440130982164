import {css} from "@emotion/react";

export const slideInLeftDuration = 400;
export const slideInLeftDurationMobile = 250;

export const slideInLeftEnterActive = "slide-in-left-enter-active";
export const slideInLeftEnter = (isSmallScreen = false) => css`
    body:not(.ie) & {
        transform: translateX(-100%);
        transition: all
            ${isSmallScreen ? slideInLeftDurationMobile : slideInLeftDuration}ms ease;
    }

    body:not(.ie) &.${slideInLeftEnterActive} {
        transform: translateX(0%);
    }
`;
export const slideInLeftExitActive = "slide-in-left-exit-active";
export const slideInLeftExit = (isSmallScreen = false) => css`
    body:not(.ie) & {
        transform: translateX(0%);
        transition: all
            ${isSmallScreen ? slideInLeftDurationMobile : slideInLeftDuration}ms ease;
    }

    body:not(.ie) &.${slideInLeftExitActive} {
        transform: translateX(-100%);
    }
`;

export const slideInRightDuration = 400;
export const slideInRightDurationMobile = 250;

export const slideInRightEnterActive = "slide-in-right-enter-active";
export const slideInRightEnter = (isSmallScreen = false) => css`
    body:not(.ie) & {
        transform: translateX(100%);
        transition: all
            ${isSmallScreen ? slideInRightDurationMobile : slideInRightDuration}ms ease;
    }

    body:not(.ie) &.${slideInRightEnterActive} {
        transform: translateX(0%);
    }
`;
export const slideInRightExitActive = "slide-in-right-exit-active";
export const slideInRightExit = (isSmallScreen = false) => css`
    body:not(.ie) & {
        transform: translateX(0%);
        transition: all
            ${isSmallScreen ? slideInRightDurationMobile : slideInRightDuration}ms ease;
    }

    body:not(.ie) &.${slideInRightExitActive} {
        transform: translateX(100%);
    }
`;

export const fadeInDuration = 200;
export const fadeInEnterActive = "fade-in-enter-active";
export const fadeInEnter = css`
    body:not(.ie) & {
        opacity: 0;
    }

    body:not(.ie) &.${fadeInEnterActive} {
        transition: all ${fadeInDuration}ms ease;
        opacity: 1;
    }
`;
export const fadeInExitActive = "fade-in-exit-active";
export const fadeInExit = css`
    body:not(.ie) & {
        opacity: 1;
    }

    body:not(.ie) &.${fadeInExitActive} {
        transition: all ${fadeInDuration}ms ease;
        opacity: 0;
    }
`;

export const fadeStatusDuration = 200;
export const fadeStatusEnterActive = "fade-status-enter-active";
export const fadeStatusEnter = css`
    body:not(.ie) & {
        background: transparent;
        color: #fff;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
    }

    body:not(.ie) &.${fadeStatusEnterActive} {
        transition: all ${fadeStatusDuration}ms ease;
        background: #fff;
        color: #014991;
        text-shadow: 1px 1px rgba(100, 100, 100, 0.25);
    }
`;
export const fadeStatusExitActive = "fade-status-exit-active";
export const fadeStatusExit = css`
    body:not(.ie) & {
        background: #fff;
        color: #014991;
        text-shadow: 1px 1px rgba(100, 100, 100, 0.25);
    }

    body:not(.ie) &.${fadeStatusExitActive} {
        transition: all ${fadeStatusDuration}ms ease;
        background: transparent;
        color: #fff;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
    }
`;
