import * as React from "react";
import type {Props as SvgIconPropType} from "../components/SvgIcon";
import SvgIcon from "../components/SvgIcon";

export function Close(props: SvgIconPropType) {
    return (
        <SvgIcon {...props}>
            <path d="M16 17.06L5.53 27.53a.75.75 0 01-1.059-1.061l10.47-10.47-10.47-10.47A.75.75 0 015.532 4.47l10.47 10.47 10.47-10.47a.75.75 0 011.059 1.061l-10.47 10.47 10.47 10.47a.75.75 0 01-1.061 1.059L16 17.06z" />
        </SvgIcon>
    );
}

export default Close;
