import * as React from "react";
import {css} from "@emotion/react";
import * as LocalStorage from "@atg-shared/storage";
import StickyContext from "../domain/StickyContext";
import * as StickyDomain from "../domain/sticky";
import * as styles from "./StickyStyles.styles";

class Sticky extends React.PureComponent {
    placeholderRef;

    contentRef;

    static defaultProps = {
        isPinned: false,
    };

    componentDidMount() {
        const {id, isPinned, addSticky, index} = this.props;
        const stickies = LocalStorage.getObject("sticky");
        const isPinnedValue = stickies?.[id] ?? isPinned;

        addSticky(id, {
            isPinned: isPinnedValue || false,
            isSticky: false,
            contentHeight: 0,
            stickyStyle: {},
            placeholder: this.placeholderRef,
            content: this.contentRef,
            index,
        });
    }

    componentWillUnmount() {
        const {id, removeSticky} = this.props;

        removeSticky(id);
    }

    setPlaceholderRef = (node) => {
        if (!node) return;

        const {id, updatePlaceholderRef} = this.props;

        this.placeholderRef = node;
        updatePlaceholderRef(id, node);
    };

    setContentRef = (node) => {
        if (!node) return;

        const {id, updateContentRef} = this.props;

        this.contentRef = node;
        updateContentRef(id, node);
    };

    togglePinned = () => {
        const {id, togglePinned} = this.props;

        togglePinned(id);
    };

    stickyUpdated = () => {
        const {id, stickies, stickyUpdated} = this.props;
        const sticky = stickies[id];
        if (StickyDomain.isHeightChanged(sticky)) {
            stickyUpdated();
        }
    };

    render() {
        const {children, stickies, id} = this.props;
        const {isPinned, isSticky, stickyStyle, contentHeight} = stickies[id] || {
            isPinned: false,
            isSticky: false,
            style: {},
            contentHeight: 0,
        };
        const element = React.cloneElement(
            children({
                isSticky,
                isPinned,
                stickyStyle,
                stickyUpdated: this.stickyUpdated,
                togglePinned: this.togglePinned,
            }),
            {ref: this.setContentRef},
        );

        return (
            <>
                <div ref={this.setPlaceholderRef} style={{height: contentHeight}} />
                <div css={[styles.noScrollBar, css(stickyStyle)]}>{element}</div>
            </>
        );
    }
}

function StickyContextConsumer(ownProps) {
    const contextProps = React.useContext(StickyContext);
    return <Sticky {...ownProps} {...contextProps} />;
}

export default StickyContextConsumer;
