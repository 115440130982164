import {put, call, take, takeLatest} from "redux-saga/effects";
import type {AnyAction} from "redux";
import type {AtgGenericResponse, AtgRequestError} from "@atg-shared/fetch-types";
import {AtgAlertTypes} from "@atg-global-shared/alerts-types";
import {MemberActions} from "@atg-global-shared/member-data-access";
import {MODAL_CLOSE} from "atg-modals/modalActionTypes";
import {
    showTwoFactorActivationModal,
    closeTwoFactorActivationModal,
    showTwoFactorDeactivationModal,
    closeTwoFactorDeactivationModal,
    showTwoFactorConfirmationModal,
    closeTwoFactorConfirmationModal,
} from "atg-modals/modalActions";
import * as ToastActions from "atg-ui-toast/domain/toastActions";
import type {
    ConfirmationResponse,
    TwoFactorActivationAction,
    TwoFactorConfirmationAction,
    TwoFactorConfirmationSuccessResponseAction,
} from "./twoFactorActions";
import {
    TWO_FACTOR_CONFIRMATION,
    TWO_FACTOR_CONFIRMATION_RESPONSE,
    TWO_FACTOR_ACTIVATION,
    TWO_FACTOR_ACTIVATION_RESPONSE,
    TWO_FACTOR_ACTIVATION_INIT,
    TWO_FACTOR_DEACTIVATION,
    TWO_FACTOR_DEACTIVATION_RESPONSE,
    TWO_FACTOR_DEACTIVATION_INIT,
    twoFactorConfirmationSuccess,
    twoFactorConfirmationError,
    twoFactorConfirmationClear,
    twoFactorActivationError,
    twoFactorActivationSuccess,
    twoFactorActivationClear,
    twoFactorDeactivationError,
    twoFactorDeactivationSuccess,
    twoFactorDeactivationClear,
} from "./twoFactorActions";
import * as api from "./twoFactorApi";

function getErrorType(error: unknown): string | undefined {
    if (typeof error !== "object" || error === null || !("response" in error)) return;
    // eslint-disable-next-line consistent-return
    return (error as AtgRequestError).response.data.status;
}

function getActivationDeactivationErrorMessage(error: unknown) {
    switch (getErrorType(error)) {
        case "INCORRECT_PASSWORD":
            return "Fel lösenord";
        case "SMS_CODE_LOCKED":
            return "Aktiveringen är tillfälligt låst. Vänligen försök senare.";
        default:
            return "Okänt tekniskt fel.";
    }
}

function getConfirmationErrorMessage(error: unknown) {
    switch (getErrorType(error)) {
        case "SMS_CODE_FAILED":
            return "Felaktig säkerhetskod är ifylld.";
        case "SMS_CODE_FAILED_NEW_SMS_SENT":
            return "En ny kod har skickats till dig.";
        case "SMS_CODE_EXPIRED":
            return "Sms-koden är ej längre giltig.";
        case "SMS_CODE_LOCKED":
            return "Aktiveringen är tillfälligt låst. Vänligen försök senare.";
        case "SMS_CODE_FAILED_TO_SEND":
            return "På grund av tekniska problem kunde inte skicka sms-koden skickas. Vänligen försök igen senare.";
        case "SMS_CODE_LOCKED_NEXT_FAILED":
            return "Sista försöket, sedan spärras kontot.";
        case "LOCKED_TEMPORARILY":
            return "Ditt konto har spärrats temporärt.";
        default:
            return "Okänt tekniskt fel.";
    }
}

export function* twoFactorLoginFlow() {
    const successOrAbort = (action: AnyAction) =>
        (action.type === TWO_FACTOR_CONFIRMATION_RESPONSE && !action.error) ||
        action.type === MemberActions.CANCELLED_LOGIN_FLOW;

    // wait for either success or abort
    const twoFactorAction:
        | TwoFactorConfirmationSuccessResponseAction
        | MemberActions.LoginFlowCancelledAction = yield take(successOrAbort);
    yield put(twoFactorConfirmationClear());

    if (twoFactorAction.type === MemberActions.CANCELLED_LOGIN_FLOW) return null;

    return twoFactorAction.payload.loginData;
}

function* twoFactorConfirmationFlow(action: TwoFactorConfirmationAction) {
    let response: AtgGenericResponse<ConfirmationResponse>;

    try {
        response = yield call(api.twoFactorConfirmation, action.params);
    } catch (error: unknown) {
        const errorMessage = getConfirmationErrorMessage(error);
        yield put(twoFactorConfirmationError(errorMessage, error as any));
        return;
    }

    yield put(twoFactorConfirmationSuccess(response.data));
}

function* twoFactorActivationFlow(action: TwoFactorActivationAction) {
    let response: AtgGenericResponse<ConfirmationResponse>;

    try {
        response = yield call(api.twoFactorActivation, action.params);
    } catch (error: unknown) {
        const errorMessage = getActivationDeactivationErrorMessage(error);
        yield put(twoFactorActivationError(errorMessage, error as any));
        return;
    }

    yield put(twoFactorActivationSuccess(response.data));
}

function* twoFactorDeactivationFlow() {
    let response: AtgGenericResponse<ConfirmationResponse>;

    try {
        response = yield call(api.twoFactorDeactivation);
    } catch (error: unknown) {
        const errorMessage = getActivationDeactivationErrorMessage(error);
        yield put(twoFactorDeactivationError(errorMessage, error as any));
        return;
    }

    yield put(twoFactorDeactivationSuccess(response.data));
}

function* twoFactorActivationModalFlow() {
    yield put(twoFactorActivationClear());
    yield put(showTwoFactorActivationModal());

    const activationSuccessOrAbort = (action: AnyAction) =>
        (action.type === TWO_FACTOR_ACTIVATION_RESPONSE && !action.error) ||
        (action.type === MODAL_CLOSE && action.payload === "twoFactorActivationModal");

    const activationAction: AnyAction = yield take(activationSuccessOrAbort);
    if (activationAction.type === MODAL_CLOSE) return;

    yield put(closeTwoFactorActivationModal());
    yield put(twoFactorConfirmationClear());
    yield put(showTwoFactorConfirmationModal());

    const confirmationSuccessOrAbort = (action: AnyAction) =>
        (action.type === TWO_FACTOR_CONFIRMATION_RESPONSE && !action.error) ||
        (action.type === MODAL_CLOSE && action.payload === "twoFactorConfirmationModal");

    const confirmationAction: AnyAction = yield take(confirmationSuccessOrAbort);
    if (confirmationAction.type === MODAL_CLOSE) return;

    yield put(
        ToastActions.showToast({
            message: "SMS-verifiering kommer nu att ske varje gång du loggar in.",
            type: AtgAlertTypes.SUCCESS,
        }),
    );
    yield put(closeTwoFactorConfirmationModal());
}

function* twoFactorDeactivationModalFlow() {
    yield put(twoFactorDeactivationClear());
    yield put(showTwoFactorDeactivationModal());

    const deactivationSuccessOrAbort = (action: AnyAction) =>
        (action.type === TWO_FACTOR_DEACTIVATION_RESPONSE && !action.error) ||
        (action.type === MODAL_CLOSE && action.payload === "twoFactorDeactivationModal");

    const deactivationAction: AnyAction = yield take(deactivationSuccessOrAbort);
    if (deactivationAction.type === MODAL_CLOSE) return;

    yield put(closeTwoFactorDeactivationModal());
    yield put(
        ToastActions.showToast({
            message: "SMS-verifiering är nu avstängd.",
            type: AtgAlertTypes.SUCCESS,
        }),
    );
}

export default function* twoFactorSaga() {
    yield takeLatest(TWO_FACTOR_CONFIRMATION, twoFactorConfirmationFlow);
    yield takeLatest(TWO_FACTOR_ACTIVATION, twoFactorActivationFlow);
    yield takeLatest(TWO_FACTOR_ACTIVATION_INIT, twoFactorActivationModalFlow);
    yield takeLatest(TWO_FACTOR_DEACTIVATION, twoFactorDeactivationFlow);
    yield takeLatest(TWO_FACTOR_DEACTIVATION_INIT, twoFactorDeactivationModalFlow);
}
