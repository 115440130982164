import * as Redux from "react-redux";
import * as LoginTypes from "@atg-login-shared/types";
import {LoginSelectors} from "@atg-login-shared/data-access";

const useIsNewUser = () => {
    const authMethod = Redux.useSelector(LoginSelectors.selectStoredAuthMethod);
    // authMethod stores the last method used to authenticate, and if it's the authenticator selector, it means the user is new
    const isNewUser = authMethod.method === LoginTypes.CurityState.AUTHENTICATOR_SELECTOR;

    return isNewUser;
};

export default useIsNewUser;
