// /* eslint-disable no-underscore-dangle */
import type {CallEffect, SelectEffect, StrictEffect} from "redux-saga/effects";
import {call, delay, takeLatest, put, take, select} from "redux-saga/effects";
import dayjs from "dayjs";
import * as Storage from "@atg-shared/storage";
import {AuthSelectors, AuthActions} from "@atg-shared/auth";
import type {SagaIterator} from "redux-saga";
import {
    LimitsSelectors,
    LimitsActions,
} from "@atg-responsible-gambling-shared/limits-domain";
import {LOGIN_FINISHED, LOGOUT_FINALIZE} from "@atg-global-shared/user/userActionTypes";
import * as UserActionTypes from "@atg-global-shared/user/userActionTypes";
import {getUsername} from "@atg-global-shared/user/userSelectors";
import * as ModalActions from "atg-modals/modalActions";
import {LOGIN_TIME_INIT} from "./loginTimeActions";

export const LOGIN_TIME = "loginTime";

export const loginTimeKey = (username: string): string => `${LOGIN_TIME}_${username}`;

const ONE_MINUTE = 60000;
const TEN_MINUTES = 600000;

export function* setLoginTime(loginTime: string): Generator<StrictEffect> {
    let username = yield select(getUsername);
    if (!username) {
        yield take(UserActionTypes.RECEIVE_USER);
        username = yield select(getUsername);
    }
    const key = loginTimeKey(username as string);
    return yield call(Storage.setItem, key, loginTime);
}

export function* getLoginTime(): Generator<StrictEffect> {
    const key = loginTimeKey((yield select(getUsername)) as string);
    return yield call(Storage.getItem, key);
}

export function* handleLoginInit(): Generator<
    SelectEffect | CallEffect<void>,
    void,
    string
> {
    const username = yield select(getUsername);
    if (!username) return;

    const storedLoginTime = yield call(getLoginTime);

    if (!storedLoginTime) {
        const now = dayjs().format();
        yield call(setLoginTime, now);
    }
}

export function* clearLoginTime() {
    const key = loginTimeKey(yield select(getUsername));
    yield call(Storage.removeItem, key);
}

/**
 * Reset login time cookie on login
 */
export function* handleLoginFinished() {
    yield call(clearLoginTime);
    const now = dayjs().format();
    yield call(setLoginTime, now);
}

export function* fetchLoginTimeLimits() {
    // requireAuth: false means that it won't try to refresh the access token in case it has expired. This is important since it otherwise prevents the session from expiring if the user leaves the page open
    yield put(LimitsActions.fetchLoginTimeLimits({requireAuth: false}));
    yield take(LimitsActions.RECEIVE_GET_LOGIN_TIME_LIMITS);
}

export function* callWithDelayIfLoggedIn(fn: () => unknown, ms: number) {
    yield delay(ms);

    const isNormalLogin: boolean = yield select(AuthSelectors.isNormalLogin);

    if (!isNormalLogin) {
        // user is not in betting scope, do nothing
        return;
    }

    // user is still in betting scope, call effect
    yield call(fn);
}

export function* periodicFetchRemainingLoginTime() {
    yield call(fetchLoginTimeLimits);

    const hasLoginTimeLimitRestriction: boolean = yield select(
        LimitsSelectors.hasSetLoginTimeLimit,
    );

    if (!hasLoginTimeLimitRestriction) return;

    // Need to cast the select to number, since the redux-saga types do not allow for it to
    // be inferred correctly, see below link for more information.
    // https://github.com/redux-saga/redux-saga/issues/2015#issuecomment-586476693
    const timeLeft = (yield select(LimitsSelectors.getRemainingLoginTime)) as number;

    if (timeLeft > 30) {
        yield call(callWithDelayIfLoggedIn, periodicFetchRemainingLoginTime, TEN_MINUTES);
    } else if (timeLeft <= 30 && timeLeft > 15) {
        yield call(callWithDelayIfLoggedIn, periodicFetchRemainingLoginTime, ONE_MINUTE);
    } else if (timeLeft <= 15 && timeLeft > 0) {
        yield put(ModalActions.showFifteenMinutesLeftModal());
        yield call(
            callWithDelayIfLoggedIn,
            periodicFetchRemainingLoginTime,
            timeLeft * ONE_MINUTE,
        );
    } else {
        yield put(ModalActions.closeFifteenMinutesLeftModal());
        yield put(ModalActions.closeUserGamblingSummaryModal());
        yield put(ModalActions.showLoginTimeReachedLogoutModal());
    }
}

export function* loginTimeSaga(): SagaIterator {
    yield takeLatest(LOGIN_TIME_INIT, handleLoginInit);
    yield takeLatest(LOGIN_FINISHED, handleLoginFinished);
    yield takeLatest(LOGOUT_FINALIZE, clearLoginTime);

    yield take([AuthActions.AUTH_CHECK_RESPONSE, LOGIN_FINISHED]);
    const isNormalLogin: boolean = yield select(AuthSelectors.isNormalLogin);
    if (isNormalLogin) {
        // trigger the periodicFetchRemainingLoginTime saga directly if user is already logged in
        yield call(periodicFetchRemainingLoginTime);
    }

    yield takeLatest(LOGIN_FINISHED, periodicFetchRemainingLoginTime);
}
