import {css} from "@emotion/react";

export const iframeDefault = css`
    border: 0;
    width: 100%;
    display: block;
    overflow: hidden;
`;

export const iframeHidden = css`
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
    border: none;
`;
