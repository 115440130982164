import * as React from "react";

export function useOneTrustLoaded() {
    const [oneTrustLoaded, setOneTrustLoaded] = React.useState(false);

    React.useEffect(() => {
        // Check if the OneTrust Banner SDK is available
        if (document.querySelector("#onetrust-consent-sdk")) {
            setOneTrustLoaded(true);
        } else {
            const observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    mutation.addedNodes.forEach((addedNode) => {
                        // @ts-ignore
                        if (addedNode.id === "onetrust-consent-sdk") {
                            setOneTrustLoaded(true);
                            // Removes observer when done
                            observer.disconnect();
                        }
                    });
                });
            });
            observer.observe(document.body, {childList: true, subtree: true});
        }
    }, []);

    return oneTrustLoaded;
}
