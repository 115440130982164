import {css} from "@emotion/react";
import {md} from "atg-ui/css/overridableVariables.styles";

export const header = css`
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
        display: flex;
        align-items: center;
        height: 100%;
    }

    & > *:first-child {
        flex-basis: 33%;
        justify-content: flex-start;

        @media ${md} {
            flex-basis: 30%;
        }
    }

    & > *:nth-child(2) {
        flex-basis: 33%;
        justify-content: center;

        @media ${md} {
            flex-basis: 40%;
        }
    }

    & > *:last-child {
        flex-basis: 33%;
        justify-content: flex-end;

        @media ${md} {
            flex-basis: 30%;
        }
    }

    @media print {
        display: none;
    }
`;
