export enum PostRequestStatus {
    IDLE = "IDLE",
    SUBMITTING = "SUBMITTING",
    SUCCESSFUL = "SUCCESSFUL",
    ERROR = "ERROR",
}

export enum CommunicationMode {
    ONE_WAY = "ONE_WAY",
    TWO_WAY = "TWO_WAY",
}

export enum Sender {
    AGENT = "AGENT",
    CUSTOMER = "CUSTOMER",
    BOT = "BOT",
    SYSTEM = "SYSTEM",
}

export enum SortOrder {
    ASC = "asc",
    DESC = "desc",
}
