import {broadcastAction} from "atg-store-addons";
import type {AuthorizedUser} from "@atg-global-shared/user/user.types";
import {LOGIN_FINISHED} from "@atg-global-shared/user/userActionTypes";

export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGIN_NEED_TWO_FACTOR = "LOGIN_NEED_TWO_FACTOR";
export const LOGIN_NEED_CHANGE_TEMP_PASSWORD = "LOGIN_NEED_CHANGE_TEMP_PASSWORD";
export const LOGIN_NEED_USER_AGREEMENT = "LOGIN_NEED_USER_AGREEMENT";
export const LOGIN_MEMBER_RECOVERY = "LOGIN_MEMBER_RECOVERY";

export const AUTH_SERVER_STATE = "AUTH_SERVER_STATE";

type LoginCredentials = {
    username: string;
    password: string;
    challengeResponse?: string;
};

type LoginError = {[key: string]: any};

type LoginData = {
    user: AuthorizedUser;
    roles?: any;
    status?: any;
};

export type LoginFinishedAction = {
    type: typeof LOGIN_FINISHED;
    payload: {
        loginData: LoginData;
        rememberedUsername: string;
    };
};

export type LoginRequestedAction = {
    type: typeof LOGIN;
    payload: LoginCredentials;
};

export type LoginErrorAction = {
    type: typeof LOGIN_ERROR;
    payload: LoginError;
};

type LoginNeedTwoFactorAction = {
    type: typeof LOGIN_NEED_TWO_FACTOR;
};

type LoginMemberRecoveryAction = {
    type: typeof LOGIN_MEMBER_RECOVERY;
};

type LoginNeedChangeTemPassWordAction = {
    type: typeof LOGIN_NEED_CHANGE_TEMP_PASSWORD;
};

type LoginNeedUserAgreementAction = {
    type: typeof LOGIN_NEED_USER_AGREEMENT;
};

export type AuthServerStateAction = {
    type: typeof AUTH_SERVER_STATE;
    payload: string;
};

export type Action =
    | LoginFinishedAction
    | LoginRequestedAction
    | LoginErrorAction
    | LoginNeedTwoFactorAction
    | LoginNeedChangeTemPassWordAction
    | LoginNeedUserAgreementAction
    | AuthServerStateAction
    | LoginMemberRecoveryAction;

export const loginFinished = (
    loginData: LoginData,
    rememberedUsername: string,
): LoginFinishedAction =>
    broadcastAction({
        type: LOGIN_FINISHED,
        payload: {
            loginData,
            rememberedUsername,
        },
    });

export const loginUser = (payload: LoginCredentials): LoginRequestedAction => ({
    type: LOGIN,
    payload,
});

export const loginError = (payload: LoginError): LoginErrorAction => ({
    type: LOGIN_ERROR,
    payload,
});

export const loginNeedTwoFactor = (): LoginNeedTwoFactorAction => ({
    type: LOGIN_NEED_TWO_FACTOR,
});

export const loginNeedChangeTempPassword = (): LoginNeedChangeTemPassWordAction => ({
    type: LOGIN_NEED_CHANGE_TEMP_PASSWORD,
});

export const loginNeedUserAgreement = (): LoginNeedUserAgreementAction => ({
    type: LOGIN_NEED_USER_AGREEMENT,
});

export const loginMemberRecovery = (): LoginMemberRecoveryAction => ({
    type: LOGIN_MEMBER_RECOVERY,
});

export const setAuthServerState = (payload: string): AuthServerStateAction => ({
    type: AUTH_SERVER_STATE,
    payload,
});
