import {LOGOUT_USER, LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
import type {LogoutOptions, UserAction} from "@atg-global-shared/user/userActions";

export type State = {
    isRequested: boolean;
    options: LogoutOptions;
};

export const initialState: State = {
    isRequested: false,
    options: {},
};

const logout = (state: State = initialState, action: UserAction) => {
    switch (action.type) {
        case LOGOUT_USER:
            return {
                isRequested: true,
                options: action.payload,
            };
        case LOGOUT_FINISHED:
            return initialState;
        default:
            return state;
    }
};

export default logout;
