import * as React from "react";
import * as Redux from "react-redux";
import * as Analytics from "@atg-shared/analytics";
import * as AuthSelectors from "@atg-shared/auth/domain/authSelectors";
import {Trans} from "@lingui/macro";
// @ts-ignore-next-line no TS file
import {withTheme} from "atg-theme";
import * as styles from "./ResponsibleGamingLogos.styles";
import * as casinoTheme from "./ResponsibleGamingLogos-casinoTheme.styles";

const appendPath = (url: string, path: string) =>
    url ? url + (url.endsWith("/") ? path.substring(1) : path) : path;

type Props = {
    theme: typeof styles;
    targetUrl?: string;
};

export function GamblingPause() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="73"
            height="22"
            viewBox="0 0 73 22"
        >
            <title>
                <Trans>Spelpaus</Trans>
            </title>
            <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                <rect
                    width="71.5"
                    height="20.5"
                    x="-.25"
                    y="-.25"
                    fill="#FFF"
                    stroke="#556473"
                    strokeWidth=".5"
                    rx="2"
                />
                <path
                    fill="#E20D18"
                    d="M17 10.016A6.986 6.986 0 0 1 10 17c-3.845 0-7-3.13-7-7.016C3.033 6.129 6.155 3 10 3c3.878 0 7 3.13 7 7.016z"
                />
                <path
                    fill="#FFF"
                    d="M12.895 13.5h-1.972a.29.29 0 0 1-.287-.292V6.786c0-.159.128-.286.287-.286h1.972c.16 0 .287.127.287.286v6.39c0 .191-.127.324-.287.324zM9.077 13.5H7.105a.29.29 0 0 1-.287-.292V6.786c0-.159.127-.286.287-.286h1.972c.16 0 .287.127.287.286v6.39c0 .191-.128.324-.287.324z"
                />
                <text
                    fill="#020203"
                    fontFamily="Verdana"
                    fontSize="10"
                    letterSpacing="-.222"
                >
                    <tspan x="21" y="14">
                        <Trans>Spelpaus</Trans>
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

export function GamblingLimits() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="87"
            height="22"
            viewBox="0 0 87 22"
        >
            <title>
                <Trans>Spelgränser</Trans>
            </title>
            <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                <rect
                    width="85.5"
                    height="20.5"
                    x="-.25"
                    y="-.25"
                    fill="#FFF"
                    stroke="#556473"
                    strokeWidth=".5"
                    rx="2"
                />
                <g transform="translate(3 3)">
                    <path
                        fill="#009739"
                        fillRule="nonzero"
                        d="M6.902 13.364c3.465 0 6.274-2.85 6.274-6.364 0-3.515-2.809-6.364-6.274-6.364S.627 3.486.627 7c0 3.515 2.81 6.364 6.275 6.364zm0 .636C3.09 14 0 10.866 0 7s3.09-7 6.902-7c3.812 0 6.902 3.134 6.902 7s-3.09 7-6.902 7z"
                    />
                    <path
                        fill="#009739"
                        fillRule="nonzero"
                        d="M4.726 3.288a.621.621 0 0 1 .19-.85.602.602 0 0 1 .837.192l2.415 3.882a.621.621 0 0 1-.189.85.602.602 0 0 1-.838-.192L4.726 3.288z"
                    />
                    <path
                        fill="#009739"
                        fillRule="nonzero"
                        d="M5.147 9.51a.602.602 0 0 1-.845-.154.621.621 0 0 1 .151-.857L7.34 6.463a.602.602 0 0 1 .845.153.621.621 0 0 1-.152.858L5.147 9.51z"
                    />
                    <ellipse
                        cx="11.922"
                        cy="9.864"
                        fill="#009739"
                        rx="4.078"
                        ry="4.136"
                    />
                    <g fill="#FFF">
                        <path d="M9.82 7.89h.847v1.72l1.317-1.72h1.067l-1.506 1.942 1.569 2.132h-1.036l-1.411-1.91v1.91H9.82V7.89zM14.149 9.29v.383a.898.898 0 0 1 .282-.318c.094-.064.189-.128.283-.128h.157v.86c-.063 0-.126-.032-.189-.032a.516.516 0 0 0-.376.159c-.094.127-.126.286-.126.477v1.177h-.784v-2.64h.753v.063z" />
                    </g>
                    <path
                        fill="#009739"
                        fillRule="nonzero"
                        d="M1.882 7.692c-.167 0-.303-.224-.303-.5s.136-.5.303-.5h.753c.168 0 .304.224.304.5s-.136.5-.304.5h-.753zM7.818 2.45c0 .17-.224.308-.5.308s-.5-.138-.5-.308v-.764c0-.17.224-.308.5-.308s.5.138.5.308v.764zM7.755 12.473c0 .17-.224.308-.5.308s-.5-.138-.5-.308v-.764c0-.17.224-.308.5-.308s.5.138.5.308v.764z"
                    />
                </g>
                <text
                    fill="#020203"
                    fontFamily="Verdana"
                    fontSize="10"
                    letterSpacing="-.222"
                >
                    <tspan x="22" y="14">
                        <Trans>Spelgränser</Trans>
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

export function GamblingSelfTest() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="72"
            height="22"
            viewBox="0 0 72 22"
        >
            <title>
                <Trans>Självtest</Trans>
            </title>
            <defs>
                <linearGradient
                    id="a"
                    x1="111.217%"
                    x2="-11.197%"
                    y1="50.152%"
                    y2="50.152%"
                >
                    <stop offset="0%" stopColor="#E41913" />
                    <stop offset="9.744%" stopColor="#EA6516" />
                    <stop offset="21.48%" stopColor="#F3A518" />
                    <stop offset="31.21%" stopColor="#F9CD1A" />
                    <stop offset="38.4%" stopColor="#FDE31A" />
                    <stop offset="42.28%" stopColor="#FEEB1A" />
                    <stop offset="62.31%" stopColor="#DFDE20" />
                    <stop offset="100%" stopColor="#009245" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                <rect
                    width="70.5"
                    height="20.5"
                    x="-.25"
                    y="-.25"
                    fill="#FFF"
                    stroke="#556473"
                    strokeWidth=".5"
                    rx="2"
                />
                <path
                    fill="#020203"
                    fillRule="nonzero"
                    d="M10 16.364a6.364 6.364 0 1 0 0-12.728 6.364 6.364 0 0 0 0 12.728zM10 17a7 7 0 1 1 0-14 7 7 0 0 1 0 14z"
                />
                <path
                    fill="url(#a)"
                    fillRule="nonzero"
                    d="M12.72 6.889a.955.955 0 0 1-1.895.222c-.564-4.794-6.991-4.84-7.652.018a.955.955 0 1 1-1.892-.258c.963-7.078 10.614-7.008 11.44.018z"
                    transform="translate(3 3)"
                />
                <path
                    fill="#1E1E1C"
                    d="M8.632 10.095l2.8-5.027-.573 5.727c-.19.605-.859.955-1.464.764a1.181 1.181 0 0 1-.763-1.464z"
                />
                <text
                    fill="#020203"
                    fontFamily="Verdana"
                    fontSize="10"
                    letterSpacing="-.222"
                >
                    <tspan x="21" y="14">
                        <Trans>Självtest</Trans>
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

const NOT_LOGGED_IN_SELF_TEST_URL = "https://atg.gamtest.se";

function ResponsibleGamingLogos({theme, targetUrl = ""}: Props) {
    const shouldGoToInternalSelfTest = Redux.useSelector(AuthSelectors.isNormalLogin);

    // mina-spelvanor?start=true is for A/B test, deep link in selfTest in RGbar
    // will be used in AccountPtsPage
    const selfTestUrl = shouldGoToInternalSelfTest
        ? appendPath(targetUrl, "/konto/mina-spelvanor?start=true")
        : NOT_LOGGED_IN_SELF_TEST_URL;

    // In case of targetUrl being sent in (i,e from tillsammnans) we want the
    // target to be _blank for the test to open in a new window
    const selfTestTarget = selfTestUrl.includes("http") ? "_blank" : "_self";

    return (
        <>
            <a
                css={theme.responsibleGamingLink}
                href={appendPath(targetUrl, "/konto/sparra")}
                target={targetUrl ? "_blank" : "_self"}
                data-test-id="responsible-gaming-spelpaus"
                onClick={() => {
                    Analytics.deprecated_logEvent({
                        responsibleGamingEvent: "spelpaus",
                    });
                }}
                rel="noreferrer"
            >
                <div css={theme.responsibleGamingImage}>
                    <GamblingPause />
                </div>
            </a>
            <a
                css={theme.responsibleGamingLink}
                href={appendPath(targetUrl, "/konto/granser")}
                target={targetUrl ? "_blank" : "_self"}
                data-test-id="responsible-gaming-spelgranser"
                onClick={() => {
                    Analytics.deprecated_logEvent({
                        responsibleGamingEvent: "spelgranser",
                    });
                }}
                rel="noreferrer"
            >
                <div css={theme.responsibleGamingImage}>
                    <GamblingLimits />
                </div>
            </a>

            <a
                css={theme.responsibleGamingLink}
                href={selfTestUrl}
                target={selfTestTarget}
                data-test-id="responsible-gaming-sjalvtest"
                onClick={() => {
                    Analytics.deprecated_logEvent({
                        responsibleGamingEvent: "sjalvtest",
                    });
                }}
            >
                <div css={theme.responsibleGamingImage}>
                    <GamblingSelfTest />
                </div>
            </a>
        </>
    );
}

const ThemedResponsibleGamingLogos = withTheme(
    {
        casinoTheme,
    },
    styles,
)(ResponsibleGamingLogos);

export default ThemedResponsibleGamingLogos;
