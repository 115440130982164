import type {Theme, Components} from "@mui/material/styles";

type ListItemIconOverrides = Components<Theme>["MuiListItemIcon"];

const overrides: ListItemIconOverrides = {
    styleOverrides: {
        root: {
            minWidth: 32,
        },
    },
};

export default overrides;
