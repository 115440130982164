import {select, takeEvery} from "redux-saga/effects";
import {trackVariable} from "@atg-shared/analytics";
import * as AuthSelectors from "./authSelectors";
import {
    LOGIN_FINISHED,
    LOGOUT_FINISHED,
    AUTH_CHECK_REQUEST,
    AUTH_CHECK_RESPONSE,
    AUTH_SET_ROLES,
} from "./authActions";

function* trackRole() {
    const isProAccount: boolean = yield select(AuthSelectors.isProAccount);

    trackVariable({userPro: isProAccount});
}

function* authAnalyticsSaga() {
    yield takeEvery(
        [
            AUTH_CHECK_REQUEST,
            AUTH_CHECK_RESPONSE,
            AUTH_SET_ROLES,
            LOGIN_FINISHED,
            LOGOUT_FINISHED,
        ],
        trackRole,
    );
}

export default authAnalyticsSaga;
