import styled from "@emotion/styled";
import {atgBlue} from "atg-ui/css/variables.styles";

type HeaderWrapperProps = {
    displaceImage: boolean;
    pattern: string;
};

const HeaderWrapper = styled.header<HeaderWrapperProps>`
    background-color: ${atgBlue};
    background-image: url(${({pattern}) => pattern});
    background-position: ${({displaceImage}) => (displaceImage ? "55px" : "left")} top;
    background-repeat: no-repeat;
`;

export default HeaderWrapper;
