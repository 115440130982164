import * as React from "react";
import * as Redux from "react-redux";
import * as MatchMediaSelectors from "atg-match-media/domain/matchMediaSelectors";
import * as MainMenuSelectors from "../domain/mainMenuSelectors";
import * as MainMenuActions from "../domain/mainMenuActions";
import {MENU_PLAY} from "../constants/mainMenuActionTypes";
import SideMenuPortal from "./SideMenuPortal";
import * as styles from "./SideMenu.styles";
import OverlaySideMenu from "./OverlaySideMenu";
import StickySideMenu from "./StickySideMenu";
import VerticalsMenu from "./VerticalsMenu";

type Props = {
    children: React.ReactNode;
};

const SideMenu = React.memo<Props>(({children}: Props) => {
    const dispatch = Redux.useDispatch();
    const isSticky = Redux.useSelector(MainMenuSelectors.getStickyState);
    const isSideMenuOpen = Redux.useSelector(
        (state: MainMenuSelectors.GlobalState) =>
            MainMenuSelectors.getSideMenu(state) === MENU_PLAY,
    );
    const isSmallScreen = Redux.useSelector(MatchMediaSelectors.isSmallScreen);

    const onLinkClick = () => dispatch(MainMenuActions.closeAllMenus());

    const openSideMenuClick = (index: string) => {
        dispatch(MainMenuActions.openSideMenu(index));
    };

    const closeSideMenu = () => {
        dispatch(MainMenuActions.closeSideMenu());
    };

    return isSticky ? (
        <StickySideMenu isSideMenuOpen={isSideMenuOpen}>
            <VerticalsMenu onLinkClick={onLinkClick} />
            <div css={styles.playDropdown}>{children}</div>
        </StickySideMenu>
    ) : (
        <SideMenuPortal>
            <OverlaySideMenu
                index={MENU_PLAY}
                isSideMenuOpen={isSideMenuOpen}
                openSideMenu={openSideMenuClick}
                closeSideMenu={closeSideMenu}
                isSmallScreen={isSmallScreen}
            >
                <VerticalsMenu onLinkClick={onLinkClick} />
                <nav css={styles.playDropdown} data-test-id="side-menu-play-dropdown">
                    {children}
                </nav>
            </OverlaySideMenu>
        </SideMenuPortal>
    );
});

export default SideMenu;
