import {css} from "@emotion/react";
import {
    brandGrayBg,
    defaultSlantDeg,
    sm,
    atgWhite,
    responsibleGamingHeaderHeight,
} from "atg-ui/css/variables.styles";
import {atgBlue3, atgBlue} from "atg-ui/css/overridableVariables.styles";

export const root = css`
    display: block;
    position: fixed;
    top: ${responsibleGamingHeaderHeight}px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;

    /* Prevent Chrome on Windows from adding a focus outline. For details, see'
     * https://github.com/twbs/bootstrap/pull/10951. */
    outline: 0;

    /* Makes ATG Check modal on top of others modals.
     * Default z-index for all modals is 1050 */
    .modal-user-gambling-summary-modal & {
        z-index: ${1050 + 1};
    }

    .modal-VMiV75-modal & {
        z-index: ${1050 - 1};
    }
`;

export const dialog = css`
    position: relative;
    width: auto;
    max-width: 335px;
    margin: 10px auto;
    margin-top: "10px";

    @media ${sm} {
        margin: 30px auto;
    }
`;

export const content = css`
    position: relative;
    background: ${brandGrayBg};
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    /* Remove focus outline from opened modal */
    outline: none;

    @media ${sm} {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }
`;

export const body = (noPadding: boolean) => css`
    font-size: 14px;
    position: relative;

    ${!noPadding &&
    css`
        background-color: ${atgWhite};
        padding: 16px 12px;
        transition: all 0.3s ease-in;
    `}
`;

export const header = css`
    background-color: #093d76;
    padding: 0;
    color: white;
    width: 100%;
    text-transform: uppercase;
    border: none;
    overflow-x: hidden;
    height: 32px;
`;

export const headerTitle = css`
    float: left;
    margin: 0;
    display: inline-block;
    font-weight: bold;
    font-size: 12px;
    padding: 8px 10px;
    line-height: normal;
    width: calc(100% - 100px);
    white-space: nowrap;
`;

export const loader = css`
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
`;

export const close = css`
    float: right;
    background-color: ${atgBlue3};
    clip-path: polygon(12px 0%, 100% 0, 100% 100%, 0% 100%);
    padding: 8px;
    padding-left: 18px;

    &:focus-visible {
        background-color: ${atgBlue};
        color: ${atgWhite};
    }
`;

export const closeUnskew = css`
    transform: skewX(${defaultSlantDeg});

    > svg {
        vertical-align: top;
    }
`;

export const closeText = css`
    font-weight: bold;
    font-size: 12px;
    margin-right: 10px;
`;

// Workaround for this issue: https://github.com/twbs/bootstrap/issues/14839
export const scroll = css`
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
`;
