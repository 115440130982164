import {isEqual} from "lodash";
import {responsibleGamingHeaderHeight} from "atg-ui/css/variables.styles";
import root from "window-or-global";

const getOriginalIfUnchanged = (original, updated) =>
    isEqual(original, updated) ? original : updated;

export const getStickyHeight = (sticky) => {
    const {content} = sticky;
    if (!content) return 0;

    return content.getBoundingClientRect().height;
};

const resetSticky = (sticky) => ({
    ...sticky,
    isPinned: false,
    isSticky: false,
    contentHeight: 0,
    stickyStyle: {},
});

export const updateStickyState = ({
    original,
    updated,
    stickyOffset,
    shouldLimitHeight,
    stickFromBottom,
}) => {
    const {isPinned, placeholder, content, contentHeight, isSticky} = updated;
    if (!placeholder || !content) return original;

    if (!isPinned) {
        return getOriginalIfUnchanged(original, resetSticky(updated));
    }

    const currentContentHeight = isSticky ? 0 : contentHeight;
    const placeholderRect = placeholder.getBoundingClientRect();
    let shouldStick = false;
    if (stickFromBottom) {
        shouldStick = placeholderRect.top >= window.innerHeight - content.offsetHeight;
    } else {
        shouldStick =
            placeholderRect.top - parseInt(responsibleGamingHeaderHeight, 10) <=
            stickyOffset - currentContentHeight;
    }

    if (!shouldStick) {
        const newSticky = {
            ...resetSticky(updated),
            isPinned: true,
        };
        return getOriginalIfUnchanged(original, newSticky);
    }

    const stickyIndex = updated && updated.index ? updated.index : 0;
    let stickyTop;
    if (stickFromBottom) {
        stickyTop = window.innerHeight - content.offsetHeight;
    } else {
        stickyTop =
            parseInt(stickyOffset, 10) + parseInt(responsibleGamingHeaderHeight, 10);
    }

    let newContentHeight = getStickyHeight(updated);

    let maxHeightStyles = {};
    if (shouldLimitHeight) {
        const maxHeight = root.innerHeight * 0.7;

        if (newContentHeight > maxHeight) {
            newContentHeight = maxHeight;
            maxHeightStyles = {
                height: newContentHeight,
                overflowY: "scroll",
            };
        }
    }

    const newSticky = {
        ...updated,
        isSticky: true,
        contentHeight: newContentHeight,
        stickyStyle: {
            position: "fixed",
            top: stickyTop,
            zIndex: 100 - stickyIndex,
            width: placeholderRect.width,
            ...maxHeightStyles,
        },
    };

    return getOriginalIfUnchanged(original, newSticky);
};

export const isHeightChanged = (sticky) => {
    if (!sticky) return false;

    const {contentHeight, isSticky} = sticky;
    if (!isSticky) return false;

    const actualHeight = getStickyHeight(sticky);
    return contentHeight !== actualHeight;
};
