import * as React from "react";
import {StyledAlert} from "./Alert.styles";

function Alert({
    type = "danger",
    style,
    children,
    dataTestId,
    dataTestValue,
    spacing,
    hiddenApp = false,
    hiddenPrint,
}) {
    return children ? (
        <StyledAlert
            type={type}
            className={hiddenApp ? "hidden-app" : null}
            css={style}
            data-test-id={dataTestId || `alert-${type}`}
            data-test-value={dataTestValue}
            role="alert"
            spacing={spacing}
            hiddenPrint={hiddenPrint}
        >
            {children}
        </StyledAlert>
    ) : null;
}

export default Alert;
