import type {Theme, Components} from ".";
import * as components from "./overrides";

export default function createOverrides() {
    const overridesOutput = Object.entries(components).reduce<Components<Theme>>(
        (acc: Components<Theme>, [muiName, overrides]) => {
            const name = muiName as keyof Components<Theme>;

            if (!overrides) return acc;

            acc[name] = {};

            type Group = keyof typeof overrides;

            Object.entries(overrides).forEach(([group, override]) => {
                const over = overrides[group as Group];
                type Override = typeof over;

                // @ts-ignore -- fix later
                acc[name][group as Group] = override as Override;
            });

            return acc;
        },
        {},
    );

    return overridesOutput;
}
