import {isiOS} from "@atg/utils/device";

/**
 * This function checks if the current environment is a webview
 * @returns true if the current environment is a webview
 */
export function isWebView() {
    if (typeof window === undefined) return false;
    const {navigator} = window;

    const {standalone} = navigator as any;
    const isFacebookWebView = navigator.userAgent.match(/FBAN|FBAV/i);

    if (isFacebookWebView) {
        return true;
    }

    const userAgent = navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad|macintosh/.test(userAgent);
    const iosWebview = ios && !safari;
    return ios ? (!standalone && !safari) || iosWebview : userAgent.includes("wv");
}

/**
 * an improved version of the shared util isiOSSafari,
 * ensuring that it correctly detects Safari on iOS devices while excluding other browsers.
 * it ensures Chrome and Firefox on iOS are excluded since they might include webkit in the userAgent string
 */
export function isSafariOniOS() {
    const ua = navigator.userAgent.toLowerCase();
    return (
        isiOS() &&
        ua.indexOf("safari") !== -1 &&
        ua.indexOf("crios") === -1 &&
        ua.indexOf("fxios") === -1 &&
        ua.indexOf("gsa") === -1 &&
        ua.indexOf("fbav") === -1 &&
        ua.indexOf("edgios") === -1 &&
        ua.indexOf("edg") === -1 &&
        ua.indexOf("opios") === -1 &&
        ua.indexOf("duckduckgo") === -1 &&
        ua.indexOf("brave") === -1
    );
}
