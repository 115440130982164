const orange = {
    50: "#FFF0E5",
    100: "#F9E5D3",
    200: "#F0C795",
    300: "#F1B064",
    400: "#F2A044",
    500: "#F39332",
    600: "#FF8B33",
    700: "#F37C1C",
    800: "#ED6A12",
    900: "#DA550B",
};

export default orange;
