import {put, call, takeLatest, takeEvery, select} from "redux-saga/effects";
import {isWeb} from "@atg-shared/system";
import * as Storage from "@atg-shared/storage";
import {trackGlobalEvent} from "@atg-global-shared/analytics";
import {
    RECEIVE_NEW_ACCESS_TOKEN,
    ACCESS_TOKEN_STORAGE_KEY,
    RESET_ACCESS_TOKEN,
    AUTHENTICATION_SUCCESS,
    RECEIVE_REDUCED_ACCESS_TOKEN,
} from "./accessTokenConstants";
import type {
    AccessTokenPayload,
    ReceiveNewAccessTokenAction,
    AuthenticationSuccessAction,
    ReceiveReducedAccessTokenAction,
} from "./accessTokenActions";
import * as AccessTokenActions from "./accessTokenActions";
import * as AuthSelectors from "./authSelectors";
import * as AuthActions from "./authActions";

export function* requestAccessToken() {
    const isTokenLoading: boolean = yield select(AuthSelectors.isTokenLoading);
    if (isTokenLoading) return;

    yield put(AccessTokenActions.fetchNewAccessToken());
}

export function* saveAccessToken(payload: AccessTokenPayload) {
    yield call(Storage.setItem, ACCESS_TOKEN_STORAGE_KEY, payload.accessToken);
}

export function* finalizeToken(action: ReceiveNewAccessTokenAction) {
    if (!action.error && action.payload?.accessToken) {
        const normalLogin: boolean = yield select(AuthSelectors.isNormalLogin);

        if (action.payload.authState === "reduced" && normalLogin) {
            trackGlobalEvent({
                event: "logout",
                destination: "general state",
            });
        }
        yield call(saveAccessToken, action.payload);
        yield put(AccessTokenActions.accessTokenSuccess());
    } else {
        yield put(AccessTokenActions.accessTokenError());
    }
}

export function* finalizeReducedToken(action: ReceiveReducedAccessTokenAction) {
    if (!action.payload) return;

    if (action.payload.accessToken) {
        yield call(saveAccessToken, action.payload);
        yield put(AccessTokenActions.accessTokenSuccess());
        yield put(AuthActions.checkAuth(false));
    }
}

export function* finalizeAuthentication(action: AuthenticationSuccessAction) {
    yield call(saveAccessToken, action.payload);
    yield put(AccessTokenActions.finishedAuthentication());
}

export function* removeAccessToken() {
    const accessToken: string | undefined = yield call(
        Storage.getItem,
        ACCESS_TOKEN_STORAGE_KEY,
    );
    if (!accessToken) return;

    yield call(Storage.removeItem, ACCESS_TOKEN_STORAGE_KEY);
}

export default function* accessTokenSaga() {
    // RECEIVE_NEW_ACCESS_TOKEN is Similar to ACCESS_TOKEN_SUCCESS
    yield takeLatest(RECEIVE_NEW_ACCESS_TOKEN, finalizeToken);
    yield takeLatest(RECEIVE_REDUCED_ACCESS_TOKEN, finalizeReducedToken);
    yield takeLatest(AUTHENTICATION_SUCCESS, finalizeAuthentication);
    yield takeLatest(RESET_ACCESS_TOKEN, removeAccessToken);

    // The app has its own way of getting its access token. See authenticationSaga.js.
    if (isWeb) {
        yield takeEvery(AccessTokenActions.REQUEST_ACCESS_TOKEN, requestAccessToken);
    }
}
