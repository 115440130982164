import {css} from "@emotion/react";
import {md, atgBrandYellow, atgV75Dark, atgV75Primary} from "atg-ui/css/variables.styles";
import {
    headline2,
    fontColor,
    fontWeightBold,
} from "atg-ui-components/components/Typography/Typography.styles";

const tabButton = (selected: boolean) => css`
    padding: 8px;
    background-color: ${atgV75Primary};

    span {
        font-weight: ${fontWeightBold};
    }

    ${selected &&
    css`
        color: ${atgBrandYellow};
        background-color: ${atgV75Dark};
    `}

    @media ${md} {
        background: none;
        height: 100%;

        span {
            ${headline2};
        }

        ${selected
            ? css`
                  border-bottom: 4px solid ${atgBrandYellow};
              `
            : css`
                  margin-bottom: 4px;
              `};
    }
`;

export const link = (selected: boolean) => css`
    color: ${fontColor.dark.secondary};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 33%;

    ${tabButton(selected)}
`;

export const wrapper = css`
    display: flex;
    width: 100%;
    height: 100%;

    li,
    a {
        width: 100%;
    }
`;
