import * as React from "react";
import {ComponentLoader} from "@atg-shared/lazy";
import features from "@atg-shared/client-features";

export function LazyScopeDebugger() {
    if (!features.isEnabled("scopeDebugger")) return null;

    return (
        <ComponentLoader
            loader={import(/* webpackChunkName: "ScopeDebugger" */ "./ScopeDebugger")}
            placeholder={null}
        />
    );
}
