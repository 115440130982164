import {css} from "@emotion/react";
import {atgBrandYellow, menuItemFontWeight} from "atg-ui/css/variables.styles";

export const wrapper = css`
    margin-top: 24px;
`;

export const icon = css`
    margin-right: 16px;
`;

export const typography = (selected: boolean) => css`
    display: inline-flex;
    align-items: center;
    font-weight: ${menuItemFontWeight};

    ${selected &&
    css`
        color: ${atgBrandYellow};
    `}
`;
