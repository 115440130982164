import {fetchAuthorized} from "@atg-shared/auth";
import {MEMBER_AUTH_URL} from "@atg-shared/service-url";
import type {
    TwoFactorActivationAction,
    TwoFactorConfirmationAction,
} from "./twoFactorActions";

export const twoFactorActivation = (params: TwoFactorActivationAction["params"]) =>
    fetchAuthorized(`${MEMBER_AUTH_URL}/smsverification/initiate`, {
        method: "PUT",
        body: JSON.stringify(params),
    });

export const twoFactorDeactivation = () =>
    fetchAuthorized(`${MEMBER_AUTH_URL}/smsverification/off`, {
        method: "PUT",
    });

export const twoFactorConfirmation = (params: TwoFactorConfirmationAction["params"]) =>
    fetchAuthorized(`${MEMBER_AUTH_URL}/smsverification/verify`, {
        method: "PUT",
        body: JSON.stringify(params),
    });

export type MemberSettingsResponse = {userId: string; smsVerificationLogin: boolean};

export const fetchTwoFactorConfirmationSetting = () =>
    fetchAuthorized<MemberSettingsResponse>(`${MEMBER_AUTH_URL}/settings`);
