/**
 * Lazily load a JS file
 * @param src URL to the JS file
 * @returns
 * Promise of the load operation. When the promise is resolved the script is downloaded and has
 * executed.
 */
export const scriptLoader = (src: string) =>
    new Promise((resolve, reject) => {
        const tag = document.createElement("script");

        tag.src = src;

        tag.onload = resolve;
        tag.onerror = () =>
            reject(new Error(`BundleLoader: Failed to load script. Chunkname: ${src}`));

        document.head.appendChild(tag);
    });

/**
 * Lazily load a CSS file
 * @param src URL to the CSS file
 * @returns
 * Promise of the load operation. When the promise is resolved the CSS is downloaded and inserted
 */
export const cssLoader = (href: string) =>
    new Promise((resolve, reject) => {
        const tag = document.createElement("link");

        tag.rel = "stylesheet";
        tag.type = "text/css";
        tag.href = href;

        tag.onload = resolve;
        tag.onerror = () =>
            reject(new Error(`BundleLoader: Failed to load css. Chunkname: ${href}`));

        document.head.appendChild(tag);
    });
