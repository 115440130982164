import * as React from "react";
import { createSvgIcon } from "../utils";

export const RadioCheckedOutlined = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.4a8.6 8.6 0 1 0 0 17.2 8.6 8.6 0 0 0 0-17.2ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z"
    />,
    "RadioCheckedOutlined",
);

export default RadioCheckedOutlined;
