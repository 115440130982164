import {css} from "@emotion/react";
import {sm} from "atg-ui/css/variables.styles";

export const globalStyles = css`
    .modal-open {
        position: relative;
        overflow: hidden;
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1040;
        background-color: rgba(0, 0, 0, 0.9);

        @media only screen and ${sm} {
            background-color: rgba(0, 0, 0, 0.7);
        }
    }
`;
