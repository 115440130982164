import * as React from "react";
import * as Redux from "react-redux";
import {getCurityConfig} from "@atg-shared/service-url";
import {VerticalsSelectors} from "@atg-global-shared/verticals";
import * as UserActions from "@atg-global-shared/user/userActions";
import CurityIFrameCommunicator from "atg-iframe/components/CurityIFrameCommunicator";
import {AUTH_SERVER_LOGOUT_SUCCESS} from "atg-iframe/components/CurityIFrameConstants";
import * as LogoutSelectors from "../domain/logoutSelectors";

type CurityLogoutResponse = {
    type: string;
};

const {url: curityUrl, logoutServiceUrl, tokenHandlerUrl} = getCurityConfig();

function CurityLogout() {
    const isLogoutRequested = Redux.useSelector(LogoutSelectors.isLogoutRequested);
    const logoutOptions = Redux.useSelector(LogoutSelectors.getLogoutOptions);
    const dispatch = Redux.useDispatch();
    const vertical = Redux.useSelector(VerticalsSelectors.getContext);

    const handleLogoutSuccess = () => {
        dispatch(UserActions.finalizeLogout(vertical, logoutOptions));
    };

    const handleAuthServerCallback = (
        response: CurityLogoutResponse | null | undefined,
    ) => {
        if (!response || (response && !response.type)) {
            return;
        }

        if (response.type === AUTH_SERVER_LOGOUT_SUCCESS) {
            handleLogoutSuccess();
        }
    };

    if (!isLogoutRequested) return null;

    return (
        <CurityIFrameCommunicator
            targetDomains={[curityUrl, tokenHandlerUrl]}
            callback={handleAuthServerCallback}
            authURL={logoutServiceUrl}
            isVisible={false}
        />
    );
}

export default CurityLogout;
