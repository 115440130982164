export const ALLOWED_MAX_TEXT_RESPONSE_LENGTH = 1000;

export const ALLOWED_MAX_FILE_NAME_CHARACTER_LENGTH = 100;
export const ALLOWED_MAX_FILES_PER_MESSAGE = 10;
export const ALLOWED_MAX_COMBINED_FILE_SIZE_PER_MESSAGE_IN_BYTES = 9_500_000; // 9.5 MB
export const ALLOWED_MAX_FILE_SIZE_IN_BYTES =
    ALLOWED_MAX_COMBINED_FILE_SIZE_PER_MESSAGE_IN_BYTES;

export const KILOBYTE_IN_BYTES = 1000; // 1 kB
export const MEGABYTE_IN_BYTES = 1_000_000; // 1 MB

export const ALLOWED_FILE_TYPES = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "application/pdf",
];
export const INVALID_FILE_NAME_CHARACTERS = [
    '"',
    "*",
    ":",
    "<",
    ">",
    "?",
    "/",
    "\\",
    "|",
];

export const ACCOUNT_USER_MESSAGE_KEY = "ACCOUNT_USER_MESSAGE";
export const KYC_QUESTIONNAIRE_RESPONSE_REQUIRED_TYPE =
    "kyc-management/notification/questionnaire/response-required";

export const INBOX_MESSAGES_REQUEST = "INBOX_MESSAGES_REQUEST";
export const INBOX_MESSAGES_RECEIVE = "INBOX_MESSAGES_RECEIVE";
export const RESET_INBOX_MESSAGES = "RESET_INBOX_MESSAGES";

export const INBOX_MESSAGE_STATUS_REQUEST = "INBOX_MESSAGE_STATUS_REQUEST";
export const INBOX_MESSAGE_STATUS_RECEIVE = "INBOX_MESSAGE_STATUS_RECEIVE";

export const INBOX_MESSAGE_READ_STATUS_UPDATE_REQUEST =
    "INBOX_MESSAGE_READ_STATUS_UPDATE_REQUEST";
export const INBOX_MESSAGE_READ_STATUS_UPDATE_RECEIVE =
    "INBOX_MESSAGE_READ_STATUS_UPDATE_RECEIVE";

export const INBOX_MESSAGE_REPLY_UPLOAD_REQUEST = "INBOX_MESSAGE_REPLY_UPLOAD_REQUEST";
export const INBOX_MESSAGE_REPLY_UPLOAD_RECEIVE = "INBOX_MESSAGE_REPLY_UPLOAD_RECEIVE";

export const RESET_INBOX_MESSAGE_POST_REQUEST_STATUS =
    "RESET_INBOX_MESSAGE_POST_REQUEST_STATUS";
