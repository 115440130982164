import * as React from "react";
import StickyContext from "../domain/StickyContext";

const StickyToggle = ({id, children}) => {
    const {togglePinned, stickies} = React.useContext(StickyContext);

    const {isPinned} = stickies[id] || {isPinned: false};
    return children({togglePinned: () => togglePinned(id), isPinned});
};

export default StickyToggle;
