import * as React from "react";
import {ComponentLoader} from "@atg-shared/lazy";
import type {Props as CampaignStartPageProps} from "./CampaignStartPage";

function LazyCampaignStartPage({
    handleCampaignClose,
    handleCampaignDisplay,
}: CampaignStartPageProps) {
    return (
        <ComponentLoader
            loader={import(
                /* webpackChunkName: "CampaignStartPage" */
                "./CampaignStartPage"
            )}
            placeholder={null}
            handleCampaignClose={handleCampaignClose}
            handleCampaignDisplay={handleCampaignDisplay}
        />
    );
}

export default LazyCampaignStartPage;
