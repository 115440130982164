import * as React from "react";
import type {Props as SvgIconPropType} from "../components/SvgIcon";
import SvgIcon from "../components/SvgIcon";

export function Broken(props: SvgIconPropType) {
    return (
        <SvgIcon {...props}>
            <path d="M11.492 15.471c-1.99-.497-3.98.25-5.721 1.742l-3.234 3.482c-2.736 2.487-2.736 6.467 0 8.955a6.306 6.306 0 008.955 0l3.482-3.234c1.492-1.741 1.99-3.731 1.741-5.721l-.962.12c.214 1.717-.202 3.416-1.48 4.93l-3.454 3.206-.013.013a5.336 5.336 0 01-7.583 0l-.017-.016-.017-.016c-2.313-2.103-2.313-5.417 0-7.52l.03-.027 3.224-3.471c1.585-1.344 3.246-1.894 4.813-1.502l.236-.94zm9.203-12.934l-3.482 3.234c-1.493 1.74-2.24 3.73-1.742 5.72l.941-.235c-.392-1.567.158-3.228 1.502-4.813l3.47-3.223.028-.03c2.103-2.314 5.417-2.314 7.52 0l.016.016.016.017a5.336 5.336 0 010 7.583l-.013.013-3.207 3.453c-1.513 1.28-3.212 1.695-4.929 1.48l-.12.963c1.99.249 3.98-.249 5.72-1.741l3.235-3.482a6.306 6.306 0 000-8.955c-2.488-2.736-6.468-2.736-8.955 0z" />
            <path d="M22.104 10.733l-11.37 11.371-.686-.685 11.37-11.371.686.685zm8.659 11.504L25.078 21.1l.19-.951 5.685 1.137-.19.951zm-9.477 8.716l-1.137-5.685.95-.19 1.138 5.685-.95.19zM10.866 1.199l1.137 5.685-.95.19L9.914 1.39l.951-.19zM1.389 9.915l5.685 1.137-.19.951L1.2 10.866l.19-.95z" />
            <path d="M30 2.202c-2.675-2.934-6.971-2.936-9.65-.007l-3.488 3.24-.017.02C15.29 7.268 14.451 9.415 15 11.609l.118.47L17 11.61l-.117-.47c-.34-1.359.114-2.856 1.382-4.36l3.465-3.22.041-.046c1.91-2.1 4.892-2.1 6.802 0l.024.026.024.024a4.851 4.851 0 010 6.898l-.019.02-3.193 3.438c-1.399 1.172-2.952 1.549-4.534 1.35l-.48-.06-.241 1.925.48.06c2.12.265 4.247-.268 6.098-1.854l.02-.018L30 11.828a6.79 6.79 0 000-9.626zM11.247.628l1.328 6.637-1.902.38-1.327-6.636 1.901-.38zM1.01 9.345l6.636 1.327-.38 1.902-6.637-1.328.38-1.901zm20.41.017l1.371 1.371L10.733 22.79l-1.371-1.37L21.419 9.362zM5.455 16.844c1.813-1.553 3.96-2.392 6.154-1.843l.47.118L11.61 17l-.47-.117c-1.359-.34-2.856.114-4.36 1.382L3.56 21.73l-.046.041c-2.1 1.91-2.1 4.892 0 6.802l.026.024.024.024a4.851 4.851 0 006.898 0l.02-.019 3.438-3.193c1.172-1.399 1.549-2.952 1.35-4.534l-.06-.48 1.925-.241.06.48c.265 2.12-.268 4.247-1.854 6.098l-.018.02L11.828 30a6.79 6.79 0 01-9.626 0c-2.934-2.676-2.936-6.973-.007-9.65l3.24-3.488.02-.018zm19.433 2.734l6.636 1.328-.38 1.902-6.637-1.328.38-1.902zm-3.408 4.93l1.328 6.636-1.902.38-1.328-6.636 1.902-.38z" />
        </SvgIcon>
    );
}

export default Broken;
