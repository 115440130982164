import type {Theme, Components} from "..";

type TypographyOverrides = Components<Theme>["MuiTypography"];

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        /**
         * @deprecated
         */
        h1Brand: true;
        /**
         * @deprecated
         */
        h2Brand: true;
        /**
         * @deprecated
         */
        h3Brand: true;
        /**
         * @deprecated
         */
        h4Brand: true;
        /**
         * @deprecated
         */
        subtitle3: true;
        body3: true;
        rubrik1: true;
        rubrik2: true;
        rubrik3: true;
        rubrik4: true;
        title1: true;
        title2: true;
        title3: true;
        title4: true;
        title5: true;
        title6: true;
    }
}

const overrides: TypographyOverrides = {
    defaultProps: {
        variantMapping: {
            /**
             * @deprecated
             */
            h1Brand: "h1",
            /**
             * @deprecated
             */
            h2Brand: "h2",
            /**
             * @deprecated
             */
            h3Brand: "h3",
            /**
             * @deprecated
             */
            h4Brand: "h4",
            /**
             * @deprecated
             */
            h1: "h1",
            /**
             * @deprecated
             */
            h2: "h2",
            /**
             * @deprecated
             */
            h3: "h3",
            /**
             * @deprecated
             */
            h4: "h4",
            /**
             * @deprecated
             */
            h5: "h5",
            /**
             * @deprecated
             */
            h6: "h6",
            /**
             * @deprecated
             */
            subtitle1: "h6",
            /**
             * @deprecated
             */
            subtitle2: "h6",
            /**
             * @deprecated
             */
            subtitle3: "h6",
            body1: "p",
            body2: "p",
            body3: "p",
            inherit: "p",
            rubrik1: "h1",
            rubrik2: "h2",
            rubrik3: "h3",
            rubrik4: "h4",
            title1: "h1",
            title2: "h2",
            title3: "h3",
            title4: "h4",
            title5: "h5",
            title6: "h6",
        },
    },
};

export default overrides;
