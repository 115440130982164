import {css} from "@emotion/react";
import {atgGreyNo1, sm, md, xl} from "atg-ui/css/variables.styles";

export const wrapper = css`
    @media ${sm} {
        padding: 0 24px;
    }

    @media ${md} {
        padding: 0 16 px;
    }

    @media ${xl} {
        padding: 0 40px;
    }
`;

export const grid = css`
    max-width: 1232px !important;
    margin: 0 auto !important;
`;

export const tableContainer = css`
    max-width: 1232px;
    margin: 0 auto;

    &:before {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
        content: "";
        display: table;
    }
`;

export const fullWidthGrid = css`
    ${grid};
    max-width: 100%;
    overflow: visible !important;
`;

export const headerWrapper = css`
    background-color: ${atgGreyNo1};
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
`;
