import type {ExperiencePayload} from "@atg-shared/personalization-types";
import * as Storage from "@atg-shared/storage";
import log, {serializeError} from "@atg-shared/log";
import {isEqual} from "lodash";
import {prefixKey} from "./personalizationUtil";

type ExperiencesState = {
    [key: number]: ExperiencePayload | undefined;
};

type ExperiencesStateUpdateCallback = (experiences: ExperiencesState) => void;

const storageKey = prefixKey("experiences");

const getExperiencesFromStorage = (): ExperiencesState => {
    const experiences = Storage.getItem(storageKey, Storage.SESSION);

    if (!experiences) return {};

    try {
        return JSON.parse(experiences);
    } catch (error: unknown) {
        return {};
    }
};

const saveExperienceToStorage = (experiences: ExperiencesState) => {
    try {
        Storage.setItem(storageKey, JSON.stringify(experiences), Storage.SESSION);
    } catch (error: unknown) {
        log.error(
            "personalizationStore:saveExperienceToStorage: Failed to save Qubit experience state to storage, using local state only",
            {error: serializeError(error)},
        );
    }
};

/**
 * This store is used to keep track of all the Qubit experiences that the user has been exposed to.
 * It stores the experiences in the session storage, and updates the state the storage is updated to
 * ensure that the state is always in sync between all microFE apps.
 */
class PersonalizationStore {
    experiences: ExperiencesState;

    private subscriptions: Array<ExperiencesStateUpdateCallback> = [];

    constructor() {
        this.experiences = getExperiencesFromStorage();
        this.subscriptions = [];

        Storage.subscribe(() => {
            const updatedExperiences = getExperiencesFromStorage();
            this.setState(updatedExperiences, false);
        }, storageKey);
    }

    setState(
        stateArg: ExperiencesState | ((state: ExperiencesState) => ExperiencesState),
        save = true,
    ) {
        if (typeof stateArg === "function") {
            this.experiences = stateArg(this.experiences);
        } else {
            this.experiences = stateArg;
        }
        if (save) saveExperienceToStorage(this.experiences);
        this.subscriptions.forEach((callback) => callback(this.experiences));
    }

    getState() {
        return this.experiences;
    }

    subscribe(callback: ExperiencesStateUpdateCallback) {
        this.subscriptions.push(callback);

        return () => {
            this.subscriptions = this.subscriptions.filter((sub) => sub !== callback);
        };
    }
}

// Singleton store, do not use directly, use the exported functions instead
export const store = new PersonalizationStore();

/**
 * Get the experience payload for a given experience ID
 */
export function getExperience(experienceId: number) {
    const experiences = store.getState();

    return experiences[experienceId];
}

/**
 * Set the experience payload for a given experience ID
 */
export function setExperience(payload: ExperiencePayload) {
    store.setState((state) => ({
        ...state,
        [payload.id]: payload,
    }));
}

/**
 * Subscribe to updates to the experience payload for a given experience ID
 */
export function subscribe(
    experienceId: number,
    callback: (experience: ExperiencePayload | undefined) => void,
) {
    let previousExperience = getExperience(experienceId);
    return store.subscribe((experiences) => {
        const updatedExperience = experiences[experienceId];
        // Prevent unnecessary re-renders by only triggering the callback if the experience payload has changed
        if (!isEqual(previousExperience, updatedExperience)) {
            previousExperience = updatedExperience;
            callback(updatedExperience);
        }
    });
}
