import type {EmotionJSX} from "@emotion/react/types/jsx-namespace";

export enum Source {
    menu = "menu",
    unauthorizedRedirect = "unauthorized-redirect",
}

export type Product = "shop-shares" | "harryboy" | "tillsammans";
export type Vertical = "horse" | "sport" | "casino";
export type LinkSelection = Vertical | Product;

export type GamingOptionsCardTypes = {
    mainImage: React.ReactElement;
    title: EmotionJSX.Element;
    text: string;
    url: string;
    buttonInner: React.ReactNode;
    id: Product;
}[];

export type GameAreaTypes = {
    url: string;
    icon: any;
    text: string;
    id: Vertical;
}[];
