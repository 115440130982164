import {alpha, type Components} from "@mui/material/styles";
import type {Theme} from "..";

type MuiToggleButtonOverrides = Components<Theme>["MuiToggleButton"];

const overrides: MuiToggleButtonOverrides = {
    defaultProps: {},
    styleOverrides: {
        root: ({ownerState, theme}) => ({
            ...(ownerState.color &&
                ownerState.color !== "standard" && {
                    borderRadius: theme.shape.borderRadius,
                    color: theme.palette[ownerState.color].main,
                    border: `1px solid ${alpha(
                        theme.palette[ownerState.color].main,
                        0.5,
                    )}`,

                    "&:hover": {
                        border: `1px solid ${theme.palette[ownerState.color].main}`,
                        backgroundColor: alpha(
                            theme.palette[ownerState.color].main,
                            theme.palette.action.hoverOpacity,
                        ),

                        // Reset on touch devices, it doesn't add specificity
                        "@media (hover: none)": {
                            backgroundColor: "transparent",
                        },
                    },

                    "&.Mui-selected": {
                        color: theme.palette[ownerState.color].contrastText,
                        backgroundColor: theme.palette[ownerState.color].main,
                        "&:hover": {
                            backgroundColor: theme.palette[ownerState.color].dark,

                            // Reset on touch devices, it doesn't add specificity
                            "@media (hover: none)": {
                                backgroundColor: theme.palette[ownerState.color].main,
                            },
                        },
                    },
                }),
        }),
    },
};

export default overrides;
