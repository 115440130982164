export const USER_FORM_STORAGE_KEY = "userForm";

export const AUTH_SERVER_POSTMESSAGE_UPDATE_USERNAME =
    "AUTH_SERVER_POSTMESSAGE_UPDATE_USERNAME";

export const AUTH_SERVER_POSTMESSAGE_CONFIRM_PASSWORD_SUCCESS =
    "AUTH_SERVER_POSTMESSAGE_CONFIRM_PASSWORD_SUCCESS";

export const AUTH_SERVER_LOGIN_SUCCESS = "login";
export const AUTH_SERVER_LOGOUT_SUCCESS = "logout";
export const AUTH_SERVER_CANCEL = "canceled";
export const AUTH_SERVER_ERROR = "error";

export const AUTH_SERVER_CHECKBOX_HIDE_BALANCE = "AUTH_SERVER_CHECKBOX_HIDE_BALANCE";
export const AUTH_SERVER_CHECKBOX_REMEMBER_USERNAME =
    "AUTH_SERVER_CHECKBOX_REMEMBER_USERNAME";

export const AUTH_SERVER_RGS_NEXT_POSSIBLE_LOGIN = "RGS_NEXT_POSSIBLE_LOGIN";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const START_BANK_ID = "START_BANK_ID";

export const START_FREJA_ID = "START_FREJA_ID";
