import {css} from "@emotion/react";
import {xs, deprecated_xsOnly} from "atg-ui/css/variables.styles";

/**
 * Replaces "import {listItem} from "./Links.styles";" since
 * updating that file with css from @emotion/react will
 *  break imports in other places.
 */
export const listItem = css`
    font-family: Roboto, sans-serif;
    font-weight: 500;
    text-transform: none;
    padding: 16px 24px;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    height: 46px;
    width: 100%; /* fix for edge-cases where html layout differs */
    justify-content: flex-start; /* same as above*/

    @media ${xs} {
        &:hover {
            background-color: rgba(255, 255, 255, 0.08);
            transition: all 0.14s ease-in-out;
        }
    }
`;

export const playDropdown = css`
    padding-top: 0px;
    padding-bottom: 32px;
    @media ${deprecated_xsOnly} {
        padding-bottom: 0px;
    }
`;

export const tillsammansPlayDropdown = css`
    ${playDropdown};
    padding-bottom: 0;
`;

export const headline = css`
    ${listItem};
    background-color: transparent;
    padding: 32px 24px 16px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    letter-spacing: 0.9px;

    :hover {
        color: rgba(255, 255, 255, 0.5);
        background-color: transparent;
        cursor: default;
    }
`;

export const iconContainer = css`
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const playLive = css`
    ${listItem};
    cursor: pointer;
`;

export const externalLinkItem = css`
    ${listItem};
    color: #fff !important;
    font-weight: 500 !important;

    > span {
        font-size: 12px;
    }

    :hover {
        text-decoration: none !important;
        color: #fff !important;
    }
`;

export const disabledLink = css`
    ${listItem};
    color: rgba(255, 255, 255, 0.4);

    :hover {
        color: rgba(255, 255, 255, 0.4);
        background-color: transparent;
        cursor: default;
    }
`;

export const pointer = css`
    cursor: pointer;
`;

export const atgCheckContainer = css`
    font-size: 12px;
`;

export const newExportIcon = css`
    margin-left: 8px;
    opacity: 0.5;
    font-size: 12px;
`;

export const sideMenuIcon = css`
    margin-right: 16px;
    opacity: 0.5;
`;

export const videoArchiveIcon = css`
    ${sideMenuIcon};
    font-size: 20px;
`;

export const sideMenuExportIcon = css`
    ${sideMenuIcon};
    margin-left: 8px;
`;

export const sideMenuHarryIcon = css`
    ${sideMenuIcon};
    font-size: 29px;
`;

export const sideMenuPeopleIcon = css`
    ${sideMenuIcon};
    font-size: 29px;
`;

export const sideMenuReturnIcon = css`
    ${sideMenuIcon};
    opacity: 1;
    font-size: 20px;
`;

export const sideMenuLiveGamesIcon = css`
    ${sideMenuIcon};
    margin-right: 15px;
`;

export const sideMenuPlayIcon = css`
    ${sideMenuIcon};
    margin-right: 15px;
`;
