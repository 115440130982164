import * as React from "react";
import * as Redux from "react-redux";
import type {Action} from "redux";
import type {LazyStore} from "@atg-shared/lazy-store";
import LoadingIndicator from "atg-ui-components/components/LoadingIndicator";

type ComponentModule<InnerProps, S, A extends Action> = Promise<{
    default: React.ComponentType<InnerProps>;
    init?: (store: LazyStore<S, A>) => void;
}>;

type Props<InnerProps, S, A extends Action> = InnerProps & {
    loader: ComponentModule<InnerProps, S, A>;
    placeholder?: React.ReactNode;
    /**
     * @default 0
     * Delay in milliseconds before showing the placeholder while component is loading. If 0, the placeholder is shown immediately.
     */
    delay?: number;
};

/**
 * Lazily load another React component. NOTE: The `loader` prop needs to be stable, i.e. not change across rerenders
 * @param loader - dynamic import statement for the component chunk with optional webpackChunkName
 * @param placeholder - IMPORTANT: if undefined, a loading indicator is rendered. Send in "null" if you do not need a loading indicator. Or specify a custom placeholder to render while loading.
 */
function ComponentLoader<IP, S, A extends Action>({
    loader,
    placeholder = <LoadingIndicator inverted />,
    delay = 0,
    ...innerProps
}: Props<IP, S, A>) {
    const store = Redux.useStore() as LazyStore<S, A>;

    // wrap in useRef to prevent a new lazy component from being created each render
    // (would cause the async function to be called each render, briefly causing the fallback to be
    // rendered)
    const {current: Component} = React.useRef(
        React.lazy(async () => {
            const module = await loader;
            if (module.init) module.init(store);
            return module;
        }),
    );

    const [showFallback, setShowFallback] = React.useState(!delay);

    React.useEffect(() => {
        if (delay > 0) {
            const timeout = setTimeout(() => {
                setShowFallback(true);
            }, delay);

            return () => clearTimeout(timeout);
        }
        return undefined;
    }, [delay]);

    return (
        <React.Suspense fallback={showFallback ? placeholder : null}>
            {/* @ts-expect-error */}
            <Component
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...innerProps}
            />
        </React.Suspense>
    );
}

export default ComponentLoader;
