import * as React from "react";
import { createSvgIcon } from "../utils";

export const CheckboxCheckedFilled = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36 3A2.36 2.36 0 0 0 3 5.36v13.28A2.36 2.36 0 0 0 5.36 21h13.28A2.36 2.36 0 0 0 21 18.64V5.36A2.36 2.36 0 0 0 18.64 3H5.36Zm12.115 5.345a.7.7 0 0 0-.99-.99L9.51 14.33l-1.995-1.995a.7.7 0 0 0-.99.99l2.49 2.49a.7.7 0 0 0 .99 0l7.47-7.47Z"
    />,
    "CheckboxCheckedFilled",
);

export default CheckboxCheckedFilled;
