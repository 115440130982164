import fetch from "@atg-shared/fetch";
import type {FetchAction} from "@atg-shared/fetch-types";
import {FETCH, call} from "@atg-shared/fetch-redux";
import type {State} from "./memberRecoveryReducer";

export const RESET_STATUS = "memberRecovery/RESET_STATUS";
export const REQUEST_CONTROL_QUESTION = "memberRecovery/REQUEST_CONTROL_QUESTION";
export const RECEIVE_CONTROL_QUESTION = "memberRecovery/RECEIVE_CONTROL_QUESTION";
export const REQUEST_RECOVERY_METHODS = "memberRecovery/REQUEST_RECOVERY_METHODS";
export const RECEIVE_RECOVERY_METHODS = "memberRecovery/RECEIVE_RECOVERY_METHODS";
export const MEMBER_RECOVERY = "memberRecovery/MEMBER_RECOVERY";
export const MEMBER_RECOVERY_RESPONSE = "memberRecovery/MEMBER_RECOVERY_RESPONSE";
export const RECOVER_USERNAME_POSTAL =
    "/services/tokenized-proxy/member-auth/api/v1/recovery/username/postal";
export const RECOVER_USERNAME_EMAIL =
    "/services/tokenized-proxy/member-auth/api/v1/recovery/username/email";
export const RECOVER_USERNAME_SMS =
    "/services/tokenized-proxy/member-auth/api/v1/recovery/username/sms";
export const RECOVER_PASSWORD_POSTAL =
    "/services/tokenized-proxy/member-auth/api/v1/recovery/password/postal";
export const RECOVER_PASSWORD_SMS =
    "/services/tokenized-proxy/member-auth/api/v1/recovery/password/sms";
export const RECOVERY_QUESTION =
    "/services/tokenized-proxy/member-auth/api/v1/recovery/question";
export const RECOVERY_METHODS =
    "/services/tokenized-proxy/member-auth/api/v1/recovery/methods";
type ResetStatusAction = {
    type: typeof RESET_STATUS;
};
type MethodsResponse = {
    methods: string[];
    username: string;
};
type ControlQuestionResponse = {
    question: string;
};
type MemberRecoveryAction = FetchAction<
    typeof MEMBER_RECOVERY,
    typeof MEMBER_RECOVERY_RESPONSE,
    any,
    State
>;
export type RecoveryMethodsAction = FetchAction<
    typeof REQUEST_RECOVERY_METHODS,
    typeof RECEIVE_RECOVERY_METHODS,
    MethodsResponse,
    State
>;
export type ControlQuestionAction = FetchAction<
    typeof REQUEST_CONTROL_QUESTION,
    typeof RECEIVE_CONTROL_QUESTION,
    ControlQuestionResponse,
    State
>;
export type Action =
    | ResetStatusAction
    | MemberRecoveryAction
    | RecoveryMethodsAction
    | ControlQuestionAction;
export const resetStatus = (): ResetStatusAction => ({
    type: RESET_STATUS,
});
export const getControlQuestion = (
    mobilePhone: string,
    personalIdentityNumber: string,
    username: string,
): ControlQuestionAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_CONTROL_QUESTION,
        receiveAction: RECEIVE_CONTROL_QUESTION,
        callApi: call(fetch, RECOVERY_QUESTION, {
            method: "POST",
            body: JSON.stringify({
                mobilePhone,
                personalIdentityNumber,
                username,
            }),
        }),
    },
});
export const sendPasswordBySms = (
    challengeResponse: string,
    personalIdentityNumber: string,
    username: string,
): MemberRecoveryAction => ({
    type: FETCH,
    payload: {
        requestAction: MEMBER_RECOVERY,
        receiveAction: MEMBER_RECOVERY_RESPONSE,
        callApi: call(fetch, RECOVER_PASSWORD_SMS, {
            method: "POST",
            body: JSON.stringify({
                answer: challengeResponse,
                personalIdentityNumber,
                username,
            }),
        }),
    },
});
export const sendUserNameByMail = (
    firstName: string,
    lastName: string,
    personalIdentityNumber: string,
): MemberRecoveryAction => ({
    type: FETCH,
    payload: {
        requestAction: MEMBER_RECOVERY,
        receiveAction: MEMBER_RECOVERY_RESPONSE,
        callApi: call(fetch, RECOVER_USERNAME_POSTAL, {
            method: "POST",
            body: JSON.stringify({
                firstName,
                lastName,
                personalIdentityNumber,
            }),
        }),
    },
});
export const sendUserNameByEmail = (
    firstName: string,
    lastName: string,
    personalIdentityNumber: string,
    email: string,
    challengeResponse: string,
): MemberRecoveryAction => ({
    type: FETCH,
    payload: {
        requestAction: MEMBER_RECOVERY,
        receiveAction: MEMBER_RECOVERY_RESPONSE,
        callApi: call(fetch, RECOVER_USERNAME_EMAIL, {
            method: "POST",
            body: JSON.stringify({
                firstName,
                lastName,
                personalIdentityNumber,
                email,
                challengeResponse,
            }),
        }),
    },
});
export const sendUserNameBySms = (
    firstName: string,
    lastName: string,
    personalIdentityNumber: string,
    mobilePhone: string,
    challengeResponse: string,
): MemberRecoveryAction => ({
    type: FETCH,
    payload: {
        requestAction: MEMBER_RECOVERY,
        receiveAction: MEMBER_RECOVERY_RESPONSE,
        callApi: call(fetch, RECOVER_USERNAME_SMS, {
            method: "POST",
            body: JSON.stringify({
                firstName,
                lastName,
                personalIdentityNumber,
                mobilePhone,
                challengeResponse,
            }),
        }),
    },
});
export const sendPasswordByMail = (
    personalIdentityNumber: string,
    challengeResponse: string,
): MemberRecoveryAction => ({
    type: FETCH,
    payload: {
        requestAction: MEMBER_RECOVERY,
        receiveAction: MEMBER_RECOVERY_RESPONSE,
        callApi: call(fetch, RECOVER_PASSWORD_POSTAL, {
            method: "POST",
            body: JSON.stringify({
                personalIdentityNumber,
                challengeResponse,
            }),
        }),
    },
});
export const getRecoveryMethods = (username: string): RecoveryMethodsAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_RECOVERY_METHODS,
        receiveAction: RECEIVE_RECOVERY_METHODS,
        callApi: call(fetch, `${RECOVERY_METHODS}/${username}`),
    },
});
