export enum AuthenticatorTypes {
    BankID = "bankid",
    AtgCaptcha = "atg-captcha",
    FrejaEID = "freja-eid",
}

export type Template = "client-operation" | "form" | "selector";

/**
 * Base Action
 * Each template type has its own model schema
 *
 * TODO: https://curity.io/docs/haapi-data-model/latest/base-action.html#base-action.model changed to OR
 */
export type BaseAction = {
    template: Template;
    kind: string;
    title?: string;
    model:
        | ClientOperationModel
        | FormTemplateModel
        | SelectorTemplateModel
        | RedirectionFormModel;
};

/**
 * Actions
 * Possible user actions to continue the flow.
 */
export type Actions = Array<Action>;

export type Action = ClientOperationAction | FormAction | SelectorAction;

// Launching an application or use some other device
export type ClientOperationAction = BaseAction & ClientOperationTemplate;

export type ClientOperationTemplate = {
    template?: "client-operation";
    model?: ClientOperationModel;
};

export type ClientOperationModel =
    | BaseClientOperation
    | ExternalBrowserFlow
    | LaunchBankIDApp;

export type BaseClientOperation = {
    name: string;
    arguments: BankIDClientOperationArguments; // we know this?
    continueActions: ContinueActions;
    errorActions?: Actions;
};

/**
 * Allows user to proceed with the flow after operation is completed
 */
export type ContinueActions = Actions | ContinueActionsItems;
export type ContinueActionsItems = Array<ContinueAction>;
export type ContinueAction = {
    kind: string;
};

export type ExternalBrowserClientOperation = {
    href: string;
};

export type ExternalBrowserFlow = {
    name?: "external-browser-flow";
    arguments?: ExternalBrowserClientOperation;
};

export enum BankIDTypesEnum {
    SameDevice = "bankid-same-device",
    OtherDevice = "bankid-other-device",
}

export type BankIDTypes = BankIDTypesEnum.OtherDevice | BankIDTypesEnum.SameDevice;

export type BankIDClientOperation = {
    name?: "bankid";
    arguments?: BankIDClientOperationArguments;
};

export type BankIDClientOperationArguments = {
    href: string;
    autoStartToken: string;
    redirect: string;
};

export type LaunchBankIDApp = BankIDClientOperation;

export type FormAction = BaseAction & FormTemplate;

export type FormTemplate = {
    template?: "form";
    kind?: string;
    properties?: FormTemplateProperties;
    model?: FormTemplateModel;
};

/**
 * Only present when the action represents an authenticator option
 */
export type FormTemplateProperties = {
    authenticatorType?: AuthenticatorTypes;
};

export type FormModelMediaType = "application/x-www-form-urlencoded" | "application/json";

export type FormTemplateModel = {
    actionTitle?: string;
    continueActions?: Actions;
    errorActions?: Actions;
    fields?: Array<FormField>;
    type?: FormModelMediaType;
    method: HTTPMethod;
    href: string;
};

export type FormField = FormFieldBase & TextField & SelectField & CheckboxField;

export type FormFieldBase = {
    name: string;
    label?: string;
    placeholder?: string;
    value?: string;
    type: FormFieldBaseType;
    personalNumber?: string;
};

export type FormFieldBaseType =
    | "hidden"
    | "text"
    | "password"
    | "username"
    | "select"
    | "context"
    | "checkbox";

export type TextField = {
    kind?: string;
};

export type SelectField = {
    options?: SelectFieldOptions;
};

export type SelectFieldOptions = Array<SelectFieldOption>;

export type SelectFieldOption = {
    label: string;
    value: string;
    selected?: boolean;
};

export type CheckboxField = {
    checked?: boolean;
    readonly?: boolean;
};

export type HTTPMethod = "GET" | "HEAD" | "POST" | "PUT" | "PATCH" | "DELETE" | "OPTIONS";

export type SelectorActions = Array<SelectorAction>; // only used for sagas
export type SelectorAction = BaseAction & SelectorTemplate;

/**
 * Selector actions are used to let the user select between different options.
 * Each option is given by an action which the user can choose to follow.
 */
export type SelectorTemplate = {
    template?: "selector" | "form"; // TODO: REVIEW IT MAYBE SHOULD BE JUST STRING
    kind?: string;
    model?: SelectorTemplateModel;
    properties?: SelectorTemplateProperties;
};

export type SelectorTemplateProperties = {
    authenticatorType: AuthenticatorTypes;
};

export type SelectorTemplateModel = {
    options: Actions;
};

export type UserMessages = Array<UserMessageItem>;

export type UserMessageItem = {
    text: string;
    classList: Array<ClassListItem>;
};

export type ClassListItem = "info" | "warn" | "error" | "heading";

export type Links = Array<LinkItem>;

export type LinkItem = {
    href: string;
    rel: string;
    type?: string;
    title?: string;
};

export enum StepType {
    AuthenticationStep = "authentication-step",
    RedirectionStep = "redirection-step",
    RegistrationStep = "registration-step",
    PollingStep = "polling-step",
    ContinueSameStep = "continue-same-step",
    OAuthAuthorizationResponse = "oauth-authorization-response",
    ConsentorStep = "consentor-step",
    UserConsentStep = "user-consent-step",
    AuthFailedStep = "https://curity.se/problems/authentication-failed",
    InvalidInputStep = "https://curity.se/problems/invalid-input",
    IncorrectCredentialsStep = "https://curity.se/problems/incorrect-credentials",
    ErrorAuthResponseStep = "https://curity.se/problems/error-authorization-response",
    UnexpectedStep = "https://curity.se/problems/unexpected",
    TooManyAttemptsStep = "https://curity.se/problems/too-many-attempts",
    GenericUserErrorStep = "https://curity.se/problems/generic-user-error",
}

export type MetaData = {
    templateArea?: string;
    viewName?: string;
};

export type Step = {
    type: StepType;
    metadata?: MetaData;
    status: number;
};

/**
 * Authentication step
 * step towards user authentication.
 */
export type AuthenticationStep = Step & {
    actions: Actions;
    messages?: UserMessages;
    links?: Links;
};

/**
 * Redirection step
 * Informs client to automatically redirect user, no user attention needed
 */
export type RedirectionStep = Step & {
    actions: RedirectionActions;
};

export type RedirectionActions = Array<RedirectionForm>;
export type RedirectionForm = FormAction & RedirectionFormObject;
export type RedirectionFormObject = {
    model?: RedirectionFormModel;
};

export type RedirectionFormModel = {
    fields?: RedirectFormModelFields;
    method: "GET" | "POST";
    continueActions?: false;
    errorActions?: false;
};
export type RedirectFormModelFields = Array<FormField>;

export type RegistrationStep = Step & {
    actions: Actions;
    links?: Links;
    messages?: UserMessages;
};

/**
 * Polling step
 * Use one of the provided actions to poll. While polling, display any
 * **links** or **messages** provided from server.
 */
export type PollingStep = Step & {
    actions: Actions;
    messages?: UserMessages;
    links?: Links;
    properties: PollingProperties;
};

export type PollingProperties = {
    recipientOfCommunication?: string;
    status: PollingStatus;
};

export type PollingStatus = "done" | "failed" | "pending";

/**
 * Tell client to continue on same step because no progress
 * could be made. Should try again with previous actions
 */
export type ContinueSameStep = Step & {
    messages?: UserMessages;
};

/**
 * Provide authorization response such as `code` and `state`.
 * This signals the end of the authorization request flow
 */
export type OAuthAuthorizationResponse = Step & {
    messages?: UserMessages;
    links?: Links;
    properties: OAuthAuthorizationResponseProperties;
};

export type OAuthAuthorizationResponseProperties = {
    scope?: string;
    state?: string;
    code?: string;
    accessToken?: string;
    tokenType?: string;
    expiresIn?: string;
    idToken?: string;
    sessionState?: string;
};

/**
 * Allows user to review which identity attributes will be available.
 * Client should display all actions provided by the server as well as any
 * messages and links
 */
export type UserConsentStep = Step & {
    actions: Actions;
    messages?: UserMessages;
    links?: Links;
};

/**
 * Allows additional processing during delegation and token issuance,
 * at the end of auth flow.
 * Client should display all actions provided by the server as well as any
 * messages and links
 */
export type ConsentorStep = Step & {
    actions: Actions;
    messages?: UserMessages;
    links?: Links;
};

export type AuthenticationFailed = Step & Problem;

/**
 * An error that occurs when an invalid form is submitted.
 */
export type InvalidInput = Step & InvalidInputProblem;

export type InvalidInputProblem = Problem & {
    invalidFields: InvalidFields;
};

export type InvalidFields = Array<InvalidField>;

export type InvalidField = {
    name: string;
    reason?: "invalidValue";
    detail?: string;
};

export type IncorrectCredentials = Step & Problem;

export type ErrorAuthorizationResponse = Step & AuthorizationProblem;

export type AuthorizationProblem = {
    error: string;
    error_description?: string;
    iss?: string;
} & Problem;

export type UnexpectedProblem = Step & Problem;

export type TooManyAttempts = Step & Problem;

export type UserError = Step & Problem;

/**
 * Indicates an error occurred on the server
 */
export type Problem = {
    title?: string;
    messages?: UserMessages;
    links?: Links;
};

/**
 * CurityRootModel represents the data structure from the backend responses.
 * It encapsulates various properties that can be present in the response.
 */
export type CurityRootModel = {
    status: number;
    actions?: Actions | RedirectionActions;
    type?: StepType;
    metadata?: MetaData;
    title?: string;
    message?: UserMessages;
    messages?: UserMessages;
    links?: Links;
    error?: string;
    errorDescription?: string;
    invalidFields?: InvalidFields;
    properties?:
        | OAuthAuthorizationResponseProperties
        | SelectorTemplateProperties
        | PollingProperties
        | FormTemplateProperties;
    model?:
        | ClientOperationModel
        | FormTemplateModel
        | SelectorTemplateModel
        | RedirectionFormModel;
    template?: Template;
    kind?: string;
    code?: string;
};

export type ContactInfo = {
    mobilephone: string;
    email: string;
};

type MarketingConsent = {"newsletter-checkbox": "on" | "off"};

export type RegisterUserInfo = ContactInfo & MarketingConsent;

export type ActivateUser = {
    mobilephone: string;
    email: string;
};

export type UnpwCredentials = {
    userName: string;
    password: string;
    showPassword?: boolean;
    "g-recaptcha-response"?: string | null;
};

export type ResetPassword = {
    passwordTemp: string;
    password: string;
};

export type OTP = {
    otp: string;
};

export type CurityFetch = Partial<Omit<Request, "body">> & {
    url: string;
};

export type UNPWRequestData = CurityFetch & {
    body: UnpwCredentials;
};

export type AuthorizeLoginData = CurityFetch & {
    body: {
        token: string;
        state: string;
    };
};

export type CurityStatus = "idle" | "loading" | "successful" | "failed";

// this is a new merged enum list taken from the actions folders like (credentialsActions etc...)
// Revisit this and see if wwe should move it into a data-access lib later (when migration is done)
export enum ActionTypes {
    CURITY_INIT = "curity/CURITY_INIT",
    CURITY_INIT_UNPW = "curity/CURITY_INIT_UNPW",
    SET_CURITY_STATE = "curity/SET_CURITY_STATE",
    GET_CURITY_STATE = "curity/GET_CURITY_STATE",
    RESET_CURITY_STATE = "curity/RESET_CURITY_STATE",
    SET_CURITY_RESPONSE = "curity/SET_CURITY_RESPONSE",
    SET_CURITY_RESPONSE_SUCCESS = "curity/SET_CURITY_RESPONSE_SUCCESS",
    GET_CURITY_AUTHENTICATORS = "curity/GET_CURITY_AUTHENTICATORS",
    SET_AUTHENTICATORS = "curity/SET_AUTHENTICATORS",
    RESTART_FLOW = "curity/RESTART_FLOW",
    SET_AUTH_CONTEXT = "curity/SET_AUTH_CONTEXT",
    SET_CURITY_ERROR = "curity/SET_CURITY_ERROR",
    RESET_PASSWORD = "curity/RESET_PASSWORD",
    SELECT_AUTH_METHOD = "curity/SELECT_AUTH_METHOD",
    SET_AUTH_METHOD = "curity/SET_AUTH_METHOD",
    SET_TEMP_AUTH_METHOD = "curity/SET_TEMP_AUTH_METHOD",
    SET_HIDE_BALANCE_LOGIN_FLOW = "curity/SET_HIDE_BALANCE_LOGIN_FLOW",
    ACTIVATE_USER = "curity/ACTIVATE_USER",
    SET_AUTH_MODE = "curity/SET_AUTH_MODE",
    LOGIN_CREDENTIALS_REQUEST_START = "curity/LOGIN_CREDENTIALS_REQUEST_START",
    LOGIN_CREDENTIALS_REQUEST_FINISH = "curity/LOGIN_CREDENTIALS_REQUEST_FINISH",
    VERIFY_SMS = "curity/VERIFY_SMS",
    GENERATE_NEW_SMS_CODE = "curity/GENERATE_NEW_SMS_CODE",
    CURITY_SUBMIT_UNPW = "curity/CURITY_SUBMIT_UNPW",
    INIT_FREJA_ID = "curity/INIT_FREJA_ID",
    INIT_FREJA_ID_QR = "curity/INIT_FREJA_ID_QR",
    INIT_BANK_ID = "curity/INIT_BANK_ID",
    INIT_BANK_ID_WITH_PERSONAL_NUMBER = "curity/INIT_BANK_ID_WITH_PERSONAL_NUMBER",
    INIT_BANK_ID_QR = "curity/INIT_BANK_ID_QR",
    INIT_BANK_ID_MANUAL = "curity/INIT_BANK_ID_MANUAL",
    INIT_BANK_ID_QR_MANUAL = "curity/INIT_BANK_ID_QR_MANUAL",
    STOP_POLLING = "curity/STOP_POLLING",
    START_POLLING = "curity/START_POLLING",
    CANCEL_POLL = "curity/CANCEL_POLL",
    REGISTER_USER_CONTACT_INFO = "curity/REGISTER_USER_CONTACT_INFO",
    // should be removed when cleaning up the old login flow
    LOGIN_ERROR = "LOGIN_ERROR",
}

export enum CurityState {
    ACTIVATE_USER = "activate-user",
    STARTED = "started",
    CANCELED = "canceled",
    PENDING = "pending",
    IDLE = "idle",
    POLLING = "polling",
    LOGIN = "login",
    ERROR = "error",
    ERROR_UNPW = "errorUNPW",
    ERROR_BANK_ID = "errorBankID",
    ERROR_FREJA_ID = "errorFrejaID",
    ERROR_TIMED_OUT = "errorTimedOut",
    REGISTER = "register",
    FREJAEID_OTHER_DEVICE = "freja-eid-other-device",
    FREJAEID_SAME_DEVICE = "freja-eid-same-device",
    AUTH_STARTER = "auth-starter",
    ATG_CAPTCHA = "atg-captcha",
    BANKID = "bankid",
    FREJAEID = "freja-eid",
    BANKID_OTHER_DEVICE = "bankid-other-device",
    BANKID_SAME_DEVICE = "bankid-same-device",
    PASSWORD_RESET = "password-reset",
    AUTHENTICATOR_SELECTOR = "authenticator-selector",
    RECOVERY = "recovery",
    REGISTER_CONTACT_INFO = "registerContactInfo",
    ERROR_AUTH = "errorAuth",
    SMS_VERIFICATION = "smsVerification",
    POLL = "poll",
    UNEXPECTED_ERROR = "unexpected-error",
    SERVER_ERROR = "service-unavailable",
    RECOVER_METHOD = "recover-method",
    EXPIRED_THUMBPRINT = "expired-thumbprint",
    AUTHORIZATION_ERROR = "AUTHORIZATION_ERROR",
    // below type moved to new @atg-login/util lib. this should probably be removed
    TIMEOUT_ERROR_MESSAGE = "Vänligen försök igen om en stund.",
    DEFAULT_ERROR_MESSAGE = "Vänligen försök igen senare.",
    SERVER_ERROR_MESSAGE = "Vi har svårt att nå vår server. Försök igen lite senare. Om problemet kvarstår, vänligen kontakta vår kundservice.",
    SMS_VERIFY_ERROR = "Felaktig eller utgången kod.Var god försök igen",
    SMS_VERIFY_TOO_MANY_ATTEMPTS = "Vi kunde inte verifiera din SMS-kod. Vänligen generera en ny kod och försök igen.",
}

export type AuthMethod = {
    method: CurityState;
    context: AuthenticatorTypes | null;
    isSuccessfulLogin: boolean;
    hideBalance: boolean;
    temp?: Omit<AuthMethod, "temp"> | null;
};

export type LoginCredentialsRequestStatus = {
    pending: boolean;
};

export type AuthScope = "login" | "register" | "recovery";

export enum AuthMode {
    Login = "login",
    Register = "register",
}

export type InitBankIDPayload = {
    usesamedevice: string;
    personalnumber: string;
};

export type InitFrejaIDPayload = {
    username: string;
};

export type QRData = {
    href: string;
    rel?: string;
    title?: string;
    type?: string;
};

export type EIDCredentials = {
    personalNumber: string;
};

export type CurityError = {
    status?: number;
    messages?: UserMessages;
    metadata?: MetaData;
    code?: string;
    type?: StepType;
    title?: string;
} | null;

// making all this to enums might need a big refactor when we are using them as strings
// export type InitMethods =
//     | typeof INIT_BANK_ID_QR
//     | typeof INIT_BANK_ID
//     | typeof INIT_BANK_ID_WITH_PERSONAL_NUMBER
//     | typeof INIT_BANK_ID_QR_MANUAL
//     | typeof INIT_BANK_ID_MANUAL
//     | typeof INIT_FREJA_ID_QR
//     | typeof INIT_FREJA_ID
//     | typeof CURITY_INIT_UNPW
//     | typeof CURITY_SUBMIT_UNPW
//     | typeof RESET_PASSWORD;

export type InitMethods =
    | ActionTypes.INIT_BANK_ID_QR
    | ActionTypes.INIT_BANK_ID
    | ActionTypes.INIT_BANK_ID_WITH_PERSONAL_NUMBER
    | ActionTypes.INIT_BANK_ID_QR_MANUAL
    | ActionTypes.INIT_BANK_ID_MANUAL
    | ActionTypes.INIT_FREJA_ID_QR
    | ActionTypes.INIT_FREJA_ID
    | ActionTypes.CURITY_INIT_UNPW
    | ActionTypes.CURITY_SUBMIT_UNPW
    | ActionTypes.RESET_PASSWORD;

// --------- from recovery ------ // TODO: Delete old recovery stuff when everything us using this ref
export type RecoveryState = "recover-password" | "recover-username" | "recover-method";

export type RecoveryContext = "username" | "password";
export type ContactType = "email" | "mail" | "sms";
export type RecoverySteps = "INDEX" | "SELECT" | "SMS" | "MAIL" | "EMAIL";

// Todo: remove, will not be used when password flow is fixed
export type RecoveryStepType = "verify" | "success";

export type UserCredentials = {
    personalIdentityNumber: string;
    firstName: string;
    lastName: string;
};

export type RecoveryType = {
    [key in ContactType]: string;
} & {
    challengeResponse?: string;
};

export enum RecoveryActionTypes {
    SET_RECOVERY_METHOD = "recovery/SET_RECOVERY_METHOD",
}
