import * as React from "react";
import {MENU_PLAY} from "../constants/mainMenuActionTypes";
import MenuController, {SIDE_MENU_PORTAL} from "./MenuController";
import * as styles from "./MenuController.styles";

function PlayMenu() {
    return (
        <MenuController
            index={MENU_PLAY}
            type={SIDE_MENU_PORTAL}
            icon="menu"
            style={styles.playButton}
            title="Meny"
        />
    );
}

export default PlayMenu;
