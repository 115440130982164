import * as React from "react";
import * as Redux from "react-redux";
import {pureFetch} from "@atg-shared/fetch";
import {SHOP_SHARE_SERVICE_URL} from "@atg-shared/service-url";
import {LoadingIndicator} from "atg-ui-components";
import {
    GlobalLoader as GlobalApp,
    ShopLoader as ShopApp,
} from "@atg-frame-shared/bundle-loader";
import * as MainNavbarActions from "@atg-global-shared/main-navbar-data-access/src/domain/mainNavbarActions";

const checkShopMatch = async (slug: string) => {
    try {
        const response = await pureFetch(`${SHOP_SHARE_SERVICE_URL}/shops/slug/${slug}`);
        return response.meta.code === 200;
    } catch (err: unknown) {
        // if we're having any backend/network issues, or the shop doesn't exist (ATG `pureFetch`
        // throws on 4xx) just return a non-match
        return false;
    }
};

/**
 * This component handles URLs with the structure `atg.se/<foo>`, and figures out ShopApp or GlobalApp
 * should be loaded. In order to do this, it checks Shop resources first, the rest will all
 * be handled in GlobalApp.
 *
 * More info -> [FE-53].
 */
const DynamicRoutes = React.memo(() => {
    const [shopMatch, setShopMatch] = React.useState<boolean | null>(null);
    const dispatch = Redux.useDispatch();
    // take this from RR so component rerenders correctly
    const {pathname} = window.location;
    const [, categorySlug] = pathname.split("/");

    React.useEffect(() => {
        dispatch({type: MainNavbarActions.CHECK_SHOP_MATCH_REQUEST});
        const loadDynamicData = async () => {
            try {
                const isShopMatch = await checkShopMatch(categorySlug);

                if (isShopMatch) {
                    // because the lack of a subdomain for shops we store this value
                    // in redux to be able to grab it when the new navbar needs
                    // to use it to have the "Butiksandelar" link active and highlighted
                    dispatch({type: MainNavbarActions.CHECK_SHOP_MATCH_SUCCESS});
                } else {
                    dispatch({type: MainNavbarActions.CHECK_SHOP_MATCH_FAILURE});
                }

                setShopMatch(isShopMatch);
            } catch (err: unknown) {
                // @ts-expect-error, error is typed as unknown
                console.log("error", err, err.stack);
                // TODO: consider if request error, should show different error page?
                setShopMatch(false);
                dispatch({type: MainNavbarActions.CHECK_SHOP_MATCH_FAILURE});
            }
        };

        loadDynamicData();

        return () => {
            dispatch({type: MainNavbarActions.CHECK_SHOP_MATCH_RESET});
        };
    }, [categorySlug, dispatch]);

    if (shopMatch === null) {
        return <LoadingIndicator />;
    }
    if (shopMatch) {
        return <ShopApp />;
    }
    return <GlobalApp />;
});

export default DynamicRoutes;
