import styled from "@emotion/styled";
import {css} from "@emotion/react";

export const Container = styled.div`
    width: 100%;
    z-index: 1000;
`;

export const toastIcon = css`
    font-size: 18px;
    vertical-align: middle;
`;
