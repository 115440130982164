import * as React from "react";

/**
 * some ref utils from Material UI source code
 */

function setRef<T>(
    ref:
        | React.MutableRefObject<T | null>
        | ((instance: T | null) => void)
        | null
        | undefined,
    value: T | null,
) {
    if (typeof ref === "function") {
        ref(value);
    } else if (ref) {
        ref.current = value;
    }
}

export default function useForkRef<T>(
    refA: React.Ref<T> | null | undefined,
    refB: React.Ref<T> | null | undefined,
): React.Ref<T> | null {
    /**
     * This will create a new function if the ref props change and are defined.
     * This means react will call the old forkRef with `null` and the new forkRef
     * with the ref. Cleanup naturally emerges from this behavior
     */
    return React.useMemo(() => {
        if (refA == null && refB == null) {
            return null;
        }

        return (refValue: any) => {
            setRef(refA, refValue);
            setRef(refB, refValue);
        };
    }, [refA, refB]);
}
