import * as React from "react";
import * as Redux from "react-redux";
import {Trans} from "@lingui/macro";
import {Button} from "@atg-ui/toolkit";
import {useWelcomeNewCustomerDispatch} from "@atg-global-shared/welcome-new-customer/src/hooks";
import {setSource} from "@atg-global-shared/welcome-new-customer-data-access/src/slices/welcomeNewCustomerSlice";
import {Source} from "@atg-global-shared/welcome-new-customer-types";
import {trackGlobalEvent} from "@atg-global-shared/analytics";

import {MemberActions} from "@atg-global-shared/member-data-access";
import {useBreakpoint} from "atg-breakpoints";
import useIsNewUser from "../hooks/useIsNewUser";

function CreateAccount() {
    const dispatch = Redux.useDispatch();
    const welcomeNewCustomerDispatch = useWelcomeNewCustomerDispatch();
    const isBelowMd = !useBreakpoint("MD");
    const isNewUser = useIsNewUser();

    if (!isNewUser) return null;

    const handleClick = () => {
        dispatch(MemberActions.startRegisterFlow({triggeredBy: {type: "menu"}}));
        welcomeNewCustomerDispatch(setSource(Source.menu));
        trackGlobalEvent({
            event: "memberRegisterStart",
        });
    };

    return (
        <Button
            color="success"
            href="/skapakonto"
            variant="contained"
            fullWidth={isBelowMd}
            onClick={handleClick}
            data-test-id="create-account"
        >
            <Trans>Öppna konto</Trans>
        </Button>
    );
}

export default CreateAccount;
