import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
// @ts-expect-error
import storage from "redux-persist/lib/storage/index";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {FetchState, ReceiveErrorAction} from "@atg-shared/fetch-types";
import {
    REQUEST_NEW_ACCESS_TOKEN,
    RECEIVE_NEW_ACCESS_TOKEN,
    RESET_ACCESS_TOKEN,
    AUTHENTICATION_SUCCESS,
    SET_ACCESS_TOKEN_TIMESTAMP,
    RECEIVE_REDUCED_ACCESS_TOKEN,
} from "./accessTokenConstants";
import type {Action, IDToken} from "./accessTokenActions";

export type TokenState = {
    accessToken: string | null | undefined;
    idToken: IDToken | null | undefined;
    timestamp?: number | null | undefined;
};

export const initialTokenState: TokenState = {
    accessToken: null,
    idToken: null,
    timestamp: null,
};

const isReceiveErrorAction = <ReceiveActionType, Context>(
    action: any,
): action is ReceiveErrorAction<ReceiveActionType, Context> => action.error;

const token = createFetchReducer(
    REQUEST_NEW_ACCESS_TOKEN,
    RECEIVE_NEW_ACCESS_TOKEN,
    RESET_ACCESS_TOKEN,
    (state: TokenState, action: Action) => {
        if (isReceiveErrorAction(action)) return state;

        switch (action.type) {
            case SET_ACCESS_TOKEN_TIMESTAMP:
                return {
                    ...state,
                    timestamp: action.payload.timestamp,
                };
            case RESET_ACCESS_TOKEN:
                return initialTokenState;
            case RECEIVE_NEW_ACCESS_TOKEN:
                return {
                    idToken: state.idToken,
                    accessToken: action.payload.accessToken,
                };
            case AUTHENTICATION_SUCCESS:
                return {
                    accessToken: action.payload.accessToken,
                    idToken: action.payload.idToken,
                };
            case RECEIVE_REDUCED_ACCESS_TOKEN:
                return {
                    idToken: state.idToken,
                    accessToken: action.payload.accessToken,
                };
            default:
                return state;
        }
    },
    initialTokenState,
);

export type State = {
    token: FetchState<TokenState>;
};

const tokenPersistConfig = {
    storage,
    key: "token",
    blacklist: ["__loadingState"],
    version: 0,
};

const accessToken = combineReducers({
    token: persistReducer(tokenPersistConfig, token),
});

export default accessToken;
