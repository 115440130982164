import {css} from "@emotion/react";
import {sm, md, atgWhite} from "atg-ui/css/variables.styles";

export const leftMenuIcon = css`
    margin: 0 10px 0 0;
    vertical-align: middle;
    color: ${atgWhite};

    @media ${sm} {
        margin: 0 16px 0 0;
    }
`;

export const dropdownMenuIcon = css`
    font-size: 28px;
    margin-left: 4px;

    @media ${md} {
        margin-left: 8px;
    }
`;

export const menuButton = css`
    transition: color ease-in-out 150ms;
    color: #c0d2e4;
    height: 100%;
    padding: 0px 12px;

    &:hover {
        color: ${atgWhite};
    }

    @media ${md} {
        border-right: 1px solid #294096;
    }

    span {
        font-weight: 500;
        color: inherit;
        white-space: nowrap;
    }
`;

export const notification = css`
    right: 4px;
    top: -7px;
`;
