import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import type {Source} from "@atg-global-shared/welcome-new-customer-types";
import {frameAction} from "atg-store-addons";

export type RootState = ReturnType<typeof welcomeNewCustomerSlice.reducer>;

export type WelcomeNewCustomerState = {
    authenticationSource: Source | null;
};

/**
 * Mimic the structure of the global state as seen by this slice.
 * The rootReducer type itself can't be imported and we really
 * only need this to have our selector understand it's wrapped in a
 * customerJourney object.
 */
export type StateWithCustomerJourney = {
    // todo: rename or rebuild this logic now that it lives in global instead of my-atg
    welcomeNewCustomer: RootState;
};

const createWelcomeNewCustomerSlice = (initialState: WelcomeNewCustomerState) =>
    createSlice({
        name: "welcomeNewCustomer",
        initialState,
        reducers: {
            setAuthenticationSource(state, action: PayloadAction<Source>) {
                state.authenticationSource = action.payload;
            },
            resetAuthenticationSource(state) {
                state.authenticationSource = null;
            },
        },
    });

const welcomeNewCustomerSlice = createWelcomeNewCustomerSlice({
    authenticationSource: null,
});

export const selectAuthenticationSource = (state: StateWithCustomerJourney) =>
    state?.welcomeNewCustomer?.authenticationSource;

export const {setAuthenticationSource, resetAuthenticationSource} =
    welcomeNewCustomerSlice.actions;

/**
 * The My-ATG micro frontend is not loaded at this point
 * so we need action to be forwarded to frame.
 */
export const setSource = (source: Source) => frameAction(setAuthenticationSource(source));

export default welcomeNewCustomerSlice.reducer;
