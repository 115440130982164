import {isNil, throttle, omit, isEqual} from "lodash/fp";
import * as React from "react";
import root from "window-or-global";
import {isTouchDevice} from "@atg/utils/device";
import * as LocalStorage from "@atg-shared/storage";
import {VIDEO_STICKY_ID, REDUCED_CONTROLS_STICKY_ID} from "../domain/sticky-ids";

import StickyContext from "../domain/StickyContext";
import {updateStickyState} from "../domain/sticky";

const TOUCH_EVENT_TYPES = ["touchstart", "touchmove", "touchend"];
const WINDOW_EVENT_TYPES = ["scroll", "resize", "hashchange"];

class StickyController extends React.Component {
    throttledUpdateStickies;

    state = {
        stickies: {},
    };

    EVENT_TYPES = [...WINDOW_EVENT_TYPES, ...(isTouchDevice() ? TOUCH_EVENT_TYPES : [])];

    constructor(props) {
        super(props);

        this.throttledUpdateStickies = throttle(16, () => this.updateStickies());
    }

    componentDidMount() {
        this.EVENT_TYPES.forEach((event) =>
            root.addEventListener(event, this.throttledUpdateStickies),
        );

        const {containerId} = this.props;
        const container = root.document.getElementById(containerId);
        if (container) {
            container.addEventListener("scroll", this.throttledUpdateStickies);
        }
    }

    componentWillUnmount() {
        this.EVENT_TYPES.forEach((event) =>
            root.removeEventListener(event, this.throttledUpdateStickies),
        );

        const {containerId} = this.props;
        const container = root.document.getElementById(containerId);
        if (container) {
            container.removeEventListener("scroll", this.throttledUpdateStickies);
        }
    }

    addSticky = (id, sticky) => {
        this.setState((prevState) => ({
            stickies: {
                ...prevState.stickies,
                [id]: {
                    ...sticky,
                    // By default give the new sticky a higher index than all existing stickies.
                    // This will cause it do be shown further down on the page when several stickies
                    // are shown at the same time.
                    //
                    // However, the sticky can override this by sending in an explicit `index`
                    // value.
                    index: !isNil(sticky.index)
                        ? sticky.index
                        : Object.keys(prevState.stickies).length,
                },
            },
        }));
    };

    removeSticky = (id) => {
        this.setState((state) => ({stickies: omit([id], state.stickies)}));
    };

    updateStickies = (stickies = this.state.stickies) => {
        const stickiesFromState = this.state.stickies;
        let stickyOffset = 0;
        const newStickies = {};

        const ids = Object.keys(stickies).sort((a, b) =>
            stickiesFromState[a].index < stickiesFromState[b].index ? -1 : 1,
        );

        ids.forEach((id) => {
            newStickies[id] = updateStickyState({
                original: stickiesFromState[id],
                updated: stickies[id],
                stickyOffset,
                shouldLimitHeight: id === VIDEO_STICKY_ID,
                stickFromBottom: id === REDUCED_CONTROLS_STICKY_ID,
            });
            stickyOffset += newStickies[id].contentHeight;
        });

        if (isEqual(stickiesFromState, newStickies)) return;

        this.setState(() => ({
            stickies: newStickies,
        }));
    };

    updatePlaceholderRef = (id, node) => {
        this.updateSticky(id, {placeholder: node});
    };

    updateContentRef = (id, node) => {
        this.updateSticky(id, {content: node});
    };

    togglePinned = (id) => {
        const {stickies} = this.state;

        const sticky = stickies[id];
        if (!sticky) return;

        const updatedStickies = {
            ...stickies,
            [id]: {
                ...sticky,
                isPinned: !sticky.isPinned,
            },
        };

        LocalStorage.setObject("sticky", {[id]: !sticky.isPinned});
        this.updateStickies(updatedStickies);
    };

    updateSticky(id, state) {
        const {stickies} = this.state;

        const sticky = stickies[id];
        if (!sticky) return;

        const newSticky = {
            ...sticky,
            ...state,
        };

        this.setState((prevState) => ({
            stickies: {
                ...prevState.stickies,
                [id]: newSticky,
            },
        }));
    }

    render() {
        const {children} = this.props;
        const {stickies} = this.state;

        return (
            <StickyContext.Provider
                value={{
                    addSticky: this.addSticky,
                    removeSticky: this.removeSticky,
                    togglePinned: this.togglePinned,
                    updatePlaceholderRef: this.updatePlaceholderRef,
                    updateContentRef: this.updateContentRef,
                    stickyUpdated: this.updateStickies,
                    stickies,
                }}
            >
                {children}
            </StickyContext.Provider>
        );
    }
}

export default StickyController;
