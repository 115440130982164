import React from "react";
import {includes, isEmpty, get} from "lodash";
import browserHistory from "atg-history";
import {extractDomain} from "@atg/utils/url";
import {LoadingIndicator} from "atg-ui-components";
import CurityIFrame from "./CurityIFrame";
import * as styles from "./CurityIFrameCommunicator.styles";
import CurityIFrameCommunicatorContext from "./CurityIFrameCommunicatorContext";
import type {
    AUTH_SERVER_LOGIN_SUCCESS,
    AUTH_SERVER_LOGOUT_SUCCESS,
} from "./CurityIFrameConstants";

export type Options = {
    postMessage?: Record<string, unknown>;
    iframeHeight?: number;
};

export type ServerLoginSuccessAction = {
    type: typeof AUTH_SERVER_LOGIN_SUCCESS;
    payload: {accessToken: string; id_token: any};
};

export type ServerLogoutSuccessAction = {
    type: typeof AUTH_SERVER_LOGOUT_SUCCESS;
    payload?: any;
};

export type ActionType =
    | {type: string; payload?: any}
    | ServerLoginSuccessAction
    | ServerLogoutSuccessAction;

export type Props = {
    targetDomains: string[];
    options?: Options;
    isVisible?: boolean;
    callback?: (action: ActionType) => void;
    authURL: string;
    noWrapper?: boolean;
    showCurityLoading?: boolean;
};

type CommunicatorProps = Props & {
    isFullscreenPage: boolean;
};

type State = {
    showCurityLoading: boolean;
    iframeHeight: number;
};

export const AUTH_INITIATED = "authenticating";

export class CurityIFrameCommunicator extends React.Component<CommunicatorProps, State> {
    // eslint-disable-next-line react/static-property-placement
    static defaultProps = {
        isVisible: true,
        options: {},
    };

    iframe: HTMLIFrameElement | null | undefined;

    postmessageActions: Record<string, any> = {
        canceled: () => this.handleCanceled(),
        curityLoading: (payload: {show?: unknown}) =>
            payload && this.handleCurityLoading(Boolean(payload.show)),
        changeHeight: (payload: {height?: unknown}) =>
            payload &&
            typeof payload.height === "number" &&
            this.handleIFrameHeight(payload.height),
        authenticating: null,
    };

    constructor(props: CommunicatorProps) {
        super(props);

        const iframeHeight = get(this.props, "options.iframeHeight", 420);
        const showCurityLoading = get(this.props, "showCurityLoading", true);

        this.state = {
            showCurityLoading,
            iframeHeight,
        };
    }

    componentDidMount() {
        window.addEventListener("message", this.handleReceiveMessage);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleReceiveMessage);
    }

    handleReceiveMessage = (event: MessageEvent) => {
        if (!this.checkDomain(event.origin) || !event.data) {
            return;
        }

        const {callback} = this.props;

        const postMessageAction = event.data;
        if (
            typeof postMessageAction === "string" && // current flow version doesn't refine this properly
            postMessageAction === AUTH_INITIATED &&
            callback
        ) {
            callback({type: postMessageAction});
        }

        if (!postMessageAction || !postMessageAction.type) {
            return;
        }

        // Unsafe cast, but at least we propagate a more refined type than `any` to this.props.callback()
        // TODO: refine the `ActionType` to to what the backend is expected to return (and add runtime checks)
        const unsafePostMessageAction = postMessageAction as ActionType;

        const action = this.postmessageActions[unsafePostMessageAction.type];
        if (action) {
            action(unsafePostMessageAction.payload);
        }
        if (callback) {
            callback(unsafePostMessageAction);
        }
    };

    handleIFrameOnLoad = () => {
        const {options} = this.props;

        if (options && options.postMessage) {
            this.sendPostMessage(options.postMessage);
        }

        const showCurityLoading = get(this.props, "showCurityLoading", false);

        this.setState({showCurityLoading});
    };

    sendPostMessage = (postMessage: Record<string, unknown>) => {
        if (this.iframe && this.iframe.contentWindow && !isEmpty(postMessage)) {
            this.iframe.contentWindow.postMessage(postMessage, "*");
        }
    };

    checkDomain = (origin: string) => {
        const {targetDomains} = this.props;
        return includes(targetDomains.map(extractDomain), extractDomain(origin));
    };

    handleCurityLoading = (show: boolean) => {
        this.setState({showCurityLoading: show});
    };

    handleIFrameHeight = (iframeHeight: number) => {
        if (iframeHeight !== undefined) {
            this.setState({
                iframeHeight,
            });
        }
    };

    handleCanceled = () => {
        const {isFullscreenPage} = this.props;
        if (isFullscreenPage) {
            browserHistory.replace("/");
        }
    };

    onIFrame = (iframe: HTMLIFrameElement | null | undefined) => {
        this.iframe = iframe;
    };

    render() {
        const {isFullscreenPage, noWrapper, authURL, isVisible} = this.props;
        const {iframeHeight, showCurityLoading} = this.state;
        return (
            <div
                css={
                    noWrapper ? styles.container : styles.outerContainer(isFullscreenPage)
                }
            >
                <CurityIFrame
                    authURL={authURL}
                    isVisible={isVisible}
                    iframeHeight={`${iframeHeight}`}
                    onIFrame={this.onIFrame}
                    handleIFrameOnLoad={this.handleIFrameOnLoad}
                />
                <CurityIFrameCommunicatorContext.Consumer>
                    {({showLoader}) => {
                        const isLoading = isVisible && showCurityLoading;

                        return showLoader || isLoading ? (
                            <div
                                css={[
                                    styles.coverFullIframe(iframeHeight),
                                    styles.curityLoadingBackground,
                                ]}
                            >
                                <LoadingIndicator />
                            </div>
                        ) : null;
                    }}
                </CurityIFrameCommunicatorContext.Consumer>
            </div>
        );
    }
}

function CurityIFrameCommunicatorWrapper(props: Props) {
    return (
        <CurityIFrameCommunicatorContext.Consumer>
            {({isFullscreenPage}) => (
                <CurityIFrameCommunicator
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    isFullscreenPage={isFullscreenPage}
                />
            )}
        </CurityIFrameCommunicatorContext.Consumer>
    );
}

export default CurityIFrameCommunicatorWrapper;
