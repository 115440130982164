import {call, takeLatest} from "redux-saga/effects";
import * as Analytics from "@atg-shared/analytics";
import * as AlertActions from "./alertActions";

export function* followLinkAnalytics(action: AlertActions.FollowLinkAction) {
    yield call(Analytics.deprecated_logEvent, {
        alert_event: "followedAlertLink",
        alert_id: action.payload.id,
    });
}

export function* closeAlertAnalytics(action: AlertActions.CloseAlertAction) {
    yield call(Analytics.deprecated_logEvent, {
        alert_event: "closedAlert",
        alert_id: action.payload.id,
    });
}

export default function* cmsAlertSaga() {
    yield takeLatest(AlertActions.CLOSE_ALERT, closeAlertAnalytics);
    yield takeLatest(AlertActions.FOLLOW_LINK, followLinkAnalytics);
}
