/**
 * Extra extra small screen
 *
 * Almost all devices are wider than this, even in portrait mode
 */
export const XXS = 375;

/**
 * Extra small screen
 */
export const XS = 480;

/**
 * Small screen
 *
 * Most tablets are at least this big
 */
export const SM = 768;

/**
 * Medium screen
 *
 * Most laptops are at least this big
 */
export const MD = 992;

/**
 * Large screen
 *
 * Most desktop computers are at least this big
 */
export const LG = 1200;

/**
 * Extra large screen
 */
export const XL = 1500;

/**
 * Huge screen
 */
export const HG = 1600;
