import * as React from "react";
import {t} from "@lingui/macro";
import {css} from "@emotion/react";
import {ComponentLoader} from "@atg-shared/lazy";
import type {GameInfo} from "@atg-payment-shared/deposit-types";
import {usePaymentDispatch} from "@atg-payment-shared/redux-store-hooks";
import {DepositActions} from "@atg-payment-shared/deposit-domain";
import features, {paymentMicroFe} from "@atg-shared/client-features";
import {LoadingIndicator} from "atg-ui-components";
import Modal from "atg-modals";

export function DepositModalPlaceholder() {
    return (
        <Modal id="deposit-modal-placeholder" title={t`Sätt in pengar`} noPadding>
            <div
                css={css`
                    display: flex;
                    // 353px matches the height of the deposit modal in its "internal" loading state while deposit options are loading.
                    // to keep a consistent UI and minimize layout shifts, we set the same height on the placeholder
                    height: 353px;
                    align-items: center;
                `}
            >
                <LoadingIndicator />
            </div>
        </Modal>
    );
}

type Props = {
    gameInfo?: GameInfo;
};

export function LazyDepositModal({gameInfo}: Props) {
    const dispatch = usePaymentDispatch();
    React.useEffect(() => {
        if (features.isEnabled(paymentMicroFe))
            dispatch(DepositActions.setGameInfo(gameInfo));
    }, [dispatch, gameInfo]);

    return (
        <ComponentLoader
            loader={import(
                /* webpackChunkName: "DepositModal" */
                "./DepositModal"
            )}
            placeholder={<DepositModalPlaceholder />}
        />
    );
}
