/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import type {SerializedStyles} from "@emotion/react";
import {useFocusWithin} from "@atg-shared/hooks";
import {focus} from "@atg/utils";
import {a11y as a11yStyles} from "atg-ui-components/mixins";
import * as styles from "./Navigation.styles";

export interface Props extends Omit<React.HTMLProps<HTMLElement>, "style"> {
    /**
     * The content of `Navigation`
     */
    children?: React.ReactNode;
    /**
     * The component or element used for the root node
     */
    component?: React.ElementType;
    /**
     * [Emotion 10 style object used in place of the traditional className.](https://developer.atg.se/frontend/styling.html#emotion)
     */
    style?: SerializedStyles;
    /**
     * Will not trap TAB focus within the drawer if true
     */
    disableFocusTrap?: boolean;
    /**
     * Callback when drawer requests to be closed
     */
    onClose?: () => void;
}

type FocusElements = {
    firstMenuElement: HTMLElement | null;
    lastMenuElement: HTMLElement | null;
    elementToFocusOnclose: HTMLElement | null;
};

/**
 * unstyled navigation menu with focus management and keyboard navigation
 */
const Navigation: React.FC<Props> = (props) => {
    const {
        children,
        component: Component = "div",
        disableFocusTrap,
        style,
        onClose,
        ...other
    } = props;

    const [focusedElements, setFocusedElements] = React.useState<FocusElements>({
        firstMenuElement: null,
        lastMenuElement: null,
        elementToFocusOnclose: null,
    });
    const menuRef = React.useRef<HTMLDivElement | null>(null);
    const closeButtonRef = React.useRef<HTMLButtonElement | null>(null);
    const [focusWithin, setFocusWithin] = React.useState(false);
    const {focusWithinProps} = useFocusWithin({
        onFocusWithinChange: (isFocusWithin) => setFocusWithin(isFocusWithin),
    });

    const handleKeyDown = React.useCallback(
        (event: KeyboardEvent) => {
            if (!focusWithin && disableFocusTrap) {
                return;
            }

            switch (event.code) {
                case "Tab":
                    if (!disableFocusTrap) {
                        // loop from first to last element in menu
                        if (
                            (!focusWithin ||
                                document.activeElement ===
                                    focusedElements.firstMenuElement) &&
                            event.shiftKey
                        ) {
                            event.preventDefault();
                            focusedElements.lastMenuElement?.focus();
                        }

                        // loop from last to first element in menu
                        if (
                            (!focusWithin ||
                                document.activeElement ===
                                    focusedElements.lastMenuElement) &&
                            !event.shiftKey
                        ) {
                            event.preventDefault();
                            focusedElements.firstMenuElement?.focus();
                        }
                    }
                    break;
                // move focus to first element on HOME key
                case "Home":
                    focusedElements.firstMenuElement?.focus();
                    break;
                // move focus to last element on END key
                case "End":
                    focusedElements.lastMenuElement?.focus();
                    break;
                case "ArrowDown":
                    event.preventDefault();

                    if (document.activeElement === focusedElements.lastMenuElement) {
                        break;
                    }

                    if (!focusWithin) {
                        focusedElements.firstMenuElement?.focus();
                    } else {
                        const focusedEl = document.activeElement;

                        if (focusedEl) {
                            const nextEl = focus.nextFocusableElement(
                                focusedEl as HTMLElement,
                            );
                            nextEl?.focus();
                        }
                    }
                    break;
                case "ArrowUp":
                    event.preventDefault();

                    if (document.activeElement === focusedElements.firstMenuElement) {
                        break;
                    }

                    if (!focusWithin) {
                        focusedElements.lastMenuElement?.focus();
                    } else {
                        const focusedEl = document.activeElement;

                        if (focusedEl) {
                            const prevEl = focus.previousFocusableElement(
                                focusedEl as HTMLElement,
                            );

                            if (prevEl) {
                                prevEl.focus();
                            }
                        }
                    }
                    break;
                case "Escape":
                    // Rather than just closing the menu, this makes it clearer
                    // for keyboard users with vision impairment that the menu may be closed
                    if (closeButtonRef.current) {
                        if (
                            document.activeElement === closeButtonRef.current &&
                            onClose
                        ) {
                            onClose();
                        } else {
                            closeButtonRef.current.focus();
                        }
                    }
                    break;
                default:
            }
        },
        [
            disableFocusTrap,
            focusWithin,
            focusedElements.firstMenuElement,
            focusedElements.lastMenuElement,
            onClose,
        ],
    );

    React.useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    // accessibility: save last focused element when opening menu
    React.useEffect(() => {
        if (menuRef.current) {
            setFocusedElements({
                firstMenuElement: focus.findFirstFocusableElement(menuRef.current),
                lastMenuElement: focus.findLastFocusableElement(menuRef.current),
                elementToFocusOnclose: document.activeElement as HTMLElement,
            });
        }

        return () => {
            focusedElements.elementToFocusOnclose?.focus();
        };
    }, [focusedElements.elementToFocusOnclose]);

    return (
        <Component
            {...other}
            {...focusWithinProps}
            css={[styles.rootStyle, style]}
            ref={menuRef}
            role={other.role || "menu"}
        >
            {onClose && (
                <button
                    css={[styles.hiddenCloseButton, a11yStyles.hiddenIndexRight]}
                    type="button"
                    onClick={onClose}
                    ref={closeButtonRef}
                >
                    Stäng menyn
                </button>
            )}

            {children}
        </Component>
    );
};

export default Navigation;
