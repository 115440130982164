import type {BrowserHistory} from "history";
import type {HistoryState} from "../types";
import type {LocationChangedAction} from "./routerActions";
import {ROUTER_ON_LOCATION_CHANGED} from "./routerActions";

export type RouterState = HistoryState;

export type GlobalRouterState = {
    router: RouterState;
};
export function createRouterReducer(history: BrowserHistory) {
    return function routerReducer(
        state: RouterState = {action: history.action, location: history.location},
        action: LocationChangedAction,
    ) {
        switch (action.type) {
            case ROUTER_ON_LOCATION_CHANGED:
                return action.payload;

            default: {
                return state;
            }
        }
    };
}
