import type { Shadows as BoxShadows } from ".";

/**
 * Shadow is an array of 4 numbers representing the box-shadow offset in the x and y axis, and the blur and spread radius of the shadow.
 * [x, y, blur, spread]
 */
type Shadow = [number, number, number, number];

/**
 * Shadows is an object containing three arrays of Shadow, one for each shadow type: umbra, penumbra, and ambient.
 */
type Shadows = {
    umbra: Array<Shadow>;
    penumbra: Array<Shadow>;
    ambient: Array<Shadow>;
};

/**
 * The opacity values for each shadow type: umbra, penumbra, and ambient.
 */
type Opacities = {
    umbraOpacity: number;
    penumbraOpacity: number;
    ambientOpacity: number;
};
const MAX_SHADOW_AMOUNTS = 24;
const umbraOpacityDefault = 0.1; // 10%
const penumbraOpacityDefault = 0.07; // 7%
const ambientOpacityDefault = 0.06; // 6%

const umbraDefault: Array<Shadow> = [
    [0, 2, 1, -1],
    [0, 3, 1, -2],
    [0, 3, 3, -2],
    [0, 2, 4, -1],
    [0, 3, 5, -1],
    [0, 3, 5, -1],
    [0, 4, 5, -2],
    [0, 5, 5, -3],
    [0, 5, 6, -3],
    [0, 6, 6, -3],
    [0, 6, 7, -4],
    [0, 7, 8, -4],
    [0, 7, 8, -4],
    [0, 7, 9, -4],
    [0, 8, 9, -5],
    [0, 8, 10, -5],
    [0, 8, 11, -5],
    [0, 9, 11, -5],
    [0, 9, 12, -6],
    [0, 10, 13, -6],
    [0, 10, 13, -6],
    [0, 10, 14, -6],
    [0, 11, 14, -7],
    [0, 11, 15, -7],
];

const penumbraDefault: Array<Shadow> = [
    [0, 1, 1, 0],
    [0, 2, 2, 0],
    [0, 3, 4, 0],
    [0, 4, 5, 0],
    [0, 5, 8, 0],
    [0, 6, 10, 0],
    [0, 7, 10, 1],
    [0, 8, 10, 1],
    [0, 9, 12, 1],
    [0, 10, 14, 1],
    [0, 11, 15, 1],
    [0, 12, 17, 2],
    [0, 13, 19, 2],
    [0, 14, 21, 2],
    [0, 15, 22, 2],
    [0, 16, 24, 2],
    [0, 17, 26, 2],
    [0, 18, 28, 2],
    [0, 19, 29, 2],
    [0, 20, 31, 3],
    [0, 21, 33, 3],
    [0, 22, 35, 3],
    [0, 23, 36, 3],
    [0, 24, 38, 3],
];

const ambientDefault: Array<Shadow> = [
    [0, 1, 3, 0],
    [0, 1, 5, 0],
    [0, 1, 8, 0],
    [0, 1, 10, 0],
    [0, 1, 14, 0],
    [0, 1, 18, 0],
    [0, 2, 16, 1],
    [0, 3, 14, 2],
    [0, 3, 16, 2],
    [0, 4, 18, 3],
    [0, 4, 20, 3],
    [0, 5, 22, 4],
    [0, 5, 24, 4],
    [0, 5, 26, 4],
    [0, 6, 28, 5],
    [0, 6, 30, 5],
    [0, 6, 32, 5],
    [0, 7, 34, 6],
    [0, 7, 36, 6],
    [0, 8, 38, 7],
    [0, 8, 40, 7],
    [0, 8, 42, 7],
    [0, 9, 44, 8],
    [0, 9, 46, 8],
];

/**
 * returns a string representing the CSS box-shadow property value for the given shadows and opacities.
 *
 * @param umbra Shadow - The umbra shadow.
 * @param penumbra Shadow - The penumbra shadow.
 * @param ambient Shadow - The ambient shadow.
 * @param opacities Opacities - The opacity values for each shadow type.
 * @returns string - The CSS box-shadow property value.
 */
export function createShadow(
    umbra: Shadow,
    penumbra: Shadow,
    ambient: Shadow,
    opacities: Opacities,
) {
    return [
        `${umbra[0]}px ${umbra[1]}px ${umbra[2]}px ${umbra[3]}px rgba(0,0,0,${opacities.umbraOpacity})`,
        `${penumbra[0]}px ${penumbra[1]}px ${penumbra[2]}px ${penumbra[3]}px rgba(0,0,0,${opacities.penumbraOpacity})`,
        `${ambient[0]}px ${ambient[1]}px ${ambient[2]}px ${ambient[3]}px rgba(0,0,0,${opacities.ambientOpacity})`,
    ].join(",");
}

const defaultOpacities: Opacities = {
    umbraOpacity: umbraOpacityDefault,
    penumbraOpacity: penumbraOpacityDefault,
    ambientOpacity: ambientOpacityDefault,
};

/**
 * returns an array of strings representing the CSS box-shadow property values for the given shadows and opacities.
 * Used in conjunction with `createTheme` to create shadows available in the theme
 * object.
 *
 * @param shadows Partial<Shadows> - The shadows to use. May be partially provided and the rest will be populated with default values. If not provided, the default shadows will be used.
 * @param opacities Partial<Opacities> - The opacity values for each shadow type. May be partially provided and the rest will be filled with default values. If not provided, the default opacity values will be used.
 * @returns ['none',
 *  string, string, string, string, string, string,
 *  string, string, string, string, string, string,
 *  string, string, string, string, string, string,
 *  string, string, string, string, string, string]
 *  - A static array of 24 CSS box-shadow property values.
 */
function createShadows(
    shadows: Partial<Shadows> = {},
    opacities: Partial<Opacities> = {},
): BoxShadows {
    const {
        umbra: umbraArg = [],
        penumbra: penumbraArg = [],
        ambient: ambientArg = [],
    } = shadows;
    const {
        umbraOpacity = defaultOpacities.umbraOpacity,
        penumbraOpacity = defaultOpacities.penumbraOpacity,
        ambientOpacity = defaultOpacities.ambientOpacity,
    } = opacities;

    const umbra: Array<Shadow> = [...umbraArg, ...umbraDefault.slice(umbraArg.length)];
    const penumbra: Array<Shadow> = [
        ...penumbraArg,
        ...penumbraDefault.slice(penumbraArg.length),
    ];
    const ambient: Array<Shadow> = [
        ...ambientArg,
        ...ambientDefault.slice(ambientArg.length),
    ];

    const boxShadows = ["none"] as unknown as BoxShadows;

    for (let i = 0; i < MAX_SHADOW_AMOUNTS; i += 1) {
        const shadow = createShadow(umbra[i], penumbra[i], ambient[i], {
            umbraOpacity,
            penumbraOpacity,
            ambientOpacity,
        });
        boxShadows.push(shadow);
    }

    return boxShadows;
}

export default createShadows;
