import {styled} from "@atg-ui/toolkit/theme";
import {Typography} from "@atg-ui/toolkit";
import {css} from "@emotion/react";

export const Wrapper = styled("div")(
    ({theme}) => `
    display: flex;
    flex-direction: column;
    min-height: inherit;
    padding: 16px;
    text-align: center;
    min-height: 100vh;
    background: ${theme.palette.background.default};
    justify-content: center;
`,
);

export const RedirectionWrapper = styled("div")(
    ({theme}) =>
        `
    align-self: center;
    width: 100%;

    & > button {
        margin-bottom: 16px;
    }

    ${theme.breakpoints.up("sm")} {
        max-width: 240px;
    }
`,
);

export const NotFoundIcon = styled("div")`
    align-self: center;
    padding-bottom: 25px;
`;

export const brokenIcon = css`
    font-size: 41px;
`;

export const Headline = styled(Typography)(({theme}) => ({
    ...theme.typography.subtitle1,
    [theme.breakpoints.up("sm")]: {
        ...theme.typography.h6,
    },
}));

export const errorText = css`
    margin-bottom: 24px;
`;
