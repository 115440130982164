import * as React from "react";
import {ClassNames} from "@emotion/react";
import {CSSTransition} from "react-transition-group";
import * as styles from "./MenuTransitions.styles";

type Props = {
    isIn?: boolean;
    children?: React.ReactNode | ((animationState: string) => React.ReactNode);
    isSmallScreen?: boolean;
};

export function SlideInLeftTransition({isIn, children, isSmallScreen}: Props) {
    return (
        <ClassNames>
            {({css}) => (
                <CSSTransition
                    in={isIn}
                    timeout={styles.slideInLeftDuration}
                    classNames={{
                        enter: css(styles.slideInLeftEnter(isSmallScreen)),
                        enterActive: styles.slideInLeftEnterActive,
                        exit: css(styles.slideInLeftExit(isSmallScreen)),
                        exitActive: styles.slideInLeftExitActive,
                    }}
                    unmountOnExit
                >
                    {children}
                </CSSTransition>
            )}
        </ClassNames>
    );
}

export function SlideInRightTransition({isIn, children, isSmallScreen}: Props) {
    return (
        <ClassNames>
            {({css}) => (
                <CSSTransition
                    in={isIn}
                    timeout={styles.slideInRightDuration}
                    classNames={{
                        enter: css(styles.slideInRightEnter(isSmallScreen)),
                        enterActive: styles.slideInRightEnterActive,
                        exit: css(styles.slideInRightExit(isSmallScreen)),
                        exitActive: styles.slideInRightExitActive,
                    }}
                    unmountOnExit
                >
                    {children}
                </CSSTransition>
            )}
        </ClassNames>
    );
}

export function FadeInTransition({isIn, children}: Props) {
    return (
        <ClassNames>
            {({css}) => (
                <CSSTransition
                    in={isIn}
                    timeout={styles.fadeInDuration}
                    classNames={{
                        enter: css(styles.fadeInEnter),
                        enterActive: styles.fadeInEnterActive,
                        exit: css(styles.fadeInExit),
                        exitActive: styles.fadeInExitActive,
                    }}
                    unmountOnExit
                >
                    {children}
                </CSSTransition>
            )}
        </ClassNames>
    );
}

export function FadeStatusTransition({isIn, children}: Props) {
    return (
        <ClassNames>
            {({css}) => (
                <CSSTransition
                    in={isIn}
                    timeout={styles.fadeStatusDuration}
                    classNames={{
                        enter: css(styles.fadeStatusEnter),
                        enterActive: styles.fadeStatusEnterActive,
                        exit: css(styles.fadeStatusExit),
                        exitActive: styles.fadeStatusExitActive,
                    }}
                >
                    {children}
                </CSSTransition>
            )}
        </ClassNames>
    );
}
