import * as React from "react";
import type {BrowserHistory} from "history";
import {Router} from "react-router-dom";
import type {HistoryState} from "../../types";

type Props = {
    basename?: string;
    history: BrowserHistory;
    children: React.ReactNode;
    onLocationChange?: (state: HistoryState) => void;
};

/**
 * A wrapper around `Router` from `react-router-dom` that uses a custom history object and a callback that triggers when the history is updated
 */
export function HistoryRouter({basename, children, history, onLocationChange}: Props) {
    const [state, setHistory] = React.useState<HistoryState>({
        action: history.action,
        location: history.location,
    });

    React.useLayoutEffect(
        () =>
            history.listen(({action, location}) => {
                setHistory({action, location});
                if (onLocationChange) {
                    onLocationChange({action, location});
                }
            }),
        [history, onLocationChange],
    );

    return (
        <Router
            basename={basename}
            location={state.location}
            navigator={history}
            navigationType={state.action}
        >
            {children}
        </Router>
    );
}
