import {css} from "@emotion/react";
import {md} from "atg-ui/css/variables.styles";

export const root = css`
    user-select: none;
    -webkit-touch-callout: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 100%;

    & > img {
        height: 20px; // doing it this way to accomodate for Tillsammans header
    }

    @media ${md} {
        padding-left: 24px;
    }
`;

export const logo = css`
    margin: 0 auto;
`;
