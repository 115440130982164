const cyan = {
    50: "#E5F6FF",
    100: "#C1E6FB",
    200: "#79C3EC",
    300: "#4CAFE6",
    400: "#1F9BE0",
    500: "#118FD4",
    600: "#0A80C2",
    700: "#0073B2",
    800: "#006399",
    900: "#005280",
};

export default cyan;
