import * as React from "react";
import type {Props as SvgIconPropType} from "../components/SvgIcon";
import SvgIcon from "../components/SvgIcon";

export function Pin(props: SvgIconPropType) {
    return (
        <SvgIcon {...props}>
            <path d="M5.97 13.24l14.264-7.96-1.24-1.24a1.2 1.2 0 111.695-1.697l.001.001 2.344 2.34.037.039.001.001 4.204 4.204a.755.755 0 01.04.04l2.34 2.34a1.2 1.2 0 11-1.696 1.698l-1.24-1.24-7.96 14.264 1.93 1.93a1.2 1.2 0 11-1.695 1.697l-.001-.001-2.554-2.552a1.328 1.328 0 01-.037-.039l-.001-.001-4.882-4.884-7.48 7.476a1.2 1.2 0 11-1.697-1.695l.001-.001 7.476-7.48-4.886-4.88a1.328 1.328 0 01-.037-.039l-.001-.001-2.552-2.552a1.2 1.2 0 111.695-1.697l.001.001 1.93 1.93zm1.764 1.764l9.262 9.26 7.96-14.26-2.96-2.96-14.26 7.96z" />
        </SvgIcon>
    );
}

export default Pin;
