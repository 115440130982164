import * as React from "react";
import root from "window-or-global";
import {camelCase, isEmpty, last} from "lodash";
import log from "@atg-shared/log";
import {capitaliseFirstLetter} from "@atg/utils/strings";
import {trackEvent} from "./analytics";

type SiteLevels = {
    siteLevel1: string;
    siteLevel2: string;
    siteLevel3: string;
    siteLevel4: string;
};

function view(levels: Array<string>, pageDate?: string, pageType?: string) {
    if (!root.document) return;

    if (!levels || levels.length === 0) {
        log.error("Analytics.view: Level one must be set, aborting!");
        return;
    }

    const siteLevels: SiteLevels = Array(4)
        .fill(null)
        .reduce(
            (prev, _, index) => ({
                ...prev,
                [`siteLevel${index + 1}`]: levels.slice(0, index + 1).join("|"),
            }),
            {} as SiteLevels,
        );

    const pageName =
        levels.length > 4
            ? `${siteLevels.siteLevel4}|${last(levels)}`
            : siteLevels.siteLevel4;

    const eventName = `pageName${capitaliseFirstLetter(camelCase(pageName))}` as const;

    const pageLayout = (() => {
        const w =
            root.innerWidth ||
            root.document.documentElement.clientWidth ||
            root.document.body.clientWidth;
        const h =
            root.innerHeight ||
            root.document.documentElement.clientHeight ||
            root.document.body.clientHeight;

        if (w < h) {
            // portrait
            if (w < 400) return "mobile portrait";
            if (w < 768) return "tablet portrait";
        } else {
            // landscape
            // eslint-disable-next-line no-lonely-if
            if (w < 481) return "mobile landscape";
            if (w < 1024) return "tablet landscape";
        }
        return "desktop";
    })();

    trackEvent({
        event: eventName,
        pageName,
        pageDate: pageDate ?? "",
        contentType: pageType ?? "",
        pageLayout,
        ...siteLevels,
    });
}

function viewFromLevels(levels: string[], pageType: string) {
    if (levels.length === 0) {
        log.error("Analytics.viewFromLevels: can't get any data from levels, aborting!");
        return;
    }

    function isDate(str: string) {
        const m = str.match(/^(\d{4})-(\d{2})-(\d{2})$/);
        return Boolean(m);
    }

    const pageDate = levels.find((level) => isDate(level));
    const filteredLevels = levels.filter((level) => !isDate(level));

    view(filteredLevels, pageDate, pageType);
}

function viewFromUrl(url: string, pageType: string) {
    if (isEmpty(url)) {
        log.error("Analytics.viewFromUrl: url is undefined or empty, aborting!");
        return;
    }

    const urlParts = url.split("/").filter((urlPart) => !isEmpty(urlPart));

    viewFromLevels(urlParts, pageType);
}

function createPageViewLogger() {
    let prevUrl = "";
    let prevType = "";
    let lastLoggedTime = 0;

    return (url: string, type: string) => {
        const currentTime = Date.now();
        if (url === prevUrl && type === prevType && currentTime - lastLoggedTime < 1000) {
            // Do not fire second time the same event if it's less than 1 second since the last one
            return;
        }

        // Log page view event
        viewFromUrl(url, type);

        // Update prevUrl, prevType and lastLoggedTime references for next invocation
        prevUrl = url;
        prevType = type;
        lastLoggedTime = currentTime;
    };
}

const logPageViewEvent = createPageViewLogger();

type Props = {
    url?: string;
    type: string;
};

/**
 * Non-visual component that sends page view events to GTM (Google Tag Manager).
 * Modifies the same global variable "dataLayer" as the rest of GTMs events.
 *
 * Note that since an event will be fired as soon as `url` or `type` changes, if they change
 * separately from each other (i.e. `url` changes in one render, and a few milliseconds later `type`
 * changes) two events will fire.
 *
 * If both dependencies `url` or `type` change at the same time,
 * the useEffect will still run only once,
 * which is always the case for initial rendering of <PageAnalytics /> only,
 * but not for every subsequent re-render
 */
function PageAnalytics({url, type}: Props) {
    React.useEffect(() => {
        const analyticsUrl = url || root.location.pathname;
        logPageViewEvent(analyticsUrl, type);
    }, [url, type]);

    return null;
}
PageAnalytics.view = view;

export default PageAnalytics;
