import {css} from "@emotion/react";

import rubrikWoff from "atg-atgse/assets/fonts/atgrubrik-font.woff";
import rubrikTtf from "atg-atgse/assets/fonts/atgrubrik-font.ttf";
import rubrikSvg from "atg-atgse/assets/fonts/atgrubrik-font.svg";

export const globalStyles = css`
    html {
        height: 100%;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-synthesis-weight: none;
    }

    body {
        font-size: 12px;
        font-family: Roboto;
        line-height: 1.428571429;
        color: #595953;
        background-color: #142433;
        min-width: 320px;
    }

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    input,
    button,
    select,
    textarea {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    a {
        color: #004f9f;
        text-decoration: none;
    }

    a:hover {
        color: darken(#004f9f, 15%);
    }

    label {
        font-weight: normal;
        margin-bottom: 0;

        > * {
            pointer-events: none;
        }
    }

    hr {
        margin-top: 17px;
        margin-bottom: 17px;
        border: 0;
        border-top: 1px solid #d7dcdf;
    }

    @font-face {
        font-family: "ATGRubrik";
        src: url(${rubrikWoff}) format("woff"), url(${rubrikTtf}) format("truetype"),
            url(${rubrikSvg}) format("svg");
        font-weight: normal;
        font-style: normal;
    }

    #onetrust-consent-sdk {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
    }
`;
