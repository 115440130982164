import {createSelector} from "reselect";
import {
    CONTEXT_HORSE,
    CONTEXT_SPORT,
    CONTEXT_CASINO,
    CONTEXT_TILLSAMMANS,
} from "./verticalsReducer";
import type {VerticalState} from "./verticalsReducer";

export type State = {
    verticals: VerticalState;
};

export type Verticals = {
    horseActive: boolean;
    sportActive: boolean;
    casinoActive: boolean;
    tillsammansActive: boolean;
};

export const getContext = (state: State) =>
    state && state.verticals && state.verticals.context;

export const isHorse: (state: State) => boolean = createSelector(
    getContext,
    (context) => context === CONTEXT_HORSE,
);
export const isSport: (state: State) => boolean = createSelector(
    getContext,
    (context) => context === CONTEXT_SPORT,
);
export const isCasino: (state: State) => boolean = createSelector(
    getContext,
    (context) => context === CONTEXT_CASINO,
);
export const isTillsammans: (state: State) => boolean = createSelector(
    getContext,
    (context) => context === CONTEXT_TILLSAMMANS,
);

export const getVerticals: (state: State) => Verticals = createSelector(
    isHorse,
    isSport,
    isCasino,
    isTillsammans,
    (horseActive, sportActive, casinoActive, tillsammansActive): Verticals => ({
        horseActive,
        sportActive,
        casinoActive,
        tillsammansActive,
    }),
);
