import {isWeb} from "@atg-shared/system";
import * as Storage from "@atg-shared/storage";
import root from "window-or-global";
import {flow} from "lodash";
import type {
    ExperiencePayload,
    ExperienceConfig,
    ExperienceReturnValue,
    GetVariationOptions,
} from "@atg-shared/personalization-types";
import log from "@atg-shared/log";
import type {QubitEnv} from "./config";

export const CONTEXT_ID_COOKIE = "_qubitContextId";
export const CONTEXT_ID_KEY = "contextId";
const getEnv = () => root.clientConfig?.qubit?.env ?? "dev";
export const env: QubitEnv = getEnv();

export const prefixKey = (key: string) => `__qubit-${key}`;
export const getItem = (key: string) => Storage.getItem(key, Storage.SESSION);
export const getItemFromStorage = flow(prefixKey, getItem);

export const getQubitContextId = () => {
    if (isWeb) {
        const qubitCookie = root.document.cookie
            .split("; ")
            .find((c) => c.includes(CONTEXT_ID_COOKIE))
            ?.split("=");

        return qubitCookie ? qubitCookie[1] : undefined;
    }
    const qubitContext = getItemFromStorage(CONTEXT_ID_KEY);
    return qubitContext ?? undefined;
};

/**
 * Given an experience payload (from the Qubit API) and a list of known variations for the
 * experience, return the index (0-based) for the active variation
 */
export const getVariationIndex = ({experience, variations}: GetVariationOptions) => {
    const variationIndex = variations.indexOf(experience.variation);
    if (variationIndex === -1) {
        log.warn(
            `personalizationUtil::getVariationIndex: mismatch in experience with config, defaulting to variationIndex 0. Make sure that variation ids in Qubit match the ones in the config`,
            {experienceId: experience.id, variationId: experience.variation},
        );

        // give up and fallback to variation 0 (the control)
        return 0;
    }
    return variationIndex;
};

export function getMappedExperience(
    experienceData: ExperiencePayload,
    config: ExperienceConfig,
): ExperienceReturnValue {
    return {
        fields: experienceData.payload,
        variation: getVariationIndex({
            experience: experienceData,
            variations: config[getEnv()].variations,
        }),
    };
}
