import * as React from "react";
import type {Props as SvgIconPropType} from "../components/SvgIcon";
import SvgIcon from "../components/SvgIcon";

export function ArrowDown(props: SvgIconPropType) {
    return (
        <SvgIcon {...props}>
            <path d="M16 20.66L27.954 8.8a1.199 1.199 0 012.076.82c0 .347-.147.66-.383.879l-.001.001-12.8 12.7c-.217.216-.516.349-.846.349s-.629-.133-.846-.349l-12.8-12.7a1.2 1.2 0 111.691-1.701l.001.001L16 20.66z" />
        </SvgIcon>
    );
}

export default ArrowDown;
