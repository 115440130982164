import * as React from "react";
import { createSvgIcon } from "../utils";

export const WarningOutlined = createSvgIcon(
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3c-.388 0-.771.096-1.11.28a2.128 2.128 0 0 0-.818.782l-8.799 14.89a1.95 1.95 0 0 0-.272 1.04c.009.366.122.72.323 1.026.2.306.482.554.81.724.328.17.696.258 1.068.258h17.596c.372 0 .74-.088 1.068-.258.329-.17.61-.418.81-.724.201-.307.314-.66.323-1.026a1.95 1.95 0 0 0-.272-1.04l-8.799-14.89a2.128 2.128 0 0 0-.818-.782A2.326 2.326 0 0 0 12 3Zm-.584 1.246c.173-.094.375-.146.584-.146.209 0 .41.052.584.146s.309.226.397.376L21.78 19.51a.85.85 0 0 1 .12.454.858.858 0 0 1-.143.45c-.092.14-.227.263-.395.35a1.23 1.23 0 0 1-.563.135H3.202c-.2 0-.394-.048-.563-.135a1.043 1.043 0 0 1-.395-.35.859.859 0 0 1-.144-.45.85.85 0 0 1 .12-.454L11.02 4.62c.088-.149.223-.28.397-.375Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 15c.304 0 .55-.284.55-.635v-6.23c0-.35-.246-.635-.55-.635-.304 0-.55.284-.55.635v6.23c0 .35.246.635.55.635Z"
        />
        <path d="M12.9 17.6a.9.9 0 1 0-1.8 0 .9.9 0 0 0 1.8 0Z" />
    </>,
    "WarningOutlined",
);

export default WarningOutlined;
