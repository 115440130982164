import type {Theme, Components} from "..";

type SwitchOverrides = Components<Theme>["MuiSwitch"];

const overrides: SwitchOverrides = {
    defaultProps: {
        disableRipple: false,
    },
    styleOverrides: {
        switchBase: ({theme}) => ({
            "&:not(.Mui-checked) .MuiSwitch-thumb": {
                color: theme.palette.grey[50],
            },
        }),
    },
};

export default overrides;
