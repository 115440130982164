import * as React from "react";
import {ClassNames} from "@emotion/react";

import {Cross} from "atg-ui-components/icons";
import * as styles from "./CloseButton.styles";

function CloseButton({onClick, className, dataTestId, size = 8, style}) {
    return (
        <ClassNames>
            {({css, cx}) => (
                <button
                    type="button"
                    className={cx(css(styles.alertCloseButton), css(style), className)}
                    onClick={onClick}
                    data-test-id={dataTestId}
                    aria-label="Close Alert"
                >
                    <Cross style={styles.closeButton(size)} />
                </button>
            )}
        </ClassNames>
    );
}

export default CloseButton;
