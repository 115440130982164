import {put, take, takeLatest} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import Features, {framtidensButik} from "@atg-shared/client-features";
import {MemberActions} from "@atg-global-shared/member-data-access";
import {DepositActions} from "@atg-payment-shared/deposit-domain";

export const START_RETAIL_PURCHASE_FLOW = "retail/START_RETAIL_PURCHASE_FLOW";

// Logic below in Saga kept as a reference if a custom flow is needed in the future
function* retailStoreSaga(action: any): SagaIterator {
    console.log("Starting retailStoreSaga...");

    console.log("Payload", action.payload);

    yield put(MemberActions.startLoginFlow());
    console.log("Dispatched startLoginFlow action creator");

    const response = yield take([MemberActions.FINISH_MEMBER_FLOW]);
    console.log("Received FINISH_MEMBER_FLOW action");

    const isUserLoggedIn = response.type === MemberActions.FINISH_MEMBER_FLOW;

    if (isUserLoggedIn) {
        yield put(DepositActions.startDepositFlowInModal());
        console.log("Dispatched startDepositFlow action creator");
    }
}

function* watchRetailStoreSaga(): SagaIterator {
    if (!Features.isEnabled(framtidensButik)) {
        return;
    }

    yield takeLatest(START_RETAIL_PURCHASE_FLOW, retailStoreSaga);
}

export default watchRetailStoreSaga;
