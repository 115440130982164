import * as React from "react";
import * as Redux from "react-redux";
import {Link} from "react-router-dom";
import * as Analytics from "@atg-shared/analytics";
import {VerticalsActions} from "@atg-global-shared/verticals";
import titlelogo from "atg-logos/images/atgicon.svg";
import * as styles from "./HomeMenu.styles";

function HomeMenu() {
    const dispatch = Redux.useDispatch();

    const onHomeMenuClickHandler = () => {
        dispatch(VerticalsActions.unlockContextAction());
        Analytics.deprecated_logEvent({
            logoClickEvent: "logoClick-redirect_to_start_page",
        });
    };

    return (
        <Link
            to="/"
            onClick={onHomeMenuClickHandler}
            aria-label="till start"
            css={styles.root}
        >
            <img css={styles.logo} aria-hidden="true" src={titlelogo} alt="" />
        </Link>
    );
}

export default HomeMenu;
