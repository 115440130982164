import {combineReducers} from "redux";
import type {MemberFlowOptions} from "./authActions";
import {
    LOGIN_FINISHED,
    LOGOUT_FINISHED,
    AUTH_CHECK_REQUEST,
    AUTH_CHECK_RESPONSE,
    AUTH_CLEAR,
    AUTH_SET_ROLES,
    AUTH_ADD_MEMBER_FLOW_OPTIONS,
    AUTH_REMOVE_MEMBER_FLOW_OPTIONS,
} from "./authActions";
import type {Roles} from "./authRoles";

export type AuthState = {
    loading: boolean;
    error: {
        message?: string;
    };
    data: {
        roles: Roles;
        username: string;
        memberId?: number;
    };
    memberFlowOptions: MemberFlowOptions | null | undefined;
};

function authResource(state = {}, action: any) {
    const {type, payload, error} = action;
    switch (type) {
        case AUTH_CHECK_REQUEST:
            return state;
        case AUTH_CHECK_RESPONSE:
            if (error) return {};
            return payload;
        case LOGIN_FINISHED:
            return {
                ...state,
                memberId: payload.loginData.memberId,
                roles: payload.loginData.roles,
            };
        case AUTH_SET_ROLES:
            return {
                ...state,
                roles: action.roles,
            };
        case LOGOUT_FINISHED:
            return {};
        default:
            return state;
    }
}

function authLoading(state = false, action: any) {
    switch (action.type) {
        case AUTH_CHECK_REQUEST:
            return true;
        case AUTH_CHECK_RESPONSE:
        case AUTH_SET_ROLES:
            return false;
        default:
            return state;
    }
}

function authError(state = {}, action: any) {
    switch (action.type) {
        case AUTH_CHECK_REQUEST:
            return state;
        case AUTH_CHECK_RESPONSE:
            if (!action.error) return {};
            return {message: action.payload};
        case AUTH_CLEAR:
            return {};
        default:
            return state;
    }
}

const authMemberFlowOptions = (
    state = null,
    action: any,
): MemberFlowOptions | null | undefined => {
    switch (action.type) {
        case AUTH_ADD_MEMBER_FLOW_OPTIONS:
            return action.payload;
        case AUTH_REMOVE_MEMBER_FLOW_OPTIONS:
        case AUTH_CLEAR:
            return null;
        default:
            return state;
    }
};

const auth = combineReducers({
    data: authResource,
    loading: authLoading,
    error: authError,
    memberFlowOptions: authMemberFlowOptions,
});

export default auth;
