import {combineReducers} from "redux";
import type {FetchState} from "@atg-shared/fetch-types";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {ControlQuestionAction, RecoveryMethodsAction} from "./memberRecoveryActions";
import {
    RESET_STATUS,
    MEMBER_RECOVERY,
    MEMBER_RECOVERY_RESPONSE,
    REQUEST_RECOVERY_METHODS,
    RECEIVE_RECOVERY_METHODS,
    REQUEST_CONTROL_QUESTION,
    RECEIVE_CONTROL_QUESTION,
} from "./memberRecoveryActions";

const recovery = createFetchReducer(
    MEMBER_RECOVERY,
    MEMBER_RECOVERY_RESPONSE,
    RESET_STATUS,
    (state) => state,
    {},
);
type MethodsState = {
    methods: string[];
    username: string;
};
const initialState: MethodsState = {
    methods: [],
    username: "",
};
const methods = createFetchReducer(
    REQUEST_RECOVERY_METHODS,
    RECEIVE_RECOVERY_METHODS,
    RESET_STATUS,
    (state: MethodsState, action: RecoveryMethodsAction) => {
        if (action.type === RECEIVE_RECOVERY_METHODS) {
            if (action.error) return state;
            return action.payload;
        }

        return state;
    },
    initialState,
);
type ControlQuestionState = {
    question: string;
};
const controlQuestionInitialState: ControlQuestionState = {
    question: "",
};
const controlQuestion = createFetchReducer(
    REQUEST_CONTROL_QUESTION,
    RECEIVE_CONTROL_QUESTION,
    RESET_STATUS,
    (state: ControlQuestionState, action: ControlQuestionAction) => {
        if (action.type === RECEIVE_CONTROL_QUESTION) {
            if (action.error) return state;
            return action.payload;
        }

        return state;
    },
    controlQuestionInitialState,
);
type MemberRecoveryState = {
    recovery: FetchState<any>;
    methods: FetchState<MethodsState>;
    controlQuestion: FetchState<ControlQuestionState>;
};
export type State = {
    memberRecovery: MemberRecoveryState;
};
const memberRecoveryReducer = combineReducers({
    recovery,
    methods,
    controlQuestion,
});
export default memberRecoveryReducer;
