import * as React from "react";
import SvgIcon, { type SvgIconProps } from "../components/SvgIcon";

function createSvgIcon(path: React.ReactNode, displayName: string) {
    function Component(props: SvgIconProps, ref: React.ForwardedRef<SVGSVGElement>) {
        return (
            <SvgIcon data-test-id={`${displayName}Icon`} ref={ref} {...props}>
                {path}
            </SvgIcon>
        );
    }
    Component.muiName = SvgIcon.muiName;
    return React.memo(React.forwardRef(Component));
}

export default createSvgIcon;
