const grey = {
    50: "#ECECED",
    100: "#D8D9DA",
    200: "#B0B2B5",
    300: "#96999C",
    400: "#7A7F83",
    500: "#6C7379",
    600: "#60676C",
    700: "#545B5F",
    800: "#484D51",
    900: "#3C4043",
};

export default grey;
