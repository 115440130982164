import {css} from "@emotion/react";

export const responsibleGamingImage = css`
    height: 22px;
    margin-left: 8px;
`;

export const responsibleGamingLink = css`
    display: flex;
    justify-content: center;
`;
