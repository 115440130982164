import * as React from "react";
import type {SerializedStyles} from "@emotion/react";
import atgCheckGreenBlackLogo from "./images/atgCheckGreenBlackLogo.svg";
import atgCheckSolidWhiteLogo from "./images/atgCheckSolidWhiteLogo.svg";
import atgCheckSolidBlackLogo from "./images/atgCheckSolidBlackLogo.svg";

export const ATG_CHECK_GREEN_BLACK = "green-black";
export const ATG_CHECK_SOLID_WHITE = "solid-white";
export const ATG_CHECK_SOLID_BLACK = "solid-black";

type AtgLogoStyle =
    | typeof ATG_CHECK_GREEN_BLACK
    | typeof ATG_CHECK_SOLID_WHITE
    | typeof ATG_CHECK_SOLID_BLACK
    | string;

type Props = {
    style?: SerializedStyles;
    logoStyle: AtgLogoStyle;
};

function AtgCheckGreenBlack({style}: {style?: SerializedStyles}) {
    return <img css={style} src={atgCheckGreenBlackLogo} alt="ATGCheck-green-black" />;
}

function AtgCheckSolidWhite({style}: {style?: SerializedStyles}) {
    return <img css={style} src={atgCheckSolidWhiteLogo} alt="ATGCheck-solid-white" />;
}

function AtgCheckSolidBlack({style}: {style?: SerializedStyles}) {
    return <img css={style} src={atgCheckSolidBlackLogo} alt="ATGCheck-solid-black" />;
}

function AtgCheckLogo({style, logoStyle}: Props) {
    switch (logoStyle) {
        case ATG_CHECK_GREEN_BLACK:
            return <AtgCheckGreenBlack style={style} />;
        case ATG_CHECK_SOLID_WHITE:
            return <AtgCheckSolidWhite style={style} />;
        case ATG_CHECK_SOLID_BLACK:
            return <AtgCheckSolidBlack style={style} />;
        default:
            return null;
    }
}

export default AtgCheckLogo;
