import {call, put, select, take, takeLatest} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {deprecated_logEvent} from "@atg-shared/analytics";
import {LOGIN_FINISHED} from "@atg-global-shared/user/userActionTypes";
import {MemberActions} from "@atg-global-shared/member-data-access";
import * as LoginActions from "atg-member-login/domain/loginActions";
import * as MemberRegisterApi from "./memberRegisterApi";
import * as MemberRegisterActions from "./memberRegisterActions";
import * as MemberRegisterSelectors from "./memberRegisterSelectors";
import type {
    MemberRegisterVerifyAction,
    MemberRegisterCreateAction,
    MemberRegisterCreateData,
} from "./memberRegisterActions";
import {MEMBER_REGISTER_VERIFY, MEMBER_REGISTER_CREATE} from "./memberRegisterActions";
import {
    REGISTER_USER_CREATE_STEP,
    REGISTER_USER_ALREADY_MEMBER_STEP,
} from "./memberRegisterSteps";
import type {Action} from "./types";

function getErrorCode(error: unknown) {
    // @ts-expect-error
    return error.response.meta.code;
}

function getVerifyError(error: unknown) {
    switch (getErrorCode(error)) {
        case 400:
            return "Det personnummer du angett är inte korrekt. Var vänlig kontrollera dina uppgifter och försök igen.";
        case 403:
            return "Namn och personnummer matchar inte. Kontrollera dina uppgifter och försök igen.";
        case 409:
            return "Vi ser att du redan är registrerad som medlem hos oss. För att gå vidare behöver du logga in eller beställa nya inloggningsuppgifter.";
        default:
            return "Ett okänt tekniskt fel har inträffat. Var vänlig försök igen senare.";
    }
}

function getCreateError(error: unknown) {
    switch (getErrorCode(error)) {
        case 400:
            return "Uppgifterna du angett är felaktiga. Vänligen kontrollera uppgifterna och försök igen.";
        case 403:
            return "Någonting gick fel vid registering. Vänligen kontakta ATG Kundservice för mer information: 08-620 07 00";
        default:
            return "Ett okänt tekniskt fel har inträffat.";
    }
}

export function* sendErrorEvent(step: string, message: string): SagaIterator {
    yield call(deprecated_logEvent, {
        memberRegisterErrorEvent: JSON.stringify({step, message}),
    });
}

export function* memberRegisterVerifyFlow(
    action: MemberRegisterVerifyAction,
): SagaIterator {
    try {
        yield call(MemberRegisterApi.verifyMember, action.payload);
        yield put(MemberRegisterActions.memberRegisterVerifySuccess(action.payload));
    } catch (error: unknown) {
        const errorCode = getErrorCode(error);
        const errorMessage = getVerifyError(error);

        if (errorCode === 409) {
            yield put(
                MemberRegisterActions.memberRegisterSetStep(
                    REGISTER_USER_ALREADY_MEMBER_STEP,
                ),
            );
        } else {
            yield call(sendErrorEvent, "verify_step", errorMessage);
        }

        yield put(
            MemberRegisterActions.memberRegisterVerifyError({
                data: action.payload,
                errorMessage,
            }),
        );

        return;
    }

    yield put(MemberRegisterActions.memberRegisterSetStep(REGISTER_USER_CREATE_STEP));
}

export function* memberRegisterCreateFlow(
    action: MemberRegisterCreateAction,
): SagaIterator {
    try {
        const response = yield call(MemberRegisterApi.createMember, action.payload);
        yield put(
            MemberRegisterActions.memberRegisterCreateSuccess({
                ...action.payload,
                ...response.data,
            }),
        );
    } catch (error: unknown) {
        const errorMessage = getCreateError(error);
        yield call(sendErrorEvent, "create_step", errorMessage);
        yield put(
            MemberRegisterActions.memberRegisterCreateError({
                data: action.payload,
                errorMessage,
            }),
        );
        return;
    }

    // login user in the background and clear registration data
    const registerData: MemberRegisterCreateData = yield select(
        MemberRegisterSelectors.getCreateData,
    );

    if (registerData.userId && registerData.password) {
        yield put(
            LoginActions.loginUser({
                username: registerData.userId,
                password: registerData.password,
            }),
        );
    }

    yield take(LOGIN_FINISHED);
    yield put(MemberActions.memberRegisterFinished());
}

export function* memberRegisterCuritySuccess(): SagaIterator {
    const action: Action = yield take([
        MemberActions.MEMBER_REGISTER_CANCEL,
        LOGIN_FINISHED,
        MemberActions.START_LOGIN_FLOW,
    ]);
    if (action.type === MemberActions.START_LOGIN_FLOW) return;
    if (action.type === MemberActions.MEMBER_REGISTER_CANCEL) return;
    if (action.type === LOGIN_FINISHED) {
        if (action.payload.loginData.status === "OK") {
            yield put(MemberActions.memberRegisterFinished());
        }
    }
}

export default function* memberRegistrationSaga(): SagaIterator {
    yield takeLatest(MEMBER_REGISTER_VERIFY, memberRegisterVerifyFlow);
    yield takeLatest(MEMBER_REGISTER_CREATE, memberRegisterCreateFlow);
    yield takeLatest(
        MemberActions.MEMBER_REGISTER_CURITY_CREATE_SUCCESS,
        memberRegisterCuritySuccess,
    );
}
