import * as React from "react";
import styled from "@emotion/styled";
import {css} from "@emotion/react";
import root from "window-or-global";
import browser from "./browser";

export const PrintContext = React.createContext({
    print: () => root.print(),
    printing: false,
});

export const noPrint = css`
    @media print {
        display: none !important;
    }
`;

export const NoPrint = styled.div`
    @media print {
        display: none !important;
    }
`;

const printStop = css`
    display: none;
`;

export const PrintOnly = styled.div`
    display: none;
    @media print {
        display: initial;
    }
`;

const PrintFrame = styled.iframe`
    visibility: hidden;
    position: absolute;
    border: none;
`;

const siblingsBetween = (
    startNode: HTMLIFrameElement | Node,
    stopNode: HTMLIFrameElement,
): Array<Node> =>
    startNode.nextSibling && startNode.nextSibling !== stopNode
        ? [startNode.nextSibling, ...siblingsBetween(startNode.nextSibling, stopNode)]
        : [];

const createPrint = (
    iframeDocument: Document,
    iframe: HTMLIFrameElement,
    last: HTMLIFrameElement,
    bodyClassName?: string,
) => {
    const nodes = siblingsBetween(iframe, last).map((node) => node.cloneNode(true));
    const styles = [
        // @ts-ignore, Spread operator make it the right type
        ...root.document.head.querySelectorAll(
            'link[rel="stylesheet"],style[type="text/css"]:not([data-emotion])',
        ),
    ];
    const emotionStyles = iframeDocument.createElement("style");
    emotionStyles.type = "text/css";
    emotionStyles.appendChild(
        iframeDocument.createTextNode(
            [
                ...(root.document.querySelectorAll("[data-emotion]") as NodeListOf<
                    Element & {sheet: CSSStyleSheet}
                >),
            ]
                .reduce(
                    (acc, {sheet}) => [
                        ...acc,
                        ...[...sheet.cssRules].map((rules) => rules.cssText),
                    ],
                    [] as Array<string>,
                )
                .join("\n"),
        ),
    );
    const {head} = iframeDocument;
    if (head !== null) {
        styles.forEach((node) => head.appendChild(node.cloneNode(true)));
        head.appendChild(emotionStyles);
    }

    const {body} = iframeDocument;
    if (body !== null) {
        nodes.forEach((node) => {
            // @ts-ignore
            const nClone = new DOMParser().parseFromString(node.outerHTML, "text/html")
                .body.firstChild;

            // @ts-ignore
            body.appendChild(nClone);
        });
        body.className = bodyClassName || "";
    }
};

const unmountPrint = (iframeWindow: Window, onAfter: () => void) => {
    if (iframeWindow.matchMedia) {
        iframeWindow
            .matchMedia("print")
            .addListener((mql: MediaQueryListEvent) => !mql.matches && onAfter());
    }
};

type Props = {
    children:
        | React.ReactNode
        | ((props: {print: () => void; printing: boolean}) => React.ReactNode);
    bodyClassName?: string;
};

function PrintRoot(props: Props) {
    const {children, bodyClassName} = props;
    const printFrame = React.useRef<HTMLIFrameElement>(null);
    const printLimiter = React.useRef<HTMLIFrameElement>(null);

    const [compKey, setCompKey] = React.useState(1);

    const [printing, setPrintingState] = React.useState<boolean>(false);

    const printMessageHandler = (event: any) => {
        if (event.data === "PRINT_CLOSED") {
            setPrintingState(false);
            setCompKey(compKey + 1);
        }
    };

    React.useEffect(() => {
        window.addEventListener("message", printMessageHandler);
        return () => {
            window.removeEventListener("message", printMessageHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compKey]);

    const printIframe = React.useCallback(() => {
        const iframe = printFrame.current;
        const last = printLimiter.current;

        if (!iframe || !last) {
            root.print();
            setPrintingState(false);
            setCompKey(compKey + 1);
            return;
        }
        if (iframe) {
            iframe.src = "about:blank";
            iframe.onload = () => {
                const {contentWindow: iframeWindow, contentDocument: iframeDocument} =
                    iframe;
                const onAfterHandler = () => {
                    parent.postMessage("PRINT_CLOSED", "*");
                };

                iframeWindow?.addEventListener("afterprint", () => {
                    onAfterHandler();
                });

                if (iframeWindow && iframeDocument) {
                    unmountPrint(iframeWindow, onAfterHandler);
                    createPrint(iframeDocument, iframe, last, bodyClassName);
                    iframeWindow.setTimeout(() => {
                        setTimeout(() => {
                            iframeWindow.print();
                            setTimeout(() => iframeWindow.close());
                        });
                    }, 500);
                }
            };
        }
    }, [bodyClassName, compKey]);

    const print = React.useCallback(() => {
        if (!printing) {
            setPrintingState(true);

            setTimeout(() => {
                printIframe();
            }, 0);
            // In Safari, some times you get an extra dialog, that blocks the CLOSE flow of the printing, so it nevers get stoped
            if (browser.isSafari()) {
                setTimeout(() => {
                    setPrintingState(false);
                }, 2000);
            }
        }
    }, [printIframe, printing]);

    const valueMemo = React.useMemo(() => ({print, printing}), [print, printing]);

    return (
        <PrintContext.Provider key={compKey.toString()} value={valueMemo}>
            {printing && <PrintFrame ref={printFrame} />}
            {typeof children === "function" ? children(valueMemo) : children}
            {printing && <div css={printStop} ref={printLimiter} />}
        </PrintContext.Provider>
    );
}

export default PrintRoot;
