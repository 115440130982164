/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import type {SerializedStyles} from "@emotion/react";
import {useNotificationState} from "../NotificationsProvider";
import * as styles from "./Notification.styles";

export interface Props extends Omit<React.HTMLAttributes<HTMLSpanElement>, "style"> {
    style?: SerializedStyles;
}

function Notification({style, ...other}: Props) {
    const {hasUnansweredMessages, numberOfUnreadMessages} = useNotificationState();

    const showNotification = hasUnansweredMessages || !!numberOfUnreadMessages;

    if (!showNotification) return null;

    const renderNotificationContent = () => {
        if (hasUnansweredMessages) return "!";
        return numberOfUnreadMessages;
    };

    return (
        <span css={[styles.rootStyle, style]} {...other}>
            {renderNotificationContent()}
        </span>
    );
}

export default Notification;
