/**
 * Checks if the current URL has bet related query parameters
 * @returns {boolean} - true if the current URL is a bet URL
 */
export const checkIsBetUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const isSharedBetUrl = searchParams.get("sharedBet") === "true";
    const isGameBetUrl = searchParams.has("game");
    const isBetUrl = isSharedBetUrl || isGameBetUrl;

    return isBetUrl;
};
