import {
    CHECK_SHOP_MATCH_FAILURE,
    CHECK_SHOP_MATCH_REQUEST,
    CHECK_SHOP_MATCH_SUCCESS,
    CHECK_SHOP_MATCH_RESET,
    CLOSE_SUBMENU,
    OPEN_SUBMENU,
} from "./mainNavbarActions";

export type MainNavbarState = {
    submenuOpen: boolean;
    loading: boolean;
    shopMatch: boolean;
};

const initialState = {
    submenuOpen: false,
    loading: false,
    shopMatch: false,
};

export const mainNavbar = (
    state: MainNavbarState = initialState,
    action: any,
): MainNavbarState => {
    switch (action.type) {
        case OPEN_SUBMENU:
            return {...state, submenuOpen: true};
        case CLOSE_SUBMENU:
            return {...state, submenuOpen: false};
        case CHECK_SHOP_MATCH_REQUEST:
            return {...state, loading: true};
        case CHECK_SHOP_MATCH_SUCCESS:
            return {...state, shopMatch: true, loading: false};
        case CHECK_SHOP_MATCH_FAILURE:
            return {...state, shopMatch: false, loading: false};
        case CHECK_SHOP_MATCH_RESET:
            return {...state, shopMatch: false};
        default:
            return state;
    }
};

export default mainNavbar;
