/* eslint-disable react/jsx-no-useless-fragment */
import * as React from "react";
import * as Redux from "react-redux";
import {Box} from "@atg-ui/toolkit";
import Features, {mobileTopMenu, newVerticalNavbar} from "@atg-shared/client-features";

import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import {useBreakpoint} from "atg-breakpoints";
import patternMobile from "../assets/patternMobile.svg";
import patternDesktop from "../assets/patternDesktop.png";
import {isVerticalLandingPage, isVerticalAccountPage} from "../domain/mainMenuSelectors";
import HomeMenu from "./HomeMenu";
import PlayMenu from "./PlayMenu";
import HeaderWrapper from "./HeaderWrapper";
import * as styles from "./BasicHeader.styles";
import HeaderVerticalLinks from "./HeaderVerticalLinks";
import CreateAccount from "./CreateAccount";
import MainHeader from "./MainHeader";

type DesktopHeaderProps = {
    renderRightMenu?: () => React.ReactNode;
};

type MobileHeaderProps = {
    showMobileNavBar: boolean;
    renderRightMenu?: () => React.ReactNode;
    isBelowMd: boolean;
};

function DesktopHeader({renderRightMenu}: DesktopHeaderProps) {
    return (
        <HeaderWrapper displaceImage pattern={patternDesktop} css={styles.header}>
            <div>
                <PlayMenu />
                <HomeMenu />
            </div>

            <nav>
                <HeaderVerticalLinks />
            </nav>

            <div>{renderRightMenu && renderRightMenu()}</div>
        </HeaderWrapper>
    );
}

function MobileHeader({showMobileNavBar, renderRightMenu, isBelowMd}: MobileHeaderProps) {
    // this user check is only needed to the ab-test to prevent render an empty Box with padding
    const user = Redux.useSelector(UserSelectors.getUser);
    return (
        <HeaderWrapper displaceImage={false} pattern={patternMobile}>
            <div css={styles.header}>
                <PlayMenu />
                <HomeMenu />
                <div>{renderRightMenu && renderRightMenu()}</div>
            </div>

            {isBelowMd && !user.username && (
                <Box padding={1.5}>
                    <CreateAccount />
                </Box>
            )}

            {showMobileNavBar && (
                <nav>
                    <HeaderVerticalLinks isMobile />
                </nav>
            )}
        </HeaderWrapper>
    );
}

type HeaderProps = {
    renderRightMenu?: () => React.ReactNode;
};

function Header({renderRightMenu}: HeaderProps) {
    const isBelowMd = !useBreakpoint("MD");
    const isBelowSm = !useBreakpoint("SM");
    const isVerticalStart = Redux.useSelector(isVerticalLandingPage);
    const isVerticalAccount = Redux.useSelector(isVerticalAccountPage);
    const newNavFeatureEnabled = Features.isEnabled(newVerticalNavbar);
    const isMobileTopMenuEnabled = Features.isEnabled(mobileTopMenu);

    const isHorseOrSportOrCasino =
        window.location.pathname === "/" ||
        (window.location.pathname === "/sport" &&
            !window.location.hash.includes("#filter")) ||
        window.location.pathname === "/casino/start";

    const showMobileNavBar = isVerticalStart || isVerticalAccount;

    // TODO: once new menu is 100% in production, MobileHeader and DesktopHeader should be removed
    return (
        <>
            {newNavFeatureEnabled ? (
                <>
                    <MainHeader renderRightMenu={renderRightMenu} />
                    {isMobileTopMenuEnabled && isBelowSm && isHorseOrSportOrCasino && (
                        <HeaderVerticalLinks isMobile />
                    )}
                </>
            ) : (
                <>
                    {isBelowMd ? (
                        <MobileHeader
                            showMobileNavBar={showMobileNavBar}
                            renderRightMenu={renderRightMenu}
                            isBelowMd={isBelowMd}
                        />
                    ) : (
                        <DesktopHeader renderRightMenu={renderRightMenu} />
                    )}
                </>
            )}
        </>
    );
}

export default Header;
