import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import type {GlobalUserState} from "@atg-global-shared/user";
import * as Utils from "./memberUtils";
import type {GlobalMemberFlowState as State} from "./memberReducer";

const getMemberState = (state: State) => state.member;

export const isLogin = (state: State) => getMemberState(state).isLogin;

export const isRegister = (state: State) => getMemberState(state).isRegister;

export const isInAuthenticationFlow = (state: State) =>
    isLogin(state) || isRegister(state);

export const isReturningMember = (state: State & GlobalUserState) =>
    UserSelectors.isReturningUser(state) || getMemberState(state).isReturningMember;

export const getFlowType = (state: State) => state.member.flowType;

export const getFlowTriggerAction = (state: State) => state.member.flowTriggerAction;

export const getFlowOptions = (state: State) => {
    const triggeredBy = getFlowTriggerAction(state);
    const flowType = getFlowType(state);
    if (!triggeredBy) return {flowType};
    return {
        flowType,
        triggeredBy,
    };
};

export const isModalFlow = (state: State) => Utils.isModalFlow(getFlowType(state));

export const isForcedAuth = (state: State) => state.member.isForcedAuth;

export const loginMessage = (state: State) => state.member.loginMessage;
