import {css} from "@emotion/react";
import type {Props} from "./ButtonBase";

export const disabled = css`
    pointer-events: none;
    cursor: default;
`;

export const rootStyle = (props: Props) => css`
    background: none;
    color: inherit;
    border: 0;
    border-radius: 0;
    padding: 0;
    margin: 0; /* Remove the margin in Safari; */

    font: inherit;
    cursor: pointer;
    box-sizing: border-box;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;

    user-select: none;
    -moz-appearance: none; /* Reset */
    -webkit-appearance: none; /* Reset */
    text-decoration: none;
    vertical-align: middle;

    &::-moz-focus-inner {
        border-style: none; /* Remove Firefox dotted outline */
    }

    ${props.disabled && disabled}
    ${props.disableFocusStyle &&
    css`
        /* Disable the focus ring for mouse, touch and keyboard users. */
        outline: 0;
        -webkit-tap-highlight-color: transparent;
    `}
`;

export default rootStyle;
