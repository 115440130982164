import * as React from "react";
import {iframeDefault, iframeHidden} from "./CurityIFrame.styles";

type CurityProps = {
    authURL: string;
    isVisible?: boolean;
    iframeHeight: string;
    onIFrame?: (frameElement: HTMLIFrameElement | null | undefined) => void;
    handleIFrameOnLoad?: (frameElement: React.SyntheticEvent<HTMLIFrameElement>) => void;
};

function CurityIFrame({
    authURL,
    isVisible = false,
    iframeHeight,
    onIFrame,
    handleIFrameOnLoad,
}: CurityProps) {
    return (
        <iframe
            name="curity-iframe"
            data-test-id="curity-iframe"
            css={isVisible ? iframeDefault : iframeHidden}
            title="Login Form"
            height={iframeHeight}
            src={authURL}
            ref={onIFrame}
            onLoad={handleIFrameOnLoad}
        />
    );
}

export default CurityIFrame;
