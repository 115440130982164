import * as LoginTypes from "@atg-login-shared/types";

export type InitBankID = {
    type: LoginTypes.ActionTypes.INIT_BANK_ID;
};

export type InitBankIDWithPersonalNumber = {
    type: LoginTypes.ActionTypes.INIT_BANK_ID_WITH_PERSONAL_NUMBER;
    payload: {
        personalnumber: string;
        usesamedevice: string;
    };
};

export type InitBankIDQR = {
    type: LoginTypes.ActionTypes.INIT_BANK_ID_QR;
};

export type InitFrejaID = {
    type: LoginTypes.ActionTypes.INIT_FREJA_ID;
    payload?: {
        username: string;
    };
};

export type InitFrejaIDQR = {
    type: LoginTypes.ActionTypes.INIT_FREJA_ID_QR;
};

export const initBankID = (): InitBankID => ({
    type: LoginTypes.ActionTypes.INIT_BANK_ID,
});

export type CancelPoll = {
    type: LoginTypes.ActionTypes.CANCEL_POLL;
    payload: LoginTypes.CurityState;
};

export type StartPolling = {
    type: LoginTypes.ActionTypes.START_POLLING;
    payload: LoginTypes.PollingStep; // ? correct should be pollingStep maybe
};

type StopPolling = {
    type: LoginTypes.ActionTypes.STOP_POLLING;
    payload: LoginTypes.Problem;
};

export const initBankIDWithPersonalNumber = (
    payload: LoginTypes.InitBankIDPayload,
): InitBankIDWithPersonalNumber => ({
    type: LoginTypes.ActionTypes.INIT_BANK_ID_WITH_PERSONAL_NUMBER,
    payload,
});

export const initBankIDQR = (): InitBankIDQR => ({
    type: LoginTypes.ActionTypes.INIT_BANK_ID_QR,
});

export const initFrejaID = (payload?: LoginTypes.InitFrejaIDPayload): InitFrejaID => ({
    type: LoginTypes.ActionTypes.INIT_FREJA_ID,
    payload,
});

export const initFrejaIDQR = (): InitFrejaIDQR => ({
    type: LoginTypes.ActionTypes.INIT_FREJA_ID_QR,
});

export const cancelPoll = (payload: LoginTypes.CurityState): CancelPoll => ({
    type: LoginTypes.ActionTypes.CANCEL_POLL,
    payload,
});

export const startPolling = (payload: LoginTypes.PollingStep): StartPolling => ({
    type: LoginTypes.ActionTypes.START_POLLING,
    payload,
});

export const stopPolling = () => ({
    type: LoginTypes.ActionTypes.STOP_POLLING,
});

export type EIDAction =
    | InitBankID
    | InitBankIDWithPersonalNumber
    | InitFrejaID
    | InitFrejaIDQR
    | CancelPoll
    | StartPolling
    | StopPolling;
