import * as React from "react";

type MainFrameProps = {
    user?: unknown;
    children: React.ReactNode;
    className?: string;
};

function MainFrame({user, children, className}: MainFrameProps) {
    const clonedChildren = React.Children.map(children, (child) => {
        if (!child || !React.isValidElement(child)) return child;
        if (child.type === "div") return child;
        return React.cloneElement(child as React.ReactElement<{user: unknown}>, {user});
    });

    return (
        <div id="main" className={className}>
            {clonedChildren}
        </div>
    );
}

export default MainFrame;
