import {Button, Typography} from "@atg-ui/toolkit";
import {css, styled} from "@atg-ui/toolkit/theme";
import {ArrowDown} from "atg-ui-components/icons";
import Pin from "atg-ui-components/icons/Pin";

export const frame = styled("div")(
    ({theme}) => css`
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 12px 20px;
        background-color: ${theme.palette.grey[900]}16;
        color: ${theme.palette.common.white};

        ${theme.breakpoints.up("md")} {
            padding: 15px 16px;
        }
    `,
);

export const frameContent = styled("div")<{useTopMargin: boolean}>(
    ({theme, useTopMargin}) => css`
        display: flex;
        flex-direction: row;
        margin: ${useTopMargin ? "12px" : "0px"} auto 0;
        max-width: 1232px;

        width: 100%;
        ${theme.breakpoints.up("md")} {
            min-height: 0;
        }
    `,
);

export const videoColumn = styled("div")<{expanded: boolean}>(
    ({theme, expanded}) => css`
        display: flex;
        flex-direction: column;
        margin-right: 0;
        padding: 0 12px;
        position: relative;
        justify-content: center;
        width: 100px;
        ${theme.breakpoints.up("md")} {
            width: ${expanded ? "calc(50% - 24px)" : "100px"};
            margin-right: 24px;
        }
    `,
);

export const livePageColumn = styled("div")<{expanded: boolean}>(
    ({theme, expanded}) => css`
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: space-between;
        position: relative;
        width: ${expanded ? "50%" : "auto"};

        ${theme.breakpoints.up("md")} {
            flex-direction: ${expanded ? "column" : "row"};
        }
        ${theme.breakpoints.up("xs")} {
            justify-content: ${expanded ? "flex-start" : "flex-end"};
        }
    `,
);

export const watchGameButton = styled(Button)(
    ({theme}) => css`
        display: none;
        align-self: center;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.75;
        letter-spacing: 0.025rem;
        text-transform: none;
        min-width: 64px;
        padding: 5px 15px;
        cursor: auto;
        white-space: nowrap;
        margin-left: 0;

        ${theme.breakpoints.up("md")} {
            cursor: pointer;
            display: block;
            opacity: 1;
            position: absolute;
            left: 84px;
            transform: translateX(-50%);
            width: auto;
            background-color: ${theme.palette.grey[900]}0;

            border: 2px solid ${theme.palette.grey[800]}50;
        }
        ${theme.breakpoints.up("xl")} {
            left: 80px;
        }
    `,
);

export const video = styled("div")(
    ({theme}) => css`
        background-color: ${theme.palette.grey[800]}50;

        width: 60px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        ${theme.breakpoints.up("md")} {
            width: 100px;
            height: 56px;
        }
    `,
);

export const icons = styled("div")(
    () => css`
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        padding-right: 8px;
        color: rgb(51, 51, 51);
    `,
);

export const expandIcon = styled("div")(
    () => css`
        margin-right: 36px;
    `,
);

export const icon = styled("span")(
    ({theme}) => css`
        color: ${theme.palette.grey[800]};
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-right: 2px;
        gap: 14px;
        ${theme.breakpoints.up("md")} {
            height: 56px;
            flex-direction: column;
            align-items: center;
            padding-left: 12px;
            padding-right: 14px;
            justify-content: space-around;
            gap: none;
        }
    `,
);

export const pinStyles = styled("div")(
    ({theme}) => css`
        display: flex;
        border-left: none;
        flex-direction: column;
        justify-content: center;
        height: 33px;
        ${theme.breakpoints.up("md")} {
            height: 68px;
            flex-direction: column;
            border-left: 1px solid rgba(216, 216, 216, 0.1);
        }
    `,
);

export const custumStyledPinIcon = styled(Pin)(
    ({theme}) => css`
        height: 22px;
        width: 24px;
        display: flex;
        justify-self: center;
        align-self: center;
        color: ${theme.palette.grey[800]};
    `,
);

export const custumStyledArrowIcon = styled(ArrowDown)(
    ({theme}) => css`
        height: 18px;
        width: 20px;
        display: flex;
        justify-self: center;
        align-self: center;
        color: ${theme.palette.grey[800]};
    `,
);

export const buttonWrapper = styled("div")(
    () => css`
        opacity: 0.2;
    `,
);

export const watchGameLabel = styled(Typography)(
    ({theme}) => css`
        color: ${theme.palette.grey[800]};
    `,
);
