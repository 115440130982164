import {css} from "@emotion/react";

import {
    mainNavigationBackground,
    responsibleGamingHeaderHeight,
    sideNavigationWidth,
} from "atg-ui/css/overridableVariables.styles";
import {
    scrollbarBgColor,
    scrollbarTrackBgColor,
    // @ts-expect-error
} from "atg-ui/css/Scrollbars.global.styles";

export const stickySideMenu = css`
    width: ${sideNavigationWidth}px;
    min-width: ${sideNavigationWidth}px;
    height: 100vh;
    overflow-y: auto;
    background: ${mainNavigationBackground};
    box-shadow: 0 0 16px 7px rgba(0, 0, 0, 0.17);
    border-radius: 0;
    position: fixed;
    top: 0;
    left: 0;
    margin-top: ${responsibleGamingHeaderHeight + "px"};

    &::-webkit-scrollbar-thumb {
        background-color: ${scrollbarBgColor};
    }

    &:hover {
        ::-webkit-scrollbar-thumb {
            background-color: ${scrollbarTrackBgColor};
        }
    }

    @media print {
        display: none;
    }
`;
