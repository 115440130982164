import root from "window-or-global";
import {MEMBER_SEGMENTATION_LOCALSTORAGE_KEY} from "./constants";

export const clearMemberSegmentationCache = (): void => {
    if (root.memberSegmentationRequest) {
        root.memberSegmentationRequestAborted = true;
    }

    delete root.memberSegmentation;
    localStorage.removeItem(MEMBER_SEGMENTATION_LOCALSTORAGE_KEY);
};
