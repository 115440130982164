import {includes} from "lodash";

import type {SagaReturnType} from "redux-saga/effects";
import {call, fork, put, select, take, takeEvery, throttle} from "redux-saga/effects";
import * as Storage from "@atg-shared/storage";
import system from "@atg-shared/system";
import type {AtgRequestError} from "@atg-shared/fetch-types";
import {broadcastAction} from "atg-store-addons";
import {AuthSelectors} from "..";
import {UserCancelledLogin} from "./fetchAuthorized";
import {type AuthResponse, checkAuth} from "./authApi";
import * as AuthActions from "./authActions";
import authAnalyticsSaga from "./authAnalyticsSaga";
import refreshAccessToken from "./refreshAccessToken";
import * as authSelectors from "./authSelectors";
import {ACCESS_TOKEN_STORAGE_KEY} from "./accessTokenConstants";
import {requestHadExpiredAccessToken} from "./requestHadExpiredAccessToken";
import {storeTimestamp} from "./authTimestamp";
import {resetAccessToken} from "./accessTokenActions";

type CheckAuthResponse = SagaReturnType<typeof checkAuth>;
type IsNormalLogin = ReturnType<typeof authSelectors.isNormalLogin>;
type CheckAuthenticationResponse = SagaReturnType<typeof checkAuthentication>;
type IsTokenRefreshedResponse = SagaReturnType<typeof refreshAccessToken>;
type AuthFlowResponse = SagaReturnType<typeof authFlow>;

export function* checkAuthentication() {
    const response: CheckAuthResponse = yield call(checkAuth);
    return response && response.data;
}

export function* authKeepAlive() {
    const isNormalLogin: IsNormalLogin = yield select(authSelectors.isNormalLogin);
    if (!isNormalLogin) return;

    try {
        const data: AuthResponse = yield call(checkAuthentication);
        if (data?.roles[0] === "LOGIN_NORMAL") {
            storeTimestamp();
        }
    } catch (error: unknown) {
        const {response: res} = error as AtgRequestError;
        if (!res) throw error;

        if (requestHadExpiredAccessToken(res)) {
            yield call(refreshAccessToken);
            yield put(AuthActions.checkAuth(false));
        }
    }
}

export function* authFlow(shouldTriggerLogin = false) {
    yield put(AuthActions.clearAuth());
    try {
        return (yield call(checkAuthentication)) as CheckAuthenticationResponse;
    } catch (error: unknown) {
        const {response: res} = error as AtgRequestError;
        if (!res) return null;

        const accessToken: string | null = yield call(
            Storage.getItem,
            ACCESS_TOKEN_STORAGE_KEY,
        );
        if (!accessToken) {
            return null;
        }

        if (!requestHadExpiredAccessToken(res)) {
            return null;
        }

        const isTokenRefreshed: IsTokenRefreshedResponse = yield call(
            refreshAccessToken,
            shouldTriggerLogin,
        );

        if (!isTokenRefreshed && !system.isApp) {
            const hasTokenError: boolean = yield select(AuthSelectors.hasTokenError);
            if (!hasTokenError) return null;
            const {exception}: {exception: AtgRequestError} = yield select(
                AuthSelectors.getTokenLoadingState,
            );
            // 401 response from the access token refresh endpoint means that the refresh token cookie is either missing or has expired
            // FE should treat the user as logged out in this scenario to avoid limbo state
            if (exception.response.meta.code === 401) {
                yield put(resetAccessToken());
                yield put(broadcastAction({type: AuthActions.LOGOUT_FINISHED}));
            }
            return null;
        }

        try {
            return (yield call(checkAuthentication)) as CheckAuthenticationResponse;
        } catch (e: unknown) {
            yield put(AuthActions.checkAuthError("Not logged in"));
            return null;
        }
    }
}

/**
 * Worker saga executing on checkAuth action
 */
export function* authModalFlow({payload}: AuthActions.CheckAuthAction) {
    const {shouldTriggerLogin} = payload;

    // do a request to /auth endpoint, it will normally return status codes 200, 401 or 403
    const response: AuthFlowResponse = yield call(authFlow, shouldTriggerLogin);
    if (response) {
        // we got a response - set roles (could be undefined if unauthorized)
        yield put(AuthActions.setAuthRoles(response.roles));
        // if should not trigger login - set success, otherwise - proceed to next if
        if (!shouldTriggerLogin) {
            yield put(AuthActions.checkAuthSuccess(response));
            return;
        }
    }

    // if a scope is "betting" user, the role will be LOGIN_NORMAL. No response or "LOGIN_COOKIE" role means we are not "betting" user.
    if (!response || includes(response.roles, "LOGIN_COOKIE")) {
        if (!shouldTriggerLogin) {
            // not betting user, but should not trigger login
            yield put(AuthActions.setAuthRoles(response ? response.roles : []));
            if (response) {
                yield put(AuthActions.checkAuthSuccess(response));
            } else {
                yield put(AuthActions.checkAuthError("Not logged in"));
            }
            return;
        }

        // not betting user, and should trigger login
        yield put(AuthActions.startAuthenticationFlow({authResponse: response}));
    } else {
        // assuming "betting" user, though that may depend on response
        yield put(AuthActions.checkAuthSuccess(response));
        return;
    }

    yield take([AuthActions.CANCELLED_LOGIN_FLOW, AuthActions.FINISH_MEMBER_FLOW]);

    // hack: make a new auth call to check roles
    const responseAfterLogin: AuthFlowResponse = yield call(authFlow, shouldTriggerLogin);

    if (!responseAfterLogin) {
        yield put(AuthActions.checkAuthError("Not logged in"));
    } else {
        yield put(AuthActions.checkAuthSuccess(responseAfterLogin));
    }
}

function isAuthErrorAction(
    authResponse:
        | AuthActions.CheckAuthErrorAction
        | AuthActions.CheckAuthSuccessAction
        | AuthActions.StartAuthenticationFlowAction,
): authResponse is AuthActions.CheckAuthErrorAction {
    return "error" in authResponse;
}

/**
 * authenticate before making a graphql request
 */
export function* bffAuthentication({
    resolve,
    reject,
}: AuthActions.BffAuthenticationAction) {
    yield put(AuthActions.checkAuth(true)); // trigger login if not logged in.

    // wait for cancelled login, successful login, or started auth flow (ie got login modal)
    let authResponse:
        | AuthActions.CheckAuthErrorAction
        | AuthActions.CheckAuthSuccessAction
        | AuthActions.StartAuthenticationFlowAction
        | {type: typeof AuthActions.CANCELLED_LOGIN_FLOW} = yield take([
        AuthActions.CANCELLED_LOGIN_FLOW,
        AuthActions.AUTH_CHECK_RESPONSE,
        AuthActions.START_AUTHENTICATION_FLOW,
    ]);

    let hadToLogin = false;

    // if auth flow started, it means the user got login modal; otherwise it means that auth check succeeded without having to relogin
    if (authResponse.type === AuthActions.START_AUTHENTICATION_FLOW) {
        // await until the auth flow is finished
        authResponse = yield take([
            AuthActions.CANCELLED_LOGIN_FLOW,
            AuthActions.AUTH_CHECK_RESPONSE,
        ]);
        hadToLogin = true;
    }

    const successfulLogin =
        authResponse.type === AuthActions.AUTH_CHECK_RESPONSE &&
        !isAuthErrorAction(authResponse);

    const userCancelledLogin = authResponse.type === AuthActions.CANCELLED_LOGIN_FLOW;

    const accessToken: string | null = yield call(
        Storage.getItem,
        ACCESS_TOKEN_STORAGE_KEY,
    );

    if (successfulLogin && accessToken) {
        resolve({token: accessToken, hadToLogin});
        return;
    }

    if (userCancelledLogin) {
        reject(new UserCancelledLogin("Login cancelled"));
        return;
    }
    reject(new Error("Login failed"));
}

export default function* authSaga() {
    yield takeEvery(AuthActions.BFF_AUTHENTICATION, bffAuthentication);
    yield takeEvery(AuthActions.AUTH_CHECK_REQUEST, authModalFlow);
    yield throttle(16 * 60 * 1000, AuthActions.AUTH_KEEP_ALIVE, authKeepAlive);
    yield fork(authAnalyticsSaga);
}
