import root from "window-or-global";
import {MEMBER_SEGMENTATION_SERVICE_URL} from "@atg-shared/service-url";
import log, {serializeError} from "@atg-shared/log";
import {fetchAuthorized} from "@atg-shared/auth";
import {runNativeABTest} from "@atg-shared/personalization";
import {STARTPAGE_HERO} from "@atg-shared/personalization/domain/nativePersonalizationConstants";
import {MEMBER_SEGMENTATION_LOCALSTORAGE_KEY} from "./constants";
import type {MemberSegmentation, CachedMemberSegmentation} from "./types";

const getTodaysDateInCacheFormat = (): string => new Date().toISOString().split("T")[0];

const isSegmentationOutdated = (lastUpdated: string): boolean => {
    const todaysDate = getTodaysDateInCacheFormat();
    return lastUpdated !== todaysDate;
};

/**
 * Non-hook way to get member segmentation data. Use this outside of React
 * components.
 */
export const getMemberSegmentationData = async (): Promise<
    MemberSegmentation | undefined
> => {
    const heroSegmentationVariation = await runNativeABTest(STARTPAGE_HERO);

    if (heroSegmentationVariation?.variation === 0) {
        return undefined;
    }

    delete root.memberSegmentationRequestAborted;

    const pendingRequest = root.memberSegmentationRequest;
    if (pendingRequest) {
        return pendingRequest;
    }

    // It's faster to load the cache from window than from localStorage, so get
    // it from there when it's available.
    const cacheFromWindow = root.memberSegmentation;
    if (cacheFromWindow && !isSegmentationOutdated(cacheFromWindow.lastUpdated)) {
        return cacheFromWindow.data;
    }

    const cacheFromLocalStorage = localStorage.getItem(
        MEMBER_SEGMENTATION_LOCALSTORAGE_KEY,
    );

    if (cacheFromLocalStorage) {
        try {
            const parsedCache: CachedMemberSegmentation =
                JSON.parse(cacheFromLocalStorage);
            if (!isSegmentationOutdated(parsedCache.lastUpdated)) {
                // Cache doesn't already exist on window because this is the
                // first time it's loaded in this tab, so set it there so we can
                // load it from window next time.
                root.memberSegmentation = parsedCache;

                return parsedCache.data;
            }
        } catch (error: unknown) {
            localStorage.removeItem(MEMBER_SEGMENTATION_LOCALSTORAGE_KEY);
            log.error("Could not parse member segmentation data from localstorage.");
        }
    }

    const request = fetchAuthorized<MemberSegmentation>(
        MEMBER_SEGMENTATION_SERVICE_URL,
        undefined,
        {memberFlowEnabled: false},
    )
        .then((res) => {
            delete root.memberSegmentationRequest;

            if (root.memberSegmentationRequestAborted) {
                return undefined;
            }

            const {data} = res;

            const cache: CachedMemberSegmentation = {
                lastUpdated: getTodaysDateInCacheFormat(),
                data,
            };

            root.memberSegmentation = cache;
            localStorage.setItem(
                MEMBER_SEGMENTATION_LOCALSTORAGE_KEY,
                JSON.stringify(cache),
            );

            return data;
        })
        .catch((error: unknown) => {
            delete root.memberSegmentationRequest;

            // Failing to fetch the data because the user that's cached in
            // the browser is unauthorized in the backend is an expected
            // error and we don't need to log that.
            const cachedUserWasUnauthorized =
                error instanceof Error && error.message === "Unauthorized";
            if (!cachedUserWasUnauthorized) {
                log.error("Error when fetching member segmentation data.", {
                    error: serializeError(error),
                });
            }

            // We still want to throw the error even if it's an expected error
            // so that the caller is aware that we failed to fetch the data.
            throw error;
        });

    root.memberSegmentationRequest = request;

    return request;
};
