import * as React from "react";
import * as Redux from "react-redux";
import {Link} from "react-router-dom";
import type {SerializedStyles} from "@emotion/react";
import {trackGlobalEvent} from "@atg-global-shared/analytics";
import type {Verticals} from "@atg-global-shared/verticals";
import {useVerticals, VerticalsActions} from "@atg-global-shared/verticals";
import {Typography} from "atg-ui-components";
import * as styles from "./HeaderVerticalLinks.styles";

export type Props = {
    isMobile?: boolean;
    style?: SerializedStyles | Array<SerializedStyles>;
};

const trackingEvent = "verticalNavigationClickEvent";

function HeaderVerticalLinks({isMobile = false, style}: Props) {
    const vertical = useVerticals();
    const dispatch = Redux.useDispatch();

    return (
        <ul css={[styles.wrapper, style]}>
            <li>
                <Link
                    to="/"
                    onClick={() => {
                        dispatch(VerticalsActions.unlockContextAction());
                        trackGlobalEvent({
                            event: trackingEvent,
                            target: isMobile
                                ? "verticalNavigationClick_mobile_header_horse"
                                : "verticalNavigationClick_header_horse",
                        });
                    }}
                    css={styles.link(vertical === "horse")}
                    data-test-id={
                        isMobile ? "mobile-header-horse" : "header-verticallink-horse"
                    }
                >
                    <Typography component="span" variant="headline3" color="inherit">
                        Häst
                    </Typography>
                </Link>
            </li>

            <li>
                <Link
                    to="/sport"
                    onClick={() => {
                        dispatch(VerticalsActions.unlockContextAction());
                        trackGlobalEvent({
                            event: trackingEvent,
                            target: isMobile
                                ? "verticalNavigationClick_mobile_header_sport"
                                : "verticalNavigationClick_header_sport",
                        });
                    }}
                    css={styles.link(vertical === "sport")}
                    data-test-id={
                        isMobile ? "mobile-header-sport" : "header-verticallink-sport"
                    }
                >
                    <Typography component="span" variant="headline3" color="inherit">
                        Sport
                    </Typography>
                </Link>
            </li>

            <li>
                <Link
                    to="/casino"
                    onClick={() => {
                        dispatch(VerticalsActions.unlockContextAction());
                        trackGlobalEvent({
                            event: trackingEvent,
                            target: isMobile
                                ? "verticalNavigationClick_mobile_header_casino"
                                : "verticalNavigationClick_header_casino",
                        });
                    }}
                    css={styles.link(vertical === "casino")}
                    data-test-id={
                        isMobile ? "mobile-header-casino" : "header-verticallink-casino"
                    }
                >
                    <Typography component="span" variant="headline3" color="inherit">
                        Casino
                    </Typography>
                </Link>
            </li>
        </ul>
    );
}

export default HeaderVerticalLinks;
