import {delay, isEqual} from "lodash";
import root from "window-or-global";
import type {Store} from "redux";
import type {LazyStore} from "@atg-shared/lazy-store";
import atgRequest from "@atg-shared/fetch";
import log from "@atg-shared/log";
import * as ClientVersionActions from "./clientVersionActions";

const INTERVAL = 2 * 60 * 1000; // Every second minute

type ClientVersionResponse = {
    status: string;
    systime: string;
    uptime: number;
    version: string;
};

type StoreType = Store<any, any> | LazyStore<any, any>;

const checkClientVersion = (store: StoreType) => {
    atgRequest<ClientVersionResponse>("/client-version").then((response) => {
        if (
            root.clientVersions &&
            response.data &&
            response.data.version &&
            !isEqual(response.data.version, root.clientVersions)
        ) {
            log.warn("ClientVersionCheck: New Update", {
                currentVersions: root.clientVersions,
                newVersion: response.data.version,
            });
            store.dispatch(ClientVersionActions.updateClientVersion());
        } else {
            delay(checkClientVersion, INTERVAL, store);
        }
    });
};

function startVersionCheck(store: StoreType) {
    delay(() => checkClientVersion(store), INTERVAL);
}

export default startVersionCheck;
