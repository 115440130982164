import * as LoginTypes from "@atg-login-shared/types";
import type * as EIDActions from "./eidActions";

export type CurityInitUNPW = {
    type: LoginTypes.ActionTypes.CURITY_INIT_UNPW;
    payload?: LoginTypes.UnpwCredentials;
};

export type LoginCredentialsRequestStart = {
    type: LoginTypes.ActionTypes.LOGIN_CREDENTIALS_REQUEST_START;
};

export type LoginCredentialsRequestFinish = {
    type: LoginTypes.ActionTypes.LOGIN_CREDENTIALS_REQUEST_FINISH;
};

export type CuritySubmitUNPW = {
    type: LoginTypes.ActionTypes.CURITY_SUBMIT_UNPW;
    payload: LoginTypes.UnpwCredentials;
};

export type CurityResetPassword = {
    type: LoginTypes.ActionTypes.RESET_PASSWORD;
    payload: LoginTypes.ResetPassword;
};

export type VerifySms = {
    type: LoginTypes.ActionTypes.VERIFY_SMS;
    payload: LoginTypes.OTP;
};
export type GenerateNewSmsCode = {
    type: LoginTypes.ActionTypes.GENERATE_NEW_SMS_CODE;
};

export type RestartFlow = {
    type: LoginTypes.ActionTypes.RESTART_FLOW;
    payload: LoginTypes.CurityState;
};

export type SetCurityState = {
    type: LoginTypes.ActionTypes.SET_CURITY_STATE;
    payload: LoginTypes.CurityState;
};

export type SetCurityError = {
    type: LoginTypes.ActionTypes.SET_CURITY_ERROR;
    payload: LoginTypes.CurityError;
};

type GetCurityState = {
    type: LoginTypes.ActionTypes.GET_CURITY_STATE;
};

export type ResetCurityState = {
    type: LoginTypes.ActionTypes.RESET_CURITY_STATE;
};

export type SetCurityResponse = {
    type: LoginTypes.ActionTypes.SET_CURITY_RESPONSE;
    payload: LoginTypes.CurityRootModel;
};

export type SetCurityResponseSuccess = {
    type: LoginTypes.ActionTypes.SET_CURITY_RESPONSE_SUCCESS;
};

export type SetAuthenticators = {
    type: LoginTypes.ActionTypes.SET_AUTHENTICATORS;
    payload: LoginTypes.Actions;
};

export type SetAuthContext = {
    type: LoginTypes.ActionTypes.SET_AUTH_CONTEXT;
    payload: LoginTypes.RecoveryState | LoginTypes.AuthenticatorTypes | null;
};

export type SetHideBalanceLoginFlow = {
    type: LoginTypes.ActionTypes.SET_HIDE_BALANCE_LOGIN_FLOW;
    payload: boolean;
};

export type GetCurityAuthenticators = {
    type: LoginTypes.ActionTypes.GET_CURITY_AUTHENTICATORS;
};

export type SelectAuthMethod = {
    type: LoginTypes.ActionTypes.SELECT_AUTH_METHOD;
};

export type SetAuthMethod = {
    type: LoginTypes.ActionTypes.SET_AUTH_METHOD;
    payload: LoginTypes.AuthMethod;
};

export type SetTempAuthMethod = {
    type: LoginTypes.ActionTypes.SET_TEMP_AUTH_METHOD;
    payload: Omit<LoginTypes.AuthMethod, "temp">;
};

export type ActivateUser = {
    type: LoginTypes.ActionTypes.ACTIVATE_USER;
    payload: LoginTypes.ContactInfo;
};

export type SetAuthMode = {
    type: LoginTypes.ActionTypes.SET_AUTH_MODE;
    payload: LoginTypes.AuthMode;
};
// ------RECOVERY------
export type SetRecoveryMethod = {
    type: LoginTypes.RecoveryActionTypes.SET_RECOVERY_METHOD;
    payload: LoginTypes.RecoveryState;
};

export const initUNPW = (payload?: LoginTypes.UnpwCredentials): CurityInitUNPW => ({
    type: LoginTypes.ActionTypes.CURITY_INIT_UNPW,
    payload,
});

export const resetPassword = (
    payload: LoginTypes.ResetPassword,
): CurityResetPassword => ({
    type: LoginTypes.ActionTypes.RESET_PASSWORD,
    payload,
});

export const restartFlow = (payload: LoginTypes.CurityState): RestartFlow => ({
    type: LoginTypes.ActionTypes.RESTART_FLOW,
    payload,
});

export const setCurityState = (payload: LoginTypes.CurityState): SetCurityState => ({
    type: LoginTypes.ActionTypes.SET_CURITY_STATE,
    payload,
});

export const setCurityError = (payload: LoginTypes.CurityError): SetCurityError => ({
    type: LoginTypes.ActionTypes.SET_CURITY_ERROR,
    payload,
});

export const getCurityState = (): GetCurityState => ({
    type: LoginTypes.ActionTypes.GET_CURITY_STATE,
});

export const resetCurityState = (): ResetCurityState => ({
    type: LoginTypes.ActionTypes.RESET_CURITY_STATE,
});

export const setCurityResponse = (
    payload: LoginTypes.CurityRootModel,
): SetCurityResponse => ({
    type: LoginTypes.ActionTypes.SET_CURITY_RESPONSE,
    payload,
});

export const setCurityResponseSuccess = (): SetCurityResponseSuccess => ({
    type: LoginTypes.ActionTypes.SET_CURITY_RESPONSE_SUCCESS,
});

export const getCurityAuthenticators = (): GetCurityAuthenticators => ({
    type: LoginTypes.ActionTypes.GET_CURITY_AUTHENTICATORS,
});

export const setAuthenticators = (payload: LoginTypes.Actions): SetAuthenticators => ({
    type: LoginTypes.ActionTypes.SET_AUTHENTICATORS,
    payload,
});

export const selectAuthMethod = (): SelectAuthMethod => ({
    type: LoginTypes.ActionTypes.SELECT_AUTH_METHOD,
});

export const loginCredentialsRequestStart = (): LoginCredentialsRequestStart => ({
    type: LoginTypes.ActionTypes.LOGIN_CREDENTIALS_REQUEST_START,
});

export const loginCredentialsRequestFinish = (): LoginCredentialsRequestFinish => ({
    type: LoginTypes.ActionTypes.LOGIN_CREDENTIALS_REQUEST_FINISH,
});

export const setAuthMethod = (payload: LoginTypes.AuthMethod): SetAuthMethod => ({
    type: LoginTypes.ActionTypes.SET_AUTH_METHOD,
    payload,
});

export const setTempAuthMethod = (payload: LoginTypes.AuthMethod): SetTempAuthMethod => ({
    type: LoginTypes.ActionTypes.SET_TEMP_AUTH_METHOD,
    payload,
});

export const setAuthContext = (
    payload: LoginTypes.RecoveryState | LoginTypes.AuthenticatorTypes | null,
): SetAuthContext => ({
    type: LoginTypes.ActionTypes.SET_AUTH_CONTEXT,
    payload,
});

export const setHideBalanceLoginFlow = (payload: boolean): SetHideBalanceLoginFlow => ({
    type: LoginTypes.ActionTypes.SET_HIDE_BALANCE_LOGIN_FLOW,
    payload,
});

export const setActivateUser = (payload: LoginTypes.ContactInfo): ActivateUser => ({
    type: LoginTypes.ActionTypes.ACTIVATE_USER,
    payload,
});

export const setAuthMode = (payload: LoginTypes.AuthMode): SetAuthMode => ({
    type: LoginTypes.ActionTypes.SET_AUTH_MODE,
    payload,
});

export const submitUNPW = (payload: LoginTypes.UnpwCredentials): CuritySubmitUNPW => ({
    type: LoginTypes.ActionTypes.CURITY_SUBMIT_UNPW,
    payload,
});

export const verifySms = (payload: LoginTypes.OTP): VerifySms => ({
    type: LoginTypes.ActionTypes.VERIFY_SMS,
    payload,
});

export const generateNewSmsCode = (): GenerateNewSmsCode => ({
    type: LoginTypes.ActionTypes.GENERATE_NEW_SMS_CODE,
});

export const setRecoveryMethod = (
    payload: LoginTypes.RecoveryState,
): SetRecoveryMethod => ({
    type: LoginTypes.RecoveryActionTypes.SET_RECOVERY_METHOD,
    payload,
});

export type CurityLoginAction =
    | CurityInitUNPW
    | EIDActions.InitBankID
    | EIDActions.InitBankIDWithPersonalNumber
    | EIDActions.InitFrejaID;

export type CurityAction =
    | CurityInitUNPW
    | CuritySubmitUNPW
    | GetCurityState
    | RestartFlow
    | SetCurityState
    | ResetCurityState
    | SetCurityResponse
    | SetCurityResponseSuccess
    | VerifySms
    | GenerateNewSmsCode
    | SetAuthenticators
    | SetAuthContext
    | SelectAuthMethod
    | SetAuthMethod
    | SetTempAuthMethod
    | GetCurityAuthenticators
    | SetCurityError
    | CurityResetPassword
    | ActivateUser
    | SetHideBalanceLoginFlow
    | SetAuthMode
    | LoginCredentialsRequestStart
    | LoginCredentialsRequestFinish;
