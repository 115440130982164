import atgRequest, {pureFetch} from "@atg-shared/fetch";
import type {AtgResponse} from "@atg-shared/fetch-types";
import {USER_SERVICE_URL, getCurityConfig} from "@atg-shared/service-url";
import type {AccessTokenPayload} from "./accessTokenActions";
import type {Roles} from "./authRoles";

export type AuthResponse = {
    username: string;
    roles: Roles;
};

export function checkAuth(token?: string): Promise<AtgResponse<AuthResponse>> {
    return atgRequest<AuthResponse>(
        `${USER_SERVICE_URL}/auth`,
        {
            method: "GET",
        },
        token,
    );
}

export function checkAuthTillsammans(token?: string): Promise<AtgResponse<AuthResponse>> {
    const {tokenHandlerUrl} = getCurityConfig();
    return atgRequest<AuthResponse>(
        `${tokenHandlerUrl}/user/auth`,
        {
            credentials: "include",
            method: "GET",
        },
        token,
    );
}

export const getAccessToken = (): Promise<AtgResponse<AccessTokenPayload>> => {
    const {tokenHandlerUrl} = getCurityConfig();
    return pureFetch<AccessTokenPayload>(`${tokenHandlerUrl}/access-token`, {
        // The refresh token, which is used to get a new access token, is stored
        // in a cookie. This tells the browser to include cookies in the
        // request.
        //
        // You can read about how the refresh and access tokens work here:
        // https://developer.atg.se/frontend/authentication/authentication.html
        credentials: "include",
    });
};
