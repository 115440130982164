import type * as React from "react";
import {deepmerge} from "@mui/utils";
import type {PaletteOptions as PaletteOptionsTypes} from "@mui/material/styles";
import {
    type TypographyOptions as TypographyOptionsTypes,
    type TypographyStyle,
} from "@mui/material/styles/createTypography";

export interface Variants {
    body1: TypographyStyle;
    body2: TypographyStyle;
    body3: TypographyStyle;
    /**
     * @deprecated
     */
    button: TypographyStyle;
    /**
     * @deprecated
     */
    caption: TypographyStyle;
    /**
     * @deprecated
     */
    h1Brand: TypographyStyle;
    /**
     * @deprecated
     */
    h2Brand: TypographyStyle;
    /**
     * @deprecated
     */
    h3Brand: TypographyStyle;
    /**
     * @deprecated
     */
    h4Brand: TypographyStyle;
    /**
     * @deprecated
     */
    h1: TypographyStyle;
    /**
     * @deprecated
     */
    h2: TypographyStyle;
    /**
     * @deprecated
     */
    h3: TypographyStyle;
    /**
     * @deprecated
     */
    h4: TypographyStyle;
    /**
     * @deprecated
     */
    h5: TypographyStyle;
    /**
     * @deprecated
     */
    h6: TypographyStyle;
    inherit: TypographyStyle;
    /**
     * @deprecated
     */
    overline: TypographyStyle;
    /**
     * @deprecated
     */
    subtitle1: TypographyStyle;
    /**
     * @deprecated
     */
    subtitle2: TypographyStyle;
    /**
     * @deprecated
     */
    subtitle3: TypographyStyle;
    /**
     * @deprecated
     */
    rubrik1: TypographyStyle;
    /**
     * @deprecated
     */
    rubrik2: TypographyStyle;
    /**
     * @deprecated
     */
    rubrik3: TypographyStyle;
    /**
     * @deprecated
     */
    rubrik4: TypographyStyle;
    title1: TypographyStyle;
    title2: TypographyStyle;
    title3: TypographyStyle;
    title4: TypographyStyle;
    title5: TypographyStyle;
    title6: TypographyStyle;
}

/**
 * Overrides for typography variant props can be found in "src/overrides/MuiTypography.ts"
 */
declare module "@mui/material/styles/createTypography" {
    interface Typography extends Variants {}

    interface TypographyOptions extends Partial<Variants> {
        fontFamilyPrimary?: TypographyStyle["fontFamily"];
        fontFamilySecondary?: TypographyStyle["fontFamily"];
        fontWeightSemibold?: TypographyStyle["fontWeight"];
    }

    interface TypographyOptions {
        fontFamilyPrimary?: TypographyStyle["fontFamily"];
        fontFamilySecondary?: TypographyStyle["fontFamily"];
        fontWeightSemibold?: TypographyStyle["fontWeight"];
    }
}

const caseAllCaps: Record<string, React.CSSProperties["textTransform"]> = {
    textTransform: "uppercase",
};

export default function createTypography(
    palette: PaletteOptionsTypes,
    typography:
        | TypographyOptionsTypes
        | ((palette: PaletteOptionsTypes) => TypographyOptionsTypes),
) {
    const {
        fontFamilyPrimary = '"Roboto", "Helvetica", "Arial", sans-serif',
        fontFamilySecondary = '"ATGRubrik", "Helvetica", "Arial", sans-serif',
        // The default font size of the Material Specification.
        fontSize = 14, // px
        fontWeightLight = 300,
        fontWeightRegular = 400,
        fontWeightMedium = 500,
        fontWeightSemibold = 600,
        fontWeightBold = 700,
        // Tell MUI what's the font-size on the html element.
        // 16px is the default font-size used by browsers.
        htmlFontSize = 16,
        // Apply the CSS properties to all the variants.
        allVariants,
        ...other
    } = typeof typography === "function" ? typography(palette) : typography;

    const coef = fontSize / 14;
    const pxToRem = (size: number) => `${(size / htmlFontSize) * coef}rem`;
    const buildVariant = (
        typeFace: React.CSSProperties["fontFamily"],
        fontWeight: React.CSSProperties["fontWeight"],
        size: number,
        lineHeight: React.CSSProperties["lineHeight"],
        letterSpacing: number,
        casing?: Record<string, unknown>,
    ) => ({
        fontFamily: typeFace,
        fontWeight,
        fontSize: pxToRem(size),
        // Unitless following http://meyerweb.com/eric/thoughts/2006/02/08/unitless-line-heights/
        lineHeight,
        letterSpacing: pxToRem(letterSpacing), // `${letterSpacing}em`,
        ...casing,
        ...allVariants,
    });

    const variants = {
        h1Brand: buildVariant(
            fontFamilySecondary,
            fontWeightMedium,
            56,
            1.14,
            0,
            caseAllCaps,
        ),
        h2Brand: buildVariant(
            fontFamilySecondary,
            fontWeightMedium,
            44,
            1.2,
            0,
            caseAllCaps,
        ),
        h3Brand: buildVariant(
            fontFamilySecondary,
            fontWeightMedium,
            36,
            1.22,
            0,
            caseAllCaps,
        ),
        h4Brand: buildVariant(
            fontFamilySecondary,
            fontWeightBold,
            20,
            1.4,
            0,
            caseAllCaps,
        ),
        rubrik1: buildVariant(
            fontFamilySecondary,
            fontWeightBold,
            48,
            1.16667,
            0,
            caseAllCaps,
        ),
        rubrik2: buildVariant(
            fontFamilySecondary,
            fontWeightBold,
            32,
            1.25,
            0,
            caseAllCaps,
        ),
        rubrik3: buildVariant(
            fontFamilySecondary,
            fontWeightBold,
            24,
            1.17,
            0,
            caseAllCaps,
        ),
        rubrik4: buildVariant(
            fontFamilySecondary,
            fontWeightBold,
            20,
            1.2,
            0,
            caseAllCaps,
        ),
        // Default
        h1: buildVariant(fontFamilyPrimary, fontWeightLight, 96, 1.167, -1.5),
        h2: buildVariant(fontFamilyPrimary, fontWeightLight, 60, 1.2, -0.5),
        h3: buildVariant(fontFamilyPrimary, fontWeightRegular, 48, 1.167, 0),
        h4: buildVariant(fontFamilyPrimary, fontWeightRegular, 34, 1.235, 0.25),
        h5: buildVariant(fontFamilyPrimary, fontWeightRegular, 24, 1.334, 0),
        h6: buildVariant(fontFamilyPrimary, fontWeightMedium, 20, 1.6, 0.15),
        subtitle1: buildVariant(fontFamilyPrimary, fontWeightMedium, 16, 1.75, 0.15),
        subtitle2: buildVariant(fontFamilyPrimary, fontWeightMedium, 14, 1.57, 0.1),
        subtitle3: buildVariant(
            fontFamilyPrimary,
            fontWeightMedium,
            12,
            1.66,
            0.4,
            caseAllCaps,
        ),
        button: buildVariant(fontFamilyPrimary, fontWeightMedium, 14, 1.75, 0.4, {
            textTransform: "none",
        }),
        caption: buildVariant(fontFamilyPrimary, fontWeightRegular, 12, 1.33333, 0),
        overline: buildVariant(
            fontFamilyPrimary,
            fontWeightRegular,
            12,
            2.66,
            1,
            caseAllCaps,
        ),
        title1: buildVariant(fontFamilyPrimary, fontWeightMedium, 32, 1.25, 0),
        title2: buildVariant(fontFamilyPrimary, fontWeightMedium, 28, 1.28571, 0),
        title3: buildVariant(fontFamilyPrimary, fontWeightMedium, 24, 1.16667, 0),
        title4: buildVariant(fontFamilyPrimary, fontWeightMedium, 20, 1.2, 0),
        title5: buildVariant(fontFamilyPrimary, fontWeightMedium, 16, 1.375, 0.2),
        title6: buildVariant(fontFamilyPrimary, fontWeightMedium, 14, 1.42857, 0.2),
        body1: buildVariant(fontFamilyPrimary, fontWeightRegular, 16, 1.375, 0),
        body2: buildVariant(fontFamilyPrimary, fontWeightRegular, 14, 1.428, 0),
        body3: buildVariant(fontFamilyPrimary, fontWeightRegular, 12, 1.33333, 0),
    };

    // const typographyOutput: TypographyOptionsTypes = deepmerge(
    const typographyOutput = deepmerge(
        {
            htmlFontSize,
            pxToRem,
            fontFamilyPrimary,
            fontFamilySecondary,
            fontSize,
            fontWeightLight,
            fontWeightRegular,
            fontWeightMedium,
            fontWeightSemibold,
            fontWeightBold,
            // Mui uses standalone `fontFamily` internally.
            fontFamily: fontFamilyPrimary,
            ...variants,
        },
        other,
        {
            clone: false, // No need to clone deep
        },
    );

    return typographyOutput;
}
