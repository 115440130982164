import {frameAction, broadcastAction} from "atg-store-addons";
import {
    OPEN_SIDE_MENU,
    OPEN_DROPDOWN_MENU,
    CLOSE_SIDE_MENU,
    CLOSE_DROPDOWN_MENU,
    CLOSE_ALL_MENUS,
} from "../constants/mainMenuActionTypes";

export type OpenMenuAction = {
    type: typeof OPEN_SIDE_MENU | typeof OPEN_DROPDOWN_MENU;
    payload: {
        key: string;
    };
};

export type CloseMenuAction = {
    type: typeof CLOSE_SIDE_MENU | typeof CLOSE_DROPDOWN_MENU | typeof CLOSE_ALL_MENUS;
};

export type MainMenuActions = OpenMenuAction | CloseMenuAction;

export const openSideMenu = (key: string): OpenMenuAction =>
    frameAction(
        broadcastAction({
            type: OPEN_SIDE_MENU,
            payload: {
                key,
            },
        }),
    );

export const openDropdownMenu = (key: string): OpenMenuAction =>
    frameAction({
        type: OPEN_DROPDOWN_MENU,
        payload: {
            key,
        },
    });

export const closeSideMenu = (): CloseMenuAction =>
    frameAction(
        // We have to broadcast this action to update the side menu state
        // in the "global" and "horse" stores.
        // https://jira-atg.riada.cloud/browse/ATGSEDIG-48408
        broadcastAction({
            type: CLOSE_SIDE_MENU,
        }),
    );

export const closeDropdownMenu = (): CloseMenuAction =>
    frameAction({
        type: CLOSE_DROPDOWN_MENU,
    });

export const closeAllMenus = (): CloseMenuAction =>
    frameAction({
        type: CLOSE_ALL_MENUS,
    });
