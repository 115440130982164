import {values} from "lodash/fp";
import {createSelector} from "reselect";
import type {ToastContainerType, ToastWithId} from "./toast";
import {type State} from "./index";

type Selector<T> = (state: State) => T;

export const getActiveToasts = (state: State): ToastWithId[] => values(state.toasts);

export const getFilteredActiveToastsByContainerId = (
    containerId?: ToastContainerType,
): Selector<ToastWithId[]> =>
    createSelector(getActiveToasts, (toasts) =>
        containerId
            ? toasts.filter((toast) => toast.containerId === containerId)
            : toasts.filter((toast) => !toast.containerId),
    );
