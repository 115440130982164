import * as React from "react";
import {ComponentLoader} from "@atg-shared/lazy";

function LazyModalFrame() {
    return (
        <ComponentLoader
            loader={import(
                /* webpackChunkName: "ModalFrame" */
                "./ModalFrame"
            )}
            placeholder={null}
        />
    );
}

export default LazyModalFrame;
