import * as React from "react";

import * as styled from "./VideoFramePlaceholder.styles";

export function VideoFramePlaceholder() {
    return (
        <styled.frame>
            <styled.frameContent useTopMargin={false}>
                <styled.videoColumn expanded={false}>
                    <styled.video />
                </styled.videoColumn>
                <styled.livePageColumn expanded={false}>
                    <styled.pinStyles>
                        <styled.icon>
                            <styled.custumStyledPinIcon />

                            <styled.custumStyledArrowIcon />
                        </styled.icon>
                    </styled.pinStyles>

                    <styled.watchGameButton disabled>
                        <styled.watchGameLabel>Titta och spela</styled.watchGameLabel>
                    </styled.watchGameButton>
                </styled.livePageColumn>
            </styled.frameContent>
        </styled.frame>
    );
}
