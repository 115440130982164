import * as React from "react";
import {Button, Typography} from "@atg-ui/toolkit";
import {Broken} from "atg-ui-components/icons";
import {
    Wrapper,
    NotFoundIcon,
    brokenIcon,
    Headline,
    RedirectionWrapper,
    errorText,
} from "./ErrorFallback.styles";

export function ErrorFallback() {
    return (
        <Wrapper>
            <NotFoundIcon>
                <Broken style={brokenIcon} />
            </NotFoundIcon>
            <Headline gutterBottom>Hoppla, något gick fel!</Headline>
            <Typography css={errorText}>
                Ett tillfälligt fel verkar ha inträffat. Prova att ladda om sidan eller
                försök igen senare.
            </Typography>
            <RedirectionWrapper>
                <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => window.location.reload()}
                >
                    Ladda om sidan
                </Button>
            </RedirectionWrapper>
        </Wrapper>
    );
}

export default ErrorFallback;
