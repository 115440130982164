import type Hls from "hls.js";
import type {HlsConfig} from "hls.js";
import type {VideoMeta} from "@atg-play-shared/types";

export enum SkipDirections {
    SKIP_BACKWARD = "skipBackward",
    SKIP_FORWARD = "skipForward",
}

export enum PlayerBrands {
    CHROMECAST = "chromecast",
    HLS_JS = "hlsjs",
    SHAKA = "shaka",
}

export enum VideoEventKeys {
    onEnded = "onEnded",
    play = "play",
    pause = "pause",
    timeupdate = "timeupdate",
    durationchange = "durationchange",
    seeked = "seeked",
    leavepictureinpicture = "leavepictureinpicture",
}

export enum VideoHtml5EventKeys {
    onEnded = "ended",
    play = "play",
    pause = "pause",
    timeupdate = "timeupdate",
    durationchange = "durationchange",
    seeked = "seeked",
    leavepictureinpicture = "leavepictureinpicture",
}

export enum TypesOfMediaContent {
    archive = "archive",
    warmup = "warmup",
    live = "live",
    contentful = "contentful",
}

export const qualityLevels = [
    {name: "Mobilvänlig", level: 0},
    {name: "Standard", level: 1},
    {name: "Hög", level: 2},
] as const;

export type QualityLevel = (typeof qualityLevels)[number] | undefined | null;

export type SpecialPlayerVariants = "normal" | "audio-only" | "vindral";

export type Props = {
    isLive: boolean;
    autoplay?: boolean;
    src: string | null;
    mediaElementRef: HTMLVideoElement;
    updateStateIsBuffering: (isBuffering: boolean) => void;
    loadFinishedCallback?: () => void;
    levelLoadedCallback?: (targetLatency: number) => void;
    videoMetaData?: VideoMeta;
    config?: Partial<HlsConfig>;
    safariPlaybackBlockedStateChanged?: (value: boolean) => void;
};

export const QUALITY_LEVEL_LOCAL_STORAGE_KEY = "video-quality-level-v1";
export const VIDEO_VOLUME_LOCAL_STORAGE_KEY = "video-volume-level-v1";
export const VIDEO_MUTED_LOCAL_STORAGE_KEY = "video-muted-v1";

export interface PlayerEngine {
    engineName: PlayerBrands;
    load(source: string, autoplay?: boolean, startLevel?: QualityLevel): Promise<void>;
    destroy(): void;
    play(): void;
    pause(): void;
    seek(newTime: number): void;
    mute(): void;
    unmute(): void;
    isPlaying(): boolean;
    addEventListener(event: string, callBack: () => void): void;
    removeEventListener(event: string, callBack: () => void): void;
    getVolumePercentage(): number;
    getCurrentTime(): number;
    getInternalPlayer():
        | HTMLVideoElement
        | cast.framework.RemotePlayer
        | cast.framework.RemotePlayerController
        | null;

    setVolume(volumePercentage: number): void;
    setQualityLevel?(qualityLevel?: QualityLevel): void;
    getQualityLevel?(): number;
    requestPictureInPicture?(): void;
    setPlaybackRate?(playbackRate: number): void;
    getEngine?(): Hls | any;
    getLatency?(): number;
    getPlayingDateTime?(): number;
    getLiveSyncPosition?(): number | null;
}
