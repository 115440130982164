const red = {
    50: "#FCE8EA",
    100: "#F7BAC0",
    200: "#EF7681",
    300: "#E72E3F",
    400: "#E51A2D",
    500: "#CE1828",
    600: "#C00A26",
    700: "#A60D1F",
    800: "#8E0B1A",
    900: "#760916",
};

export default red;
