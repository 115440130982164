import type {SagaIterator} from "redux-saga";
import {put, call, take, select, takeLatest} from "redux-saga/effects";
import root from "window-or-global";
import type {AuthActions} from "@atg-shared/auth";
import browserHistory from "atg-history";
import {LOGIN_FINISHED} from "@atg-global-shared/user/userActionTypes";
import * as MemberFlowTypes from "./memberFlowType";
import * as MemberSelectors from "./memberSelectors";
import {
    START_LOGIN_FLOW,
    START_REGISTER_FLOW,
    CANCELLED_LOGIN_FLOW,
    MEMBER_REGISTER_CANCEL,
    startLoginFlow,
    startRegisterFlow,
} from "./memberActions";

export function* handleCancelingOfRegisterFlow(): SagaIterator {
    const flowOptions: AuthActions.MemberFlowOptions = yield select(
        MemberSelectors.getFlowOptions,
    );
    yield take(MEMBER_REGISTER_CANCEL);

    yield put(startRegisterFlow(flowOptions));
}

export function* handleRegisterPageFlow(): SagaIterator {
    const flowType: MemberFlowTypes.FlowType = yield select(MemberSelectors.getFlowType);
    if (
        flowType.type === MemberFlowTypes.MODAL_FLOW_TYPE ||
        flowType.type === MemberFlowTypes.INLINE_FLOW_TYPE
    )
        return;
    yield call(browserHistory.push, flowType.registerPath);
    yield call(handleCancelingOfRegisterFlow);
}

export function* handleCancelingOfLoginFlow(): SagaIterator {
    const flowOptions: AuthActions.MemberFlowOptions = yield select(
        MemberSelectors.getFlowOptions,
    );
    yield take(CANCELLED_LOGIN_FLOW);
    yield put(startLoginFlow(flowOptions));
}

export function* handleLoginPageFlow(): SagaIterator {
    const flowType: MemberFlowTypes.FlowType = yield select(MemberSelectors.getFlowType);
    if (
        flowType.type === MemberFlowTypes.MODAL_FLOW_TYPE ||
        flowType.type === MemberFlowTypes.INLINE_FLOW_TYPE
    )
        return;
    yield call(browserHistory.push, flowType.loginPath);
    yield call(handleCancelingOfLoginFlow);
}

export const updateWindowLocation = (href: string) => {
    root.location.href = href;
};

export function* redirectOnSuccess(): SagaIterator {
    const flowType: MemberFlowTypes.FlowType = yield select(MemberSelectors.getFlowType);
    if (flowType.type !== MemberFlowTypes.PAGE_FLOW_TYPE) return;

    if (flowType.successPathRedirectMethod === "location") {
        yield call(updateWindowLocation, flowType.successPath);
    } else {
        yield call(browserHistory.push, flowType.successPath);
    }
}

export default function* memberFlowSaga() {
    yield takeLatest(START_LOGIN_FLOW, handleLoginPageFlow);
    yield takeLatest(START_REGISTER_FLOW, handleRegisterPageFlow);
    yield takeLatest(LOGIN_FINISHED, redirectOnSuccess);
}
