import type {FetchReducer, FetchState} from "@atg-shared/fetch-types";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {
    Action,
    UnauthorizedLoginAlertPayload,
} from "./UnauthorizedLoginAlertActions";
import {
    REQUEST_LATEST_LOGIN_DETAILS,
    RECEIVE_LATEST_LOGIN_DETAILS,
    RESET_LATEST_LOGIN_DETAILS,
} from "./UnauthorizedLoginAlertActions";

export type State = FetchState<UnauthorizedLoginAlertPayload>;

const defaultState: UnauthorizedLoginAlertPayload = {
    result: null,
    timestamp: null,
};

// @ts-expect-error: suppressed error from Flow to TS migration
const unauthorizedLoginAttemptAlerts: FetchReducer<
    UnauthorizedLoginAlertPayload,
    Action
> = createFetchReducer(
    REQUEST_LATEST_LOGIN_DETAILS,
    RECEIVE_LATEST_LOGIN_DETAILS,
    undefined,
    (state: UnauthorizedLoginAlertPayload, action: Action) => {
        switch (action.type) {
            case RECEIVE_LATEST_LOGIN_DETAILS:
                if (action.error) return state;

                // On the fist login attempt for new user payload is empty
                if (!action.payload.result && !action.payload.timestamp) {
                    return defaultState;
                }

                return {
                    result: action.payload.result,
                    timestamp: action.payload.timestamp,
                };

            case RESET_LATEST_LOGIN_DETAILS:
                return defaultState;

            default:
                return state;
        }
    },
    defaultState,
);

export default unauthorizedLoginAttemptAlerts;
