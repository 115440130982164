import {put, call, takeEvery} from "redux-saga/effects";
import log, {serializeError} from "@atg-shared/log";
import system from "@atg-shared/system";
import {
    type GamblingResult,
    type LatestSuccessfulLoginTime,
    constants,
} from "@atg-responsible-gambling-shared/user-gambling-summary-types";
import * as ModalActions from "atg-modals/modalActions";
import * as UserGamblingSummaryApi from "../api/userGamblingSummaryApi";
import * as UserGamblingSummaryActions from "../userGamblingSummaryActions";

const {GAMBLING_RESULT_FETCH, LATEST_SUCCESFUL_LOGIN_TIME_FETCH, NAR_CHECK_TRIGGER} =
    constants;

export function* fetchGamblingResult() {
    try {
        const response: GamblingResult = yield call(
            UserGamblingSummaryApi.fetchGamblingResult,
        );
        yield put(UserGamblingSummaryActions.gamblingResultSuccess(response));
    } catch (error: unknown) {
        log.error("error fetching GamblingResult", {error: serializeError(error)});
    }
}

export function* fetchLatestSuccessfulLoginTime() {
    try {
        const response: LatestSuccessfulLoginTime = yield call(
            UserGamblingSummaryApi.fetchLatestSuccessfulLoginTime,
        );
        yield put(UserGamblingSummaryActions.latestSuccessfulLoginTimeSuccess(response));
    } catch (error: unknown) {
        log.error("error fetching LatestSuccessfulLoginTime", {
            error: serializeError(error),
        });
    }
}

export function* fetchNarBlocked() {
    try {
        const response: boolean = yield call(UserGamblingSummaryApi.fetchNarBlocked);
        yield put(UserGamblingSummaryActions.narBlockedSuccess(response));
    } catch (error: unknown) {
        log.error("error fetching NarBlocked", {error: serializeError(error)});
    } finally {
        const userGamblingSummaryModalClosed = sessionStorage.getItem(
            "userGamblingSummaryModalClosed:tillsammans",
        );

        /**
         * If the user has not closed the modal, show user gambling summary modal.
         *
         * This is a temporary solution for tillsammans until we remove the old login flow.
         * The issue was founded during the new SSO implementation, more details can be found here: https://jira-atg.riada.cloud/browse/CUST-777
         */
        if (!userGamblingSummaryModalClosed) {
            yield put(ModalActions.showUserGamblingSummaryModal());
        }
    }
}

export default function* userGamblingSummarySaga() {
    yield takeEvery(GAMBLING_RESULT_FETCH, fetchGamblingResult);
    yield takeEvery(LATEST_SUCCESFUL_LOGIN_TIME_FETCH, fetchLatestSuccessfulLoginTime);
    yield takeEvery(NAR_CHECK_TRIGGER, fetchNarBlocked);
}
