import {css} from "@emotion/react";
import {
    sm,
    navbarDefaultLinkColor,
    xs,
    deprecated_xxsMax,
    atgWhite,
} from "atg-ui/css/variables.styles";

type TitleProps = {
    open?: boolean;
    active?: boolean;
};
export const hiddenCloseButton = css`
    color: ${atgWhite};
`;

export const title = ({open = false, active = false}: TitleProps) => css`
    ${open && `background: ${atgWhite}`};
    box-sizing: border-box;
    color: ${(open && navbarDefaultLinkColor) || (active && atgWhite) || "#C0D2E4"};
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 56px;
    padding: 0 16px;
    position: relative;
    text-decoration: none;
    transition: border ease-in-out 150ms, color ease-in-out 150ms;
    user-select: none;

    @media ${sm} {
        font-size: 14px;
        padding: 0 20px;
    }

    &:hover {
        color: ${open ? navbarDefaultLinkColor : atgWhite};
    }

    > * {
        color: inherit;
        text-decoration: inherit;
    }
`;

export const playButton = css`
    @media ${xs} {
        padding: 0 20px;
    }
`;

export const headerMenu = css`
    position: relative;
    user-select: none;
    -webkit-touch-callout: none;
`;

export const backdrop = css`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 399;
    background: rgba(0, 0, 0, 0.7);
`;

export const transparentBackdrop = css`
    ${backdrop};
    background: transparent;
    top: 60px;
`;

export const clickBlocker = css`
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
`;

export const scrollbars = css`
    width: 100%;
    height: 100%;
`;

export const scrollbarsThumb = css`
    background: rgba(255, 255, 225, 0);
    z-index: 3;
    position: relative;
    border-radius: 2px;
    width: 16px;
`;

export const dropdownMenu = css`
    background: ${atgWhite};
    position: absolute;
    z-index: 400;
    right: 0;
    box-shadow: 0px 3px 30px #000;
    clip: rect(0, 9999px, 9999px, -9999px);
`;

export const icon = (isSideMenuOpen = false) => css`
    margin-left: 8px;

    .circle {
        stroke: ${isSideMenuOpen ? navbarDefaultLinkColor : atgWhite};
    }

    .fill {
        fill: ${isSideMenuOpen ? navbarDefaultLinkColor : atgWhite};
    }

    @media ${deprecated_xxsMax} {
        margin-left: 4px;
    }
`;

export const menuOption = css`
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    white-space: nowrap;

    @media ${sm} {
        flex-direction: row;
    }
`;
