import {device} from "@atg/utils";
import pageVisibilityUtil from "@atg/utils/pageVisibilityUtil";
import {
    configureSolaceImport,
    configureConnectCallback,
    initSolace,
    connect,
    reconnect,
    disconnect,
    subscribe,
    resumeSubscriptions,
    pauseSubscriptions,
    onSessionEvent,
    getClient,
} from "./push";

// Lazy load solclientjs only when it is needed to make the site load faster since it's a large import
// eslint-disable-next-line no-underscore-dangle
const _solaceImport = () =>
    import(
        /* webpackChunkName: "solclientjs" */
        "solclientjs"
    ).then((solace) => solace.default);

configureSolaceImport(_solaceImport);

// Listeners for pausing subscriptions on devices other than computers.
if (device.isDevice()) {
    pageVisibilityUtil.onVisible(resumeSubscriptions);
    pageVisibilityUtil.onHidden(pauseSubscriptions);
}

// You must import things from atg-push through this index file, because it has
// been set up as a shared library inside
// `@atg-frame/singletons/index.ts`. If you would
// import from a deeper path (e.g. `atg-push/push`), then that import would
// become a separate bundle which would lead to issues when setting up the
// Solace client.
//
// Remember to also add the exports to the `index.native.ts` file so the index
// files are in sync.
export {
    // Export prefixed with _ because it should only be used by tests.
    _solaceImport,
    initSolace,
    connect,
    reconnect,
    disconnect,
    subscribe,
    getClient,
    onSessionEvent,
    configureConnectCallback,
};
export {
    checkVersionedPushMessageState,
    DROPPING_MESSAGE,
    REFETCH_NEEDED,
    PUSH_IN_SYNC,
} from "./checkVersionedPushMessage";

export type {Solace} from "./domain";
