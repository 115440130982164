import {deepmerge} from "@mui/utils";
import type {
    PaletteOptions as MuiPaletteOptions,
    PaletteColorOptions,
    TypeText as MuiTypeText,
    TypeBackground as MuiTypeBackground,
    TypeAction,
    PaletteColor,
} from "@mui/material/styles";
import {getContrastRatio} from "@mui/material";
import {alpha, lighten} from "@mui/material/styles";
import blue from "./colors/blue";
import common from "./colors/common";
import cyan from "./colors/cyan";
import deepgreen from "./colors/deepgreen";
import green from "./colors/green";
import grey from "./colors/grey";
import orange from "./colors/orange";
import pink from "./colors/pink";
import purple from "./colors/purple";
import red from "./colors/red";
import yellow from "./colors/yellow";

interface Gradient {
    horizontal: React.CSSProperties["backgroundImage"];
    vertical: React.CSSProperties["backgroundImage"];
}

interface Mode {
    text: MuiTypeText;
    textInverted: MuiTypeText;
    divider: string;
    background: MuiTypeBackground;
    action: TypeAction;
    primary: PaletteColor;
    secondary: PaletteColor;
    error: PaletteColor;
    warning: PaletteColor;
    info: PaletteColor;
    success: PaletteColor;
}

interface ModeOptions {
    text?: Partial<MuiTypeText>;
    textInverted?: Partial<MuiTypeText>;
    divider?: Partial<string>;
    background?: Partial<MuiTypeBackground>;
    action?: Partial<TypeAction>;
    primary?: PaletteColorOptions;
    secondary?: PaletteColorOptions;
    error?: PaletteColorOptions;
    warning?: PaletteColorOptions;
    info?: PaletteColorOptions;
    success?: PaletteColorOptions;
}

// use module agumentation for the theme to accept our custom color palette
declare module "@mui/material/styles" {
    interface TypeBackground {
        default: string;
        paper: string;
        info: string;
        success: string;
        error: string;
        warning: string;
        receipt: string;
        snackbar: string;
    }

    interface TypeText {
        hint: string;
        main: string;
        dark: string;
        contrastText: string;
        primary: string;
        secondary: string;
        disabled: string;
    }

    interface Palette {
        blue: typeof blue;
        cyan: typeof cyan;
        deepgreen: typeof deepgreen;
        green: typeof green;
        orange: typeof orange;
        purple: typeof purple;
        red: typeof red;
        pink: typeof pink;
        yellow: typeof yellow;
        V75: PaletteColor;
        V86: PaletteColor;
        GS75: PaletteColor;
        V64: PaletteColor;
        V65: PaletteColor;
        assorted: PaletteColor;
        big9: PaletteColor;
        gradients: {
            V75: Gradient;
            V86: Gradient;
            GS75: Gradient;
            V64: Gradient;
            V65: Gradient;
            assorted: Gradient;
            big9: Gradient;
        };
        text: TypeText;
        textInverted: TypeText;
    }

    interface PaletteOptions {
        blue?: Partial<typeof blue>;
        cyan?: Partial<typeof cyan>;
        deepgreen?: Partial<typeof deepgreen>;
        green?: Partial<typeof green>;
        orange?: Partial<typeof orange>;
        purple?: Partial<typeof purple>;
        red?: Partial<typeof red>;
        pink?: Partial<typeof pink>;
        yellow?: Partial<typeof yellow>;
        success?: PaletteColorOptions;
        info?: PaletteColorOptions;
        error?: PaletteColorOptions;
        warning?: PaletteColorOptions;
        V75?: PaletteColorOptions;
        V86?: PaletteColorOptions;
        GS75?: PaletteColorOptions;
        V64?: PaletteColorOptions;
        V65?: PaletteColorOptions;
        assorted?: PaletteColorOptions;
        big9?: PaletteColorOptions;
        gradients?: {
            V75?: Partial<Gradient>;
            V86?: Partial<Gradient>;
            GS75?: Partial<Gradient>;
            V64?: Partial<Gradient>;
            V65?: Partial<Gradient>;
            assorted?: Partial<Gradient>;
            big9?: Partial<Gradient>;
        };
        text?: Partial<TypeText>;
        textInverted?: Partial<TypeText>;
        modes?: {
            dark?: Partial<ModeOptions>;
            light?: Partial<ModeOptions>;
        };
    }
}

// ATG custom color swatches
const colors = {
    blue,
    cyan,
    deepgreen,
    green,
    orange,
    pink,
    purple,
    red,
    yellow,
};

// palette for dark text – used against bright backgrounds
export const darkText: MuiTypeText = {
    primary: alpha(common.black, 0.87),
    secondary: alpha(common.black, 0.6),
    disabled: alpha(common.black, 0.38),
    hint: alpha(grey[900], 0.32),
    main: common.black,
    dark: alpha(common.black, 0.8), // Mui uses `dark` for hover.
    contrastText: common.white,
};

// palette for light text – used against dark backgrounds
export const lightText: MuiTypeText = {
    primary: common.white,
    secondary: alpha(common.white, 0.7),
    disabled: alpha(grey[900], 0.5),
    hint: alpha(grey[900], 0.32),
    main: common.white,
    dark: alpha(common.white, 0.9),
    contrastText: common.black,
};

function getContrastText(background: string, contrastThreshold = 3) {
    const contrastText =
        getContrastRatio(background, lightText.primary) >= contrastThreshold
            ? lightText.primary
            : darkText.primary;

    return contrastText;
}

// palette for light mode
export const light = {
    // The colors used to style the text.
    text: darkText,
    // The colors used to style inverted text.
    textInverted: lightText,
    // The color used to divide different elements.
    divider: "rgba(0, 0, 0, 0.12)",
    // The background colors used to style the surfaces.
    // Consistency between these values is important.
    background: {
        default: grey[50],
        paper: common.white,
        info: blue[50],
        success: green[50],
        error: red[50],
        warning: orange[50],
        receipt: yellow[50],
        snackbar: "#223240",
    },
    // The colors used to style the action elements.
    action: {
        // The color of an active action like an icon button.
        active: "rgba(0, 0, 0, 0.54)",
        // The background color of an hovered action.
        hover: "rgba(0, 0, 0, 0.04)",
        hoverOpacity: 0.04,
        // The background color of a selected action.
        selected: "rgba(0, 0, 0, 0.08)",
        selectedOpacity: 0.08,
        // The color of a disabled action.
        disabled: alpha(common.black, 0.26),
        // The background color of a disabled action.
        disabledBackground: alpha(common.black, 0.12),
        disabledOpacity: 0.26,
        focus: "rgba(0, 0, 0, 0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
    },
    primary: {
        light: blue[600],
        main: blue[700],
        dark: blue[800],
        contrastText: getContrastText(blue[700]),
    },
    secondary: {
        light: lighten(cyan[50], 0.2), // 0.2 = default tonal offset
        main: cyan[50],
        dark: cyan[100],
        contrastText: getContrastText(cyan[50]),
    },
    error: {
        light: red[500],
        main: red[600],
        dark: red[700],
        contrastText: getContrastText(red[600]),
    },
    warning: {
        light: orange[300],
        main: orange[400],
        dark: orange[500],
        contrastText: getContrastText(orange[400]),
    },
    info: {
        light: blue[600],
        main: blue[700],
        dark: blue[800],
        contrastText: getContrastText(blue[700]),
    },
    success: {
        light: green[700],
        main: green[800],
        dark: green[900],
        contrastText: getContrastText(green[800]),
    },
};

// palette for dark mode
export const dark: Mode = {
    text: lightText,
    textInverted: darkText,
    divider: "rgba(255, 255, 255, 0.12)",
    background: {
        default: grey[900],
        paper: grey[800],
        info: blue[50],
        success: green[50],
        error: red[50],
        warning: orange[50],
        receipt: yellow[50],
        snackbar: "#223240",
    },
    action: {
        active: "rgba(255, 255, 255, 0.56)",
        hover: "rgba(255, 255, 255, 0.08)",
        hoverOpacity: 0.08,
        selected: "rgba(255, 255, 255, 0.16)",
        selectedOpacity: 0.16,
        disabled: "rgba(255, 255, 255, 0.30)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        disabledOpacity: 0.3,
        focus: "rgba(255, 255, 255, 0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
    },
    primary: {
        light: blue[50],
        main: blue[200],
        dark: blue[400],
        contrastText: getContrastText(blue[200]),
    },
    secondary: {
        light: yellow[50],
        main: yellow[200],
        dark: yellow[400],
        contrastText: getContrastText(yellow[200]),
    },
    error: {
        light: red[50],
        main: red[200],
        dark: red[400],
        contrastText: getContrastText(red[200]),
    },
    warning: {
        light: orange[50],
        main: orange[200],
        dark: orange[400],
        contrastText: getContrastText(orange[200]),
    },
    info: {
        light: blue[50],
        main: blue[200],
        dark: blue[400],
        contrastText: getContrastText(blue[200]),
    },
    success: {
        light: green[50],
        main: green[200],
        dark: green[400],
        contrastText: getContrastText(green[200]),
    },
};

const verticalGradient = (
    from: string,
    to: string,
): React.CSSProperties["backgroundImage"] =>
    `linear-gradient(360deg, rgba(22, 43, 105, 0.4) 0%, ${from} 100%), ${to}`;
const horizontalGradient = (
    from: string,
    to: string,
): React.CSSProperties["backgroundImage"] =>
    `linear-gradient(90deg, rgba(22, 43, 105, 0.4) 0%, ${from} 100%), ${to}`;

const createGradients = (from: string, to: string): Gradient => ({
    horizontal: horizontalGradient(from, to),
    vertical: verticalGradient(from, to),
});

export const gradients = {
    V75: createGradients(blue[700], blue[800]),
    V86: createGradients(purple[700], purple[800]),
    GS75: createGradients(deepgreen[700], deepgreen[800]),
    V64: createGradients(orange[500], orange[800]),
    V65: createGradients(red[400], red[600]),
    assorted: createGradients(cyan[200], cyan[300]),
    big9: createGradients(blue[700], blue[800]),
};

/**
 * ATG custom palette creation function based on Muis own
 * The palette will be passed to the `createMuiTheme` function which is why it has
 * PaletteOptions as a return type instead of Palette.
 */
export default function createPalette(palette: MuiPaletteOptions): MuiPaletteOptions {
    const {
        modes = {dark, light},
        mode = "light",
        contrastThreshold,
        tonalOffset = 0.2,
        ...other
    } = palette;

    const primary = palette.primary || modes[mode]?.primary;
    const secondary = palette.secondary || modes[mode]?.secondary;
    const error = palette.error || modes[mode]?.error;
    const info = palette.info || modes[mode]?.info;
    const warning = palette.warning || modes[mode]?.warning;
    const success = palette.success || modes[mode]?.success;

    const V75 = palette.V75 || {
        light: blue[700],
        main: blue[800],
        dark: blue[900],
        contrastText: getContrastText(blue[800]),
    };
    const V86 = palette.V86 || {
        light: purple[700],
        main: purple[800],
        dark: purple[900],
        contrastText: getContrastText(purple[800]),
    };
    const GS75 = palette.GS75 || {
        light: deepgreen[700],
        main: deepgreen[800],
        dark: deepgreen[900],
        contrastText: getContrastText(deepgreen[800]),
    };
    const V64 = palette.V64 || {
        light: orange[300],
        main: orange[500],
        dark: orange[800],
        contrastText: getContrastText(orange[900]),
    };
    const V65 = palette.V65 || {
        light: red[300],
        main: red[600],
        dark: red[700],
        contrastText: getContrastText(red[600]),
    };
    const assorted = palette.assorted || {
        light: cyan[200],
        main: cyan[300],
        dark: cyan[400],
        contrastText: getContrastText(cyan[300], 2),
    };
    const big9 = palette.big9 || {
        light: blue[700],
        main: blue[800],
        dark: blue[900],
        contrastText: getContrastText(blue[800]),
    };

    const paletteOutput = deepmerge(
        {
            // A collection of common colors.
            common,
            // The palette mode, can be light or dark.
            mode,
            // The colors used to represent primary interface elements for a user.
            primary,
            // The colors used to represent secondary interface elements for a user.
            secondary,
            // The colors used to represent interface elements that the user should be
            // made aware of.
            error,
            // The colors used to represent potentially dangerous actions or important messages.
            warning,
            // The colors used to present information to the user that is neutral and not necessarily important.
            info,
            // The colors used to indicate the successful completion of an action that user triggered.
            success,
            // The grey colors.
            grey,
            // Used by `getContrastText()` to maximize the contrast between the background and
            // the text.
            contrastThreshold,
            // Used by the functions below to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset,
            // custom colors for ATG
            ...colors,
            // custom horse product colors for ATG
            V75,
            V86,
            GS75,
            V64,
            V65,
            assorted,
            big9,
            gradients,
            // The light and dark type object.
            ...modes[mode],
        },
        other,
    );

    return paletteOutput;
}
