import * as React from "react";
import {Link} from "react-router-dom";
import {ArrowRight} from "atg-ui-components/icons";
import {Typography} from "atg-ui-components";
import * as styles from "./Links.styles";
import {useNotificationState} from "./NotificationsProvider";
import Notification from "./Notification";

type Props = {
    to: string;
    children: React.ReactNode;
    className?: string;
    onLinkClick?: () => void;
    testId?: string;
    LinkComponent?: React.ElementType;
};

export function MenuLink(props: Props) {
    const {to, className, children, onLinkClick, testId, LinkComponent = Link} = props;
    const onClick = () => {
        if (onLinkClick) {
            onLinkClick();
        }
    };
    const testIds = LinkComponent !== Link ? {testId} : {"data-test-id": testId};

    return (
        <LinkComponent className={className} onClick={onClick} to={to} {...testIds}>
            {children}
        </LinkComponent>
    );
}

type ListLinkProps = {
    to: string;
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
    testId?: string;
};

export function ListLink({to, children, className, onClick, testId}: ListLinkProps) {
    return (
        <MenuLink className={className} to={to} onLinkClick={onClick} testId={testId}>
            <div css={styles.listItem}>{children}</div>
        </MenuLink>
    );
}

type AnchorLinkProps = {
    to: string;
    testId?: string;
    children: React.ReactNode;
};

export function AnchorLink({to, children, testId, ...rest}: AnchorLinkProps) {
    return (
        <a
            href={to}
            target="_blank"
            rel="noreferrer noopener"
            data-test-id={testId}
            {...rest}
        >
            {children}
        </a>
    );
}

type ListLinkInvertedProps = {
    to: string;
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
    LinkComponent?: React.ElementType;
    testId?: string;
};

export function ListLinkInverted({
    to,
    children,
    className,
    onClick,
    LinkComponent = Link,
    testId,
}: ListLinkInvertedProps) {
    return (
        <MenuLink
            className={className}
            css={styles.listItemInverted}
            to={to}
            onLinkClick={onClick}
            LinkComponent={LinkComponent}
            testId={testId}
        >
            {children}
            <ArrowRight size="inherit" />
        </MenuLink>
    );
}

type ListLinkNotificationProps = {
    to: string;
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
};

export function ListLinkNotification({
    to,
    children,
    className,
    onClick,
}: ListLinkNotificationProps) {
    const {ListItemNotificationWrapper, listItemNotification, listItemNotificationText} =
        styles;
    const {hasUnansweredMessages} = useNotificationState();

    return (
        <MenuLink
            className={className}
            css={styles.listItemInverted}
            to={to}
            onLinkClick={onClick}
        >
            <div>{children}</div>
            <>
                <ListItemNotificationWrapper>
                    <Notification css={listItemNotification} />
                    {hasUnansweredMessages && (
                        <Typography
                            style={listItemNotificationText}
                            variant="body3"
                            color="error"
                        >
                            Svar krävs
                        </Typography>
                    )}
                </ListItemNotificationWrapper>

                <ArrowRight size="inherit" />
            </>
        </MenuLink>
    );
}

type VerticalLinkProps = {
    to: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
    testId?: string;
    LinkComponent?: React.ElementType;
    children: React.ReactNode;
};

export function VerticalLink(props: VerticalLinkProps) {
    const {to, children, onClick, testId, LinkComponent = Link} = props;
    const testIds = LinkComponent !== Link ? {testId} : {"data-test-id": testId};

    return (
        <LinkComponent to={to} onClick={onClick} {...testIds}>
            {children}
        </LinkComponent>
    );
}
