import * as React from "react";
import {ComponentLoader} from "@atg-shared/lazy";

function SystemAlerts() {
    return (
        <ComponentLoader
            loader={import(/* webpackChunkName: "SystemAlerts" */ "./SystemAlerts")}
            placeholder={null}
        />
    );
}

export default SystemAlerts;
