/* eslint-disable max-classes-per-file */
import * as React from "react";
import {getDisplayName} from "@atg/utils/withRuntime";
import log, {serializeError} from "@atg-shared/log";

type Options = {
    forwardRef?: boolean;

    /** render this component if a crash happens in the wrapped component */
    fallback?: React.ReactNode;
    enableLogging?: boolean;
};

type Props = {
    name: string;
    placeholder?: React.ReactNode;
    children: React.ReactNode;
    enableLogging?: boolean;
};

export class ErrorBoundary extends React.Component<Props> {
    state = {
        hasError: false,
    };

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        const {name, enableLogging = true} = this.props;

        if (enableLogging) {
            log.error("[ErrorBoundary]: A react component crashed", {
                error: serializeError(error), // make sure we don't lose the stack trace, etc.
                componentStack: info.componentStack,
                crashedComponentName: name,
            });
        }

        this.setState({hasError: true});
    }

    render() {
        const {children, name, placeholder} = this.props;
        const {hasError} = this.state;

        if (hasError) {
            if (!placeholder && process.env.NODE_ENV === "production") {
                return null;
            }

            if (placeholder) {
                return placeholder;
            }

            return (
                <div
                    style={{
                        padding: "15px",
                        background: "#d9534f",
                        color: "#fff",
                        margin: "20px 0",
                        fontSize: "22px",
                    }}
                >
                    <div>{`Failed to render "${name}"`}</div>
                </div>
            );
        }

        return children;
    }
}

/**
 * HOC (Higher Order Component) which catches and reports all component errors (`componentDidCatch`)
 *
 * NOTE: For better production logs, it is recommended to use this HOC in combination with
 * explicitly setting the `displayName` property of the wrapped component.
 * ref: https://github.com/facebook/create-react-app/issues/3753
 *
 * @example
 * // When used in conjunction with `connect`, the three generic types could be annotated as
 * // follows:
 * export default withErrorBoundary<_, _, OwnProps>()(
 *     connect<Props, OwnProps, _, _, _, _>(
 *         mapStateToProps,
 *         mapDispatchToProps
 *     )(MyComponent)
 * );
 *
 * @deprecated Just use `ErrorBoundary` directly instead
 */
export function withErrorBoundary<InnerProps>(options: Options = {}) {
    return function wrapComponent(WrappedComponent: React.ComponentType<InnerProps>) {
        const ErrorBoundaryClass = class extends React.Component<any> {
            // eslint-disable-next-line react/static-property-placement
            static displayName: string;

            static wrappedComponentDisplayName: string;

            wrappedComponent: unknown;

            getWrappedComponent() {
                return this.wrappedComponent;
            }

            handleRef = (ref: unknown) => {
                this.wrappedComponent = ref;
            };

            render() {
                // Encourage setting an explicit `displayName` property on components wrapped with
                // `withErrorBoundary. This will survive minification, and provide a starting point
                // for debugging production component crashes.
                // ref: https://github.com/facebook/create-react-app/issues/3753
                if (
                    process.env.NODE_ENV !== "production" &&
                    !WrappedComponent.displayName
                ) {
                    // eslint-disable-next-line no-console
                    console.log(
                        `ErrorBoundary: explicit "displayName" missing for wrapped component "${getDisplayName(
                            WrappedComponent,
                        )}"`,
                    );
                }
                if (options.forwardRef) {
                    return (
                        <ErrorBoundary
                            name={getDisplayName(WrappedComponent)}
                            placeholder={options.fallback}
                            enableLogging={options.enableLogging}
                        >
                            {/* @ts-expect-error */}
                            <WrappedComponent {...this.props} ref={this.handleRef} />
                        </ErrorBoundary>
                    );
                }

                return (
                    <ErrorBoundary
                        name={getDisplayName(WrappedComponent)}
                        placeholder={options.fallback}
                        enableLogging={options.enableLogging}
                    >
                        {/* @ts-expect-error */}
                        <WrappedComponent {...this.props} />
                    </ErrorBoundary>
                );
            }
        };

        ErrorBoundaryClass.wrappedComponentDisplayName = getDisplayName(WrappedComponent);
        ErrorBoundaryClass.displayName = `withErrorBoundary(${ErrorBoundaryClass.wrappedComponentDisplayName})`;
        return ErrorBoundaryClass;
    };
}

export default ErrorBoundary;
