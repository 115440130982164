import * as Redux from "react-redux";

// eslint-disable-next-line no-underscore-dangle
const store = window._globalStore;

type GlobalDispatch = typeof store.dispatch;
type GlobalState = ReturnType<typeof store.getState>;

export const useGlobalDispatch: () => GlobalDispatch = Redux.useDispatch;
export const useGlobalSelector: Redux.TypedUseSelectorHook<GlobalState> =
    Redux.useSelector;
