import * as React from "react";
import type {Props as SvgIconPropType} from "../components/SvgIcon";
import SvgIcon from "../components/SvgIcon";

export function Arrow(props: SvgIconPropType) {
    return (
        <SvgIcon {...props}>
            <path d="M16.497 1.759A14.157 14.157 0 0126.42 6.28a14.161 14.161 0 013.82 10.216 14.156 14.156 0 01-4.521 9.924 14.16 14.16 0 01-10.216 3.82 14.158 14.158 0 01-9.923-4.52 14.157 14.157 0 01-3.82-10.216 14.156 14.156 0 014.52-9.925 14.165 14.165 0 0110.217-3.82zm-.508 1.49a12.66 12.66 0 00-8.685 3.427 12.663 12.663 0 00-4.046 8.88 12.668 12.668 0 003.418 9.14 12.666 12.666 0 008.88 4.045c3.416.133 6.65-1.094 9.14-3.417a12.662 12.662 0 004.046-8.88 12.665 12.665 0 00-3.417-9.14 12.663 12.663 0 00-9.336-4.054zm7.61 12.287l-.017-.022-.01-.01-.032-.034.059.066zm.004.005l.029.039a.779.779 0 01.078.15l.006.016.005.015a.728.728 0 01.038.21v.014l.001.015-.004.07.002-.019-.001.018a.75.75 0 01-.047.2l-.024.057a.72.72 0 01-.077.125l-.005.007-.064.072-6.23 6.23a.75.75 0 01-1.06-1.06l4.95-4.951-12.21.001a.75.75 0 110-1.5l12.208-.001L16.25 10.3a.75.75 0 011.06-1.06l6.23 6.23.032.033.004.005.006.006.007.01.01.012.004.005z" />
        </SvgIcon>
    );
}

export default Arrow;
