import {take, takeEvery, takeLeading} from "redux-saga/effects";
import log, {serializeError} from "@atg-shared/log";
import {isEmpty} from "lodash";
import * as Analytics from "@atg-shared/analytics";
import {START_LOGIN_FLOW} from "./memberActions";
import {LOGIN_FINISHED, LOGOUT_USER} from "@atg-global-shared/user/userActionTypes";
import type {LoginFinishedAction} from "@atg-global-shared/user/userActions";

function loginStarted() {
    Analytics.deprecated_logEvent({login_event: "login_started"});
}

function loginCompleted({yearOfBirth, sex}: {yearOfBirth?: string; sex?: string}) {
    if (isEmpty(yearOfBirth) && isEmpty(sex)) {
        log.error(
            "UserAnalytics.loginCompleted: At least one parameter needs to be set, aborting!",
        );
        return;
    }

    const utagData = {
        login_event: "login_completed",
    };

    Analytics.deprecated_logEvent(utagData);
}

function logout() {
    Analytics.deprecated_logEvent({login_event: "logout"});
}

function* loginAnalytics() {
    loginStarted();
    const action: LoginFinishedAction = yield take(LOGIN_FINISHED);
    try {
        loginCompleted(action.payload.loginData.user);
    } catch (err: unknown) {
        log.error("user login analytics failed.", {error: serializeError(err as Error)});
    }
}

function* logoutAnalytics() {
    yield logout();
}

export default function* memberAnalytics() {
    yield takeLeading(START_LOGIN_FLOW, loginAnalytics);
    yield takeEvery(LOGOUT_USER, logoutAnalytics);
}
