import * as React from "react";
import log, {serializeError} from "@atg-shared/log";
import {MicroFrontend} from "@atg-shared/micro-frontend";
import type {GameInfo} from "@atg-payment-shared/deposit-types";
import {type MediaObject} from "@atg-play-shared/media-graphql-client/__generated__/types.generated";
import {LoadingIndicator} from "atg-ui-components";
import {scriptLoader, cssLoader} from "./loaders";

type MaybeComponentProps<T> = T extends undefined
    ? {componentProps?: undefined}
    : {componentProps: T};

type ComponentProps = Record<string, unknown> | undefined;
type ComponentPropsMap = Record<string, ComponentProps>;

declare global {
    interface Window
        extends Partial<
            Record<
                `__${MicroFrontend}`,
                Partial<
                    Record<string, () => Promise<{default: React.ComponentType<any>}>>
                >
            >
        > {
        entrypoints: Record<MicroFrontend, Array<string>>;
        cssBundles: Partial<Record<MicroFrontend, Array<string>>>;
    }
}

const {entrypoints, cssBundles} = window;

const DefaultLoading = <LoadingIndicator inverted />;

/**
 * This factory function returns a React component that can be used to load and render React
 * components from any of our atgse micro frontends. The key feature is that the micro frontend
 * components are built by a completely separate webpack build, and can be released independently.
 *
 * micro frontend
 * - [background and high-level architecture](https://confluence-atg.riada.cloud/display/FE/Micro+frontend)
 * - [technical details](../README.md)
 *
 * We have borrowed inspiration and terminology from
 * [Webpack Module Federation](https://webpack.js.org/concepts/module-federation/), and in the
 * future we might switch to that.
 */
function createLoader<
    TComponentPropsMap extends Partial<ComponentPropsMap> = {App: undefined},
>(containerName: MicroFrontend) {
    /**
     * If a remote container has already been loaded, this will return a promise that will resolve
     * to the `component` we're looking for. If the remote container is not yet loaded, we return
     * `undefined`.
     */
    const getComponent = (component: string) =>
        window[`__${containerName}`]?.[component]?.();

    /**
     * Keep track of ongoing requests so that we can prevents scripts from being loaded multiple
     * times. This can otherwise happen if the loader is being used for two or more components at
     * the same time.
     */
    let activeRequests: Array<Promise<unknown>> | undefined;

    /**
     * This component is responsible for (lazily) loading a React component from a remote microFE
     *
     * The contract is that the remote microFE should:
     * - expose its exports through webpack's `libraryTarget: "window"` output
     * - have at least an `App` export
     * - uses "lazy promise exports" (`() => import("./MyComponent");`)
     *   (note: This is to avoid code being downloaded before it's needed. It's the same syntax that
     *   is used with e.g. `React.lazy(() => import("./MyComponent"))`.)
     *
     * The Loader also takes `loading` prop, a React.Node that will be rendered while:
     * - the remote bundle script/CSS is loaded
     * - the lazy component is loaded
     *
     * NOTE: The `componentName` prop needs to be stable, i.e. not change across rerenders
     */
    function Loader<TComponentName extends keyof TComponentPropsMap>({
        componentName,
        loading = DefaultLoading,
        componentProps,
    }: {
        componentName?: TComponentName;
        loading?: React.ReactNode;
    } & MaybeComponentProps<TComponentPropsMap[TComponentName]>) {
        const _componentName = (componentName ?? "App") as string;
        // start fetching resources for the microFE, unless they are already fetched or currently
        // being fetched
        //
        // wrap in useRef to prevent a new lazy component from being created each render
        // (would cause the async function to be called each render, briefly causing the fallback to be
        // rendered)
        const {current: LazyComponent} = React.useRef(
            // @ts-expect-error
            React.lazy(async () => {
                // exit early (without making any new network request) if the resources are already
                // loaded since before
                if (getComponent(_componentName)) {
                    return getComponent(_componentName);
                }

                // start loading resources if there's not already any pending requests
                if (!activeRequests) {
                    const js = (entrypoints?.[containerName] ?? []).map(scriptLoader);
                    const css = (cssBundles?.[containerName] ?? []).map(cssLoader);

                    activeRequests = [...js, ...css];
                }

                // wait for the pending requests (which could be initiated either in this or in another
                // Loader instance) to complete before rendering the final component
                try {
                    await Promise.all(activeRequests);
                    activeRequests = undefined;
                    return getComponent(_componentName);
                } catch (err: unknown) {
                    log.error(`Errors loading micro-FE bundles`, {
                        product: containerName,
                        component: _componentName,
                        error: serializeError(err),
                    });

                    // if any request fails, just show spinner forever (might wanna change this...)
                    return {
                        default: () => loading,
                    };
                }
            }),
        );

        return (
            <React.Suspense fallback={loading}>
                <LazyComponent {...componentProps} />
            </React.Suspense>
        );
    }

    Loader.displayName = `${containerName.charAt(0).toUpperCase()}${containerName.slice(
        1,
    )}Loader`;

    return Loader;
}

export const GlobalLoader = createLoader<{
    App: undefined;
    Header: undefined;
}>(MicroFrontend.GLOBAL);

export const HorseLoader = createLoader<{
    App: undefined;
    SideMenu: undefined;
    VideoFrame: undefined;
}>(MicroFrontend.HORSE);

export const ShopLoader = createLoader<{App: undefined; SideMenu: undefined}>(
    MicroFrontend.SHOP,
);

export const CasinoLoader = createLoader<{App: undefined; SideMenu: undefined}>(
    MicroFrontend.CASINO,
);

export const SportsbookLoader = createLoader<{App: undefined; SideMenu: undefined}>(
    MicroFrontend.SPORTSBOOK,
);

export const PlayLoader = createLoader<{
    App: undefined;
    VideoFrame: undefined;
    StartlistArticles: {
        gameId: string;
        eventTracking?: (slug: string) => void;
    };
}>(MicroFrontend.PLAY);

export const MyAtgLoader = createLoader<{App: undefined}>(MicroFrontend.MY_ATG);

export const TillsammansLoader = createLoader<{App: undefined; SideMenu: undefined}>(
    MicroFrontend.TILLSAMMANS,
);

export const AmlLoader = createLoader<{
    App: undefined;
    Section: undefined;
    Modal: undefined;
}>(MicroFrontend.AML);

export const PaymentLoader = createLoader<{
    App: undefined;
    DepositModal: GameInfo | undefined;
}>(MicroFrontend.PAYMENT);

export const VideoPlayerLoader = createLoader<{
    App: {
        videoInfo: MediaObject;
        playerProps: {
            shouldShowCinemaModeButton?: boolean;
            shouldAutoFocus?: boolean;
            onSetCinemaMode?: (arg: boolean) => void;
        };
    };
}>(MicroFrontend.VIDEO_PLAYER);
