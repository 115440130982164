import {omit} from "lodash";
import {
    FETCH_USER,
    RECEIVE_USER,
    RECEIVE_LOCAL_USER,
    RECEIVE_USER_FAILED,
    RECEIVE_BALANCE,
    SET_REMEMBER_USERNAME,
    UPDATE_SHOW_BALANCE,
    SET_FINGERPRINT_VERIFICATION,
    LOGIN_FINISHED,
    LOGOUT_FINISHED,
    SET_NEXT_POSSIBLE_LOGIN,
    USER_AVATAR_UPDATED,
    SET_REMEMBERED_USERNAME,
} from "./userActionTypes";
import type {User, MixedUser} from "./user.types";
import type {UserAction} from "./userActions";

export type UserState = MixedUser;

const omitDeprecatedUserServiceProps = (user: User) => {
    const deprecatedUserServiceProps = [
        "loginAliasActive",
        "smsVerificationLogin",
        "verificationCodeActive",
    ];
    return omit(user, deprecatedUserServiceProps);
};

const userReducer = (state = {} as MixedUser, action: UserAction): MixedUser => {
    switch (action.type) {
        case FETCH_USER:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case RECEIVE_USER:
            return {
                ...state,
                ...omitDeprecatedUserServiceProps(action.payload.user),
                // renamed in api from username -> userId, keep username for consistency
                username: action.payload.user.userId,
                loading: false,
                // missing in new endpoint
                loggedIn: true,
            };
        case RECEIVE_LOCAL_USER:
            return {
                ...state,
                ...action.payload.user,
                loading: false,
            };
        case RECEIVE_USER_FAILED:
            return {
                ...state,
                error: action.payload,
            };
        case LOGIN_FINISHED:
            return {
                ...state,
                ...omitDeprecatedUserServiceProps(action.payload.loginData.user),
                // renamed in api from username -> userId, keep username for consistency
                username: action.payload.loginData.user.userId,
                showBalance: state.showBalance !== undefined ? state.showBalance : false,
                rememberUsername:
                    state.rememberUsername !== undefined ? state.rememberUsername : false,
                // missing in new endpoint
                loggedIn: true,
            };
        case LOGOUT_FINISHED: {
            return state.rememberUsername
                ? ({
                      username: state.username,
                      rememberUsername: state.rememberUsername,
                      rememberedUsername: state.rememberedUsername,
                      showBalance: state.showBalance,
                      fingerprintVerification: state.fingerprintVerification,
                      contactInfo: {
                          email: "",
                          mobilePhone: "",
                      },
                  } as MixedUser)
                : ({rememberUsername: state.rememberUsername} as MixedUser);
        }
        case SET_REMEMBER_USERNAME:
            return {
                ...state,
                rememberUsername: action.payload.status,
                username: action.payload ? state.username : undefined,
            };
        case SET_REMEMBERED_USERNAME:
            return {
                ...state,
                rememberedUsername: action.payload.userName,
            };
        case SET_NEXT_POSSIBLE_LOGIN:
            return {
                ...state,
                nextPossibleLogin: action.payload.timestamp,
            };
        case UPDATE_SHOW_BALANCE:
            return {
                ...state,
                showBalance: action.payload.status,
            };
        case SET_FINGERPRINT_VERIFICATION:
            return {
                ...state,
                fingerprintVerification: action.payload,
            };
        case RECEIVE_BALANCE:
            return {
                ...state,
                balance: action.payload,
            };
        case USER_AVATAR_UPDATED:
            return action.payload.avatarRef
                ? {
                      ...state,
                      avatarRef: action.payload.avatarRef,
                  }
                : {...state};
        default:
            return state;
    }
};

export default userReducer;
