import type * as React from "react";
import type {Theme, Components} from "..";

type AccordionSummaryOverrides = Components<Theme>["MuiAccordionSummary"];

// Hack to ensure that we get title5 variant by default
// But we should still allow for setting a different variant to the summary
const getFirstChildVariant = (children: React.ReactNode) =>
    // @ts-expect-error - props is not guaranteed to exist in "React.ReactNode"
    Array.isArray(children) ? children?.[0]?.props?.variant : children?.props?.variant;

const overrides: AccordionSummaryOverrides = {
    styleOverrides: {
        content: ({ownerState, theme}) => ({
            ...(!getFirstChildVariant(ownerState.children) && {
                "& > *:first-child": {
                    ...theme.typography.title5,
                },
            }),
        }),
    },
};

export default overrides;
