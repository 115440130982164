/* eslint-disable import/dynamic-import-chunkname */
import React from "react";
import root from "window-or-global";
import {ErrorBoundary} from "@atg-shared/error-boundary";
import {isAppWebview} from "@atg-shared/system";
import {ComponentLoader} from "@atg-shared/lazy";

let query: URLSearchParams;

if (isAppWebview) {
    query = new URLSearchParams(root.location.search);

    // We need to strip login params from the URL immediately to prevent them from reaching Splunk.
    const newQuery = new URLSearchParams(root.location.search);
    newQuery.delete("accessToken");
    newQuery.delete("idToken");

    const strippedURL = `${root.location.protocol}//${root.location.host}${
        root.location.pathname
    }?${newQuery.toString()}`;

    root.history.replaceState({}, root.document.title, strippedURL);
}

export function AppDeposit() {
    return (
        <ErrorBoundary name="AppDepositInWebview">
            <ComponentLoader query={query} loader={import("./AppDeposit")} />
        </ErrorBoundary>
    );
}

export function AppAccount(props: any) {
    return <ComponentLoader {...props} query={query} loader={import("./AppAccount")} />;
}

export function AppLogin() {
    return <ComponentLoader query={query} loader={import("./AppLogin")} />;
}

export function AppLoginComplete() {
    return <ComponentLoader query={query} loader={import("./AppLoginComplete")} />;
}

export function AppTrackCommissionLandingPage() {
    return <ComponentLoader loader={import("./AppTrackCommissionLandingPage")} />;
}

export function AppContentfulArticle() {
    return <ComponentLoader loader={import("./AppContentfulArticle")} />;
}

export function AppDynamicLink() {
    return <ComponentLoader loader={import("./AppDynamicLink")} />;
}

export function AppCookiesConsent() {
    return <ComponentLoader loader={import("./AppCookiesConsent")} />;
}
