import * as React from "react";
import {ComponentLoader} from "@atg-shared/lazy";
import {ResponsibleGamingHeader} from "@atg-responsible-gambling-shared/header-feature";
import {LoadingIndicator} from "atg-ui-components";
// Look into after atg-frame-components is migrated to NX
// eslint-disable-next-line @nx/enforce-module-boundaries
import {LazyModalFrame} from "atg-frame-components";
import {RedirectFrame} from "./DepositRedirectFrame.styles";

function DepositRedirectPage() {
    return (
        <ComponentLoader
            // eslint-disable-next-line @nx/enforce-module-boundaries
            loader={import(
                /* webpackChunkName: "DepositRedirectPage" */ "@atg-payment-shared/deposit-feature/src/RedirectPage/DepositRedirectPage"
            )}
            placeholder={<LoadingIndicator />}
        />
    );
}

function DepositRedirectFrame() {
    return (
        <RedirectFrame>
            <ResponsibleGamingHeader />
            <DepositRedirectPage />
            <LazyModalFrame />
        </RedirectFrame>
    );
}

export default DepositRedirectFrame;
