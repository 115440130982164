import type {ResponsibleGamingHeaderActions} from "./responsibleGamingHeaderActions";
import {
    RESPONSIBLE_GAMING_HEADER_ADD_HIJACKER,
    RESPONSIBLE_GAMING_HEADER_REMOVE_HIJACKER,
} from "./responsibleGamingHeaderActions";

export type State = {
    hijackers: number;
};

const reducer = (
    state: State = {hijackers: 0},
    {type}: ResponsibleGamingHeaderActions,
) => {
    switch (type) {
        case RESPONSIBLE_GAMING_HEADER_ADD_HIJACKER:
            return {hijackers: state.hijackers + 1};
        case RESPONSIBLE_GAMING_HEADER_REMOVE_HIJACKER:
            return {hijackers: state.hijackers - 1};
        default:
            return state;
    }
};

export default reducer;
