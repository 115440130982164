import * as React from "react";
import { createSvgIcon } from "../utils";

export const CheckboxBlankOutlined = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36 4.4a.96.96 0 0 0-.96.96v13.28a.96.96 0 0 0 .96.96h13.28a.96.96 0 0 0 .96-.96V5.36a.96.96 0 0 0-.96-.96H5.36Zm-1.669-.709A2.36 2.36 0 0 1 5.36 3h13.28A2.36 2.36 0 0 1 21 5.36v13.28A2.36 2.36 0 0 1 18.64 21H5.36A2.36 2.36 0 0 1 3 18.64V5.36c0-.626.249-1.226.691-1.669Z"
    />,
    "CheckboxBlankOutlined",
);

export default CheckboxBlankOutlined;
