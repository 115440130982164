import * as Redux from "react-redux";

// eslint-disable-next-line no-underscore-dangle
const store = window._paymentStore;

type PaymentDispatch = typeof store.dispatch;
type PaymentState = ReturnType<typeof store.getState>;

export const usePaymentDispatch: () => PaymentDispatch = Redux.useDispatch;
export const usePaymentSelector: Redux.TypedUseSelectorHook<PaymentState> =
    Redux.useSelector;
