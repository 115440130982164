import * as React from "react";
import styled from "@emotion/styled";
import {keyframes, css} from "@emotion/react";

const progress = keyframes`
    0% {
        background-position: -100% 0;
    }

    100% {
        background-position: 300% 0;
    }
`;

export const backgroundAnimation = css`
    animation: ${progress} 1.5s ease-in-out infinite;
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0)
    );
    background-size: 50% 100%;
    background-repeat: no-repeat;
`;

const placeholder = ({
    theme: {placeHolderAnimation = true, placeHolderColor = "rgba(0, 0, 0, 0.04)"},
}) => css`
    background-color: ${placeHolderColor};
    ${placeHolderAnimation && backgroundAnimation};
`;

type Props = {
    width?: number | string;
    height?: number | string;
    marginBottom?: number;
    backgroundColor?: string;
};

export const Placeholder = styled.div<Props>(
    placeholder,
    ({width = "100%", height = 11, marginBottom, backgroundColor}) => ({
        width,
        height,
        marginBottom,
        backgroundColor,
    }),
);

export const TextPlaceholder = styled.div<Props>(
    placeholder,
    ({width = 110, height = 11, marginBottom = 2, backgroundColor}) => ({
        width,
        height,
        marginBottom,
        backgroundColor,
    }),
);

export const ButtonPlaceholder = styled.div<Props>(
    placeholder,
    ({width = "100%", height = 40, marginBottom = 16, backgroundColor}) => ({
        width,
        height,
        marginBottom,
        backgroundColor,
    }),
);

const PlaceholderWrapper = styled.div<Props>(
    ({width, height, marginBottom, backgroundColor = "#fff"}) => ({
        width,
        height,
        marginBottom,
        backgroundColor,
    }),
);

export function PanelPlaceholder({width, height, marginBottom, backgroundColor}: Props) {
    const panelWidth = width || "100%";
    const panelHeight = height || "100%";
    return (
        <PlaceholderWrapper
            width={panelWidth}
            height={panelHeight}
            marginBottom={marginBottom}
            backgroundColor={backgroundColor}
        >
            <Placeholder width={panelWidth} height={panelHeight} />
        </PlaceholderWrapper>
    );
}
