import type {Theme, Components} from "..";
import ChevronDownIcon from "../../icons/ChevronDown";

type SelectOverrides = Components<Theme>["MuiSelect"];

const overrides: SelectOverrides = {
    defaultProps: {
        IconComponent: ChevronDownIcon,
        MenuProps: {
            PaperProps: {
                elevation: 4,
            },
        },
    },
    styleOverrides: {
        select: {
            // align icon and label in a row
            "> .MuiListItemIcon-root": {
                minWidth: 32,
            },
            display: "flex",
            alignItems: "center",
        },
    },
};

export default overrides;
