import {css} from "@emotion/react";
import {
    mainNavigationBackground,
    responsibleGamingHeaderHeight,
} from "atg-ui/css/variables.styles";

const overlayWidth = 264;

const sideMenu = (lockScrolling) => css`
    position: fixed;
    z-index: 400;
    top: 0;
    width: ${overlayWidth}px;
    height: 100vh;
    background: ${mainNavigationBackground};
    box-shadow: 0 0 11px 7px rgba(0, 0, 0, 0.17);
    -webkit-overflow-scrolling: touch;
    overflow-y: ${lockScrolling ? "hidden" : "scroll"};
    overflow-x: hidden;

    @media (min-width: 375px) {
        width: 300px;
    }
    margin-top: ${responsibleGamingHeaderHeight + "px"};
`;

export const overlaySideMenu = (swipe, lockScrolling) => css`
    ${sideMenu(lockScrolling)};
    transform: translateX(${swipe}px);
    left: 0;
`;

export const overlaySideMenuRight = (swipe, lockScrolling) => css`
    ${sideMenu(lockScrolling)};
    left: auto;
    right: 0;
    transform: translateX(${swipe}px);
    background: #fff;
`;
