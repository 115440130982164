import {put, takeLatest, select} from "redux-saga/effects";
import * as AuthSelectors from "@atg-shared/auth/domain/authSelectors";
import * as UnauthorizedLoginAlertActions from "./UnauthorizedLoginAlertActions";

/** duplicate values to avoid circular dependency */
export const LOGOUT_FINISHED = "LOGOUT_FINISHED";
export const FINISH_MEMBER_FLOW = "FINISH_MEMBER_FLOW";

export function* checkLatestLoginAttempt() {
    const isLoggedIn: boolean = yield select(AuthSelectors.isLoggedIn);

    if (!isLoggedIn) {
        // We use FINISH_MEMBER_FLOW while restoring password as well.
        // Obviously, user cannot be logged in during this process
        return;
    }

    yield put(UnauthorizedLoginAlertActions.fetchLatestLoginDetails());
}

export function* finishLatestLoginAttempt() {
    yield put(UnauthorizedLoginAlertActions.closeUnauthorizedLoginAlertAction());
}

export default function* unauthorizedLoginAlertSaga() {
    yield takeLatest(FINISH_MEMBER_FLOW, checkLatestLoginAttempt);
    yield takeLatest(LOGOUT_FINISHED, finishLatestLoginAttempt);
}
