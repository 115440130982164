import {css, injectGlobal} from "@emotion/css";
import {
    loadingIndicatorWidth,
    loadingIndicatorHeight,
    atgWhite,
    brandPrimaryColor,
} from "../css/variables.styles";

export {overlay} from "./LoadingIndicator.styles";

export const root = css`
    margin: 20px auto;
    width: ${loadingIndicatorWidth};

    height: ${loadingIndicatorHeight};

    text-align: center;
    transform: skew(-22.5deg, 0);
`;

injectGlobal`
    @keyframes stretchdelay {
        0%,
        40%,
        100% {
            transform: scaleY(0.4);
        }

        20% {
            transform: scaleY(1);
        }
    }
`;

const skewedBar = css`
    background-color: ${atgWhite};

    height: 100%;
    width: 7px;
    margin: 0 3px 0 0;
    display: inline-block;
    animation: stretchdelay 1.2s infinite ease-in-out;
`;

export const rect1 = css`
    ${skewedBar};
`;

export const rect2 = css`
    ${skewedBar};
    animation-delay: -1.1s;
`;

export const rect3 = css`
    ${skewedBar};
    animation-delay: -1s;
`;

export const rect4 = css`
    ${skewedBar};
    animation-delay: -0.9s;
`;

export const rect5 = css`
    ${skewedBar};
    animation-delay: -0.8s;
`;

export const invertedRect1 = css`
    ${rect1};
    background: ${brandPrimaryColor};
`;

export const invertedRect2 = css`
    ${rect2};
    background: ${brandPrimaryColor};
`;

export const invertedRect3 = css`
    ${rect3};
    background: ${brandPrimaryColor};
`;

export const invertedRect4 = css`
    ${rect4};
    background: ${brandPrimaryColor};
`;

export const invertedRect5 = css`
    ${rect5};
    background: ${brandPrimaryColor};
`;
