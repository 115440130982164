import {fetchAuthorized} from "@atg-shared/auth";
import {INBOX_SERVICE_URL} from "@atg-shared/service-url";
import {FETCH, call} from "@atg-shared/fetch-redux";
import {fetchFileUpload, addAuthHeaders} from "@atg-shared/fetch";
import {InboxConstants} from "@atg-aml-shared/inbox-types";
import type {
    ResetInboxMessagePostRequestStatusAction,
    FetchInboxMessagesAction,
    ResetInboxMessagesAction,
    UpdateInboxMessageReadStatusAction,
    UploadInboxMessageReplyAction,
    FetchInboxMessageStatusAction,
} from "@atg-aml-shared/inbox-types";
import {frameAction} from "atg-store-addons";

const {
    RESET_INBOX_MESSAGE_POST_REQUEST_STATUS,
    INBOX_MESSAGES_REQUEST,
    INBOX_MESSAGES_RECEIVE,
    RESET_INBOX_MESSAGES,
    INBOX_MESSAGE_READ_STATUS_UPDATE_REQUEST,
    INBOX_MESSAGE_READ_STATUS_UPDATE_RECEIVE,
    INBOX_MESSAGE_REPLY_UPLOAD_REQUEST,
    INBOX_MESSAGE_REPLY_UPLOAD_RECEIVE,
    INBOX_MESSAGE_STATUS_REQUEST,
    INBOX_MESSAGE_STATUS_RECEIVE,
} = InboxConstants;

export const resetMessagePostRequestStatus =
    (): ResetInboxMessagePostRequestStatusAction =>
        frameAction({
            type: RESET_INBOX_MESSAGE_POST_REQUEST_STATUS,
        });

export const fetchInboxMessages = (): FetchInboxMessagesAction => ({
    type: FETCH,
    payload: {
        requestAction: INBOX_MESSAGES_REQUEST,
        receiveAction: INBOX_MESSAGES_RECEIVE,
        callApi: call(fetchAuthorized, `${INBOX_SERVICE_URL}/messages`),
    },
});

export const resetInboxMessages = (): ResetInboxMessagesAction => ({
    type: RESET_INBOX_MESSAGES,
});

export const updateInboxMessageReadStatus = ({
    conversationId,
    messageId,
}: {
    conversationId: number;
    messageId: number;
}): UpdateInboxMessageReadStatusAction => ({
    type: FETCH,
    payload: {
        requestAction: INBOX_MESSAGE_READ_STATUS_UPDATE_REQUEST,
        receiveAction: INBOX_MESSAGE_READ_STATUS_UPDATE_RECEIVE,
        callApi: call(
            fetchAuthorized,
            `${INBOX_SERVICE_URL}/message/read-status/${messageId}?status=READ`,
            {
                method: "PUT",
            },
        ),
        context: {conversationId, messageId},
    },
});

export const fetchInboxMessageStatus = (): FetchInboxMessageStatusAction => ({
    type: FETCH,
    payload: {
        requestAction: INBOX_MESSAGE_STATUS_REQUEST,
        receiveAction: INBOX_MESSAGE_STATUS_RECEIVE,
        callApi: call(
            fetchAuthorized,
            `${INBOX_SERVICE_URL}/fetch-customer-message-status`,
        ),
    },
});

export const uploadInboxMessageReply = ({
    conversationId,
    textMessage,
    files,
}: {
    conversationId: number;
    textMessage: string;
    files: Array<File> | undefined;
}): UploadInboxMessageReplyAction => {
    const formData = new FormData();
    let nextFiles;

    if (files) {
        nextFiles = [...files];
        nextFiles.forEach((file) => {
            formData.append("files", file);
        });
    }

    formData.append("textMessage", textMessage);

    return {
        type: FETCH,
        payload: {
            requestAction: INBOX_MESSAGE_REPLY_UPLOAD_REQUEST,
            receiveAction: INBOX_MESSAGE_REPLY_UPLOAD_RECEIVE,
            callApi: call(
                fetchAuthorized,
                `${INBOX_SERVICE_URL}/messages/${conversationId}/upload`,
                {
                    method: "POST",
                    headers: addAuthHeaders({}),
                    body: formData,
                },
                undefined,
                fetchFileUpload,
            ),
            context: {conversationId, textMessage, files: nextFiles},
        },
    };
};
