import {css} from "@emotion/react";

/**
 * used to hide navigation that should only appear on tab focus or
 * screen readers to quickly and conveniently "jump to content".
 * */
export const hiddenIndex = css`
    background: inherit;
    left: 50%;
    top: 0;
    padding: 8px;
    position: absolute;
    transform: translate(-50%, -100%);

    &:focus {
        transform: translate(calc(-50% - 8px), 8px);
    }
`;

export const hiddenIndexLeft = css`
    ${hiddenIndex};
    right: auto;
    left: 0;

    &:focus {
        transform: translate(8px, 8px);
    }
`;

export const hiddenIndexRight = css`
    ${hiddenIndex};
    right: 0;
    left: auto;

    &:focus {
        transform: translate(-8px, 8px);
    }
`;

/**
 * hide text that should only appear on screen readers
 */
export const visuallyHidden = css`
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
`;

export default hiddenIndex; // remove this line if more mixins are added.
