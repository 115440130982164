import {css} from "@emotion/react";
import {
    root as loadingIndicatorRoot,
    rect1 as loadingIndicatorRect1,
    rect2 as loadingIndicatorRect2,
    rect3 as loadingIndicatorRect3,
    rect4 as loadingIndicatorRect4,
    rect5 as loadingIndicatorRect5,
} from "./LoadingIndicator.styles";

export const rect1 = css`
    ${loadingIndicatorRect1};
    width: 4px;
`;

export const rect2 = css`
    ${loadingIndicatorRect2};
    width: 4px;
`;

export const rect3 = css`
    ${loadingIndicatorRect3};
    width: 4px;
`;

export const rect4 = css`
    ${loadingIndicatorRect4};
    width: 4px;
`;

export const rect5 = css`
    ${loadingIndicatorRect5};
    width: 4px;
`;

export const root = css`
    ${loadingIndicatorRoot};
    margin: 0 auto;
    height: 24px;
`;

export const invertedRect1 = css`
    ${rect1};
    background: #fff;
`;

export const invertedRect2 = css`
    ${rect2};
    background: #fff;
`;

export const invertedRect3 = css`
    ${rect3};
    background: #fff;
`;

export const invertedRect4 = css`
    ${rect4};
    background: #fff;
`;

export const invertedRect5 = css`
    ${rect5};
    background: #fff;
`;

export const overlay = css``;
