import type {ExperienceConfig} from "@atg-shared/personalization-types";
/**
 * [ATGSEDIG-37837] - Deep link to self test from RG-bar AB test
 */
export const DEEP_LINK_SELFTEST_EXPERIENCE_CONFIG: ExperienceConfig = {
    dev: {
        experienceId: 211275,
        variations: [1335530, 1335531],
    },
    prod: {
        experienceId: 211287,
        // TODO: verify that the control ID is correct before using this test again
        variations: [1335643, 1335644],
    },
};

/**
 * [CUST-241] Qubit A/A measure test
 */
export const QUBIT_MEASURE_TEST: ExperienceConfig = {
    dev: {
        experienceId: 216297,
        variations: [1380967, 1380968],
    },
    prod: {
        experienceId: 216298,
        variations: [1380971, 1380972],
    },
};

/**
 * [CUST-211] More intuitive deposit limit modals
 */
export const DEPOSIT_LIMITS: ExperienceConfig = {
    dev: {
        experienceId: 217940,
        variations: [1397228, 1397229],
    },
    prod: {
        experienceId: 217941,
        // TODO: verify that the control ID is correct before using this test again
        variations: [1397230, 1397231],
    },
};

/**
 * [APP-1299] Big 9 poll
 */
export const BIG9_POLL_APP: ExperienceConfig = {
    dev: {
        experienceId: 219794,
        variations: [1416031, 1416032],
    },
    prod: {
        experienceId: 219795,
        variations: [1416033, 1416034],
    },
};

/**
 * [TBET-1263] New Harry Subscription API toggle
 */
export const NEW_HARRY_SUBSCRIPTION_API: ExperienceConfig = {
    dev: {
        experienceId: 234511,
        variations: [1530866, 1530867],
    },
    prod: {
        experienceId: 234510,
        variations: [1530864, 1530865],
    },
};

/**
 * [TBET-1359] Adapt to new API for reduction /metadata endpoint
 */
export const NEW_REDUCTION_METADATA_ENDPOINT: ExperienceConfig = {
    dev: {
        experienceId: 237701,
        variations: [1556774, 1556775],
    },
    prod: {
        experienceId: 237702,
        variations: [1556776, 1556777],
    },
};

/**
 * [APP-2129] VXY Bet placement proxy
 */
export const APP_VARENNE_BET_PLACEMENT_PROXY: ExperienceConfig = {
    dev: {
        experienceId: 234874,
        variations: [1533800, 1533801],
    },
    prod: {
        experienceId: 234875,
        variations: [1533804, 1533805],
    },
};

/**
 * [SPORT-4416] TV-Guide in Sports landing page
 */
export const SPORTS_TV_GUIDE: ExperienceConfig = {
    dev: {
        experienceId: 229591,
        variations: [1485992, 1485993, 1485998],
    },
    prod: {
        experienceId: 229594,
        variations: [1486003, 1486004, 1486005],
    },
};

/*
 * [CUST-668] Startpage hero
 */
export const STARTPAGE_HERO: ExperienceConfig = {
    dev: {
        experienceId: 232546,
        variations: [1513756, 1513757],
    },
    prod: {
        experienceId: 232545,
        variations: [1513754, 1513755],
    },
};

/**
 * [APP-1986] Hero Bet Method ShopShares
 */
export const BET_METHOD_ALERT_APP: ExperienceConfig = {
    dev: {
        experienceId: 234221,
        variations: [1528346, 1528347],
    },
    prod: {
        experienceId: 234226,
        variations: [1528358, 1528359],
    },
};

/**
 * [PEGASUS-1309] Enable champions (vmiv75) marketing modals
 */
export const CHAMPIONS_MARKETING_CAMPAIGN: ExperienceConfig = {
    dev: {
        experienceId: 238720,
        variations: [1565134, 1565135],
    },
    prod: {
        experienceId: 238722,
        variations: [1565160, 1565161],
    },
};

/**
 * [CUST-832] Mitt ATG micro frontend
 */
export const MY_ATG_MICROFE: ExperienceConfig = {
    dev: {
        experienceId: 235432,
        variations: [1538399, 1538400],
    },
    prod: {
        experienceId: 235433,
        variations: [1538403, 1538404],
    },
};

/**
 * [ATGSTR-1048] Setup A/B test (Phased rollout) for new videframe
 */
export const NEW_VIDEOFRAME_FLOW: ExperienceConfig = {
    dev: {
        experienceId: 237552,
        variations: [1555523, 1555524],
    },
    prod: {
        experienceId: 237758,
        variations: [1557136, 1557137],
    },
};

/**
 * [SPORT-14583] Match comments for Football events
 */
export const MATCH_COMMENTS_FOOTBALL: ExperienceConfig = {
    dev: {
        experienceId: 238666,
        variations: [1564654, 1564655],
    },
    prod: {
        experienceId: 238801,
        variations: [1565962, 1565963],
    },
};

/** [CUST-987] New welcome modal for registering users
 */
export const WELCOME_NEW_CUSTOMER: ExperienceConfig = {
    dev: {
        experienceId: 238844,
        variations: [1566403, 1566404],
    },
    prod: {
        experienceId: 238843,
        variations: [1566399, 1566400],
    },
};

/**
 * [SPORT-15950] CTA in match comment
 */
export const CTA_IN_MATCH_COMMENT: ExperienceConfig = {
    dev: {
        experienceId: 239644,
        variations: [1572963, 1572964],
    },
    prod: {
        experienceId: 239651,
        variations: [1572985, 1572986],
    },
};

/**
 * [ATGSTR-1143] Low latency stream
 */
export const LOW_LATENCY_HLS: ExperienceConfig = {
    dev: {
        experienceId: 240037,
        variations: [1576046, 1576047],
    },
    prod: {
        experienceId: 240075,
        variations: [1576293, 1576294],
    },
};

/**
 * [CUST-1175] New visitor landing modal
 */
export const NEW_VISITOR_LANDING_MODAL: ExperienceConfig = {
    dev: {
        experienceId: 240495,
        variations: [1579752, 1579753, 1579754],
    },
    prod: {
        experienceId: 240496,
        variations: [1579756, 1579757, 1579758],
    },
};
/**
 * [PAS-8395] Different Casino Hero Layouts
 */
export const DIFFERENT_CASINO_HERO_LAYOUTS: ExperienceConfig = {
    dev: {
        experienceId: 240299,
        variations: [1578326, 1578327, 1578328],
    },
    prod: {
        experienceId: 240505,
        variations: [1579885, 1579886, 1579887],
    },
};

/**
 * [ATGSTR-1373] Enhanced live for web on Desktop
 */
export const ENHANCED_LIVE_WEB: ExperienceConfig = {
    dev: {
        experienceId: 240467,
        variations: [1579584, 1579585],
    },
    prod: {
        experienceId: 240468,
        variations: [1579586, 1579587],
    },
};

/**
 * [ATGSTR-1373] Enhanced live for web on Mobile
 */
export const ENHANCED_LIVE_WEB_MOBILE: ExperienceConfig = {
    dev: {
        experienceId: 240472,
        variations: [1579596, 1579597],
    },
    prod: {
        experienceId: 240473,
        variations: [1579598, 1579599],
    },
};

/**
 * [SPORT-17748] Carousels on start page
 */
export const CAROUSELS_ON_START_PAGE: ExperienceConfig = {
    dev: {
        experienceId: 240628,
        variations: [1580795, 1580796, 1580797],
    },
    prod: {
        experienceId: 240630,
        variations: [1580804, 1580805, 1580806],
    },
};

/**
 * [TBET-1921] Direct horse coupon integration (no atg-service)
 */
export const DIRECT_COUPON_INTEGRATION: ExperienceConfig = {
    dev: {
        experienceId: 241345,
        variations: [1586206, 1586207],
    },
    prod: {
        experienceId: 241346,
        variations: [1586210, 1586211],
    },
};
