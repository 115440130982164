import * as React from "react";
import Features, {type Feature} from "@atg-shared/client-features";
import * as Redux from "react-redux";
import {useNavigate, matchPath, useLocation} from "react-router-dom";
import * as Storage from "@atg-shared/storage";
import {AtgAlertTypes} from "@atg-global-shared/alerts-types";
import type {Toast} from "atg-ui-toast/domain/toast";
import {showToast} from "atg-ui-toast/domain/toastActions";

const STORAGE_KEY = "feature_toggler_toast_message";

const storeToastMessageInSessionStorage = (toast: Toast) => {
    Storage.setObject(STORAGE_KEY, toast, Storage.SESSION);
};

const retrieveToastMessageFromSessionStorage = () => {
    const toast: Toast | null = Storage.getObject(STORAGE_KEY, Storage.SESSION);
    if (!toast) return null;
    // remove from storage as soon as it's been retrieved since message should only be shown once
    Storage.removeItem(STORAGE_KEY, Storage.SESSION);
    return toast;
};

export function useFeatureToggleRoute() {
    const location = useLocation();

    const match = [
        "/features/override/:feature/enable",
        "/features/override/:feature/disable",
        "/features/override/:feature/reset",
    ].reduce((prev, path) => {
        const matched = matchPath(
            {
                path,
                end: true,
                caseSensitive: true,
            },
            location.pathname,
        );

        if (matched) {
            return matched;
        }

        return prev;
    }, null as ReturnType<typeof matchPath> | null);

    const navigate = useNavigate();
    const dispatch = Redux.useDispatch();

    React.useEffect(() => {
        // read toast message from session storage and show toast if it exists
        const toast = retrieveToastMessageFromSessionStorage();
        if (toast) {
            dispatch(showToast({...toast, timeout: 5000}));
        }
    }, [dispatch]);

    React.useEffect(() => {
        if (match) {
            const {
                pathname,
                params: {feature},
            } = match as typeof match & {params: {feature: Feature}};

            if (!((feature as Feature) in Features._getAll())) {
                dispatch(
                    showToast({
                        message: `"${feature}" feature name does not exist`,
                        type: AtgAlertTypes.DANGER,
                    }),
                );
                navigate("/", {replace: true});
                return;
            }

            const shouldEnable = pathname.endsWith("/enable");
            const shouldDisable = pathname.endsWith("/disable");
            const shouldReset = pathname.endsWith("/reset");

            if (shouldEnable) {
                // override to enabled
                const isAlreadyEnabled = Features.isEnabled(feature);
                if (!isAlreadyEnabled) Features._updateOverriden(feature);
                // store toast message in session storage so that it can be displayed after reloading the page
                storeToastMessageInSessionStorage({
                    message: `${feature} feature enabled`,
                    type: AtgAlertTypes.SUCCESS,
                });
                navigate("/", {replace: true});
                // hard reload of the page is required for the updated feature toggle values to take effect
                window.location.reload();
                return;
            }

            if (shouldDisable) {
                // override feature to disabled
                const isAlreadyDisabled = !Features.isEnabled(feature);
                if (!isAlreadyDisabled) Features._updateOverriden(feature);
                storeToastMessageInSessionStorage({
                    message: `${feature} feature disabled`,
                    type: AtgAlertTypes.SUCCESS,
                });
                navigate("/", {replace: true});
                window.location.reload();
                return;
            }

            if (shouldReset) {
                // remove override
                Features._removeOverriden(feature);
                storeToastMessageInSessionStorage({
                    message: `${feature} feature reset to default value (${
                        Features._getDefault()[feature]
                    })`,
                    type: AtgAlertTypes.SUCCESS,
                });
                navigate("/", {replace: true});
                window.location.reload();
            }
        }
    }, [match, dispatch, navigate]);
}
