import * as React from "react";
import { createSvgIcon } from "../utils";

export const InfoOutlined = createSvgIcon(
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.632 20.6a8.6 8.6 0 1 0 0-17.2 8.6 8.6 0 0 0 0 17.2Zm0 1.4c5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10s4.477 10 10 10Z"
        />
        <path d="M15.048 17.386c-.567.223-2.53 1.16-3.662.164a1.437 1.437 0 0 1-.506-1.13c0-.855.282-1.601.788-3.392.09-.338.198-.777.198-1.125 0-.6-.228-.76-.846-.76-.302 0-.636.107-.938.22l.167-.685c.674-.274 1.521-.608 2.246-.608 1.088 0 1.889.542 1.889 1.574 0 .298-.052.819-.16 1.179l-.626 2.213c-.129.447-.363 1.434 0 1.726.356.289 1.2.136 1.617-.06l-.167.684Zm-1.034-8.815A1.286 1.286 0 1 1 14.015 6a1.286 1.286 0 0 1 0 2.572Z" />
    </>,
    "InfoOutlined",
);

export default InfoOutlined;
