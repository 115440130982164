/* eslint no-unused-expressions: 0 */

export type ListType = "SORT" | "GROUP" | "LIST";

export type MockSettings = {
    mockServiceApi: boolean;
    listType: ListType;
};

export const setSetting = <T extends keyof MockSettings>(
    key: T,
    value: MockSettings[T],
) => {
    const apiMockSettingsLocalStorage: MockSettings = JSON.parse(
        localStorage.getItem("api-mock-settings") ?? "{}",
    );

    apiMockSettingsLocalStorage[key] = value;

    localStorage.setItem(
        "api-mock-settings",
        JSON.stringify(apiMockSettingsLocalStorage),
    );
};

export const getSetting = <T extends keyof MockSettings>(key: T): MockSettings[T] => {
    const apiMockSettingsLocalStorage: MockSettings = JSON.parse(
        localStorage.getItem("api-mock-settings") ?? "{}",
    );

    // Default
    apiMockSettingsLocalStorage.listType ??
        (apiMockSettingsLocalStorage.listType = "LIST");
    apiMockSettingsLocalStorage.mockServiceApi ??
        (apiMockSettingsLocalStorage.mockServiceApi = false);

    return apiMockSettingsLocalStorage[key];
};
