import * as React from "react";
import { createSvgIcon } from "../utils";

export const ErrorOutlined = createSvgIcon(
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3.4a8.6 8.6 0 1 0 0 17.2 8.6 8.6 0 0 0 0-17.2ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 7.073a.7.7 0 0 1 .7.7v5.072a.7.7 0 1 1-1.4 0V7.773a.7.7 0 0 1 .7-.7Z"
        />
        <path d="M12 17.073a.845.845 0 1 0 0-1.691.845.845 0 0 0 0 1.69Z" />
    </>,
    "ErrorOutlined",
);

export default ErrorOutlined;
