import {put, select, take, call, takeLatest} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";

import {deprecated_defaultErrorTransform} from "@atg-shared/response-mapping/apiMessages";
import * as Storage from "@atg-shared/storage";
import * as UserApi from "@atg-global-shared/user/userApi";
import type {UserCredentials} from "@atg-global-shared/user/user.types";
import {LOGIN_FINISHED} from "@atg-global-shared/user/userActionTypes";
import {MemberActions} from "@atg-global-shared/member-data-access";
import {TWO_FACTOR_CONFIRMATION_RESPONSE} from "@atg-global-shared/two-factor-login-feature/domain/twoFactorActions";
import {changePasswordLoginFlow} from "@atg-global-shared/change-password-feature/domain/changePasswordSaga";
import {twoFactorLoginFlow} from "@atg-global-shared/two-factor-login-feature/domain/twoFactorSaga";
import * as LoginActions from "./loginActions";
import * as LoginSelectors from "./loginSelectors";
import {getInitialUserCredentials} from "./loginReducer";
import type {Action} from "./index";

export const CREDENTIALS_STORAGE_KEY = "member_credentials";

export const getCredentialsFromStorage = (): UserCredentials => {
    const credentials = Storage.getItem(CREDENTIALS_STORAGE_KEY);
    if (!credentials) return getInitialUserCredentials();
    return JSON.parse(credentials);
};

export const setCredentialsInStorage = (credentials: UserCredentials): void => {
    const {rememberUsername} = credentials;
    Storage.setItem(
        CREDENTIALS_STORAGE_KEY,
        JSON.stringify(credentials.rememberUsername ? credentials : {rememberUsername}),
    );
};

export function* loginErrorFlow(
    error: Record<string, any>,
    username?: string | null,
): SagaIterator {
    // even though we expect an error to be an object with some props, we cannot trust it to be, especially since we have bad TS support in sagas.
    const errorType = error?.response?.data?.status;
    const errorTransformed = error?.response
        ? deprecated_defaultErrorTransform(error.response)
        : error;
    switch (errorType) {
        case "NEED_SMS_VERIFICATION":
            yield put(LoginActions.loginNeedTwoFactor());
            return yield call(twoFactorLoginFlow);
        case "PASSWORD_NEED_CHANGE":
            yield put(LoginActions.loginError(errorTransformed));
            yield put(LoginActions.loginNeedChangeTempPassword());
            return yield call<typeof changePasswordLoginFlow>(
                changePasswordLoginFlow,
                username,
                login,
            );
        default:
            yield put(
                LoginActions.loginError(
                    errorTransformed?.error?.type
                        ? errorTransformed
                        : // logic in reducer expects error to have type and message
                          {
                              error: {
                                  type: "unexpected",
                                  message: JSON.stringify(error),
                              },
                          },
                ),
            );
            return null;
    }
}

export function* login(
    username?: string | null,
    password?: string,
    challengeResponse?: string,
): SagaIterator {
    let response;
    let responseData;
    try {
        response = yield call(UserApi.login, {username, password, challengeResponse});
        responseData = response.data; // data = {roles, status, user}
    } catch (error: unknown) {
        // @ts-expect-error unknown saga error
        responseData = yield call(loginErrorFlow, error, username);
    }

    if (!responseData) throw new Error("Login failed");

    return responseData;
}

export function* handleTwoFactorResponse({payload: {username}}: any): SagaIterator {
    while (true) {
        const action: Action = yield take([
            TWO_FACTOR_CONFIRMATION_RESPONSE,
            MemberActions.CANCELLED_LOGIN_FLOW,
            MemberActions.FINISH_MEMBER_FLOW,
        ]);
        if (action.type !== TWO_FACTOR_CONFIRMATION_RESPONSE) break;
        if (!action.error) break;
        const responseData = yield call(loginErrorFlow, action.payload.error, username);
        if (responseData) yield put(LoginActions.loginFinished(responseData, username));
    }
}

export function* loginFlow({
    payload: {username, password, challengeResponse},
}: Record<string, any>): SagaIterator {
    try {
        const responseData = yield call(login, username, password, challengeResponse);
        yield put(LoginActions.loginFinished(responseData, username));
    } catch (e: unknown) {
        // eslint-disable-next-line no-useless-return
        return;
    }
}

export function* storeCredentials(): SagaIterator {
    const credentials = yield select(LoginSelectors.getRememberedUserCredentials);
    yield call(setCredentialsInStorage, credentials);
}

export default function* loginSaga() {
    yield takeLatest(LoginActions.LOGIN, loginFlow);
    yield takeLatest(LoginActions.LOGIN, handleTwoFactorResponse);
    yield takeLatest(LOGIN_FINISHED, storeCredentials);
}
