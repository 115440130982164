import {call, FETCH} from "@atg-shared/fetch-redux";
import {CUSTOMER_TRANSACTIONS_URL} from "@atg-shared/service-url";
import {fetchAuthorized} from "@atg-shared/auth";
import {constants} from "@atg-responsible-gambling-shared/user-gambling-result-types";
import type {FetchGamblingPeriodResultAction} from "./actionTypes";

const {GAMBLING_PERIOD_RESULT_FETCH, GAMBLING_PERIOD_RESULT_SUCCESS} = constants;

export const fetchGamblingPeriodResultAction = (): FetchGamblingPeriodResultAction => ({
    type: FETCH,
    payload: {
        requestAction: GAMBLING_PERIOD_RESULT_FETCH,
        receiveAction: GAMBLING_PERIOD_RESULT_SUCCESS,
        callApi: call(
            fetchAuthorized,
            `${CUSTOMER_TRANSACTIONS_URL}/my-gambling-result`,
            {
                method: "GET",
            },
        ),
    },
});
