import * as React from "react";
import { createSvgIcon } from "../utils";

export const ChevronDown = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.171 9.241a.7.7 0 0 1 .988-.07L12 13.373l4.841-4.202a.7.7 0 1 1 .918 1.058l-5.3 4.6a.7.7 0 0 1-.918 0l-5.3-4.6a.7.7 0 0 1-.07-.988Z"
    />,
    "ChevronDown",
);

export default ChevronDown;
