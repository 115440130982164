import * as React from "react";
import * as Redux from "react-redux";
import {trackGlobalEvent} from "@atg-global-shared/analytics";
import {useVerticals, VerticalsActions} from "@atg-global-shared/verticals";
import {Arrow as ArrowIcon} from "atg-ui-components/icons";
import {Typography} from "atg-ui-components";
import {ListLink} from "./Links";
import * as styles from "./VerticalsMenu.styles";

type Props = {
    onLinkClick: () => void;
};

const trackingEvent = "verticalNavigationClickEvent";

const VerticalsMenu = React.forwardRef<HTMLElement, Props>(({onLinkClick}, ref) => {
    const dispatch = Redux.useDispatch();
    const vertical = useVerticals();

    return (
        <nav css={styles.wrapper} ref={ref}>
            <ul aria-label="ATG:s spelområden">
                <li>
                    <ListLink
                        to="/"
                        testId="horse-verticals-menu"
                        onClick={() => {
                            dispatch(VerticalsActions.unlockContextAction());
                            onLinkClick();
                            trackGlobalEvent({
                                event: trackingEvent,
                                target: "verticalNavigationClick_leftmenu_horse",
                            });
                        }}
                    >
                        <Typography
                            component="span"
                            mode="dark"
                            style={styles.typography(vertical === "horse")}
                            color="secondary"
                            variant="headline3"
                        >
                            <ArrowIcon style={styles.icon} />
                            Häst
                        </Typography>
                    </ListLink>
                </li>

                <li>
                    <ListLink
                        to="/sport"
                        testId="sport-verticals-menu"
                        onClick={() => {
                            dispatch(VerticalsActions.unlockContextAction());
                            onLinkClick();
                            trackGlobalEvent({
                                event: trackingEvent,
                                target: "verticalNavigationClick_leftmenu_sport",
                            });
                        }}
                    >
                        <Typography
                            component="span"
                            mode="dark"
                            style={styles.typography(vertical === "sport")}
                            color="secondary"
                            variant="headline3"
                        >
                            <ArrowIcon style={styles.icon} />
                            Sport
                        </Typography>
                    </ListLink>
                </li>

                <li>
                    <ListLink
                        to="/casino"
                        testId="casino-verticals-menu"
                        onClick={() => {
                            dispatch(VerticalsActions.unlockContextAction());
                            onLinkClick();
                            trackGlobalEvent({
                                event: trackingEvent,
                                target: "verticalNavigationClick_leftmenu_casino",
                            });
                        }}
                    >
                        <Typography
                            component="span"
                            mode="dark"
                            style={styles.typography(vertical === "casino")}
                            color="secondary"
                            variant="headline3"
                        >
                            <ArrowIcon style={styles.icon} />
                            Casino
                        </Typography>
                    </ListLink>
                </li>
            </ul>
        </nav>
    );
});

export default VerticalsMenu;
