import * as React from "react";
import type {Theme, Components} from "..";
import WarningOutlinedIcon from "../../icons/WarningOutlined";
import SuccessOutlinedIcon from "../../icons/SuccessOutlined";
import InfoOutlinedIcon from "../../icons/InfoOutlined";
import ErrorOutlinedIcon from "../../icons/ErrorOutlined";

type AlertOverrides = Components<Theme>["MuiAlert"];

const overrides: AlertOverrides = {
    defaultProps: {
        iconMapping: {
            info: <InfoOutlinedIcon />,
            success: <SuccessOutlinedIcon />,
            warning: <WarningOutlinedIcon />,
            error: <ErrorOutlinedIcon />,
        },
    },
};

export default overrides;
