import {css} from "@emotion/react";

export const alertCloseButton = css`
    position: relative;
    right: -5px;
    cursor: pointer;
    float: right;
    padding: 0 8px;
    cursor: pointer;
    background: 0 0;
    border: 0;
`;

export const closeButton = (size) => css`
    font-size: ${size}px;
`;
