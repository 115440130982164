export const LOGIN_FORM_STEP = "LOGIN_FORM_STEP";
export const LOGIN_TWO_FACTOR_STEP = "LOGIN_TWO_FACTOR_STEP";
export const LOGIN_USER_AGREEMENT_STEP = "LOGIN_USER_AGREEMENT_STEP";
export const LOGIN_CHANGE_TEMP_PASSWORD_STEP = "LOGIN_CHANGE_TEMP_PASSWORD_STEP";
export const LOGIN_MEMBER_RECOVERY_STEP = "LOGIN_MEMBER_RECOVERY_STEP";

export type LoginStep =
    | typeof LOGIN_FORM_STEP
    | typeof LOGIN_TWO_FACTOR_STEP
    | typeof LOGIN_USER_AGREEMENT_STEP
    | typeof LOGIN_CHANGE_TEMP_PASSWORD_STEP
    | typeof LOGIN_MEMBER_RECOVERY_STEP;
