import * as React from "react";

export const INITIAL_VALUES = {
    addSticky: () => {},
    removeSticky: () => {},
    togglePinned: () => {},
    updatePlaceholderRef: () => {},
    updateContentRef: () => {},
    stickyUpdated: () => {},
    stickies: {},
};

const StickyContext = React.createContext(INITIAL_VALUES);
export default StickyContext;
