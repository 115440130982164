import * as React from "react";

type VideoDataType = {
    videoId: string;
    source: string | null;
    title: string | null;
    playing: boolean;
    thumbnailUrl: string | null;
    position?: number;
};

interface MiniPlayerContextInterface {
    video: VideoDataType | null;
    id: string | null;
    url: string | null;
    setVideo: (video: VideoDataType | null) => void;
    setUrl: (url: string) => void;
    setId: (id: string) => void;
}

type Props = {
    children: React.ReactNode;
};

declare global {
    interface Window {
        miniPlayerContext?: React.Context<MiniPlayerContextInterface>;
    }
}

window.miniPlayerContext =
    window.miniPlayerContext ??
    React.createContext<MiniPlayerContextInterface>({
        video: null,
        id: null,
        url: null,
        setVideo: () => undefined,
        setUrl: () => undefined,
        setId: () => undefined,
    });

export const MiniPlayerContext = window.miniPlayerContext;

export const useMiniPlayer = (): MiniPlayerContextInterface => {
    const context = React.useContext(MiniPlayerContext);

    if (!context) {
        throw new Error(`useMiniPlayer must be used within the`);
    }

    return context;
};

export function MiniPlayerContextProvider({children}: Props) {
    const [video, setVideo] = React.useState<any | undefined>();
    const [id, setId] = React.useState<any | undefined>();
    const [url, setUrl] = React.useState<any | undefined>();
    const value = React.useMemo(
        () => ({
            video,
            setVideo,
            id,
            url,
            setUrl,
            setId,
        }),
        [video, setVideo, id, setId, url, setUrl],
    );
    return (
        <MiniPlayerContext.Provider value={value}>{children}</MiniPlayerContext.Provider>
    );
}
