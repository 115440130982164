/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const getDisplayName = (WrappedComponent) =>
    WrappedComponent.displayName || WrappedComponent.name || "Component";

const ThemeContext = React.createContext({});

export function ThemeProvider(props) {
    const {children, themeName, ...contextValue} = props;

    return (
        <ThemeContext.Provider value={{...contextValue, themeName}}>
            {children}
        </ThemeContext.Provider>
    );
}

const selectTheme = (themeSelector, themeName) => {
    if (typeof themeSelector === "function") return themeSelector(themeName);
    if (!themeName) return null;
    return themeSelector[themeName] || null;
};

const getTheme = (themeSelector, themeName, defaultStyles) => {
    const theme = selectTheme(themeSelector, themeName);
    if (theme) return theme;
    return defaultStyles;
};

export const withTheme =
    (themeSelector, defaultStyles, themerOptions = {forwardRef: undefined}) =>
    (WrappedComponent) => {
        const ThemedClass = class extends React.Component {
            static wrappedComponentDisplayName;

            wrappedComponent;

            getWrappedComponent() {
                return this.wrappedComponent;
            }

            handleRef = (ref) => {
                this.wrappedComponent = ref;
            };

            render() {
                const {forwardRef} = themerOptions;
                const {themeName: propsThemeName, theme: propsTheme} = this.props;
                const {themeName: contextThemeName} = this.context;
                const themeName = propsThemeName || contextThemeName;
                const theme =
                    propsTheme || getTheme(themeSelector, themeName, defaultStyles);

                if (forwardRef) {
                    return (
                        <WrappedComponent
                            {...this.props}
                            ref={this.handleRef}
                            theme={theme}
                        />
                    );
                }

                return <WrappedComponent {...this.props} theme={theme} />;
            }
        };

        ThemedClass.contextType = ThemeContext;
        ThemedClass.wrappedComponentDisplayName = getDisplayName(WrappedComponent);
        ThemedClass.displayName = `WithTheme(${ThemedClass.wrappedComponentDisplayName})`;

        return ThemedClass;
    };
