import {put, call, takeLatest, take} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {checkAuth, AccessTokenConstants} from "@atg-shared/auth";
import * as UserApi from "@atg-global-shared/user/userApi";
import * as UserActions from "@atg-global-shared/user/userActions";
import {RECEIVE_BALANCE} from "@atg-global-shared/user/userActionTypes";
import type {UserResponse} from "@atg-global-shared/user/user.types";

import {setAuthServerState} from "atg-member-login/domain/loginActions";
import {AUTH_SERVER_STATE_ERROR} from "atg-member-login/domain/loginConstants";

import * as LoginActions from "./loginActions";
import {loginErrorFlow} from "./loginSaga";

export function* loginCurityFlow(): SagaIterator {
    try {
        const responseUser: UserResponse = yield call(UserApi.fetchUser);
        const responseRoles = yield call(checkAuth);

        if (responseUser.ok && responseRoles.ok) {
            const user = responseUser.data;
            const {roles, memberId} = responseRoles.data;
            const loginData: any = {
                status: "OK",
                user,
                roles,
                memberId,
            };

            // Finish the login flow first so we can make authenticated requests.
            yield put(LoginActions.loginFinished(loginData, user.userId));

            // TODO: this may get stuck. fetchBalance can result in RECEIVE_BALANCE_ERROR, MEMBER_REGISTER_FINISHED or CANCELLED_LOGIN_FLOW too.
            // It is better to call yield call(fetchBalanceFlow, UserActions.fetchBalance()); if needed to wait for the result of a fetch, or at least take[RECEIVE_BALANCE, RECEIVE_BALANCE_ERROR, MEMBER_REGISTER_FINISHED, CANCELLED_LOGIN_FLOW]

            // Call balance to send analytics data.
            yield put(UserActions.fetchBalance());
            yield take(RECEIVE_BALANCE);
        }
    } catch (error: unknown) {
        yield put(setAuthServerState(AUTH_SERVER_STATE_ERROR));
        yield call<typeof loginErrorFlow>(
            loginErrorFlow,
            error as Record<string, any>,
            null,
        );
    }
}

export function* handleLoginCurityFlowError(): SagaIterator {
    yield put(setAuthServerState(AUTH_SERVER_STATE_ERROR));
    yield call<typeof loginErrorFlow>(
        loginErrorFlow,
        {error: "LoginCurityFlowError"},
        null,
    );
}

export default function* curityLoginSaga(): SagaIterator {
    yield takeLatest(AccessTokenConstants.FINISHED_AUTHENTICATION, loginCurityFlow);
    yield takeLatest(
        AccessTokenConstants.AUTHENTICATION_ERROR,
        handleLoginCurityFlowError,
    );
}
