import * as React from "react";
import {ComponentLoader} from "@atg-shared/lazy";

export function LazyFooter() {
    return (
        <ComponentLoader
            loader={import(/* webpackChunkName: "Footer" */ "./Footer")}
            placeholder={null}
        />
    );
}
