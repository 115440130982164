import type {FetchAction} from "@atg-shared/fetch-types";
import {FETCH, call} from "@atg-shared/fetch-redux";
import fetchUnauthorizedLoginAttempt from "./UnauthorizedLoginAlertApi";

export const REQUEST_LATEST_LOGIN_DETAILS = "alert/REQUEST_LATEST_LOGIN_DETAILS";
export const RECEIVE_LATEST_LOGIN_DETAILS = "alert/RECEIVE_LATEST_LOGIN_DETAILS";
export const RESET_LATEST_LOGIN_DETAILS = "alert/RESET_LATEST_LOGIN_DETAILS";

export type UnauthorizedLoginAlertPayload = {
    result: string | null | undefined;
    timestamp: string | null | undefined;
};

export type FetchLatestLoginDetailsAction = FetchAction<
    typeof REQUEST_LATEST_LOGIN_DETAILS,
    typeof RECEIVE_LATEST_LOGIN_DETAILS,
    UnauthorizedLoginAlertPayload
>;

export const fetchLatestLoginDetails = (): FetchLatestLoginDetailsAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_LATEST_LOGIN_DETAILS,
        receiveAction: RECEIVE_LATEST_LOGIN_DETAILS,
        callApi: call(fetchUnauthorizedLoginAttempt),
    },
});

type CloseUnauthorizedLoginAlertAction = {
    type: typeof RESET_LATEST_LOGIN_DETAILS;
};

export const closeUnauthorizedLoginAlertAction =
    (): CloseUnauthorizedLoginAlertAction => ({
        type: RESET_LATEST_LOGIN_DETAILS,
    });

export type Action = FetchLatestLoginDetailsAction | CloseUnauthorizedLoginAlertAction;
