import {useTheme} from "@atg-ui/toolkit/theme";
import {useMediaQuery} from "@atg-ui/toolkit/hooks";
import {device} from "@atg/utils";

export function useNavbarBreakpoints() {
    const theme = useTheme();
    const isLargeDesktop = useMediaQuery(theme.breakpoints.up(1500));
    const isSmallDesktop =
        useMediaQuery(theme.breakpoints.between("md", 1500)) && !device.isTouchDevice();
    const isMobile =
        useMediaQuery(theme.breakpoints.down("md")) || device.isTouchDevice();
    const isSmallMobile =
        useMediaQuery(theme.breakpoints.down(400)) || device.isTouchDevice();

    return {
        isLargeDesktop,
        isSmallDesktop,
        isMobile,
        isSmallMobile,
    };
}
