import * as Redux from "react-redux";
import type {StateWithCustomerJourney} from "@atg-global-shared/welcome-new-customer-data-access/src/slices/welcomeNewCustomerSlice";
import type {WelcomeNewCustomerDispatch} from "../types";

/**
 * Custom hook for typed dispatch and selector, ensuring type safety
 * @link https://redux.js.org/usage/usage-with-typescript#typing-the-useselector-hook
 */
export const useWelcomeNewCustomerDispatch: () => WelcomeNewCustomerDispatch =
    Redux.useDispatch;
export const useWelcomeNewCustomerSelector: Redux.TypedUseSelectorHook<StateWithCustomerJourney> =
    Redux.useSelector;
