import * as React from "react";
import {stickySideMenu} from "./StickySideMenu.styles";
import Navigation from "./Navigation";

type Props = {
    children?: React.ReactNode;
    isSideMenuOpen: boolean;
};

function StickySideMenu({isSideMenuOpen, children}: Props) {
    return isSideMenuOpen ? (
        <Navigation disableFocusTrap data-test-id="sticky-menu" css={stickySideMenu}>
            {children}
        </Navigation>
    ) : null;
}

export default StickySideMenu;
