import * as React from "react";
import ReactDOM from "react-dom";
import * as ReactRedux from "react-redux";
import * as Redux from "redux";
import * as ReduxSaga from "redux-saga";
import * as ReduxSagaEffects from "redux-saga/effects";
// @ts-expect-error
import * as ReduxLogger from "redux-logger";
import * as ReduxPersist from "redux-persist";
import * as ReduxThunk from "redux-thunk";
import * as ReduxActionLog from "redux-action-log";
// eslint-disable-next-line @emotion/no-vanilla
import * as EmotionCss from "@emotion/css";
import * as EmotionReact from "@emotion/react";
import * as EmotionStyled from "@emotion/styled";
import history from "atg-history";

// @ts-expect-error Flow
import * as atgSticky from "@atg-shared/sticky";
import * as AtgFramePush from "@atg-frame-shared/push";
import * as AtgBundleLoader from "@atg-frame-shared/bundle-loader";

declare global {
    interface Window {
        __sharedReact: typeof React;
        __sharedReactDOM: typeof ReactDOM;
        __sharedReactRedux: typeof ReactRedux;
        __sharedRedux: typeof Redux;
        __sharedReduxSaga: typeof ReduxSaga;
        __sharedReduxSagaEffects: typeof ReduxSagaEffects;
        __sharedReduxLogger: typeof ReduxLogger;
        __sharedReduxPersist: typeof ReduxPersist;
        __sharedReduxThunk: typeof ReduxThunk;
        __sharedReduxActionLog: typeof ReduxActionLog;
        __sharedEmotionCss: typeof EmotionCss;
        __sharedEmotionReact: typeof EmotionReact;
        __sharedEmotionStyled: typeof EmotionStyled;
        __sharedAtgHistory: typeof history;
        __sharedAtgSticky: typeof atgSticky;
        __sharedAtgPush: typeof AtgFramePush;
        __sharedAtgBundleLoader: typeof AtgBundleLoader;
    }
}

/* eslint-disable no-underscore-dangle */
// These libraries are shared across all micro frontends mainly to improve bundle size
// (big libraries that all microFEs use).
// This means that the version of each library is owned by the frame, and if you upgrade one of them
// it will affect all microFEs.
// NOTE: the list of libraries below must match the libraries set as "externals" in @atg-shared/micro-frontend/webpack.base.config.js
window.__sharedReact = React;
window.__sharedReactDOM = ReactDOM;
window.__sharedReactRedux = ReactRedux;
window.__sharedRedux = Redux;
window.__sharedReduxSaga = ReduxSaga;
window.__sharedReduxSagaEffects = ReduxSagaEffects;
window.__sharedReduxLogger = ReduxLogger;
window.__sharedReduxPersist = ReduxPersist;
window.__sharedReduxThunk = ReduxThunk;
window.__sharedReduxActionLog = ReduxActionLog;
// TODO: remove this when vanilla Emotion 11 is no longer used by any microFE
window.__sharedEmotionCss = EmotionCss;
window.__sharedEmotionReact = EmotionReact;
window.__sharedEmotionStyled = EmotionStyled;

// the `history` library does not work properly (e.g. URL updates but no navigation happens) if we
// instantiate it several times (e.g. once per microFE), so we create a shared instance in the frame
window.__sharedAtgHistory = history;

window.__sharedAtgSticky = atgSticky;

// atg-push was loaded multiple times which made the global solace client in
// push.ts to be overriden and set to null. Adding it as a shared library
// prevents this from happening.
//
// You must import from the "atg-push" index path for this to work. If you
// import something from a deeper path (e.g. "atg-push/push") then it will be
// loaded as a separate module and not shared, and you will have the issue of
// the client being null as described above.
window.__sharedAtgPush = AtgFramePush;

window.__sharedAtgBundleLoader = AtgBundleLoader;
