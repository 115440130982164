import atgRequest from "@atg-shared/fetch";
import {CUSTOMER_SERVICE_URL, MEMBER_SERVICE_URL} from "@atg-shared/service-url";
import type {
    MemberRegisterVerifyData,
    MemberRegisterCreateData,
} from "./memberRegisterActions";

export const verifyMember = (data: MemberRegisterVerifyData) =>
    atgRequest(`${CUSTOMER_SERVICE_URL}/status`, {
        method: "POST",
        body: JSON.stringify(data),
    });

export const createMember = (data: MemberRegisterCreateData) =>
    atgRequest(MEMBER_SERVICE_URL, {
        method: "POST",
        body: JSON.stringify(data),
    });
