import {createSelector} from "reselect";
import dayjs from "dayjs";
import "dayjs/locale/sv";
import relativeTime from "dayjs/plugin/relativeTime";
import type {RealityCheckState} from "@atg-responsible-gambling-shared/reality-check-types";

dayjs.extend(relativeTime);
dayjs.locale("sv");

type State = {
    realityCheck: RealityCheckState;
};

export const getRealityCheckState = (state: State): RealityCheckState =>
    state && state.realityCheck;

export const getSessionDuration: (state: State) => string = createSelector(
    getRealityCheckState,
    (state: RealityCheckState): string => state && dayjs(state.startTime).fromNow(true),
);

export const showModal: (state: State) => boolean = createSelector(
    getRealityCheckState,
    (state) => state && state.showModal,
);

export const noInterruptMode: (state: State) => boolean = createSelector(
    getRealityCheckState,
    (state) => state && state.noInterruptMode,
);
