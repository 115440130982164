import {isEqual, get} from "lodash";
import {put, select, take, takeLatest, call} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import type {AnyAction} from "redux";
import * as Analytics from "@atg-shared/analytics";
import {MemberActions} from "@atg-global-shared/member-data-access";
import {PurchaseActions} from "@atg-horse-shared/purchase";
import * as ModalSelectors from "atg-modals/modalSelectors";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import type {State as UserState} from "@atg-global-shared/user";
import * as UserAPI from "@atg-global-shared/user/userApi";
import {waitForKycQuestionnaireModalClosed} from "@atg-aml-shared/kyc-domain/src/saga/kycQuestionnaireSaga";
import type {State as ModalsState} from "atg-modals/domain";
import * as ModalActions from "atg-modals/modalActions";

export const needToReviewContactInfo = (state: UserState) =>
    get(state, ["user", "contactInfo", "needVerification"]);
export const isShowTempPasswordChangeModalOpen = (state: ModalsState) =>
    state.modals.showChangeTempPasswordModal;
export const isLoginModalOpen = (state: ModalsState) => state.modals.showLoginModal;
export const isTwoFactorLoginConfirmationModalOpen = (state: ModalsState) =>
    state.modals.showTwoFactorLoginConfirmationModal;
export const isVMiV75ModalOpen = (state: ModalsState) => state.modals.showVMiV75Modal;

export const BET_BUDGET_ALERT_SHOWN_KEY = "bet-budget-alert-shown";

function* postLoginModalFlow(): SagaIterator {
    const showReviewContactModal = yield select(needToReviewContactInfo);
    if (showReviewContactModal) {
        yield put(ModalActions.showReviewContactInformationModal());
        yield take((action: AnyAction) =>
            isEqual(action, ModalActions.closeReviewContactInformationModal()),
        );
    }
}

export function* postLoginFlow(): SagaIterator {
    const loginModalIsOpen = yield select(isLoginModalOpen);
    if (loginModalIsOpen) yield put(ModalActions.closeLoginModal());

    const twoFactorLoginModalIsOpen = yield select(isTwoFactorLoginConfirmationModalOpen);
    if (twoFactorLoginModalIsOpen)
        yield put(ModalActions.closeTwoFactorLoginConfirmationModal());

    const forcePasswordChangeModalIsOpen = yield select(
        isShowTempPasswordChangeModalOpen,
    );
    if (forcePasswordChangeModalIsOpen)
        yield put(ModalActions.closeChangeTempPasswordModal());

    yield take((action: AnyAction) =>
        isEqual(action, ModalActions.closeUserGamblingSummaryModal()),
    );

    // After user gambling summary modal, the kyc modal will pop up, if the user have question to answer
    const kycQuestionnaireModalIsOpen = yield select(
        ModalSelectors.shouldShowKycQuestionnaireModal,
    );
    if (kycQuestionnaireModalIsOpen) {
        yield call(waitForKycQuestionnaireModalClosed);
    }

    const missingContactInfo = yield select(UserSelectors.isMissingContactInfo);

    if (missingContactInfo) {
        // wait for the user gambling summary modal to be closed before opening modal

        yield put(ModalActions.showUpdateContactInformationModal());

        yield call(Analytics.deprecated_logEvent, {
            event: "show_contact_info_update_modal",
        });

        // wait for the contact info modal to be closed before continuing
        yield take((action: AnyAction) =>
            isEqual(action, ModalActions.closeUpdateContactInformationModal()),
        );
        yield call(Analytics.deprecated_logEvent, {
            event: "close_contact_info_update_modal",
        });
    }

    const contactInformationResponse = yield call(
        UserAPI.fetchUserContactOptionsCustomer,
    );
    const contactInformation = contactInformationResponse.data;
    const isProtectedIdentity = yield select(UserSelectors.isProtectedIdentity);

    // protected identity user is forbidden to change their contact info
    if (!isProtectedIdentity && contactInformation.showModal) {
        yield put(ModalActions.showVerifyContactInformationModal());

        const showReviewContactInfoModal = yield select(needToReviewContactInfo);

        if (showReviewContactInfoModal) {
            yield put(ModalActions.showReviewContactInformationModal());
            yield take((action: AnyAction) =>
                isEqual(action, ModalActions.closeReviewContactInformationModal()),
            );
        }
    }
}

function* purchaseFlow(): SagaIterator {
    const action = yield take([
        MemberActions.FINISH_MEMBER_FLOW,
        PurchaseActions.FINISH_PURCHASE_FLOW,
    ]);
    if (action.type !== MemberActions.FINISH_MEMBER_FLOW) return;
    yield call(postLoginModalFlow);
}

function* regularLoginFlow(): SagaIterator {
    const action = yield take([
        MemberActions.CANCELLED_LOGIN_FLOW,
        MemberActions.FINISH_MEMBER_FLOW,
    ]);
    if (action.type === MemberActions.CANCELLED_LOGIN_FLOW) return;
    yield call(postLoginModalFlow);
}

export default function* postLoginSaga() {
    yield takeLatest(PurchaseActions.START_PURCHASE_FLOW, purchaseFlow);
    yield takeLatest(MemberActions.START_LOGIN_FLOW, regularLoginFlow);
    yield takeLatest(MemberActions.FINISH_MEMBER_FLOW, postLoginFlow);
}
