import log from "@atg-shared/log";

export const DROPPING_MESSAGE = "DROPPING_MESSAGE";
export const REFETCH_NEEDED = "REFETCH_NEEDED";
export const PUSH_IN_SYNC = "PUSH_IN_SYNC";

export const checkVersionedPushMessageState = (
    topic: string,
    localVersion: number,
    message: {version: number; previousVersion: number},
) => {
    const {version, previousVersion} = message;
    log.debug("checkVersionedPushMessage: Comparing push versions", {
        topic,
        previousVersion,
        version,
        localVersion,
    });

    if (localVersion >= version) {
        log.debug(
            "checkVersionedPushMessage: Push message version is same or older than local version, dropping the message",
            {topic},
        );
        return DROPPING_MESSAGE;
    }
    if (previousVersion === undefined || localVersion < previousVersion) {
        log.info(
            "checkVersionedPushMessage: The local data is not in sync with push, refetching the data from the server",
            {topic},
        );
        return REFETCH_NEEDED;
    }

    log.debug("checkVersionedPushMessage: We are in sync, applying the update");
    return PUSH_IN_SYNC;
};
