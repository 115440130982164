import * as React from "react";
import { createSvgIcon } from "../utils";

export const SuccessOutlined = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.4a8.6 8.6 0 1 0 0 17.2 8.6 8.6 0 0 0 0-17.2ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm14.952-3.534a.7.7 0 0 1 .082.986l-5.5 6.5a.7.7 0 0 1-1.029.043l-3.5-3.5a.7.7 0 0 1 .99-.99l2.962 2.962 5.009-5.92a.7.7 0 0 1 .986-.081Z"
    />,
    "SuccessOutlined",
);

export default SuccessOutlined;
