import * as React from "react";
import type {Props as SvgIconPropType} from "../components/SvgIcon";
import SvgIcon from "../components/SvgIcon";

export function Ticket(props: SvgIconPropType) {
    return (
        <SvgIcon {...props}>
            <path d="M28 2.5a.75.75 0 01.743.648l.007.102v25.5l-.002.01c-.001.065-.021.125-.038.187-.009.033-.009.069-.023.1-.013.031-.04.056-.057.085-.033.055-.064.111-.11.155l-.006.009c-.028.025-.062.036-.091.056-.048.034-.093.071-.148.092-.054.021-.112.026-.17.034-.036.006-.068.022-.105.022l-.011-.002c-.064-.001-.125-.021-.186-.038-.034-.009-.07-.009-.1-.023-.031-.014-.057-.04-.086-.057-.055-.034-.11-.064-.154-.11l-.005-.003-.004-.004L24 25.594l-3.454 3.669a.75.75 0 01-1.007.078l-.085-.078L16 25.594l-3.454 3.669a.75.75 0 01-1.007.078l-.085-.078L8 25.594l-3.454 3.669c-.003.003-.007.004-.009.007-.044.046-.1.076-.154.11-.03.017-.055.043-.086.057-.031.014-.067.014-.1.023-.04.011-.081.024-.123.031l-.063.007L4 29.5c-.037 0-.069-.016-.106-.022-.057-.008-.114-.013-.169-.034-.056-.021-.1-.058-.148-.092-.03-.02-.064-.031-.091-.056l-.007-.009c-.045-.044-.076-.1-.109-.155-.018-.029-.044-.054-.058-.085-.013-.031-.013-.067-.022-.1-.017-.062-.037-.122-.038-.187l-.001-.005L3.25 3.25a.75.75 0 01.648-.743L4 2.5h24zM27.25 4H4.75v22.858l2.704-2.872a.75.75 0 011.007-.078l.085.078L12 27.655l3.454-3.669a.75.75 0 011.007-.078l.085.078L20 27.655l3.454-3.669a.75.75 0 011.007-.078l.085.078 2.704 2.872V4zM9.998 15.544a1 1 0 110 2 1 1 0 010-2zm12.238.25a.75.75 0 010 1.5H14.78a.75.75 0 110-1.5h7.455zM9.998 10.803a1 1 0 110 2 1 1 0 010-2zm12.25.25a.75.75 0 010 1.5h-7.455a.75.75 0 010-1.5h7.455z" />
        </SvgIcon>
    );
}

export default Ticket;
