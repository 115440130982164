import * as React from "react";

import StickyContext from "../domain/StickyContext";

const StickyConsumer = ({id, children}) => {
    const {stickies} = React.useContext(StickyContext);

    const {isPinned, isSticky, stickyStyle, contentHeight} = stickies[id] || {
        isPinned: false,
        isSticky: false,
        stickyStyle: {},
        contentHeight: 0,
    };

    return children({
        isSticky,
        isPinned,
        stickyStyle,
        contentHeight,
    });
};

export default StickyConsumer;
