import * as React from "react";
import Features, {sessionTimer} from "@atg-shared/client-features";
import {ComponentLoader} from "@atg-shared/lazy";

function LazySessionTimer() {
    if (!Features.isEnabled(sessionTimer)) {
        return null;
    }

    return (
        <ComponentLoader
            loader={import(
                /* webpackChunkName: "SessionTimer" */
                "../internal/SessionTimer"
            )}
        />
    );
}

export default LazySessionTimer;
