import * as React from "react";
import {withTheme} from "atg-theme";
import {cx} from "@emotion/css";
import * as styles from "./LoadingIndicator.styles";
import * as buttonTheme from "./LoadingIndicator-button.styles";
import * as overlayTheme from "./LoadingIndicator-overlay.styles";
import * as overlayLightTheme from "./LoadingIndicator-overlayLight.styles";
import * as scalableTheme from "./LoadingIndicator-scalable.styles";
import * as darkTheme from "./LoadingIndicator-darkTheme.styles";

function LoadingIndicator({className, theme, inverted = false}) {
    return (
        <div data-test-id="loading-indicator" className={cx(theme.root, className)}>
            <div className={inverted ? theme.invertedRect1 : theme.rect1} />
            <div className={inverted ? theme.invertedRect2 : theme.rect2} />
            <div className={inverted ? theme.invertedRect3 : theme.rect3} />
            <div className={inverted ? theme.invertedRect4 : theme.rect4} />
            <div className={inverted ? theme.invertedRect5 : theme.rect5} />
        </div>
    );
}

export function OverlayLoadingIndicator({inverted, theme = overlayTheme}) {
    return (
        <div className={theme.overlay}>
            <LoadingIndicator theme={theme} inverted={inverted} />
        </div>
    );
}

export function ButtonLoadingIndicator({theme = buttonTheme, inverted = true}) {
    return <LoadingIndicator theme={theme} inverted={inverted} />;
}

export function OverlayLightLoadingIndicator() {
    return <OverlayLoadingIndicator theme={overlayLightTheme} />;
}

export function ScalableLoadingIndicator() {
    return <LoadingIndicator theme={scalableTheme} />;
}

export default withTheme(
    {
        darkTheme,
    },
    styles,
)(LoadingIndicator);
