import * as React from "react";
import ReactDOM from "react-dom";
import root from "window-or-global";
import log from "@atg-shared/log";

type Props = {
    children: React.ReactNode;
};

function SideMenuPortal({children}: Props) {
    const portalRoot = root.document.getElementById("react-root");
    if (!portalRoot) {
        log.error("SideMenuPortal invalid mount id");
        return null;
    }

    return ReactDOM.createPortal(<div id="sidemenu">{children}</div>, portalRoot);
}

export default SideMenuPortal;
