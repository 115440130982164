import {noop} from "lodash/fp";
import * as React from "react";
import {ClassNames} from "@emotion/react";

type Props = {
    modalDismiss?: () => unknown;
    onConfirm: () => unknown;
    message: string;
    buttonTextConfirm: string;
    buttonTextCancel?: string;
    danger?: boolean;
};

function ConfirmDialog({
    modalDismiss = noop,
    onConfirm,
    message,
    buttonTextConfirm,
    buttonTextCancel = "Avbryt",
    danger,
}: Props) {
    const confirm = () => {
        onConfirm();
        modalDismiss();
    };

    return (
        <div>
            <p>{message}</p>

            <div className="button-group">
                <ClassNames>
                    {({cx}) => (
                        <button
                            className={cx({
                                button: true,
                                "button--danger": danger,
                                "button--primary": !danger,
                            })}
                            data-test-id="confirm-primary"
                            onClick={confirm}
                        >
                            {buttonTextConfirm}
                        </button>
                    )}
                </ClassNames>
                <button
                    className="button button--secondary"
                    data-test-id="confirm-secondary"
                    onClick={modalDismiss}
                >
                    {buttonTextCancel}
                </button>
            </div>
        </div>
    );
}

export default ConfirmDialog;
