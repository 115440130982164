import {css} from "@emotion/react";

export const rootStyle = css`
    background-color: #d0021a;
    border-radius: 50%;
    color: white !important;
    font-size: 12px;
    height: 19px;
    line-height: 18px;
    position: absolute;
    right: 12px;
    text-align: center;
    top: 7px;
    width: 19px;
`;
