export const REGISTER_USER_ALREADY_MEMBER_STEP = "REGISTER_USER_ALREADY_MEMBER_STEP";
export const REGISTER_USER_VERIFY_STEP = "REGISTER_USER_VERIFY_STEP";
export const REGISTER_USER_CREATE_STEP = "REGISTER_USER_CREATE_STEP";
export const REGISTER_USER_COMPLETED_STEP = "REGISTER_USER_COMPLETED_STEP";

export type RegisterStep =
    | typeof REGISTER_USER_ALREADY_MEMBER_STEP
    | typeof REGISTER_USER_VERIFY_STEP
    | typeof REGISTER_USER_CREATE_STEP
    | typeof REGISTER_USER_COMPLETED_STEP;
