import * as React from "react";
import Features, {abTestOverlay} from "@atg-shared/client-features";
import {ComponentLoader} from "@atg-shared/lazy";

function LazyABTestOverlay() {
    if (!Features.isEnabled(abTestOverlay)) {
        return null;
    }

    return (
        <ComponentLoader
            loader={import(
                /* webpackChunkName: "ABTestOverlay" */
                "./internal/ABTestOverlay"
            )}
        />
    );
}

export default LazyABTestOverlay;
