import * as React from "react";
import {nextFocusableSibling, previousFocusableSibling} from "@atg/utils/focus";
import useForkRef from "../useForkRef";

let focusTrapInstance = 0;

export interface Props<T = HTMLDivElement, D = HTMLDivElement> {
    startRef?: React.Ref<T>;
    endRef?: React.Ref<D>;
    readonly startRefElement?: T; // only used for handling typing
    readonly endRefElement?: D; // only used for handling typing
}

type UseFocusTrapReturn<T, D> = {
    startFocusTrapProps: {
        onFocus: () => void;
        ref: React.Ref<T | HTMLDivElement>;
        tabIndex: 0 | -1;
    };
    endFocusTrapProps: {
        onFocus: () => void;
        ref: React.Ref<D | HTMLDivElement>;
        tabIndex: 0 | -1;
    };
    getFirstFocusElement: () => HTMLElement | null;
    getLastFocusElement: () => HTMLElement | null;
    isFocusOutside: () => boolean;
};

/**
 * Tries to contain focus within two "guard" elements.
 *
 * example usage:
 * ```
 * const {startFocusTrapProps, endFocusTrapProps} = useFocusTrap();
 *
 * return <div>
 *           <div {...startFocusTrapProps} />
 *           <button>one</button>
 *           <button>two</button>
 *           <div {...endFocusTrapProps} />
 * </div>
 * ```
 */
const useFocusTrap: <T = HTMLDivElement, D = HTMLDivElement>(
    props: Props<T, D>,
) => UseFocusTrapReturn<T, D> = (props) => {
    const {
        startRefElement,
        endRefElement,
        startRef: startRefProp,
        endRef: endRefProp,
    } = props || {};

    const focusStartRef = React.useRef<Exclude<typeof startRefElement, undefined>>(null);
    const focusEndRef = React.useRef<Exclude<typeof endRefElement, undefined>>(null);

    const lastKeyDownEvent = React.useRef<KeyboardEvent | null>();
    const lastFocusedElement = React.useRef<Element | null>();
    const currentInstance = React.useRef(0);
    const [isActive, setIsActive] = React.useState(true);

    const parentHasAriaHidden = (parent: HTMLElement | null) => {
        if (parent === null) {
            return false;
        }
        while (parent?.getAttribute("aria-hidden") === null) {
            parent = parent.parentElement;
            if (parent === null) {
                return false;
            }
        }

        return true;
    };

    /**
    This effect solves the focus conflict between the mui-dialog and modal from atg-modals packages.  
     */

    React.useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (
                    mutation.type === "attributes" &&
                    mutation.attributeName === "aria-hidden"
                ) {
                    const parent = focusStartRef.current as unknown as HTMLElement | null;
                    setIsActive(!parentHasAriaHidden(parent));
                }
            });
        });

        observer.observe(document.body, {
            attributes: true,
            subtree: true,
            attributeFilter: ["aria-hidden"],
        });

        return () => {
            observer.disconnect();
        };
    }, []);

    const getFirstFocusElement = React.useCallback(
        () =>
            focusStartRef.current
                ? nextFocusableSibling(focusStartRef.current as unknown as HTMLElement)
                : null,
        [focusStartRef],
    );

    const getLastFocusElement = React.useCallback(
        () =>
            focusEndRef.current
                ? previousFocusableSibling(focusEndRef.current as unknown as HTMLElement)
                : null,
        [focusEndRef],
    );

    const isFocusOutside = React.useCallback(() => {
        const firstEl = focusStartRef.current as unknown as HTMLElement;
        const parent = firstEl?.parentElement;

        return !parent?.contains(document.activeElement);
    }, [focusStartRef]);

    const handleKeyDown = React.useCallback(
        (event: KeyboardEvent) => {
            lastKeyDownEvent.current = event;

            if (event.key === "Tab") {
                if (currentInstance.current !== focusTrapInstance) {
                    return;
                }

                if (isFocusOutside()) {
                    if (event.shiftKey) {
                        getLastFocusElement()?.focus();
                    } else {
                        getFirstFocusElement()?.focus();
                    }
                }
            }
        },
        [getFirstFocusElement, getLastFocusElement, isFocusOutside],
    );

    const handleFirstFocus = () => {
        if (
            lastKeyDownEvent?.current?.key === "Tab" &&
            lastKeyDownEvent?.current?.shiftKey
        ) {
            getLastFocusElement()?.focus();
        }
    };

    const handleLastFocus = () => {
        if (
            lastKeyDownEvent?.current?.key === "Tab" &&
            !lastKeyDownEvent?.current?.shiftKey
        ) {
            getFirstFocusElement()?.focus();
        }
    };

    React.useEffect(() => {
        focusTrapInstance += 1;
        currentInstance.current = focusTrapInstance;

        return () => {
            focusTrapInstance -= 1;
        };
    }, []);

    React.useLayoutEffect(() => {
        lastFocusedElement.current = document.activeElement;

        return () => {
            (lastFocusedElement?.current as HTMLElement)?.focus();
        };
    }, []);

    React.useLayoutEffect(() => {
        getFirstFocusElement()?.focus();
    }, [getFirstFocusElement]);

    React.useEffect(() => {
        if (isActive) {
            window.addEventListener("keydown", handleKeyDown, true);
        } else {
            window.removeEventListener("keydown", handleKeyDown, true);
        }

        return () => {
            window.removeEventListener("keydown", handleKeyDown, true);
        };
    }, [handleKeyDown, isActive]);

    const startRef = useForkRef(startRefProp, focusStartRef);
    const endRef = useForkRef(endRefProp, focusEndRef);

    return {
        /**
         * Props to spread on first element enveloping the focusable elements
         *
         * ```
         * const {startFocusTrapProps, endFocusTrapProps} = useFocusTrap();
         *
         * return <div>
         *           <div {...startFocusTrapProps} />
         *           <button>one</button>
         *           <button>two</button>
         *           <div {...endFocusTrapProps} />
         * </div>
         * ```
         */
        startFocusTrapProps: {
            ref: startRef,
            onFocus: handleFirstFocus,
            tabIndex: isActive ? 0 : -1,
        },
        /**
         * Props to spread on last element enveloping the focusable elements
         *
         * ```
         * const {startFocusTrapProps, endFocusTrapProps} = useFocusTrap();
         *
         * return <div>
         *           <div {...startFocusTrapProps} />
         *           <button>one</button>
         *           <button>two</button>
         *           <div {...endFocusTrapProps} />
         * </div>
         * ```
         */
        endFocusTrapProps: {
            ref: endRef,
            onFocus: handleLastFocus,
            tabIndex: isActive ? 0 : -1,
        },
        /**
         * function that returns the first focusable element inside the trap
         */
        getFirstFocusElement,
        /**
         * function that returns the last focusable element inside the trap
         */
        getLastFocusElement,
        /**
         * function the returns true if focus is outside the trap
         *
         * ```
         * const {isFocusOutside} = useFocusTrap();
         *
         * if (isFocusOutside()) {
         *    console.log("focus is outside");
         * }
         * ```
         */
        isFocusOutside,
    };
};

export default useFocusTrap;
