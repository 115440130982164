/* eslint-disable no-underscore-dangle */
import root from "window-or-global";
import type {FetchLike} from "@curity/identityserver-haapi-web-driver";
import {
    createHaapiFetch,
    InitializationError,
    TimeoutError,
} from "@curity/identityserver-haapi-web-driver";
import log, {serializeError} from "@atg-shared/log";

import fetch from "node-fetch";
import {pureFetch} from "@atg-shared/fetch";

type CurityConfig = {
    authorizationRequest: string;
    haapiClientId: string;
    tokenEndpoint: string;
    codeVerifier: string;
};

type Options = Partial<Request> & {
    data?: Record<string, string> | null;
};

type VerifyCodeCallback = {
    access_token: string;
    expires_in: number;
    id_token: string;
};
/**
 * A class representing a CurityApi
 * using createHaapiFetch to perform HAAPI requests
 */
export default class CurityApi {
    private _rootUri: string;

    private _logoutUri: string;

    private _tokenHandlerURI: string;

    private _config: CurityConfig | Record<string, never>;

    private _haapiFetch: FetchLike | undefined | typeof fetch;

    private _possible: string;

    private _retry: number;

    /** @constructor */
    constructor() {
        /**
         * @type {String} _rootUri - ATG root URI
         */
        this._rootUri = root.clientConfig.curity.uri;
        /**
         * @type {String} _logoutUri - ATG logout URI
         */
        this._logoutUri = root.clientConfig.curity.logoutURI;
        /**
         * @type {String} _tokenHandlerURI - ATG tokenHandler URI
         */
        this._tokenHandlerURI = root.clientConfig.curity.tokenHandlerURI;
        /**
         * @type {Object} _config - `CurityConfig`
         */
        this._config = {};
        /**
         * @type {Object} _haapiFetch - a function similar to fetch used to perform HAAPI requests.
         */
        this._haapiFetch = process.env.NODE_ENV === "test" ? fetch : undefined;
        /**
         * @type {String} _possible - a hash
         */
        this._possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        /**
         * @type {number} _retry - number of retries to create haapiFetch
         * */
        this._retry = 3;
    }

    init = async () => {
        let count = this._retry;
        this._config = await this._buildConfiguration();

        if (!this._haapiFetch) {
            while (count > 0) {
                const fetchConfig = {
                    clientId: this._config.haapiClientId,
                    tokenEndpoint: this._config.tokenEndpoint,
                    timeout: 15,
                };
                try {
                    // eslint-disable-next-line no-await-in-loop
                    this._haapiFetch = await createHaapiFetch(fetchConfig).init();
                    break;
                } catch (err: unknown) {
                    if (count === 1) throw new Error(`${err}`);
                }
                count -= 1;
            }
        }

        const authorizationResponse = await this.getHaapiFetchResponse(
            this._config.authorizationRequest,
        );

        if (
            authorizationResponse instanceof InitializationError ||
            authorizationResponse instanceof TimeoutError
        )
            throw authorizationResponse;

        const authenticateUrl = authorizationResponse.actions[0].model.href;

        const response = await this.getHaapiFetchResponse(authenticateUrl);
        return response;
    };

    private _buildConfiguration = async () => {
        const paramsFromQueryString = new URLSearchParams(window.location.search);
        const isCaptchaRequired = localStorage.getItem("captchaOn");

        const haapiClientId = paramsFromQueryString.get("haapi_client_id") || "atg-haapi";
        const oauthClientId = paramsFromQueryString.get("oauth_client_id") || "atg-haapi";
        const redirectUri =
            paramsFromQueryString.get("redirect_uri") ||
            `${this._tokenHandlerURI}/callback-haapi`;
        const {codeChallenge, codeVerifier} = await this._generateCode();

        const params = new URLSearchParams({
            client_id: oauthClientId,
            redirect_uri: redirectUri,
            response_type: "code",
            scope: "openid general_user betting_user",
            state: "foo",
            code_challenge: codeChallenge,
            code_challenge_method: "S256",
            captchaOn: isCaptchaRequired || "false",
        });

        const config = {
            authorizationRequest: `${this._rootUri}/oauth/authorize?${params}`,
            haapiClientId,
            tokenEndpoint: `${this._rootUri}/oauth/token`,
            codeVerifier,
        };

        return config;
    };

    private _generateCode = async () => {
        let text = "";

        for (let i = 0; i < 64; i++) {
            text += this._possible.charAt(
                Math.floor(Math.random() * this._possible.length),
            );
        }

        const codeVerifier = text;
        const digest = await crypto.subtle.digest(
            "SHA-256",
            new TextEncoder().encode(codeVerifier),
        );
        const codeChallenge = btoa(String.fromCharCode(...new Uint8Array(digest)))
            .replace(/=/g, "")
            .replace(/\+/g, "-")
            .replace(/\//g, "_");

        return {codeVerifier, codeChallenge};
    };

    verifyCode = async (url: string) => {
        const {data: codeToToken} = await pureFetch<VerifyCodeCallback>(
            `${url}&code_verifier=${this._config.codeVerifier}`,
            {credentials: "include"},
        );
        return codeToToken;
    };

    /**
     * Use haapiFetch to access HAAPI resources
     * @param {string} url
     * @param {Object} options
     * @returns HAAPI response
     */
    getHaapiFetchResponse = async (url: string, options: Options = {}) => {
        const {method = "GET", ...other} = options;
        let {data = undefined} = options;
        let body;

        if (method === "GET" || method === "HEAD") {
            if (options.body) {
                const bodyArgs: Record<string, string> = options.body as any;
                data = {...data, ...bodyArgs};
            }

            body = undefined;
            if (data) {
                // send data in query parameters
                const searchParams = new URLSearchParams({...data});
                if (url.includes("?")) {
                    url = `${url}&${searchParams}`;
                } else {
                    url = `${url}?${searchParams}`;
                }
            }
        } else if (options.body) {
            body = options.body;
        } else {
            body = data;
        }

        // @ts-expect-error can be undefined until init is called
        const response = await this._haapiFetch(`${url}`, {
            method,
            // @ts-expect-error Uint8Array
            body,
            keepalive: true,
            ...other,
        })
            .then((res) =>
                res
                    .json()
                    .then((curityData) => ({status: res.status, ...curityData}))
                    .catch((err) => err),
            )
            .catch((err) => {
                log.error("error fetching HAAPI response", {
                    error: serializeError(err),
                });
                return err;
            });

        return response;
    };

    logout = () => pureFetch(this._logoutUri);
}
