import * as React from "react";
import * as Redux from "react-redux";
import * as Analytics from "@atg-shared/analytics";
import * as AuthSelectors from "@atg-shared/auth/domain/authSelectors";
import {ResponsibleGamingHeaderSelectors} from "@atg-responsible-gambling-shared/header-domain";
import {Trans} from "@lingui/macro";
import * as MatchMediaSelectors from "atg-match-media/domain/matchMediaSelectors";
import ATGCheckLogo, {ATG_CHECK_GREEN_BLACK} from "atg-logos/components/AtgCheckLogo";
import * as styles from "./ResponsibleGamingHeader.styles";
import ResponsibleGamingLogos from "./ResponsibleGamingLogos";

type Props = {
    targetUrl?: string;
};

const ResponsibleGamingHeader = React.memo<Props>(({targetUrl = ""}: Props) => {
    const isNormalLogin = Redux.useSelector(AuthSelectors.isNormalLogin);
    const isSmallScreen = Redux.useSelector(MatchMediaSelectors.isSmallScreen);
    const isHijacked = Redux.useSelector(ResponsibleGamingHeaderSelectors.isHijacked);

    return !isHijacked ? (
        <div css={styles.responsibleGaming}>
            {!isSmallScreen && (
                <div css={styles.responsibleGamingATGCheck}>
                    <ATGCheckLogo
                        logoStyle={ATG_CHECK_GREEN_BLACK}
                        style={styles.atgCheckLogo}
                    />
                    <div css={styles.caption}>
                        <Trans>Spela med måtta.</Trans>
                    </div>
                    <a
                        href="https://www.atg.se/atgcheck/spelansvar"
                        target="_blank"
                        data-test-id="responsible-gaming-spelansvar"
                        onClick={() => {
                            Analytics.deprecated_logEvent({
                                responsibleGamingEvent: "spelansvar",
                            });
                        }}
                        rel="noreferrer"
                    >
                        <div css={styles.atgCheckLink}>
                            <Trans>Läs mer om spelansvar</Trans>
                        </div>
                    </a>
                </div>
            )}
            <div css={styles.logos}>
                <ResponsibleGamingLogos
                    targetUrl={targetUrl}
                    shouldGoToInternalSelfTest={isNormalLogin}
                />
            </div>
        </div>
    ) : null;
});

export default ResponsibleGamingHeader;
