import {css} from "@emotion/react";
import {
    responsibleGamingImage as responsibleGamingImageRoot,
    responsibleGamingLink as responsibleGamingLinkRoot,
} from "./ResponsibleGamingLogos.styles";

export const responsibleGamingImage = css`
    ${responsibleGamingImageRoot}
    margin: 0;
`;

export const responsibleGamingLink = css`
    ${responsibleGamingLinkRoot}
    margin-left: 0;
    padding: 6px 0;

    @media screen and (min-width: 340px) {
        margin-left: 2px;
    }

    @media screen and (min-width: 350px) {
        margin-left: 6px;
    }
`;
