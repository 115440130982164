import * as React from "react";
import * as Redux from "react-redux";
import {isEmpty} from "lodash";
import type {CSSInterpolation} from "@emotion/serialize";
import {Collapse} from "@atg-ui/toolkit";
import type {ToastContainerType} from "../domain/toast";
import * as ToastSelectors from "../domain/toastSelectors";
import Toast from "./Toast";
import * as Styles from "./Toasts.styles";

type Props = {
    containerId?: ToastContainerType;
    style?: CSSInterpolation;
};

function Toasts({containerId, style}: Props) {
    const toasts = Redux.useSelector(
        ToastSelectors.getFilteredActiveToastsByContainerId(containerId),
    );

    return (
        <Collapse appear unmountOnExit in={!isEmpty(toasts)}>
            <Styles.Container css={style}>
                {toasts.map((toast) => (
                    <Toast key={toast.id} toast={toast} />
                ))}
            </Styles.Container>
        </Collapse>
    );
}

export default Toasts;
