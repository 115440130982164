import {createSelector} from "reselect";
import * as LoginTypes from "@atg-login-shared/types";

// todo: add a new state for recovery
export type State = {
    loginHAAPI: {
        authenticatorSelector: {
            authMode: LoginTypes.AuthMode;
        };
        curityState: LoginTypes.CurityState;
        curityResponse: LoginTypes.CurityRootModel;
        authenticators: LoginTypes.Actions;
        authContext: LoginTypes.AuthenticatorTypes;
        error: LoginTypes.CurityError | null;
        authMethod: LoginTypes.AuthMethod;
        loginCredentialsRequestStatus: LoginTypes.LoginCredentialsRequestStatus;
        recovery: LoginTypes.RecoveryState;
    };
};

export const getAuthMode = (state: State) =>
    state.loginHAAPI.authenticatorSelector.authMode;

export const selectCurityResponse = (state: State) => state.loginHAAPI.curityResponse;

export const selectCurityAuthenticators = (state: State) =>
    state.loginHAAPI.authenticators;

export const selectAuthContext = (state: State): LoginTypes.AuthenticatorTypes =>
    state.loginHAAPI.authContext;

export const selectRecoveryMethod = (state: State): LoginTypes.RecoveryState =>
    state.loginHAAPI.recovery;

export const selectError = (state: State) => state.loginHAAPI.error;

export const selectStoredAuthMethod = (state: State) => state.loginHAAPI.authMethod;

export const selectLoginCredentialsRequestStatus = (state: State) =>
    state.loginHAAPI.loginCredentialsRequestStatus.pending;

export const getHideBalanceLoginFlow = createSelector(
    selectStoredAuthMethod,
    (authMethod) => authMethod.hideBalance,
);
export const selectCurityActions = createSelector(selectCurityResponse, (response) => {
    if (response?.actions) {
        return response.actions;
    }
    return [];
});

export const getCurityState = (state: State): LoginTypes.CurityState =>
    state.loginHAAPI.curityState;

export const getErrorMessage = createSelector(
    selectCurityResponse,
    (error) => error?.messages?.[0].text,
);

export const selectInitMethod = (method: LoginTypes.InitMethods) =>
    createSelector(selectCurityAuthenticators, (option) => {
        switch (method) {
            case LoginTypes.ActionTypes.INIT_BANK_ID_QR:
                return option[0];
            case LoginTypes.ActionTypes.CURITY_INIT_UNPW:
            case LoginTypes.ActionTypes.CURITY_SUBMIT_UNPW:
            case LoginTypes.ActionTypes.RESET_PASSWORD:
                return option[1];
            case LoginTypes.ActionTypes.INIT_BANK_ID:
                return option[2];
            case LoginTypes.ActionTypes.INIT_FREJA_ID_QR:
                return option[3];
            case LoginTypes.ActionTypes.INIT_FREJA_ID:
                return option[4];
            default:
                return "";
        }
    });

export const selectAuthenticatorType = (method: LoginTypes.InitMethods) =>
    createSelector(selectCurityAuthenticators, () => {
        switch (method) {
            case LoginTypes.ActionTypes.INIT_BANK_ID:
            case LoginTypes.ActionTypes.INIT_BANK_ID_QR:
                return LoginTypes.CurityState.BANKID;
            case LoginTypes.ActionTypes.CURITY_INIT_UNPW:
                return LoginTypes.CurityState.ATG_CAPTCHA;
            case LoginTypes.ActionTypes.INIT_FREJA_ID_QR:
            case LoginTypes.ActionTypes.INIT_FREJA_ID:
                return "freja-eid";
            default:
                return "";
        }
    });

export const selectPreferredAuthMethod = (method: LoginTypes.InitMethods) =>
    createSelector(selectCurityAuthenticators, () => {
        switch (method) {
            case LoginTypes.ActionTypes.INIT_BANK_ID:
                return "auth-starter";
            case LoginTypes.ActionTypes.INIT_BANK_ID_QR:
                return "bankid";
            case LoginTypes.ActionTypes.CURITY_INIT_UNPW:
                return "atg-captcha";
            case LoginTypes.ActionTypes.INIT_FREJA_ID_QR:
            case LoginTypes.ActionTypes.INIT_FREJA_ID:
                return "freja-eid";
            default:
                return "authenticator-selector";
        }
    });

export const selectAuthenticatorModel = createSelector(
    selectCurityResponse,
    getCurityState,
    (response, state) => {
        if (!response.actions) return {};
        switch (state) {
            case LoginTypes.CurityState.BANKID_OTHER_DEVICE:
                return response.actions[1].model;
            default:
                return response.actions[0].model;
        }
    },
);

export const selectCurityMessages = createSelector(
    selectCurityResponse,
    (response) => response.messages,
);

export const selectCurityLinks = createSelector(
    selectCurityResponse,
    (response) => response?.links,
);
