import {createSelector} from "reselect";
import type {State} from "./responsibleGamingHeaderReducer";

export type GlobalState = {
    responsibleGamingHeader?: State;
};

const getState = (state: GlobalState): State | null | undefined =>
    state && state.responsibleGamingHeader;

export const getNumHijackers: (arg0: GlobalState) => number = createSelector(
    getState,
    (state) => (state && state.hijackers) || 0,
);

export const isHijacked: (arg0: GlobalState) => boolean = createSelector(
    getNumHijackers,
    (numHijackers) => numHijackers > 0,
);
