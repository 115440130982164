/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import {reduce} from "lodash";
import {isDevice} from "@atg/utils/device";
import {sideNavigationWidth} from "atg-ui/css/variables.styles";
import * as styles from "./SwipeTransition.styles";

const MAX_SCROLL_DIRECTION_POINTS = 5;

export const getScrollDirection = (touchesArray) => {
    if (touchesArray.length === 1) return "horizontal";

    const pairsDistance = reduce(
        touchesArray,
        (acc, current, index) => {
            if (index === touchesArray.length - 1) return acc;

            const nextItem = touchesArray[index + 1];
            const xPairDistance = Math.abs(current.x - nextItem.x);
            const yPairDistance = Math.abs(current.y - nextItem.y);

            return {
                x: acc.x + xPairDistance,
                y: acc.y + yPairDistance,
            };
        },
        {x: 0, y: 0},
    );

    return pairsDistance.x >= pairsDistance.y ? "horizontal" : "vertical";
};

export class SwipeTransition extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            startX: 0,
            xPosition: 0,
            lockScroll: false,
            scrollDirection: null,
        };

        this.swipeTouches = [];

        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.excludeGivenProp = this.excludeGivenProp.bind(this);

        // Get a reference to the current DOM node
        this.currentDOMElem = React.createRef();
    }

    onTouchStart(touch) {
        if (!touch || !touch.changedTouches || !touch.changedTouches[0]) return;

        this.swipeTouches.push({
            x: touch.changedTouches[0].pageX,
            y: touch.changedTouches[0].pageY,
        });
        this.setState({
            startX: touch.changedTouches[0].pageX,
        });
    }

    onTouchMove(touch) {
        if (!touch || !touch.changedTouches || !touch.changedTouches[0]) return;

        const touchedItem = touch.changedTouches[0];

        const {startX} = this.state;
        const {direction} = this.props;

        let endX = touchedItem.pageX;
        if (this.swipeTouches.length < MAX_SCROLL_DIRECTION_POINTS) {
            this.swipeTouches.push({x: touchedItem.pageX, y: touchedItem.pageY});
        }

        let {scrollDirection} = this.state;
        if (
            scrollDirection === null ||
            this.swipeTouches.length < MAX_SCROLL_DIRECTION_POINTS
        ) {
            scrollDirection = getScrollDirection(this.swipeTouches);
        }

        const horizontalSwipeDistance = endX - startX;

        if (scrollDirection === "vertical") {
            this.setState({
                lockScroll: false,
                xPosition: 0,
                scrollDirection: "vertical",
            });
            return;
        }

        // Horizontal scroll
        if (scrollDirection === "horizontal") {
            this.setState({
                lockScroll: true,
                scrollDirection: "horizontal",
            });
        }

        if (direction === "left") {
            if (startX < endX) {
                endX = 0;
            } else {
                endX = horizontalSwipeDistance; // Set position to swiped position
            }
        } else if (endX - startX < 0) {
            endX = 0;
        } else {
            endX = horizontalSwipeDistance;
        }

        this.setState({
            xPosition: endX,
        });
    }

    onTouchEnd() {
        const {xPosition} = this.state;
        if (sideNavigationWidth - Math.abs(xPosition) < sideNavigationWidth * 0.6) {
            // Triggered when less than 60% of element swiped
            this.props.onSwipeFinished();
        }
        this.swipeTouches = [];
        this.setState({
            xPosition: 0,
            lockScroll: false,
            scrollDirection: null,
        });
    }

    excludeGivenProp(propName, restProps) {
        return (
            Object.keys(restProps)
                // Where propName is "onSwipeFinished"
                .filter((key) => key !== propName)
                .reduce((acc, key) => {
                    acc[key] = restProps[key];
                    return acc;
                }, {})
        );
    }

    render() {
        const {direction, children, ...other} = this.props;
        const {xPosition, startX, lockScroll} = this.state;
        const rest = this.excludeGivenProp("onSwipeFinished", other);

        const style = (() => {
            if (direction === "left")
                return styles.overlaySideMenu(xPosition, lockScroll);
            if (direction === "right")
                return styles.overlaySideMenuRight(xPosition, lockScroll);
            return undefined;
        })();

        return (
            <div
                {...rest}
                css={style}
                onTouchStart={isDevice() ? this.onTouchStart : null}
                onTouchMove={isDevice() ? this.onTouchMove : null}
                onTouchEnd={isDevice() ? this.onTouchEnd : null}
                direction={direction}
                data-test-value={`xPosition-${xPosition}__startPosition-${startX}`}
                data-test-id="swipe-transition-container"
                ref={this.currentDOMElem}
            >
                {children}
            </div>
        );
    }
}

export default SwipeTransition;
