import {broadcastAction, frameAction} from "atg-store-addons";

export const OPEN_SUBMENU = "mainNavbar/OPEN_SUBMENU";
export const CLOSE_SUBMENU = "mainNavbar/CLOSE_SUBMENU";
export const SET_ACTIVE_LINK = "mainNavbar/SET_ACTIVE_LINK";
export const CHECK_SHOP_MATCH_REQUEST = "mainNavbar/CHECK_SHOP_MATCH_REQUEST";
export const CHECK_SHOP_MATCH_SUCCESS = "mainNavbar/CHECK_SHOP_MATCH_SUCCESS";
export const CHECK_SHOP_MATCH_FAILURE = "mainNavbar/CHECK_SHOP_MATCH_FAILURE";
export const CHECK_SHOP_MATCH_RESET = "mainNavbar/CHECK_SHOP_MATCH_RESET";

export const openSubmenu = () => frameAction(broadcastAction({type: OPEN_SUBMENU}));

export const closeSubmenu = () =>
    frameAction(
        broadcastAction({
            type: CLOSE_SUBMENU,
        }),
    );
